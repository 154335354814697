import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { setStatusBarEmail } from 'src/app/shared/user/ngrx/user.actions';
import { AccountStatusMessage } from 'src/app/shared/Models/models';
import { State } from 'src/app/reducers';
import { Store } from '@ngrx/store';
import { User } from 'src/app/shared/Models/user';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe],
})
export class StatusBarComponent implements OnChanges {
  @Input() status: Partial<AccountStatusMessage>;
  @Input() user: User;
  @Input() isEmail: boolean;
  @Input() isMobile: boolean;

  constructor(
    private utils: UtilsService,
    private router: Router,
    private store: Store<State>,
  ) {}

  ngOnChanges(): void {
    if (this.status) {
      this.store.dispatch(setStatusBarEmail({ data: !!this.status.email }));
    }
  }

  statusAction(link) {
    if (link === void 0) return undefined;
    if (link.route !== void 0)
      return this.router.navigate(link.route, { queryParams: link.query });
    this.utils.getNativeWindow().open(link, '_self');
    return true;
  }
}
