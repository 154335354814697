<ingredients
  [dish]="dish"
  [ingredientsAuto]="ingredientsAuto"
  [lang]="lang"
  [recipe]="recipe"
  [translations]="translations"
  (addIngredientToRecipe)="addIngredientToRecipe.emit($event)"
  (clearIngredientsAuto)="clearIngredientsAuto.emit()"
  (closeInfo)="clearIngredientInfo.emit()"
  (fetchIngredientsAuto)="fetchIngredientsAuto.emit($event)"
  (deleteDishRecipeIngredientEvent)="
    deleteDishRecipeIngredientEvent.emit($event)
  "
  (createNewIngredientEvent)="createNewIngredientEvent.emit($event)"
  (addMultipleIngredientsToRecipeEvent)="
    addMultipleIngredientsToRecipeEvent.emit($event)
  "
  (getInfo)="getInfo($event)"
  (refetch)="refetch.emit()"
></ingredients>
<ingredient-info
  [ingredient]="ingredientInfo"
  [lang]="lang"
  [recipe]="recipe"
  (clearIngredientInfo)="clearIngredientInfo.emit()"
  (updateIngredient)="patchIngredient($event)"
></ingredient-info>
