import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'regenerate-menu',
  templateUrl: './regenerate-menu.component.html',
  styleUrls: [
    `../regenerate-menu/regenerate-menu.component.scss`,
    `./regenerate-menu.component.scss`,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButtonModule, TranslocoPipe],
})
export class RegenerateMenuComponent {
  @Input() regenerateContent: string;
  @Input() regenerateTitle: string;
  @Output() cancelAction = new EventEmitter<void>();
  @Output() regenerateMenusActions = new EventEmitter<void>();

  constructor() {}
}
