import { Separator } from 'src/app/shared/Models/separator';

import {
  Dish,
  DishCategory,
  ItemCategory,
  SeparatorCategory,
  Variant,
} from './dish';
import { BaseDescriptionExtendedSecondary, BaseNameExtended } from './models';
import { DeepPartial } from './generics';

export type MenuDishLevel = 1 | 2 | 3 | 4;

export interface ChangeDishOptions {
  onlyName?: boolean;
  condensed?: boolean;
  edit?: boolean;
  current_menu?: number;
}

export interface ItemRequestParams {
  condensed?: boolean;
  edit?: boolean;
  current_menu?: number;
}

export interface MenuDishRequestParams {
  current_menu?: number;
}

export class UserDetails extends BaseDescriptionExtendedSecondary {
  wine: string;
  pagebreak: boolean;
  empty_page: boolean;
  spacer: boolean;
  highlight: boolean;
  display_options: number;
  ordertaking_options: number;
  auto_translated: string[];
  translation_disabled_description: boolean;
  translation_disabled_description_secondary: boolean;
  background?: number;
  background_detail?: any;
  price: number;
  image: string;
  image_small: string;
}

export class MenuDishes {
  is_loading: boolean;
  dishes: { list: (SimpleMenuDish | MenuDish)[]; search: boolean };
}

export class SimpleMenuDish {
  url: string;
  id: number;
  level: MenuDishLevel = 1;
  order: number;
  numbering: number;
  date: string | null;
  separator: number | null;
  separator_detail: Separator | null;
  dish: number | null;
  dish_detail: Dish | null;

  index?: number;
  deleted?: boolean;
  removeLevel?: boolean;
  disabled?: boolean;
  current_date?: string;

  setSeparatorId(separator: number, category: SeparatorCategory): this {
    this.separator = separator;
    if (!this.separator_detail)
      this.separator_detail = new Separator(null, category);
    if (category) this.separator_detail.category = category;
    return this;
  }

  setSeparatorLevel(level: MenuDishLevel): this {
    this.level = level;
    this.separator_detail.level = level;
    return this;
  }

  setSeparator(separator: Separator): this {
    this.separator_detail = separator;
    this.separator = separator.id;
    return this;
  }

  setDishId(dish: number, category?: DishCategory): this {
    this.dish = dish;
    if (!this.dish_detail) this.dish_detail = new Dish(null, category);
    if (category) this.dish_detail.category = category;
    return this;
  }

  setDish(dish: Dish): this {
    this.dish_detail = dish;
    this.dish = dish.id;
    return this;
  }

  setOrder(dishes): this {
    this.order = dishes.findIndex((item) => item === this) + 1;
    if (!this.order) this.order = dishes.length + 1;
    return this;
  }

  setCategory(category: ItemCategory): void {
    if (!this.separator_detail && !this.dish_detail) {
      if (['dow', 'sec', 'cou', 'opt'].includes(category)) {
        this.separator_detail = new Separator(
          null,
          category as SeparatorCategory,
        );
      } else {
        this.dish_detail = new Dish(null, category as DishCategory);
      }
    } else {
      this[this.dish_detail ? 'dish_detail' : 'separator_detail'].category =
        category as DishCategory | SeparatorCategory;
    }
  }
}

export class MenuDish extends SimpleMenuDish {
  menu?: number;
  has_recipes?: boolean;
  user_details: Partial<UserDetails>;
  variants?: Variant[];

  constructor(menuDish?: DeepPartial<MenuDish>, setTempId = false) {
    super();
    if (menuDish) Object.assign(this, menuDish);
    if (!this.user_details) this.user_details = new UserDetails();
    if (setTempId) this.id = Date.now() + Math.floor(Math.random() * 100);
  }
}

export interface MenuDishParams extends BaseNameExtended {
  category: ItemCategory;
  condensed: true;
  empty: boolean;
  search: string;
  type: 'dish' | 'separator';
}
