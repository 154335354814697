import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { SwiperDirective } from 'src/app/shared/Directives/swiper/swiper.directive';
import { Menu, PatchStyleNameData, Style } from 'src/app/shared/Models/menu';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { SwiperOptions } from 'swiper/types';
import { SelectStyleComponent } from '../select-style/select-style.component';
import { SwiperDirective as SwiperDirective_1 } from '../../../../../shared/Directives/swiper/swiper.directive';

export abstract class Updatable {
  abstract update(): void;
}

@Component({
  selector: 'styles-carousel',
  templateUrl: './styles-carousel.component.html',
  styleUrls: ['./styles-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: Updatable,
      useExisting: forwardRef(() => StylesCarouselComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [SwiperDirective_1, SelectStyleComponent],
})
export class StylesCarouselComponent
  extends Updatable
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() canModify: boolean;
  @Input() lang: InterfaceLanguage;
  @Input() menu: Menu;
  @Input() styles: Style[];
  @Input() styleModified: boolean;
  @Input() styleSpinner: string;

  @Output() createStyle = new EventEmitter();
  @Output() deleteStyle = new EventEmitter<Style>();
  @Output() openDialog = new EventEmitter<Style>();
  @Output() renameStyle = new EventEmitter<{
    style: Style;
    value: PatchStyleNameData;
  }>();
  @Output() selectStyle = new EventEmitter<Style>();
  @Output() updateStyle = new EventEmitter();

  private destroyed$ = new Subject<void>();
  swiperConfig: SwiperOptions = {
    observer: false,
    observeParents: false,
  };

  @ViewChild(SwiperDirective, { static: false })
  swiper: SwiperDirective;

  constructor(private utils: UtilsService) {
    super();
  }

  ngAfterViewInit() {
    this.utils.navigationCollapsed
      .pipe(skip(1), takeUntil(this.destroyed$))
      .subscribe(() => this.update());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.styles && ('styles' in changes || 'styleModified' in changes)) {
      this.update();
    }
  }

  swiperOnInit(slider: SwiperDirective) {
    slider.update();
  }

  update(): void {
    if (this.swiper) this.swiper.update();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
