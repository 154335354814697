import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import {
  acceptInvitation,
  checkInvitationKey,
} from 'src/app/auth/ngrx/auth.actions';
import {
  selectInvitee,
  selectInviteeSpinnerState,
} from 'src/app/auth/ngrx/auth.selectors';
import { State } from 'src/app/reducers';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { AcceptInvitationForm } from 'src/app/shared/Models/authentication';
import { Invitee } from 'src/app/shared/Models/invitee';
import { Language } from 'src/app/shared/Models/models';
import { setInterfaceLang } from 'src/app/shared/ngrx/shared.actions';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { SpinnerComponent } from '../../../shared/Components/spinner/spinner.component';
import { FooterComponent } from '../../../shared/Components/footer/footer.component';
import { AcceptInvitationComponent } from '../../Components/accept-invitation/accept-invitation.component';
import { MatButtonModule } from '@angular/material/button';
import { LanguageSelectorComponent } from '../../../shared/Components/language-selector/language-selector.component';

@Component({
  selector: 'invitation-container',
  templateUrl: './accept-invitation.container.html',
  styleUrls: [
    '../registration/registration.container.scss',
    './accept-invitation.container.scss',
  ],
  standalone: true,
  imports: [
    LanguageSelectorComponent,
    MatButtonModule,
    RouterLink,
    AcceptInvitationComponent,
    FooterComponent,
    SpinnerComponent,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class AcceptInvitationContainer implements OnDestroy {
  language: { current: InterfaceLanguage } = { current: 'en' };
  logo = null;
  name = '';
  organisation = '';
  private destroyed$ = new Subject<void>();

  invitee$ = this.ngrxStore.select(selectInvitee);
  spinner$ = this.ngrxStore.select(selectInviteeSpinnerState);

  constructor(
    private ngrxStore: Store<State>,
    private router: ActivatedRoute,
    private translate: TranslocoService,
  ) {
    this.invitee$
      .pipe(
        filter((inv) => !!inv),
        takeUntil(this.destroyed$),
      )
      .subscribe((inv: Invitee) => {
        this.name = `${inv.invitation.inviter.first_name} ${inv.invitation.inviter.last_name}`;
        this.organisation = inv.invitation.organisation.name;
        this.logo = inv.invitation.organisation.logo;
      });
    this.router.paramMap
      .pipe(takeUntil(this.destroyed$))
      .subscribe((v) => this.handleKey(v.get('key')));
    this.translate.langChanges$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (el) =>
          (this.language.current =
            translate.getActiveLang() as InterfaceLanguage),
      );
    this.translate.getActiveLang() &&
      (this.language.current = translate.getActiveLang() as InterfaceLanguage);
    this.router.queryParams
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.handleLang);
  }

  handleLang = ({ lang }: { lang: InterfaceLanguage }) => {
    if (lang) {
      this.language.current = Language(lang);
      this.translate.setActiveLang(Language(lang));
      this.ngrxStore.dispatch(setInterfaceLang({ lang: Language(lang) }));
    }
  };

  changeLanguage() {
    this.translate.setActiveLang(this.language.current);
  }

  submitData(data: AcceptInvitationForm) {
    this.ngrxStore.dispatch(acceptInvitation({ data }));
  }

  handleKey(key: string) {
    this.ngrxStore.dispatch(checkInvitationKey({ key }));
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
