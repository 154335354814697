import { createAction, props } from '@ngrx/store';
import { DeepPartial } from 'src/app/shared/Models/generics';
import {
  CopyAsOptions,
  CopyMenuOptions,
  Menu,
  MenusOverviewParams,
  MenusResults,
  OverviewMenu,
  ShareMenuDetail,
} from 'src/app/shared/Models/menu';

export const fetchMenus = createAction(
  `[Menus overview container] Fetch menus`,
  props<{ query: Partial<MenusOverviewParams>; url?: string }>(),
);

export const setTableDataLoading = createAction(
  `[Menus overview container] Set Table data loading`,
  props<{ loading: boolean }>(),
);

export const setMenus = createAction(
  `[Menus overview container] Set Menus`,
  props<{ menus: MenusResults }>(),
);

export const changeMenu = createAction(
  `[Menus overview container] Change menu`,
  props<{ menu: OverviewMenu | Menu; data: DeepPartial<Menu> }>(),
);

export const setUpdatedMenu = createAction(
  `[Menus overview container] Set updated menu locally`,
  props<{ menu: Menu }>(),
);

export const duplicateMenu = createAction(
  `[Menus overview container] Duplicate menu`,
  props<{
    id: number;
    date?: string;
    diets?: number[];
    rule?: number;
    options?: Partial<CopyMenuOptions | CopyAsOptions>;
    redirectStep: 1 | 2 | 3 | 4;
    consumerType?: number;
  }>(),
);

export const deleteChildren = createAction(
  `[Menus overview container] Delete children`,
  props<{ id: number; query?: Partial<MenusOverviewParams> }>(),
);

export const deleteMenu = createAction(
  `[Menus overview container] Delete menu`,
  props<{ id: number; query?: Partial<MenusOverviewParams> }>(),
);

export const regenerateMenus = createAction(
  `[Menus overview container] Regenerate menus`,
  props<{ menu: OverviewMenu; query?: Partial<MenusOverviewParams> }>(),
);

export const refreshSubMenu = createAction(
  `[Menus overview container] Refresh subMenu`,
  props<{ menu: OverviewMenu }>(),
);

export const shareMenu = createAction(
  `[Menus overview container] Share menu`,
  props<{
    url: string;
    shareMenuData: ShareMenuDetail;
    query?: Partial<MenusOverviewParams>;
  }>(),
);

export const clearMenus = createAction(
  `[Menus overview container] Clear Menus`,
);
