import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import {
  Additive,
  Allergen,
  GenericDeclaration,
  Label,
} from 'src/app/shared/Models/declarations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@Component({
  selector: 'option-item',
  templateUrl: './option-item.component.html',
  styles: [
    `
      :host {
        display: block;
      }
      .allergen-symbol {
        display: inline-block;
        position: relative;
        top: 2px;
      }
    `,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatCheckboxModule, ReactiveFormsModule, FormsModule],
})
export class OptionItemComponent implements OnChanges {
  @Input() color = 'primary';
  @Input() disabled: boolean;
  @Input() items: Allergen[] | Additive[] | Label[];
  @Input() lang: ContentLanguage;
  @Input() mayAffect: any;
  @Input() option: GenericDeclaration;
  @Input() type: string;
  @Input() showConfirmDialog: boolean;

  @Output() triggerOption = new EventEmitter<GenericDeclaration>();
  @Output() showDialog = new EventEmitter<{
    declaration: GenericDeclaration;
    checkbox: MatCheckbox;
  }>();

  public symbolsData:
    | {
        app_symbol: string;
        symbol: string;
        legend_symbol: string;
        name: string;
      }
    | {};
  public classForSymbols: string;

  constructor() {
    this.symbolsData = {};
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('type' in changes && this.type) {
      this.classForSymbols = `${
        this.type !== 'additives' ? 'allergen' : this.type
      }-symbol`;
    }
    if ('items' in changes && this.items) {
      this.items.forEach((el) => {
        this.symbolsData[el.code] = {
          app_symbol: el.app_symbol,
          symbol: el.symbol,
          legend_symbol: el.legend_symbol,
          name: el[this.lang],
        };
      });
    }
  }

  confirmAssign(option: GenericDeclaration, checkbox: MatCheckbox) {
    checkbox.checked = !this.option.value;
    this.showDialog.emit({ declaration: option, checkbox });
  }
}
