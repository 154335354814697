@if (menus$ && isMobileView) {
<button
  mat-flat-button
  color="primary"
  class="add-entity-button mobile-customise-button"
  (click)="showSidePanel()"
>
  {{ "menus.overview.filter_menus" | transloco }}
</button>
}

<menu-overview-table
  class="menu-overview-table"
  [consumerTypes]="consumerTypes$ | async"
  [currentMenu]="currentMenu"
  [diets]="diets$ | async"
  [menus]="menus$ | async"
  [loadingSpinner]="isTableDataLoading$ | async"
  [params]="params"
  [privileges]="privileges$ | async"
  [locations]="locations$ | async"
  [rulesets]="rulesets$ | async"
  [user]="user$ | async"
  [userStatus]="userStatus$ | async"
  [userStatusBlockMessage]="userStatusBlockMessage$ | async"
  (changeMenu)="changeMenu($event)"
  (changePagination)="changePagination($event)"
  (copyWithDiets)="copyAsDiet($event)"
  (copyWithOptions)="copyWithOptions($event)"
  (createBtnClicked)="createBtnClicked($event)"
  (deleteChildren)="deleteChildren($event)"
  (deleteMenu)="deleteMenu($event)"
  (createMenu)="createChildMenu($event)"
  (generateArchive)="generateArchive($event)"
  (openCreateMenu)="openCreateMenu()"
  (previewMenu)="previewMenu($event)"
  (regenerateMenus)="regenerateMenus($event)"
  (showCopyMenu)="showCopyMenu($event)"
  (shareMenu)="shareMenu($event)"
  (refreshSubMenu)="refreshSubMenu($event)"
  (showSnackbarMessageEvent)="showMessage($event)"
></menu-overview-table>

<ng-template #sidePanel>
  @if (isMobileView) {
  <return-side-panel-mobile
    class="return-side-panel"
    [text]="'menus.overview.go-back' | transloco"
    (click)="hideSidePanel()"
  ></return-side-panel-mobile>
  }
  <menu-overview-sidebar
    [actionName]="selectedAction"
    [allergens]="allergens$ | async"
    [currentLocation]="currentLocation"
    [deleteMenuWarning]="deleteMenuWarning"
    [deleteChildrenList]="deleteChildrenList"
    [diets]="diets$ | async"
    [hasModuleAllergens]="hasModuleAllergens"
    [hasModuleOrders]="hasModuleOrders"
    [lang]="lang"
    [languages]="(menus$ | async)?.languages"
    [locations]="locations$ | async"
    [menu]="currentMenu"
    [menuDate]="menuDate"
    [menuName]="menuName"
    [organisationUsers]="organisationUsers$ | async"
    [params]="params"
    [showMenuFilter]="showMenuFilter"
    [templates]="usedTemplates$ | async"
    [user]="user$ | async"
    (cancelAction)="cancelAction()"
    (chooseFilter)="chooseFilter($event)"
    (createNewDiet)="createNewDiet($event)"
    (createArchive)="createArchive($event)"
    (deleteChildrenMenu)="deleteChildrenMenu()"
    (deleteSelectedMenu)="deleteSelectedMenu()"
    (copyMenuWithOptions)="copyMenuWithOptions($event)"
    (regenerateMenusActions)="regenerateMenusActions()"
    (refreshSubMenuActions)="refreshSubMenuActions()"
    (copyAsAction)="copyAsAction($event)"
    (shareMenuAction)="shareMenuAction($event)"
  >
  </menu-overview-sidebar>
</ng-template>

@if (showSpinner) {
<div class="spinner-container">
  <spinner class="centered-spinner"></spinner>
</div>
}

<mat-form-field class="hidden">
  <input matInput [matDatepicker]="picker" (dateChange)="copyMenu($event)" />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker touchUi="true"></mat-datepicker>
</mat-form-field>

@if ((isAdministrator$ | async) && (locations$ | async)?.length) {
<view-as-location
  [locations]="locations$ | async"
  (locationChanged)="locationChanged($event)"
></view-as-location>
}
