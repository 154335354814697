<div [formGroup]="form">
  <mat-checkbox
    class="checkbox"
    color="primary"
    [formControlName]="field"
    [name]="field"
  >
    {{
      "write.blocks.dish-overview.other-tab.information.spacing.options." +
        field | transloco
    }}
  </mat-checkbox>
</div>
