import { createSelector } from '@ngrx/store';
import { State } from 'src/app/reducers';

import {
  adapter,
  adapterFull,
  menuWriteFeatureKey,
} from './menu-write.reducer';
import { menusFeatureKey } from 'src/app/menus/ngrx/menus.store';

const selectState = (state: State) =>
  state[menusFeatureKey]?.[menuWriteFeatureKey];

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const {
  selectIds: selectIdsFull,
  selectEntities: selectEntitiesFull,
  selectAll: selectAllFull,
  selectTotal: selectTotalFull,
} = adapterFull.getSelectors();

export const selectMenuDishes = createSelector(selectState, (state) =>
  selectAll(state.menudishes),
);

export const selectMenuDishesFull = createSelector(selectState, (state) =>
  selectAllFull(state.menudishesFull),
);

export const selectLoading = createSelector(
  selectState,
  (state) => state.loading,
);

export const selectLoadingFull = createSelector(
  selectState,
  (state) => state.loadingFull,
);

export const selectSearch = createSelector(
  selectState,
  (state) => state.search,
);

export const selectIsSorting = createSelector(
  selectState,
  (state) => state.isSorting,
);
