<h2 mat-dialog-title class="header">
  {{ "menus.complete.instructions.title" | transloco }}
</h2>

<mat-dialog-content>
  <ul class="steps">
    @for (step of currentIns; track step) {
      <li>{{ step }}</li>
    }
  </ul>
</mat-dialog-content>

<mat-dialog-actions class="actions">
  <button color="primary" mat-button mat-dialog-close>
    {{ "shared.buttons.done" | transloco }}
  </button>
</mat-dialog-actions>
