import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  ContentLanguage,
  langs,
  rtlLangs,
} from 'src/app/shared/constants/languages';
import { Variant } from 'src/app/shared/Models/dish';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { Detail, Menu } from 'src/app/shared/Models/menu';
import { User } from 'src/app/shared/Models/user';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { TranslocoPipe } from '@jsverse/transloco';
import { VariantsComponent } from '../../../../shared/Components/variants/variants.component';
import { SaveRestoreComponent } from '../save-restore/save-restore.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'menu-details',
  templateUrl: './menu-details.component.html',
  styleUrls: ['./menu-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatExpansionModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    SaveRestoreComponent,
    TourMatMenuModule,
    TranslocoPipe,
    VariantsComponent,
  ],
})
export class MenuDetailsComponent implements OnChanges {
  @Input() disabled = false;
  @Input() menu: Menu;
  @Input() hideEmptyLang: ContentLanguage;
  @Input() lang: ContentLanguage;
  @Input() showVariants = true;
  @Input() translations: any;
  @Input() title: string;
  @Input() user: User;

  @Output() changeMenu = new EventEmitter<DeepPartial<Menu>>();
  @Output() restoreDefault = new EventEmitter<{
    target: string;
    field: string;
  }>();
  @Output() saveDefault = new EventEmitter<{ target: string; field: string }>();

  detailsForm: FormGroup;
  languages = langs;
  rtl = false;

  constructor(
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private destroyRef: DestroyRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('lang' in changes && this.lang) {
      this.rtl = rtlLangs.includes(this.lang);
      setTimeout(() => this.buildForm(), 0);
    }
  }

  buildForm(): void {
    if (this.detailsForm) this.detailsForm = undefined;
    const fields = {
      wine: [this.menu.user_details.wine],
    };
    ['title', 'subtitle', 'endtext', 'endtext_secondary', 'footnote'].forEach(
      (field) => {
        fields[`${field}_${this.lang}`] = [
          this.menu.user_details[`${field}_${this.lang}`],
        ];
      },
    );

    this.detailsForm = this.fb.group(fields);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
    this.detailsForm.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((data) => this.onValueChanged(data));
  }

  onValueChanged(data: Partial<Detail>): void {
    this.changeMenu.emit({ user_details: data });
  }

  replaceNewlinesWithBr(text: string): string {
    return text ? text.replace(/\n/g, '<br>') : '';
  }

  variantsChanged(data: { variants: Partial<Variant>[] }): void {
    this.changeMenu.emit({ user_details: { variants: data.variants } });
  }
}
