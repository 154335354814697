import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import {
  Menu,
  PatchStyleNameData,
  Style,
  StyleCategories,
} from 'src/app/shared/Models/menu';
import { StylesCarouselComponent } from './styles-carousel/styles-carousel.component';
import { CategoriesTabsComponent } from '../categories-tabs/categories-tabs.component';

@Component({
  selector: 'select-styles',
  templateUrl: './select-styles.component.html',
  styleUrls: ['./select-styles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CategoriesTabsComponent, StylesCarouselComponent],
})
export class SelectStylesComponent {
  @Input() categories: StyleCategories;
  @Input() eventStyles: Style[];
  @Input() lang: InterfaceLanguage;
  @Input() menu: Menu;
  @Input() publicStyles: Style[];
  @Input() showStyleSpinner: string;
  @Input() styleModified: boolean;
  @Input() tabIndex: number;
  @Input() userStyles: Style[];

  @Output() createStyle = new EventEmitter<void>();
  @Output() deleteStyle = new EventEmitter<Style>();
  @Output() openNameDialog = new EventEmitter<Style>();
  @Output() renameStyle = new EventEmitter<{
    style: Style;
    value: PatchStyleNameData;
  }>();
  @Output() selectStyle = new EventEmitter<Style>();
  @Output() tabChange = new EventEmitter<number>();
  @Output() updateStyle = new EventEmitter<void>();

  constructor() {}
}
