import { SimpleUser, User } from 'src/app/shared/Models/user';
import { Action, createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';
import { AccountStatusMessage } from 'src/app/shared/Models/models';

export const userFeatureKey = 'user';

export interface UserState {
  currentUser: User;
  error: Partial<User>;
  organisation_users: SimpleUser[];
  main: AccountStatusMessage;
  menus: AccountStatusMessage;
  templates: AccountStatusMessage;
  statusBar: boolean;
  statusBarEmail: boolean;
  statusBarMobile: boolean;
}

const initialState = {
  currentUser: null,
  error: null,
  organisation_users: null,
  main: null,
  menus: null,
  templates: null,
  statusBar: false,
  statusBarEmail: false,
  statusBarMobile: false,
};

const _userReducer = createReducer(
  initialState,
  on(UserActions.setUser, (state, { user }) => ({
    ...state,
    currentUser: user,
    error: null,
  })),
  on(UserActions.setUserError, (state, { error }) => ({ ...state, error })),
  on(UserActions.setOrganisationUsers, (state, { payload }) => {
    return {
      ...state,
      organisation_users: payload,
    };
  }),
  on(UserActions.setStatusBar, (state, { data }) => {
    return { ...state, statusBar: data };
  }),
  on(UserActions.setStatusBarEmail, (state, { data }) => {
    return { ...state, statusBarEmail: data };
  }),
  on(UserActions.setStatusBarMobile, (state, { data }) => {
    return { ...state, statusBarMobile: data };
  }),
  on(UserActions.setStatusMain, (state, { main }) => {
    return { ...state, main };
  }),
  on(UserActions.setStatusMenus, (state, { menus }) => {
    return { ...state, menus };
  }),
  on(UserActions.setStatusTemplates, (state, { templates }) => {
    return { ...state, templates };
  }),
  on(UserActions.incrementAiUsage, (state, { amount, credit }) => {
    const key = `ai_${credit}_this_month`;
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        status: {
          ...state.currentUser.status,
          current_usage: {
            ...state.currentUser.status.current_usage,
            [key]: state.currentUser.status.current_usage[key] + amount,
          },
        },
      },
    };
  }),
);

export function userReducer(state: UserState, action: Action): UserState {
  return _userReducer(state, action);
}
