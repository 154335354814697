<div class="container">
  <mat-icon class="blue thumb-up">thumb_up</mat-icon>
  <div class="title">
    {{ "dishes.translation.no-missing-translation" | transloco }}
  </div>
  <div class="description" [class.bottom-space]="isOtherLang">
    {{ "dishes.translation.Well-done" | transloco }}
    @if (!isOtherLang) {
      <span>
        {{ "dishes.translation.deserve-quote" | transloco }}
      </span>
    }
  </div>
  @if (!isOtherLang) {
    <div class="description bottom-space">
      {{ selectedQuote }}
    </div>
  }
</div>
