@if (url && !blob) {
  <div class="view" [style.position]="'relative'">
    @if (
      (!show && formatControl?.value !== "html") ||
      (formatControl?.value === "html" && (previewHtml$ | async) === null)
    ) {
      <mat-spinner class="spinner"></mat-spinner>
    }
    @if (baseLanguage && !hideLangs) {
      <div class="preview-actions">
        <language-buttons
          class="lang-buttons"
          [langs]="langs"
          [hideLabel]="true"
          [langsAllowed]="formatControl?.value === 'html' ? 1 : numberLanguages"
          [multiRequired]="multiRequired"
          (langsChange)="langsChanged($event)"
        >
        </language-buttons>
        <mat-button-toggle-group
          class="toggle-switch"
          [formControl]="formatControl"
          aria-label="Preview mode"
        >
          <mat-button-toggle value="pdf">{{
            "pdf_preview.view.print" | transloco
          }}</mat-button-toggle>
          <mat-button-toggle value="html">{{
            "pdf_preview.view.mobile" | transloco
          }}</mat-button-toggle>
        </mat-button-toggle-group>
        @if (showRefresh) {
          <button
            mat-button
            color="primary"
            class="refresh"
            (click)="refresh()"
          >
            {{ "pdf_preview.refresh" | transloco }}
          </button>
        }
      </div>
    }
    <div class="viewer-container" [class.non-modal]="nonModal">
      @if (
        formatControl?.value === "pdf" &&
        htmlPreview() &&
        (previewPdfHtml$ | async) !== null
      ) {
        <app-pdf-html-preview
          class="html-viewer"
          [html]="previewPdfHtml$ | async"
        ></app-pdf-html-preview>
      } @else if (
        formatControl?.value === "pdf" && !htmlPreview() && src !== null
      ) {
        <pdf-viewer
          #pdfViewer
          *pageSelector="true; let currentPage = page; let showPages = onLoad"
          class="viewer rendered-pdf"
          [class.rendered]="show"
          [autoresize]="true"
          [original-size]="false"
          [fit-to-page]="true"
          [page]="currentPage"
          [render-text]="true"
          [src]="src"
          [show-all]="false"
          [show-borders]="true"
          [stick-to-page]="false"
          [zoom-scale]="'page-fit'"
          (after-load-complete)="
            renderPdf($event);
            pdfViewer.updateSize();
            showPages($event, holdPage ? currentPage : 1);
            holdPage = false
          "
          (page-rendered)="pageRendered()"
          (error)="(null)"
        >
        </pdf-viewer>
      }
      @if (formatControl?.value === "html" && (previewHtml$ | async) !== null) {
        <app-html-preview
          class="html-viewer"
          [htmlPreview]="previewHtml$ | async"
        ></app-html-preview>
      }
    </div>
  </div>
}
@if (blob) {
  <div class="blob-image">
    <img [src]="src" alt="" />
  </div>
}
@if (!nonModal && !hideButtons) {
  <mat-dialog-actions class="options" [class.non-modal]="nonModal">
    <button mat-button (click)="printDocument()">
      <mat-icon class="icon print">print</mat-icon
      >{{ "pdf_preview.actions.print" | transloco }}
    </button>
    <button mat-button [disabled]="isDownloading" (click)="downloadPdf()">
      <mat-icon
        class="icon"
        [style.display]="isDownloading ? 'none' : 'inline-block'"
        >file_download</mat-icon
      >
      <spinner
        [style.display]="isDownloading ? 'inline-block' : 'none'"
        class="download-spinner"
        [config]="{ radius: 7 }"
      ></spinner>
      {{ "pdf_preview.actions.download" | transloco }}
    </button>
  </mat-dialog-actions>
}
