import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  MatDatepicker,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { days } from 'src/app/shared/constants/days';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Categories } from 'src/app/shared/constants/categories';

@Component({
  selector: 'date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.css'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    TranslocoPipe,
  ],
})
export class DateFieldComponent implements OnChanges, OnDestroy {
  @Input() dish: MenuDish;
  @Input() field: string;
  @Input() form: UntypedFormGroup;

  @ViewChild('picker', { static: true })
  picker: MatDatepicker<Date>;

  private destroyed$ = new Subject<void>();
  dayDigit: number;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      'dish' in changes &&
      this.dish?.separator_detail?.category === Categories.DAY
    )
      this.getDay();
  }

  dayFilter = (d: Date): boolean => {
    if (!d) return false;
    const day = d.getDay();
    return day === this.dayDigit;
  };

  getDay(): void {
    days.some((v, index: number) => {
      if (v['en'] === this.dish.separator_detail.en) {
        this.dayDigit = index < 6 ? index + 1 : 0;
        return true;
      }
      return false;
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
