import { ContentLanguage } from '../constants/languages';
export class Translation {
  url: string;
  id: number;
  menu: number;
  language: ContentLanguage;
  printed_on?: Date;
  preview: string;
  translations_missing?: number;
  print?: any;
  constructor(instance: any) {
    Object.assign(this, instance);
  }
}

export class SearchForm {
  search: string;
  filter: string;
}

export enum SimilarTypes {
  DISH = 'dish-ingredient',
  SEPARATOR = 'separator',
  DESCRIPTION = 'description',
  FILTERED_DESC = 'dish-ingredient-separator',
}
