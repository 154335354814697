import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { Diet } from 'src/app/shared/Models/diet';
import { SimpleLocation } from 'src/app/shared/Models/location';
import {
  TaskType,
  MenusOverviewParams,
  MenusResults,
  OverviewMenu,
} from 'src/app/shared/Models/menu';
import { AccountStatusMessage } from 'src/app/shared/Models/models';
import { Ruleset } from 'src/app/shared/Models/ruleset';
import { Type } from 'src/app/shared/Models/type';
import { User, UserStatusPrivileges } from 'src/app/shared/Models/user';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatDividerModule } from '@angular/material/divider';
import { MenuOverviewItemComponent } from './menu-overview-item/menu-overview-item.component';
import { SpinnerComponent } from '../../../shared/Components/spinner/spinner.component';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'menu-overview-table',
  templateUrl: './menu-overview-table.component.html',
  styleUrls: ['./menu-overview-table.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    SpinnerComponent,
    MenuOverviewItemComponent,
    MatTooltipModule,
    MatButtonModule,
    MatDividerModule,
    MatPaginatorModule,
    TranslocoPipe,
  ],
})
export class MenuOverviewTableComponent implements OnChanges {
  @Input() consumerTypes: Type[];
  @Input() currentMenu: OverviewMenu;
  @Input() diets: Diet[];
  @Input() loadingSpinner: boolean;
  @Input() locations: SimpleLocation[];
  @Input() menus: MenusResults;
  @Input() params: Partial<MenusOverviewParams>;
  @Input() privileges: UserStatusPrivileges;
  @Input() rulesets: Ruleset[];
  @Input() user: User;
  @Input() userStatus: AccountStatusMessage;
  @Input() userStatusBlockMessage: string;

  @Output() changeMenu = new EventEmitter<{
    menu: OverviewMenu;
    featured: boolean;
  }>();
  @Output() changePagination = new EventEmitter<{
    page: number;
    pageSize: number;
  }>();
  @Output() copyWithDiets = new EventEmitter<OverviewMenu>();
  @Output() copyWithOptions = new EventEmitter<OverviewMenu>();
  @Output() createBtnClicked = new EventEmitter<{
    btn: MatButton;
    tooltip: MatTooltip;
  }>();
  @Output() deleteChildren = new EventEmitter<OverviewMenu>();
  @Output() deleteMenu = new EventEmitter<OverviewMenu>();
  @Output() generateArchive = new EventEmitter<{
    menu: OverviewMenu;
    rulesetId?: number;
    taskType: TaskType;
  }>();
  @Output() createMenu = new EventEmitter<{
    menu: OverviewMenu;
    data: {
      id: number;
      entity: 'rule' | 'type';
    };
  }>();
  @Output() openCreateMenu = new EventEmitter<void>();
  @Output() previewMenu = new EventEmitter<OverviewMenu>();
  @Output() refreshSubMenu = new EventEmitter<OverviewMenu>();
  @Output() regenerateMenus = new EventEmitter<OverviewMenu>();
  @Output() shareMenu = new EventEmitter<OverviewMenu>();
  @Output() showCopyMenu = new EventEmitter<OverviewMenu>();
  @Output() showSnackbarMessageEvent = new EventEmitter<{ message: string }>();

  consumerTypesByLocation: { [locationId: number]: Type[] } = {};
  dietsLocationsMap: { [locationId: number]: boolean } = {};

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('diets' in changes && this.diets) {
      this.diets.forEach((d) => {
        this.dietsLocationsMap[d.location] = true;
      });
    }
    if ('consumerTypes' in changes && this.consumerTypes) {
      this.consumerTypesByLocation = {};
      this.consumerTypes.forEach((ct) => {
        if (!this.consumerTypesByLocation[ct.location ?? 0]) {
          this.consumerTypesByLocation[ct.location ?? 0] = [];
        }
        this.consumerTypesByLocation[ct.location ?? 0].push(ct);
      });
    }
  }

  handlePaginator(event: PageEvent): void {
    this.changePagination.emit({
      page: event.pageIndex > 0 ? event.pageIndex + 1 : null,
      pageSize: event.pageSize,
    });
  }
}
