import { createAction, props } from '@ngrx/store';
import {
  Course,
  CourseParams,
  Dashboard,
} from 'src/app/shared/Models/dashboard';
import { Menu } from 'src/app/shared/Models/menu';
import { MenuDish, MenuDishParams } from 'src/app/shared/Models/menudish';
import {
  OnboardingTemplate,
  UserOnboardingTemplate,
} from 'src/app/shared/Models/onboarding_template';

export const fetchDashboardData = createAction(
  `[Dashboard container] Fetch dashboard data`,
);

export const startLoadingDashboard = createAction(
  `[Dashboard container] Set dashboard loading`,
  props<{ is_loading: boolean }>(),
);

export const setData = createAction(
  `[Dashboard container] Set dashboard data`,
  props<{ dashboard: Dashboard }>(),
);

export const restrictLatestTemplates = createAction(
  `[Dashboard container] Restrict latest template`,
);

export const setTemplateRestrictions = createAction(
  `[Dashboard container] Set template restrictions`,
  props<{ templates: OnboardingTemplate[] }>(),
);

export const showBouncedEmailModal = createAction(
  `[Dashboard container] Show bounced email modal`,
);

export const clearData = createAction(
  `[Dashboard container] Clear dashboard data`,
);

export const clearLastMenu = createAction(
  `[Dashboard container] Clear dashboard last menu if the ID matches`,
  props<{ id: number }>(),
);

export const fetchDishesCurrentMenu = createAction(
  `[Dashboard container] Fetches dishes for current menu`,
  props<{ url: string; params: Partial<MenuDishParams> }>(),
);

export const setDishesCurrentMenu = createAction(
  `[Dashboard container] Sets dishes for current menu locally`,
  props<{ dishes: MenuDish[] }>(),
);

export const setDishesCurrentMenuLoading = createAction(
  `[Dashboard container] Set dishes for current menu loading state`,
  props<{ loading: boolean }>(),
);

export const fetchCourses = createAction(
  `[Dashboard container] Fetches courses `,
  props<{ params: Partial<CourseParams> }>(),
);

export const setCoursesLoading = createAction(
  `[Dashboard container] Set courses loading state`,
  props<{ loading: boolean }>(),
);

export const setCourses = createAction(
  `[Dashboard container] Sets courses`,
  props<{ courses: Course[] }>(),
);

export const fetchUserOnboardingTemplates = createAction(
  '[Authentication] Fetch user onboarding templates',
);

export const setUserOnboardingTemplates = createAction(
  `[Authentication] Set user onboarding templates locally`,
  props<{ payload: UserOnboardingTemplate[] }>(),
);

export const setUserOnboardingTemplateUsed = createAction(
  '[Authentication] Set set user onboarding template used',
  props<{ id: number; menu: Menu }>(),
);

export const removeUserOnboardingTemplateMenu = createAction(
  '[Menu overview] Remove user onboarding template menu',
  props<{ menuId: number }>(),
);
