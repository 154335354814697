<div class="wrapper simple-theme">
  <h1 class="title">{{ "email_preferences.title" | transloco }}</h1>
  @if (!error) {
    <section class="info">
      <div class="email">{{ email }}</div>
      <p class="description description-info">
        {{ "email_preferences.description" | transloco }}
      </p>
    </section>
    <div class="items-wrapper">
      <div class="checkbox-item">
        <mat-checkbox disabled="true" [checked]="true" color="primary">
          <span class="checkbox-label">{{
            "email_preferences.label_essentials" | transloco
          }}</span>
        </mat-checkbox>
        <p class="description checkbox-description">
          {{ "email_preferences.description_essentials" | transloco }}
        </p>
        <div class="faded-block"></div>
      </div>
      <div
        class="checkbox-item"
        [class.faded]="!welcome.checked || all.checked"
      >
        <mat-checkbox #welcome [checked]="true" color="primary">
          <span class="checkbox-label">{{
            "email_preferences.label_welcome" | transloco
          }}</span>
        </mat-checkbox>
        <p class="description checkbox-description">
          {{ "email_preferences.description_welcome" | transloco }}
        </p>
        <div class="faded-block"></div>
      </div>
      <div class="checkbox-item" [class.faded]="!news.checked || all.checked">
        <mat-checkbox #news [checked]="true" color="primary">
          <span class="checkbox-label">{{
            "email_preferences.label_newsletter" | transloco
          }}</span>
        </mat-checkbox>
        <p class="description checkbox-description">
          {{ "email_preferences.description_newsletter" | transloco }}
        </p>
        <div class="faded-block"></div>
      </div>
    </div>
    <div class="center-wrapper">
      <mat-checkbox #all color="primary" class="unsubscribe">{{
        "email_preferences.unsub" | transloco
      }}</mat-checkbox>
      <button (click)="submit()" mat-button class="update-prefs">
        {{ "email_preferences.unsub_button" | transloco }}
      </button>
      <p class="bottom-info">
        {{ "email_preferences.bottom_info" | transloco }}
      </p>
    </div>
  } @else {
    <div class="center-wrapper">
      <p>{{ error }}</p>
      <a
        mat-button
        routerLink="/login"
        queryParamsHandling="preserve"
        class="update-prefs space-below"
      >
        {{ "email_preferences.to_login" | transloco }}
      </a>
    </div>
  }
  <footer class="footer">
    <ul class="footer-links">
      <li class="footer-link">
        <a [href]="'https://menutech.com/' + lang"
          >&copy; {{ "shared.footer.labels.gmbh" | transloco }}</a
        >
      </li>
      <li class="footer-link">
        <a
          [href]="
            'https://menutech.com/' +
            lang +
            '/' +
            ('shared.footer.links.imprint' | transloco)
          "
          >{{ "shared.footer.labels.imprint" | transloco }}</a
        >
      </li>
      <li class="footer-link">
        <a
          [href]="
            'https://menutech.com/' +
            lang +
            '/' +
            ('shared.footer.links.privacy' | transloco)
          "
          >{{ "shared.footer.labels.privacy" | transloco }}</a
        >
      </li>
      <li class="footer-link">
        <a
          [href]="
            'https://menutech.com/' +
            lang +
            '/' +
            ('shared.footer.links.terms_link' | transloco)
          "
          >{{ "shared.footer.labels.terms_link" | transloco }}</a
        >
      </li>
    </ul>
  </footer>
</div>
