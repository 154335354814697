import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { PasswordValidators } from 'ngx-validators';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { Invitee } from 'src/app/shared/Models/invitee';
import { selectAcceptButtonSpinnerState } from 'src/app/auth/ngrx/auth.selectors';
import { State } from 'src/app/reducers';
import { TERMS_LINKS } from 'src/app/app.config';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { ValidationMessages } from 'src/app/shared/constants/validation';
import { AcceptInvitationForm } from 'src/app/shared/Models/authentication';
import { TranslocoPipe } from '@jsverse/transloco';
import { AsyncPipe } from '@angular/common';
import { SpinnerComponent } from '../../../shared/Components/spinner/spinner.component';
import { MatButtonModule } from '@angular/material/button';
import {
  MatCheckboxModule,
  _MatCheckboxRequiredValidatorModule,
} from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'accept-invitation',
  templateUrl: './accept-invitation.component.html',
  styleUrls: ['./accept-invitation.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatCheckboxModule,
    _MatCheckboxRequiredValidatorModule,
    MatButtonModule,
    SpinnerComponent,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class AcceptInvitationComponent implements OnChanges, OnInit {
  @Input() data: Invitee;
  @Input() lang: InterfaceLanguage;
  @Output() submitData = new EventEmitter<AcceptInvitationForm>();

  formErrors = {
    first_name: '',
    last_name: '',
    password1: '',
    password2: '',
    terms: '',
  };
  inviteeForm: UntypedFormGroup;
  invitee = new Invitee();
  links = TERMS_LINKS;
  validationMessages = ValidationMessages;

  spinnerState$ = this.ngrxStore.select(selectAcceptButtonSpinnerState);

  constructor(
    private utils: UtilsService,
    protected ngrxStore: Store<State>,
    private fb: UntypedFormBuilder,
  ) {
    const keys = [].concat.apply(
      [],
      Object.keys(this.validationMessages).map((el) =>
        Object.keys(this.validationMessages[el])
          .filter((value) => Object.keys(this.formErrors).includes(value))
          .map((res) => `auth.registration.fields.${el}.validation.${res}`),
      ),
    );
    this.utils.getTranslation(keys, (res) => this.handleLang(keys, res));
  }

  ngOnInit() {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('data' in changes) {
      Object.assign(this.invitee, this.data);
      if (this.inviteeForm) {
        this.inviteeForm.setValue({
          email: this.invitee.email,
          first_name: this.invitee.first_name,
          last_name: this.invitee.last_name,
          password1: '',
          password2: '',
          terms: false,
        });
      }
    }
  }

  handleLang = (keys, res) => {
    keys.forEach((key: string, index: number) => {
      const spl = key.split('.');
      this.validationMessages[spl[3]][spl[5]] = res[index];
    });
    this.onValueChanged();
  };

  onSubmit(event: Event) {
    event.preventDefault();
    this.invitee = this.inviteeForm.value;
    this.isTermsEnable(this.inviteeForm.get('terms'));
    if (this.inviteeForm.valid) {
      const data = { ...this.inviteeForm.value, language: this.lang };
      this.submitData.emit(data);
    }
  }

  onValueChanged(data?: any) {
    if (!this.inviteeForm) return undefined;
    const form = this.inviteeForm;
    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  isTermsEnable(control) {
    if (control && !control.valid) {
      const messages = this.validationMessages.terms;
      for (const key in control.errors) {
        this.formErrors.terms = messages[key];
      }
    }
  }

  buildForm(): void {
    this.inviteeForm = this.fb.group({
      email: [this.invitee.email],
      first_name: [
        this.invitee.first_name,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(24),
        ],
      ],
      last_name: [
        this.invitee.last_name,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(24),
        ],
      ],
      terms: [
        this.invitee.terms,
        [Validators.required, Validators.requiredTrue],
      ],
      password1: [this.invitee.password],
      password2: [this.invitee.confirm_password],
    });
    this.inviteeForm.setValidators(
      PasswordValidators.mismatchedPasswords('password1', 'password2'),
    );
  }
}
