import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { EmailPreferences } from 'src/app/shared/Models/user';
import { TranslocoPipe } from '@jsverse/transloco';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'email-preferences',
  templateUrl: './email-preferences.component.html',
  styleUrls: ['./email-preferences.component.scss'],
  standalone: true,
  imports: [MatCheckboxModule, MatButtonModule, RouterLink, TranslocoPipe],
})
export class EmailPreferencesComponent {
  @Input() email: string;
  @Input() error: string;
  @Input() lang: InterfaceLanguage;
  @Output() updateSettings = new EventEmitter<EmailPreferences>();

  @ViewChild('welcome', { static: false }) welcome: MatCheckbox;
  @ViewChild('news', { static: false }) news: MatCheckbox;
  @ViewChild('all', { static: false }) all: MatCheckbox;

  submit() {
    this.updateSettings.emit(
      this.all.checked
        ? {
            onboarding: false,
            newsletter: false,
          }
        : {
            onboarding: !!this.welcome.checked,
            newsletter: !!this.news.checked,
          },
    );
  }
}
