<form [formGroup]="inviteeForm" (ngSubmit)="onSubmit($event)">
  <div class="label">
    <mat-icon class="icon">email</mat-icon>
    <mat-form-field class="input email">
      <mat-label>{{
        "auth.registration.fields.email.placeholder" | transloco
      }}</mat-label>
      <input
        [matTooltip]="'auth.accept_invitation.email-tooltip' | transloco"
        matTooltipClass="invitation-tooltip"
        readonly
        matInput
        formControlName="email"
        autocomplete="email"
        minlength="2"
        maxlength="100"
        required
        type="email"
      />
    </mat-form-field>
  </div>
  <div class="label">
    <mat-icon class="icon">personal</mat-icon>
    <mat-form-field
      [hintLabel]="formErrors.first_name"
      [color]="formErrors.first_name ? 'warn' : 'primary'"
      class="input"
    >
      <mat-label>{{
        "auth.registration.fields.first_name.placeholder" | transloco
      }}</mat-label>
      <input
        matInput
        formControlName="first_name"
        autocomplete="given-name"
        minlength="2"
        maxlength="30"
        required
        type="text"
      />
    </mat-form-field>
  </div>
  <div class="label">
    <mat-icon class="icon">personal</mat-icon>
    <mat-form-field
      [hintLabel]="formErrors.last_name"
      [color]="formErrors.last_name ? 'warn' : 'primary'"
      class="input"
    >
      <mat-label>{{
        "auth.registration.fields.last_name.placeholder" | transloco
      }}</mat-label>
      <input
        matInput
        formControlName="last_name"
        autocomplete="family-name"
        minlength="2"
        maxlength="30"
        required
        type="text"
      />
    </mat-form-field>
  </div>
  <div class="label">
    <mat-icon class="icon">lock</mat-icon>
    <mat-form-field
      [hintLabel]="formErrors.password1"
      [color]="formErrors.password1 ? 'warn' : 'primary'"
      class="input"
    >
      <mat-label>{{
        "auth.registration.fields.password1.placeholder" | transloco
      }}</mat-label>
      <input
        matInput
        formControlName="password1"
        autocomplete="new-password"
        minlength="8"
        required
        type="password"
      />
    </mat-form-field>
  </div>
  <div class="label">
    <mat-icon class="icon">lock</mat-icon>
    <mat-form-field
      [hintLabel]="formErrors.password2"
      [color]="formErrors.password2 ? 'warn' : 'primary'"
      class="input"
    >
      <mat-label>{{
        "auth.registration.fields.password2.placeholder" | transloco
      }}</mat-label>
      <input
        matInput
        formControlName="password2"
        autocomplete="new-password"
        minlength="8"
        required
        type="password"
      />
    </mat-form-field>
  </div>
  <div class="label terms">
    <mat-checkbox
      color="primary"
      id="terms"
      class="terms"
      formControlName="terms"
      required
    >
      <a target="_blank" rel="noopener" [href]="links[lang]" class="terms-link">
        {{ "auth.registration.fields.terms.placeholder" | transloco }}
      </a>
    </mat-checkbox>
  </div>
  <label class="error" for="terms"> {{ formErrors.terms }} </label>

  <div
    class="button-bar"
    [matTooltip]="
      inviteeForm.valid
        ? ''
        : ('auth.accept_invitation.button-tooltip' | transloco)
    "
    matTooltipClass="invitation-btn-tooltip"
  >
    <button
      mat-flat-button
      color="primary"
      class="button-spinner"
      type="submit"
      [disabled]="!inviteeForm.valid || (spinnerState$ | async)"
    >
      <span class="button-text">
        {{ "auth.accept_invitation.button-title" | transloco }}
        @if (spinnerState$ | async) {
          <spinner class="inner-spinner" [config]="{ radius: 7 }"></spinner>
        }
      </span>
    </button>
  </div>
</form>
