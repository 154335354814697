<h3 class="mat-h3">
  {{ "shared.dialogs.confirm-delete-dialog.title" | transloco }}
</h3>
@for (item of deleteChildrenList; track item) {
  <div class="item">{{ item }}</div>
}

<div class="actions">
  <button mat-button class="cancel" (click)="cancelAction.emit()">
    {{ "menus.overview.duplicate.dialog.actions.cancel" | transloco }}
  </button>
  <button
    mat-flat-button
    color="warn"
    class="warn"
    (click)="deleteChildrenMenu.emit()"
  >
    {{ "shared.buttons.confirm" | transloco }}
  </button>
</div>
