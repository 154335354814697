<div
  class="wrapper"
  [mtTooltip]="
    disabled
      ? ('style.sidebar.restrictions.layout-field-locked' | transloco)
      : ''
  "
>
  @if (!imageUrl) {
    <label class="file-upload" [class.disabled]="disabled">
      @if (showTitle && label) {
        <p>
          @if (overwritten) {
            <span
              [mtTooltip]="
                'write.blocks.dish-overview.information.overwritten' | transloco
              "
              class="overwritten-info"
            ></span>
          }
          {{ label }}:
        </p>
      }
      <file-upload
        [control]="fileUploadControl"
        [multiple]="false"
        [animation]="false"
        class="upload-single-image-label"
      >
        <ng-template #placeholder>
          <file-upload-placeholder
            [control]="fileUploadControl"
            [formats]="'shared.show-and-upload-image.formats' | transloco"
            [placeholder]="
              'shared.show-and-upload-image.placeholder' | transloco
            "
            [showSpinner]="showSpinner"
          ></file-upload-placeholder>
        </ng-template>
        <ng-template #item></ng-template>
      </file-upload>
      @if (error) {
        <p class="error-message">{{ error }}</p>
      }
      <ng-template #spinner>
        <spinner></spinner>
      </ng-template>
    </label>
  }
  @if (imageUrl) {
    <div class="image" [class.deleting]="deleting">
      <img [src]="imageUrl" alt="image" />
      @if (!deleting) {
        <span stop-propagation class="delete" (click)="delete()">
          <mat-icon>clear</mat-icon>
        </span>
      }
    </div>
  }
</div>
