import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { Categories } from 'src/app/shared/constants/categories';
import {
  ContentLanguage,
  InterfaceLanguage,
} from 'src/app/shared/constants/languages';
import { Additive, Allergen, Label } from 'src/app/shared/Models/declarations';
import { Dish } from 'src/app/shared/Models/dish';
import { Ingredient } from 'src/app/shared/Models/ingredients';
import {
  Recipe,
  RecipeIngredient,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';
import {
  clearSimilarDishes,
  fetchSimilarDishes,
} from 'src/app/shared/ngrx/shared.actions';
import {
  selectSimilarDishesAdditivesCount,
  selectSimilarDishesAllergensCount,
} from 'src/app/shared/ngrx/shared.selectors';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { CopyDeepPipe } from '../../Pipes/copy-deep.pipe';
import { TranslocoPipe } from '@jsverse/transloco';
import { AsyncPipe } from '@angular/common';
import { OptionsComponent } from './options/options.component';
import { OptionPopoverComponent } from './option-popover/option-popover.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'declarations',
  templateUrl: './declarations.component.html',
  styleUrls: ['./declarations.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    OptionPopoverComponent,
    OptionsComponent,
    AsyncPipe,
    TranslocoPipe,
    CopyDeepPipe,
  ],
})
export class DeclarationsComponent implements OnChanges {
  @Input() item: Dish | Ingredient;
  @Input() lang: InterfaceLanguage;
  @Input() contentLang: ContentLanguage;
  @Input() dishDeclarations = true;
  @Input() hasRecipes: boolean;
  @Input() recipes: Recipe[];

  @Output() changeOption = new EventEmitter<{
    data: Dish | Ingredient;
    type: 'allergens' | 'additives' | 'labels';
  }>();
  @Output() fetchRecipes = new EventEmitter<void>();
  @Output() showModal = new EventEmitter<{
    item: Dish | Ingredient;
    type: 'allergens' | 'additives';
    contentLang: ContentLanguage;
  }>();
  @Output() synchroniseRecipeDeclarations = new EventEmitter<{
    dish: Dish | Ingredient;
    type: 'all' | 'add';
  }>();
  @Output() searchIngredients = new EventEmitter<string>();
  @Output() deleteIngredientEvent = new EventEmitter<{
    deletingIngredient: SimpleRecipeIngredient;
    recipe: Recipe;
  }>();
  @Output() addIngredientEvent = new EventEmitter<Recipe>();
  @Output() selectedIngredientEvent = new EventEmitter<{
    ingredient_id: number;
    recipe: Recipe;
  }>();
  @Output() createIngredientEvent = new EventEmitter<{
    newIngredient: Partial<Recipe>;
    recipe: Recipe;
  }>();
  @Output() updateRecipeIngredientEvent = new EventEmitter<{
    recipe: Recipe;
    updatedIngredient: {
      url: string;
      recipeIngredient: Partial<RecipeIngredient>;
      onFulfilled: Function;
    };
  }>();
  @Output() updateIngredientEvent = new EventEmitter<{
    recipe: Recipe;
    updatedIngredient: {
      ingredient: Partial<Ingredient>;
      recipeIngredient: RecipeIngredient;
    };
  }>();

  numberSimilarAdditives$ = this.ngrxStore.select(
    selectSimilarDishesAdditivesCount,
  );
  numberSimilarAllergens$ = this.ngrxStore.select(
    selectSimilarDishesAllergensCount,
  );

  additives: Additive[] = [];
  additivesView = false;
  allergens: Allergen[] = [];
  allergensView = false;
  labels: Label[] = [];
  labelsView = false;
  moduleMap = {
    allergens: 'all',
    additives: 'add',
  };
  showDependants = false;

  constructor(
    private utils: UtilsService,
    protected ngrxStore: Store<State>,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('item' in changes && this.item) {
      this.additives = this.item.additives_contained;
      this.allergens = this.item.allergens_contained;
      this.labels = this.item.labels_contained;
      if (
        this.dishDeclarations &&
        changes.item.previousValue?.id !== this.item.id
      ) {
        if (this.item.category !== Categories.WINE) {
          this.getSimilarItems(this.item as Dish);
        } else {
          this.clearSimilarItems();
        }
      }
    }
  }

  clearSimilarItems() {
    this.ngrxStore.dispatch(clearSimilarDishes());
  }

  getSimilarItems(item: Dish) {
    const lookupLang = this.contentLang ? this.contentLang : this.lang;
    for (const decl of ['allergens', 'additives']) {
      if (this.item[`${decl}_contained`] === undefined) return undefined;
      if (this.hasModules(this.moduleMap[decl])) {
        this.ngrxStore.dispatch(
          fetchSimilarDishes({
            name: item[lookupLang],
            lang: lookupLang,
            containsOption: decl,
            dish: item,
          }),
        );
      }
    }
  }

  hasModules = (code: string | string[]): boolean =>
    this.utils.hasModules(code);

  showAdditives() {
    this.additivesView = !this.additivesView;
  }

  showAllergens() {
    this.allergensView = !this.allergensView;
  }

  showLabels() {
    this.labelsView = !this.labelsView;
  }
}
