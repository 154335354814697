import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  MatDialogRef,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { OptionItemComponent } from '../option-item/option-item.component';

@Component({
  selector: 'affect-modal',
  template: `
    <mat-dialog-content>
      @for (item of childrens; track item) {
        <div>
          <p class="description">
            {{ 'additives.affects.' + item.name | transloco }}:
          </p>
          <option-item
            class="options"
            type="additives"
            (triggerOption)="changed.emit($event); stopPropagation($event)"
            [option]="item"
            [items]="items"
          ></option-item>
        </div>
      }
    </mat-dialog-content>
    <mat-dialog-actions class="actions">
      <button
        mat-button
        color="primary"
        class="continue"
        (click)="dialog.close(); stopPropagation($event)"
      >
        {{ 'shared.buttons.continue' | transloco }}
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      .continue {
        margin: 10px 0;
        text-transform: uppercase;
      }
      .options {
        display: block;
      }
      .actions {
        justify-content: flex-end;
      }
    `,
  ],
  standalone: true,
  imports: [
    MatDialogContent,
    OptionItemComponent,
    MatDialogActions,
    MatButtonModule,
    TranslocoPipe,
  ],
})
export class AffectModalComponent implements OnDestroy, AfterViewInit {
  @Input() option: any;
  @Input() childrens: any;
  @Input() items: any;
  @Output() changed = new EventEmitter<any>();
  overlay;

  ngAfterViewInit() {
    this.overlay = document.querySelector('.cdk-overlay-container');
    this.overlay.addEventListener('click', this.stopPropagation);
  }

  stopPropagation = (e) => e.stopPropagation && e.stopPropagation();

  constructor(public dialog: MatDialogRef<AffectModalComponent>) {}

  ngOnDestroy() {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    if (this.overlay) {
      this.overlay.removeEventListener('click', this.stopPropagation);
    }
  }
}
