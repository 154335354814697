import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { SimpleMenu } from 'src/app/shared/Models/menu';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-last-menu',
  template: `
    <mat-card color="primary">
      <div class="content">
        <p>
          {{ 'dashboard.statistics.last_menu.title' | transloco }}
        </p>
        <p class="text">
          @if (menu) {
            <span>
              <span>{{ days }} </span>
              <span class="months">{{ months }}</span>
            </span>
          }
          @if (!menu) {
            <span>{{
              'dashboard.statistics.last_menu.empty' | transloco
            }}</span>
          }
        </p>
      </div>
      @if (menu && menu.date) {
        <mat-card-footer>
          <hr />
          <button
            mat-flat-button
            color="primary"
            class="button"
            (click)="showPreview()"
          >
            <span class="element-action"
              ><mat-icon class="icon">visibility</mat-icon>
              {{ 'dashboard.statistics.last_menu.preview' | transloco }}</span
            >
          </button>
        </mat-card-footer>
      }
    </mat-card>
  `,
  styleUrls: [
    `../menus-counter/menus-counter.component.scss`,
    `./last-menu.component.scss`,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatIconModule, TranslocoPipe],
})
export class LastMenuComponent implements OnChanges {
  @Input() menu: SimpleMenu;
  @Output() previewMenu = new EventEmitter<SimpleMenu>();

  days: string;
  months: string;

  constructor(private dateAdapter: DateAdapter<Date>) {}

  showPreview() {
    if (!this.menu) return undefined;
    this.previewMenu.emit(this.menu);
  }

  ngOnChanges() {
    if (this.menu) {
      const date = new Date(this.menu.date);
      this.days = this.dateAdapter.format(date, 'dd');
      this.months = this.dateAdapter.format(date, 'MMMM yyyy');
    }
  }
}
