<section class="create">
  <h3 class="create-title">
    {{
      "menus.complete.backup.backup-line"
        | transloco
          : {
              date: (backup.created_at | date: "mediumDate" : undefined : lang),
              time: backup.created_at | date: "shortTime" : undefined : lang,
            }
    }}
  </h3>
  <div class="buttons-wrapper">
    <div class="action-buttons">
      <button class="action-button" mat-button (click)="showPreview()">
        {{ "menus.steps.complete.actions.preview" | transloco }}
      </button>
      <span
        class="action-button"
        [matTooltip]="
          blockDelete ? ('menus.complete.edit_disable' | transloco) : ''
        "
        matTooltipClass="top-navbar-tooltip"
      >
        <button
          mat-button
          [disabled]="blockDelete"
          (click)="delete.emit(backup)"
        >
          {{ "menus.complete.backup.delete" | transloco }}
        </button>
      </span>
      <span
        class="action-button"
        [matTooltip]="
          blockRestore ? ('menus.complete.edit_disable' | transloco) : ''
        "
        matTooltipClass="top-navbar-tooltip"
      >
        <button
          mat-button
          [disabled]="blockRestore"
          (click)="restore.emit(backup)"
        >
          {{ "menus.complete.backup.restore" | transloco }}
        </button>
      </span>
    </div>
  </div>
</section>
