import { arrayToObject } from '../Models/type-utils';

export const interfaceLangsDescription = [
  'description_en',
  'descripion_de',
  'descripion_fr',
  'descripion_it',
  'descripion_es',
] as const;
export const langs = [
  'en',
  'de',
  'fr',
  'it',
  'es',
  'ca',
  'hr',
  'pl',
  'pt',
  'nl',
  'uk',
  'ru',
  'el',
  'da',
  'sv',
  'ar',
  'eu',
  'gl',
  'et',
  'fi',
  'zh',
  'th',
  'vi',
] as const;
export const langsExtended = [
  'ca',
  'hr',
  'pl',
  'pt',
  'nl',
  'uk',
  'ru',
  'el',
  'da',
  'sv',
  'ar',
  'eu',
  'gl',
  'et',
  'fi',
  'zh',
  'th',
  'vi',
];
export const interfaceLangs = ['en', 'de', 'fr', 'it', 'es'] as const;
export const blockExpressTranslationLangs = [
  'ca',
  'hr',
  'ar',
  'eu',
  'gl',
  'th',
  'vi',
];
export const blockGrammarCheckLangs = [
  'hr',
  'eu',
  'gl',
  'et',
  'fi',
  'th',
  'vi',
];
export const rtlLangs = ['ar'];

export const InterfaceLangsAsObject = arrayToObject(
  interfaceLangs as unknown as string[],
);

export const InterfaceLangsAsDescriptionObject = arrayToObject(
  interfaceLangs as unknown as string[],
  'description_',
);

export type ContentLanguage = (typeof langs)[number];
export type InterfaceLanguage = (typeof interfaceLangs)[number];
