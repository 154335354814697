import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { TranslocoService } from '@jsverse/transloco';
import { HttpErrorResponse } from '@angular/common/http';
import { setInterfaceLang } from 'src/app/shared/ngrx/shared.actions';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import {
  setEmailPreferencesError,
  setNextPage,
} from 'src/app/auth/ngrx/auth.actions';
import { selectLoginState } from 'src/app/auth/ngrx/auth.selectors';
import { ConfigService } from 'src/app/shared/Services/config/config.service';
import { GenericsService } from 'src/app/shared/Services/generics/generics.service';
import { UserEmailPreferences } from 'src/app/shared/Models/user';
import { Language } from 'src/app/shared/Models/models';
import { inject } from '@angular/core';
import { State } from 'src/app/reducers';

export const emailTokenGuard = (
  next: ActivatedRouteSnapshot,
): Observable<boolean> | Promise<boolean> | boolean => {
  const configService = inject(ConfigService);
  const genericService = inject(GenericsService);
  const store = inject(Store) as Store<State>;
  const translate = inject(TranslocoService);

  let language = { current: 'en' as InterfaceLanguage };
  const login$ = store.select(selectLoginState);
  let loggedIn = false;

  login$.subscribe((el) => (loggedIn = el));

  const handleLang = ({ lang }: { lang: string }) => {
    if (lang) {
      const l = Language(lang);
      language.current = l;
      translate.setActiveLang(l);
      store.dispatch(setInterfaceLang({ lang: l }));
    }
  };

  const setLang = (lang: InterfaceLanguage) => {
    translate.getActiveLang() &&
      (language = {
        current: translate.getActiveLang() as InterfaceLanguage,
      });
    if (lang) handleLang({ lang });
  };

  setLang(next.queryParamMap.get('lang') as InterfaceLanguage);
  const email = next.paramMap.get('email');
  const token = next.paramMap.get('token');

  const handleError = (
    status: number,
    message: string,
    email: string,
    token: string,
  ) => {
    if (status === 410 && !loggedIn) {
      store.dispatch(
        setNextPage({ nextPage: `/email_preferences/${email}/${token}` }),
      );
      store.dispatch(setEmailPreferencesError({ message }));
    } else if (status === 404) {
      store.dispatch(setEmailPreferencesError({ message }));
    }
    return true;
  };

  return genericService
    .get<UserEmailPreferences>(
      `${configService.emailPreferences}${email}/${token}/`,
      {},
      UtilsService.createLanguageHeader(
        translate.getActiveLang() as InterfaceLanguage,
      ),
    )
    .pipe(
      catchError((error: HttpErrorResponse) =>
        from([handleError(error.status, error.error.message, email, token)]),
      ),
      switchMap(() => from([true])),
    );
};
