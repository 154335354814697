@if (!messageSent) {
  <div class="wrapper">
    <h2 class="header" mat-dialog-title>
      {{ "app.navigation.send-message-dialog.title" | transloco }}
    </h2>
    <mat-dialog-content class="content">
      <form [formGroup]="messageGroup">
        <mat-form-field class="select-field">
          <mat-label>{{
            "app.navigation.send-message-dialog.subject.title" | transloco
          }}</mat-label>
          <mat-select formControlName="subject">
            @for (subject of subjectOptions; track subject) {
              <mat-option [value]="subject">
                {{ subject }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        @if (showEmail) {
          <mat-form-field class="input-field">
            <mat-label>{{
              "app.navigation.send-message-dialog.email" | transloco
            }}</mat-label>
            <input
              matInput
              class="email-field"
              type="text"
              formControlName="email"
            />
          </mat-form-field>
        }
        <mat-form-field class="message-field">
          <mat-label>{{
            "app.navigation.send-message-dialog.message" | transloco
          }}</mat-label>
          <textarea
            matInput
            class="message-input"
            formControlName="message"
          ></textarea>
        </mat-form-field>
        <file-upload [control]="fileUploadControl" [multiple]="true">
          <ng-template #placeholder>
            <file-upload-placeholder
              [control]="fileUploadControl"
              [formats]="'support.template.fields.upload.formats' | transloco"
              [placeholder]="
                'app.navigation.send-message-dialog.upload' | transloco
              "
            ></file-upload-placeholder>
          </ng-template>
          <ng-template let-file="file" let-control="control" #item>
            <file-upload-item
              [file]="file"
              [control]="control"
            ></file-upload-item>
          </ng-template>
        </file-upload>
        @if (error) {
          <p class="error-message">{{ error }}</p>
        }
        <br />
        <div class="phone-field">
          <mat-checkbox
            color="primary"
            (change)="checkboxChanged($event)"
            class="lighter simple-theme"
            >{{
              "app.navigation.send-message-dialog.call-me" | transloco
            }}</mat-checkbox
          >
          <mat-form-field class="number-form-field" subscriptSizing="dynamic">
            <input
              matInput
              class="number-field"
              formControlName="phone"
              type="tel"
              [placeholder]="
                'app.navigation.send-message-dialog.call-me-placeholder'
                  | transloco
              "
            />
          </mat-form-field>
        </div>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions class="btn-wrapper">
      <button mat-button mat-dialog-close class="btn cancel">
        {{ "default_buttons.cancel" | transloco }}
      </button>
      <div
        [matTooltip]="
          checked && !messageGroup.controls.phone.value
            ? ('app.navigation.send-message-dialog.phone-tooltip' | transloco)
            : ''
        "
        matTooltipClass="top-navbar-tooltip send-dialog"
        class="send-wrapper"
      >
        <button
          mat-flat-button
          color="primary"
          class="btn send"
          [disabled]="
            (checked && !messageGroup.controls.phone.value) ||
            !messageGroup.valid
          "
          (click)="sendMessage()"
        >
          {{ "app.navigation.send-message-dialog.send" | transloco }}
          <mat-icon class="icon">chevron_right</mat-icon>
        </button>
      </div>
    </mat-dialog-actions>
  </div>
} @else {
  <sent-success></sent-success>
}
