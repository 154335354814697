<mat-form-field class="field">
  <mat-label>{{
    translations["write.blocks.dish-overview.information.article"] ||
      ("write.blocks.dish-overview.information.article" | transloco)
  }}</mat-label>
  <input matInput type="text" name="article" [formControl]="control" />
  @if (loading) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }
</mat-form-field>
