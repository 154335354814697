import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { SwiperContainer, register } from 'swiper/element/bundle';
import { A11y, Navigation } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';

@Directive({
  selector: '[winSwiper]',
  standalone: true,
})
export class SwiperDirective implements AfterViewInit {
  @Input() config: SwiperOptions = {};

  defaultConfig: SwiperOptions = {
    modules: [Navigation, A11y],
    spaceBetween: 30,
    slidesPerGroup: 1,
    breakpoints: {
      200: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      950: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1400: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      1600: {
        slidesPerView: 5,
        spaceBetween: 20,
      },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: false,
    scrollbar: {
      hide: true,
    },
  };
  private readonly swiperElement: SwiperContainer;

  constructor(private el: ElementRef<SwiperContainer>) {
    register();
    this.swiperElement = this.el.nativeElement;
  }

  ngAfterViewInit() {
    Object.assign(this.swiperElement, {
      ...this.defaultConfig,
      ...this.config,
    });
    setTimeout(() => {
      this.swiperElement?.initialize?.();
    }, 0);
  }

  update() {
    setTimeout(() => {
      if (this.swiperElement.swiper) {
        this.swiperElement.swiper.update();
        this.swiperElement.swiper.navigation?.update();
        // this.swiperElement.swiper.beforeResize = (ev) => {};
      } else {
        this.swiperElement?.initialize?.();
        this.swiperElement?.swiper.navigation?.update();
      }
    });
  }
}
