import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { currencyList } from 'src/app/shared/Models/user';
import { selectUser } from 'src/app/shared/user/ngrx/user.selectors';
import { Subject } from 'rxjs';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

export interface TrialBlockBoxDeps {
  currencySymbol?: string;
  minPrice?: number;
  currency?: string;
}

@Component({
  selector: 'trial-block-box',
  templateUrl: './trial-block-box.component.html',
  styleUrls: ['./trial-block-box.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule, TranslocoPipe],
})
export class TrialBlockBoxComponent implements OnInit, OnDestroy {
  @Input() deps: TrialBlockBoxDeps = {};
  @Output() actionClicked = new EventEmitter();
  @Output() closePanel = new EventEmitter();
  @Output() completeProfile = new EventEmitter();

  private destroyed$ = new Subject<void>();
  user$ = this.ngrxStore.select(selectUser);

  constructor(private ngrxStore: Store<State>) {}

  ngOnInit(): void {
    this.user$.subscribe((userDetail) => {
      this.deps = currencyList.find(
        (currencyDetail) =>
          currencyDetail.currency === userDetail?.status?.subscription_currency,
      );
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
