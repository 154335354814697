<h3>
  {{ "style.chosen-rule.title" | transloco }}
  <mat-icon [mtTooltip]="'style.chosen-rule.info' | transloco">info</mat-icon>
</h3>
<mat-card class="card">
  <div class="element">
    <div class="name-text">
      <span>{{ menu?.rule_detail[lang] }}</span>
    </div>
    <div class="button-wrapper">
      <button
        mat-stroked-button
        class="style-button"
        (click)="updateRuleStyle()"
      >
        {{
          "style.choose-style." +
            (menu?.rule_detail?.style ? "update" : "create") | transloco
        }}
      </button>
      @if (menu?.rule_detail?.style || styleCreated) {
        <button class="restore" mat-flat-button (click)="restoreStyle()">
          <mat-icon [mtTooltip]="'style.choose-style.restore' | transloco"
            >settings_backup_restore</mat-icon
          >
        </button>
      }
    </div>
  </div>
</mat-card>
