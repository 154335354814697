<form [formGroup]="registrationForm">
  <div class="label">
    <mat-icon class="icon">email</mat-icon>
    <mat-form-field
      [hintLabel]="formErrors.email"
      [color]="formErrors.email ? 'warn' : 'primary'"
      class="input email"
      [class.error-shown]="formErrors.email"
    >
      <mat-label>{{
        "auth.registration.fields.email.placeholder" | transloco
      }}</mat-label>
      <input
        matInput
        autocomplete="email"
        name="email"
        formControlName="email"
        minlength="2"
        maxlength="100"
        required
        type="email"
        (focusout)="checkEmail($event.target)"
      />
    </mat-form-field>
    @if (isEmailExist) {
      <div
        class="email-error email-exist"
        [innerHTML]="
          'auth.registration.fields.email.validation.email_already_exists'
            | transloco
        "
      ></div>
    }
    @if (
      !isEmailExist &&
      registrationForm?.get("email")?.errors?.did_you_mean_blank &&
      errorsLength(registrationForm?.get("email")) === 1
    ) {
      <div class="email-error did-you-mean">
        {{
          "auth.registration.fields.email.validation.did_you_mean" | transloco
        }}:
        <a (click)="replaceEmail()">{{
          registrationForm?.get("email")?.errors?.did_you_mean_blank
        }}</a>
      </div>
    }
    @if (
      !isEmailExist &&
      emailVerification?.smtp_check === false &&
      errorsLength(registrationForm?.get("email")) === 0
    ) {
      <div class="email-error">
        {{ "auth.registration.fields.email.validation.smtp_check" | transloco }}
      </div>
    }
  </div>
  <div class="label">
    <mat-icon class="icon">personal</mat-icon>
    <mat-form-field
      [hintLabel]="formErrors.first_name"
      [color]="formErrors.first_name ? 'warn' : 'primary'"
      class="input first-name"
    >
      <mat-label>{{
        "auth.registration.fields.first_name.placeholder" | transloco
      }}</mat-label>
      <input
        matInput
        formControlName="first_name"
        autocomplete="given-name"
        minlength="2"
        maxlength="30"
        name="first_name"
        required
        type="text"
      />
    </mat-form-field>
  </div>
  <div class="label">
    <mat-icon class="icon">personal</mat-icon>
    <mat-form-field
      [hintLabel]="formErrors.last_name"
      [color]="formErrors.last_name ? 'warn' : 'primary'"
      class="input last-name"
    >
      <mat-label>{{
        "auth.registration.fields.last_name.placeholder" | transloco
      }}</mat-label>
      <input
        matInput
        formControlName="last_name"
        autocomplete="family-name"
        minlength="2"
        maxlength="30"
        name="last_name"
        required
        type="text"
      />
    </mat-form-field>
  </div>
  <div class="label">
    <mat-icon class="icon">lock</mat-icon>
    <mat-form-field
      [hintLabel]="formErrors.password1"
      [color]="formErrors.password1 ? 'warn' : 'primary'"
      class="input password-1"
    >
      <mat-label>{{
        "auth.registration.fields.password1.placeholder" | transloco
      }}</mat-label>
      <input
        matInput
        formControlName="password1"
        autocomplete="new-password"
        minlength="8"
        required
        name="password1"
        type="password"
      />
    </mat-form-field>
  </div>
  <div class="label">
    <mat-icon class="icon">lock</mat-icon>
    <mat-form-field
      [hintLabel]="formErrors.password2"
      [color]="formErrors.password2 ? 'warn' : 'primary'"
      class="input password-2"
    >
      <mat-label>{{
        "auth.registration.fields.password2.placeholder" | transloco
      }}</mat-label>
      <input
        matInput
        formControlName="password2"
        autocomplete="new-password"
        minlength="8"
        required
        name="password2"
        type="password"
      />
    </mat-form-field>
  </div>
  <div class="label terms">
    <mat-checkbox
      color="primary"
      id="terms"
      class="terms"
      formControlName="terms"
      name="terms"
      required
    >
      <a target="_blank" rel="noopener" [href]="links[lang]" class="terms-link">
        {{ "auth.registration.fields.terms.placeholder" | transloco }}
      </a>
    </mat-checkbox>
  </div>
  <mat-error class="error">{{ formErrors.terms }}</mat-error>

  <button
    mat-flat-button
    color="primary"
    class="button-spinner"
    type="submit"
    [disabled]="registerButtonState"
    (click)="onSubmit($event)"
  >
    <span class="button-text">
      @if (registerButtonState) {
        <spinner class="inner-spinner" [config]="{ radius: 10 }"></spinner>
      }
      {{ "auth.registration.actions.register" | transloco }}
    </span>
  </button>
</form>
