<div
  #tooltip="matTooltip"
  (click)="createClicked(createButton, tooltip)"
  [matTooltip]="
    (userStatus$ | async)?.message || (menuStatus$ | async)?.message
  "
  class="top-right-corner"
  matTooltipClass="top-navbar-tooltip"
>
  <button
    #createButton
    #menuTrigger="matMenuTrigger"
    mat-fab
    extended
    color="primary"
    class="button"
    [class.hidden]="hideButton"
    [class.small]="small"
    [disabled]="
      !(privileges$ | async)?.create_menu || !(userStatus$ | async)?.ready
    "
    [matMenuTriggerFor]="createMenu"
    (touchstart)="showTooltip(tooltip)"
  >
    <mat-icon aria-hidden="true" class="icons">create</mat-icon>
    @if (!small) {
      <span class="text">{{
        "menus.overview.actions.create" | transloco
      }}</span>
    }
  </button>
</div>
<mat-menu
  class="create-menu"
  #createMenu="matMenu"
  [overlapTrigger]="false"
  [backdropClass]="backdrop"
  [class]="small ? 'write-new-menu-nav-closed' : 'write-new-menu-nav-opened'"
>
  @if (defaultTemplates !== null && defaultTemplates.length === 0) {
    <div class="special-group empty-notice">
      {{ "menus.create.no-results" | transloco }}
    </div>
  }
  @if (defaultTemplates !== null) {
    @for (group of defaultTemplates | groupBy: "time_limited"; track group) {
      @if (group.key === "true") {
        <hr />
        <div class="special-group">
          {{ "menus.create.events" | transloco }}:
        </div>
      }
      @for (template of group.value; track template; let i = $index) {
        <write-new-menu-item
          (showDatepicker)="showDatepicker($event)"
          [disabled]="
            !(privileges$ | async).create_menu ||
            ((limitTemplates$ | async) &&
              !(templatesUsed$ | async).includes(template.id))
          "
          [lang]="lang"
          [statusMessage]="(templateStatus$ | async)?.message"
          [template]="template"
        ></write-new-menu-item>
      }
    }
  } @else {
    <div class="loading"><spinner radius="20"></spinner></div>
  }
  <ng-template #loading
    ><div class="loading"><spinner radius="20"></spinner></div
  ></ng-template>

  @if (dataImportTemplates.length || (hasModule("data") && provider)) {
    <div class="create-menu-text border-top">
      {{ "menus.create.upload.title" | transloco }}:
    </div>
  }

  @for (template of dataImportTemplates; track template) {
    <div mat-menu-item class="create-menu-item upload-menu">
      <file-upload
        class="file-upload"
        [control]="fileUploadControl"
        (onDrop)="setCurrentDataImportTemplate(template.id)"
        (click)="setCurrentDataImportTemplate(template.id)"
      >
        <ng-template #placeholder>
          {{ template[lang] }}
          <mat-icon aria-hidden="true" class="icons end"
            >keyboard_arrow_right</mat-icon
          >
        </ng-template>
      </file-upload>
    </div>
  }

  @if (hasModule("data") && provider) {
    <div mat-menu-item class="create-menu-item upload-menu">
      <file-upload class="file-upload" [control]="fileUploadControl">
        <ng-template #placeholder>
          {{ "menus.create.upload." + provider | transloco }}
          <mat-icon aria-hidden="true" class="icons end"
            >keyboard_arrow_right</mat-icon
          >
        </ng-template>
      </file-upload>
    </div>
  }

  <input
    #inputPicker
    matInput
    [matDatepicker]="picker"
    class="hide"
    (dateChange)="createMenuEvent($event.value, currentTemplate)"
  />
  <mat-datepicker touchUi="true" #picker></mat-datepicker>
</mat-menu>
