<div class="row">
  @if (menusCreated) {
    <app-menus-counter
      class="stat-item"
      [counter]="menusCreated"
    ></app-menus-counter>
  }
  @if (lastMenu) {
    <app-last-menu
      class="stat-item"
      [menu]="lastMenu"
      (previewMenu)="previewMenu.emit($event)"
    ></app-last-menu>
  }
  <!--<app-popular-menu *ngIf="popularDish" class="stat-item" [dish]="popularDish"></popular-menu>-->
</div>
