<div (mousedown)="stopFocusOut($event)" class="wrapper icon-button-density-2">
  <div class="item">
    <div class="base">
      <small>{{ upperData }}</small>
    </div>
    <div class="similar">{{ lowerData }}</div>
  </div>
  <button
    mat-icon-button
    class="copy-button"
    (mousedown)="stopFocusOut($event)"
    ngxClipboard
    [cbContent]="lowerData"
    (click)="triggerCopy.emit(lowerData)"
  >
    <mat-icon class="icon"> content_copy </mat-icon>
  </button>
</div>
