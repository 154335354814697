import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  MatExpansionPanel,
  MatExpansionModule,
} from '@angular/material/expansion';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MtTooltipDirective } from '../../Directives/mt-tooltip/mt-tooltip.directive';
import { MatIconModule } from '@angular/material/icon';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'magic-stick',
  templateUrl: './magic-stick.component.html',
  styleUrls: ['./magic-stick.component.scss'],
  standalone: true,
  imports: [
    MatExpansionModule,
    SpinnerComponent,
    MatIconModule,
    MtTooltipDirective,
    MatButtonModule,
    TranslocoPipe,
  ],
})
export class MagicStickComponent implements OnChanges {
  @Input() ai = false;
  @Input() aiCredits = 1;
  @Input() aiCreditsRemaining: number;
  @Input() customIcon: string;
  @Input() loading = false;
  @Input() disabled = false;
  @Input() title: string;
  @Input() description: string;
  @Input() buttonMsg: string;
  @Input() buttonLoadingMsg: string;
  @Input() tooltip: string;

  @Output() clicked = new EventEmitter();

  @ViewChild(`panel`) panel: MatExpansionPanel;

  collapse: () => void;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.loading && !changes.loading.firstChange && !this.loading) {
      this.collapse?.();
      this.collapse = undefined;
    }
  }

  emitAndCollapse = (event: MouseEvent) => {
    event?.stopPropagation();
    event?.stopImmediatePropagation();
    if (!this.loading) {
      if (!this.disabled) this.clicked.emit();
      this.panel.expanded = true;
      this.collapse = () => (this.panel.expanded = false);
    }
  };
}
