<span class="step-number">
  <button
    (click)="prev.emit()"
    [style.display]="first ? 'none' : 'inline-block'"
    class="arrow"
    mat-icon-button
  >
    <mat-icon>arrow_back</mat-icon>
  </button>
  {{ "auth.registration.steps.step" | transloco }}
  {{ currentStep }}/2
  <button
    (click)="next.emit()"
    [disabled]="disabled"
    class="arrow"
    mat-icon-button
  >
    <mat-icon>arrow_forward</mat-icon>
  </button>
</span>
