<mat-toolbar class="toolbar">
  <div class="logo">
    <a routerLink="/dashboard">
      <img class="big" [src]="partner?.logo?.src" [alt]="partner?.logo?.alt" />
    </a>
  </div>
  <div class="right">
    @if (
      user?.status?.trial_period ||
      (!user?.status?.subscription_active && !user?.status?.subscription_oneoff)
    ) {
      <button
        mat-flat-button
        color="primary"
        class="upgrade-btn"
        (click)="goToBilling()"
      >
        {{ "app.navigation.help.upgrade-button" | transloco }}
      </button>
    }
    <div class="inner">
      @if (false) {
        <a
          [matTooltip]="'shared.top-navbar.notifications' | transloco"
          matTooltipClass="top-navbar-tooltip"
          class="navigation__option link"
        >
          <mat-icon aria-hidden="true" class="icons">notifications</mat-icon>
        </a>
      }
      <a
        [mtTooltip]="'shared.top-navbar.settings' | transloco"
        class="navigation__option link"
        [routerLink]="['/settings', 'profile']"
      >
        <mat-icon aria-hidden="true" class="icons">settings</mat-icon>
      </a>
      <a
        #helpTooltip
        [mtTooltip]="currentTooltipKey | transloco"
        [isHtml]="canCloseHelpTooltip"
        [canClose]="canCloseHelpTooltip"
        (closed)="canCloseHelpTooltip && helpTooltipClosed()"
        class="navigation__option link"
        [matMenuTriggerFor]="helpMenu"
      >
        <mat-icon aria-hidden="true" class="icons">help</mat-icon>
      </a>
    </div>
    <a
      #tooltip
      class="navigation__option link"
      matTooltipClass="top-navbar-tooltip"
      [mtTooltip]="'shared.top-navbar.logout.button' | transloco"
      [matMenuTriggerFor]="logoutMenu"
      [routerLinkActive]="['active']"
    >
      <mat-icon aria-hidden="true" class="icons">exit_to_app</mat-icon>
    </a>
  </div>
</mat-toolbar>

<mat-menu #helpMenu="matMenu" class="help-menu-panel">
  <span class="text">{{ "app.navigation.help.title" | transloco }}</span>
  @if (user?.accountsettings?.handbook) {
    <div mat-menu-item class="menu-button">
      <a
        class="menu-button-text"
        target="_blank"
        [href]="user?.accountsettings?.handbook"
        >{{ "app.navigation.help.download-manual" | transloco }}
        <mat-icon class="menu-button-icon">chevron_right</mat-icon></a
      >
    </div>
  }
  <div mat-menu-item class="menu-button">
    <a class="menu-button-text" target="_blank" [href]="helpUrls[lang]"
      >{{ "app.navigation.help.help-centre" | transloco }}
      <mat-icon class="menu-button-icon">chevron_right</mat-icon></a
    >
  </div>
  <div mat-menu-item class="menu-button">
    <a class="menu-button-text" (click)="showMessageOverlay()"
      >{{ "app.navigation.help.chat-now" | transloco }}
      <mat-icon class="menu-button-icon">chevron_right</mat-icon></a
    >
  </div>
  <div mat-menu-item class="menu-button">
    <a class="menu-button-text" (click)="sendMessage()"
      >{{ "app.navigation.help.send-message" | transloco }}
      <mat-icon class="menu-button-icon">chevron_right</mat-icon></a
    >
  </div>
</mat-menu>

<mat-menu #logoutMenu="matMenu" class="help-menu-panel logout-panel">
  <span class="text">{{ "shared.top-navbar.logout.title" | transloco }}</span>
  <div mat-menu-item class="menu-button">
    <a class="menu-button-text" (click)="logout.emit()"
      >{{ "shared.top-navbar.logout.button" | transloco }}
      <mat-icon class="menu-button-icon">chevron_right</mat-icon></a
    >
  </div>
</mat-menu>
