<section [class.add-bottom-space]="addBottomSpace">
  <mat-accordion>
    @for (match of spellcheck.matches; track match) {
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong
              [innerHTML]="
                wrapText(spellcheck.original, match.offset, match.length)
              "
            ></strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <header class="title">
            <span class="spellcheck-error">"{{ match.message }}"</span>
          </header>
          <button
            class="ignore-button add-button"
            dir="ltr"
            mat-button
            (click)="
              addWord.emit(
                spellcheck.original.substring(
                  match.offset,
                  match.offset + match.length
                )
              )
            "
          >
            <mat-icon>add</mat-icon
            >{{ "dishes.grammar-list.add-to-dictionary" | transloco }}
          </button>
          @if (match.replacements.length > 0) {
            <header class="title">
              <span>{{ "dishes.grammar-list.did-you-mean" | transloco }}:</span>
            </header>
          }
          @for (replacement of match.replacements; track replacement) {
            <copy-data-line
              [upperData]="spellcheck.original"
              [lowerData]="replacement"
              (triggerCopy)="copied($event)"
            ></copy-data-line>
          }
        </ng-template>
      </mat-expansion-panel>
    }
  </mat-accordion>
  <button
    class="ignore-button"
    mat-button
    (click)="ignoreSpellcheck.emit()"
    dir="ltr"
  >
    <mat-icon>spellcheck</mat-icon
    >{{ "dishes.grammar-list.ignore-button" | transloco }}
  </button>
</section>
