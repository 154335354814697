import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { selectCurrentMenu } from 'src/app/menus/menu-edit/ngrx/menu-edit.selectors';
import { TreeManagerService } from 'src/app/menus/tree-manager.service';
import { State } from 'src/app/reducers';
import { Dish } from 'src/app/shared/Models/dish';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { MenuDish, SimpleMenuDish } from 'src/app/shared/Models/menudish';
import { Separator } from 'src/app/shared/Models/separator';
import {
  handleHttpError,
  showSnackbarMessage,
} from 'src/app/shared/ngrx/shared.actions';
import { ConfigService } from 'src/app/shared/Services/config/config.service';
import { GenericsService } from 'src/app/shared/Services/generics/generics.service';
import { selectUser } from 'src/app/shared/user/ngrx/user.selectors';
import { EMPTY } from 'rxjs';
import {
  catchError,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { setCurrentTranslations } from '../../translate/ngrx/menu-translate.actions';
import * as MenuActions from './menu-write.actions';
import { selectMenuDishes } from './menu-write.selectors';
import { clearCurrentMenu, getCurrentMenu } from '../../ngrx/menu-edit.actions';

@Injectable()
export class MenuWriteEffects {
  fetchMenuDishes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.fetchMenuDishes),
      tap(({ params }) => {
        this.store.dispatch(MenuActions.setLoading({ loading: true }));
        const { condensed, ...rest } = params ?? {};
        this.store.dispatch(
          MenuActions.setSearch({ search: Object.keys(rest).length > 0 }),
        );
        this.treeManager.clearTree();
      }),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      switchMap(([{ params }, currentMenu]) =>
        this.genericsService
          .get<(SimpleMenuDish | MenuDish)[]>(currentMenu.dishes, params)
          .pipe(
            mergeMap((newDishes: MenuDish[]) => [
              MenuActions.setLoading({ loading: false }),
              MenuActions.setMenuDishes({ menuDishes: newDishes || [] }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  fetchMenuDishesFull$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.fetchMenuDishesFull),
      tap(() => {
        this.store.dispatch(MenuActions.setLoadingFull({ loading: true }));
      }),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      switchMap(([{ params }, currentMenu]) =>
        this.genericsService
          .get<MenuDish[]>(currentMenu.dishes, params ?? {})
          .pipe(
            mergeMap((newDishes: MenuDish[]) => [
              MenuActions.setLoadingFull({ loading: false }),
              MenuActions.setMenuDishesFull({ menuDishes: newDishes || [] }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  fetchMenuDish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.fetchMenuDish),
      mergeMap(({ url, onFulfilled }) =>
        this.genericsService.get<MenuDish>(url).pipe(
          mergeMap((menuDish: MenuDish) => {
            onFulfilled?.(menuDish);
            return [MenuActions.changeMenuDish({ id: menuDish.id, menuDish })];
          }),
          catchError((error) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  createMenuDish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.createMenuDish),
      withLatestFrom(
        this.store.select(selectCurrentMenu),
        this.store.select(selectMenuDishes),
      ),
      tap(() => {
        this.store.dispatch(MenuActions.setIsSorting({ value: true }));
      }),
      mergeMap(
        ([
          { menuDish, sendSortRequest, onFulfilled, onError },
          currentMenu,
          currentMenudishes,
        ]) =>
          this.genericsService.post(currentMenu.dishes, menuDish).pipe(
            mergeMap((result: MenuDish) => {
              onFulfilled?.(result);
              this.store.dispatch(MenuActions.setIsSorting({ value: false }));
              return [
                MenuActions.changeMenuDish({
                  id: menuDish.id,
                  menuDish: result,
                }),
                ...(sendSortRequest ? [MenuActions.sortMenuDishes({})] : []),
              ];
            }),
            catchError((error) => {
              if (error.status === 409) {
                this.treeManager.clearTree();
                this.treeManager.setSource({
                  list: currentMenudishes,
                  search: false,
                });
              }
              onError?.();
              this.store.dispatch(MenuActions.setIsSorting({ value: false }));
              return [handleHttpError({ error })];
            }),
          ),
      ),
    ),
  );

  createMenuDishWithAi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.createMenuDishWithAi),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      mergeMap(
        ([
          { menuDish, section, sendSortRequest, onFulfilled, onError },
          currentMenu,
        ]) =>
          this.genericsService
            .post(currentMenu.dishes + 'ai_recommend/', {
              ...menuDish,
              section,
            })
            .pipe(
              mergeMap((result: MenuDish) => {
                onFulfilled?.(result);
                return [
                  MenuActions.changeMenuDish({
                    id: menuDish.id,
                    menuDish: result,
                  }),
                  ...(sendSortRequest ? [MenuActions.sortMenuDishes({})] : []),
                ];
              }),
              catchError((error) => {
                onError?.();
                return [handleHttpError({ error })];
              }),
            ),
      ),
    ),
  );

  duplicateMenuDish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.duplicateMenuDish),
      tap(() => {
        this.store.dispatch(MenuActions.setLoading({ loading: true }));
        this.treeManager.clearTree();
      }),
      switchMap(({ url }) =>
        this.genericsService.post<{}, MenuDish[]>(`${url}duplicate/`, {}).pipe(
          mergeMap((newDishes) => [
            MenuActions.setLoading({ loading: false }),
            MenuActions.setMenuDishes({ menuDishes: newDishes }),
          ]),
          catchError((error) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  applyPreset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.applyPreset),
      tap(() => {
        this.store.dispatch(MenuActions.setLoading({ loading: true }));
        this.treeManager.clearTree();
      }),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      switchMap(([{ preset }, currentMenu]) =>
        this.genericsService
          .post<{ preset: number }, MenuDish[]>(
            currentMenu.url + 'apply_preset/',
            {
              preset,
            },
          )
          .pipe(
            mergeMap((menuDishes) => [
              MenuActions.setLoading({ loading: false }),
              MenuActions.setMenuDishes({ menuDishes }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  regenerateMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.regenerateMenu),
      tap(() => {
        this.store.dispatch(MenuActions.setLoading({ loading: true }));
        this.treeManager.clearTree();
      }),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      switchMap(([{}, currentMenu]) =>
        this.genericsService
          .post<{}, MenuDish[]>(currentMenu.url + 'regenerate/', {})
          .pipe(
            mergeMap((menuDishes) => {
              const message = this.transloco.translate(
                'menus.overview.refresh_sub_menu.done',
              );
              return [
                showSnackbarMessage({ message }),
                MenuActions.setLoading({ loading: false }),
                MenuActions.setMenuDishes({ menuDishes }),
              ];
            }),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  refreshMenuDish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.refreshMenuDish),
      mergeMap(({ url, onFulfilled }) =>
        this.genericsService.get<MenuDish>(url).pipe(
          mergeMap((menuDish: MenuDish) => {
            onFulfilled?.(menuDish);
            return [MenuActions.changeMenuDish({ id: menuDish.id, menuDish })];
          }),
          catchError((error) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  updateMenuDish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.updateMenuDish),
      withLatestFrom(this.store.select(selectMenuDishes)),
      tap(() => {
        this.store.dispatch(MenuActions.setIsSorting({ value: true }));
      }),
      mergeMap(([{ url, menuDish, onFulfilled, onError }, currentMenudishes]) =>
        this.genericsService
          .patch<DeepPartial<MenuDish>, MenuDish>(url, menuDish)
          .pipe(
            mergeMap((result: MenuDish) => {
              onFulfilled?.(result);
              this.store.dispatch(MenuActions.setIsSorting({ value: false }));
              return [
                MenuActions.changeMenuDish({
                  id: result.id,
                  menuDish: result,
                }),
              ];
            }),
            catchError((error) => {
              if (error.status === 409) {
                this.treeManager.clearTree();
                this.treeManager.setSource({
                  list: currentMenudishes,
                  search: false,
                });
              }
              onError?.();
              this.store.dispatch(MenuActions.setIsSorting({ value: false }));
              return [handleHttpError({ error })];
            }),
          ),
      ),
    ),
  );

  sortMenuDishes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.sortMenuDishes),
      withLatestFrom(
        this.store.select(selectCurrentMenu),
        this.store.select(selectMenuDishes),
      ),
      tap(() => {
        this.store.dispatch(MenuActions.setIsSorting({ value: true }));
      }),
      switchMap(
        ([{ menuDishes, onFulfilled }, currentMenu, currentMenudishes]) => {
          const currentMenuDishes = (menuDishes ?? currentMenudishes)
            .filter(
              (dish) =>
                !!dish.url && dish.level !== undefined && dish.level > 0,
            )
            .map((dish) => ({ id: dish.id, level: dish.level }));
          if (!currentMenuDishes.length) return EMPTY;
          return this.genericsService
            .post<
              { items: { id: number; level: number }[] },
              { message: string }
            >(currentMenu.dishes + 'sort/', { items: currentMenuDishes })
            .pipe(
              tap(() => {
                onFulfilled?.();
                this.store.dispatch(MenuActions.setIsSorting({ value: false }));
              }),
              switchMap(() => [
                MenuActions.setMenuDishes({
                  menuDishes: [...(menuDishes ?? currentMenudishes)],
                }),
              ]),
              catchError((error) => {
                if (error.status === 409) {
                  this.treeManager.clearTree();
                  this.treeManager.setSource({
                    list: currentMenudishes,
                    search: false,
                  });
                }
                return error.status === 404
                  ? [
                      MenuActions.fetchMenuDishes({
                        params: { condensed: true },
                      }),
                    ]
                  : [handleHttpError({ error })];
              }),
            );
        },
      ),
    ),
  );

  deleteMenuDish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.deleteMenuDish),
      tap(() => {
        this.store.dispatch(MenuActions.setIsSorting({ value: true }));
      }),
      mergeMap(({ menudish, onFulfilled, onError }) =>
        this.genericsService.delete(menudish.url).pipe(
          tap(() => {
            onFulfilled?.();
            this.store.dispatch(MenuActions.setIsSorting({ value: false }));
          }),
          mergeMap(() => [MenuActions.removeMenuDish({ id: menudish.id })]),
          catchError((error) => {
            onError?.();
            this.store.dispatch(MenuActions.setIsSorting({ value: false }));
            return [handleHttpError({ error })];
          }),
        ),
      ),
    ),
  );

  deleteMenuDishes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.deleteMenuDishes),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      mergeMap(([{ ids, onFulfilled }, currentMenu]) =>
        this.genericsService
          .post(currentMenu.dishes + 'delete/', { items: ids })
          .pipe(
            mergeMap(() => {
              onFulfilled?.();
              return [MenuActions.removeMenuDishes({ ids })];
            }),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  createDish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.createDish),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      mergeMap(
        ([
          { dish, menuDish, sendSortRequest, onFulfilled, onError },
          currentMenu,
        ]) =>
          this.genericsService
            .post<DeepPartial<Dish>, Dish>(this.configService.dishes, dish, {
              condensed: true,
              current_menu: currentMenu.id,
            })
            .pipe(
              mergeMap((newDish: Dish) => {
                const newMenuDish = new MenuDish(menuDish).setDish(newDish);
                if (!sendSortRequest) {
                  delete newMenuDish['level'];
                  delete newMenuDish['order'];
                }
                if (menuDish.url) {
                  return [
                    MenuActions.updateMenuDish({
                      url: menuDish.url,
                      id: menuDish.id,
                      menuDish: newMenuDish,
                      onFulfilled,
                      onError,
                    }),
                  ];
                } else {
                  return [
                    MenuActions.createMenuDish({
                      menuDish: newMenuDish,
                      sendSortRequest,
                      onFulfilled,
                      onError,
                    }),
                  ];
                }
              }),
              catchError((error) => {
                onError?.();
                return [
                  handleHttpError({ error }),
                  MenuActions.fetchMenuDishes({ params: { condensed: true } }),
                ];
              }),
            ),
      ),
    ),
  );

  createSeparator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.createSeparator),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      mergeMap(
        ([
          { separator, menuDish, sendSortRequest, onFulfilled, onError },
          currentMenu,
        ]) =>
          this.genericsService
            .post<
              DeepPartial<Separator>,
              Separator
            >(this.configService.separators, separator, { condensed: true, current_menu: currentMenu.id })
            .pipe(
              mergeMap((newSeparator: Separator) => {
                const newMenuDish = new MenuDish(menuDish).setSeparator(
                  newSeparator,
                );
                if (!sendSortRequest) {
                  delete newMenuDish['level'];
                  delete newMenuDish['order'];
                }
                if (menuDish.url) {
                  return [
                    MenuActions.updateMenuDish({
                      url: menuDish.url,
                      id: menuDish.id,
                      menuDish: newMenuDish,
                      onFulfilled,
                      onError,
                    }),
                  ];
                } else {
                  return [
                    MenuActions.createMenuDish({
                      menuDish: newMenuDish,
                      sendSortRequest,
                      onFulfilled,
                      onError,
                    }),
                  ];
                }
              }),
              catchError((error) => {
                onError?.();
                return [
                  handleHttpError({ error }),
                  MenuActions.fetchMenuDishes({ params: { condensed: true } }),
                ];
              }),
            ),
      ),
    ),
  );

  updateDish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.updateDish),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      mergeMap(
        ([
          {
            url,
            id,
            dish,
            menuDish,
            searchIsActive,
            params,
            onFulfilled,
            onError,
          },
          currentMenu,
        ]) =>
          this.genericsService
            .patch<DeepPartial<Dish>, Dish>(url, dish, {
              current_menu: currentMenu.id,
              ...(params ?? {}),
            })
            .pipe(
              mergeMap((newDish: Dish) => {
                const newMenuDish = new MenuDish(menuDish).setDish(newDish);
                if (searchIsActive) {
                  delete newMenuDish['level'];
                  delete newMenuDish['order'];
                }
                if (newDish.id !== id) {
                  return [
                    MenuActions.updateMenuDish({
                      url: menuDish.url,
                      id: menuDish.id,
                      menuDish: {
                        dish: newMenuDish.dish,
                        separator: newMenuDish.separator,
                      },
                      onFulfilled,
                      onError,
                    }),
                  ];
                } else {
                  onFulfilled?.(newMenuDish);
                  return [
                    MenuActions.changeMenuDish({
                      id: menuDish.id,
                      menuDish: newMenuDish,
                    }),
                  ];
                }
              }),
              catchError((error) => {
                onError?.();
                return [
                  handleHttpError({ error }),
                  MenuActions.refreshMenuDish({ url: menuDish.url }),
                ];
              }),
            ),
      ),
    ),
  );

  updateSeparator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.updateSeparator),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      mergeMap(
        ([
          {
            url,
            id,
            separator,
            menuDish,
            searchIsActive,
            params,
            onFulfilled,
            onError,
          },
          currentMenu,
        ]) =>
          this.genericsService
            .patch<DeepPartial<Separator>, Separator>(url, separator, {
              current_menu: currentMenu.id,
              ...(params ?? {}),
            })
            .pipe(
              mergeMap((newSeparator: Separator) => {
                const newMenuDish = new MenuDish(menuDish).setSeparator(
                  newSeparator,
                );
                if (searchIsActive) {
                  delete newMenuDish['level'];
                  delete newMenuDish['order'];
                }
                if (newSeparator.id !== id) {
                  return [
                    MenuActions.updateMenuDish({
                      url: menuDish.url,
                      id: menuDish.id,
                      menuDish: newMenuDish,
                      onFulfilled,
                      onError,
                    }),
                  ];
                } else {
                  onFulfilled?.(newMenuDish);
                  return [
                    MenuActions.changeMenuDish({
                      id: menuDish.id,
                      menuDish: newMenuDish,
                    }),
                  ];
                }
              }),
              catchError((error) => {
                onError?.();
                return [
                  handleHttpError({ error }),
                  MenuActions.refreshMenuDish({ url: menuDish.url }),
                ];
              }),
            ),
      ),
    ),
  );

  addWordToUserDictionary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.addWordToUserDictionary),
      withLatestFrom(this.store.select(selectUser)),
      mergeMap(([{ word, text, lang, menuDish, onFulfilled }, user]) =>
        this.genericsService
          .post(user.url + 'add_word/', { word, text, language: lang })
          .pipe(
            mergeMap(() => {
              const newMenuDish = new MenuDish(menuDish);
              (
                newMenuDish.dish_detail ?? newMenuDish.separator_detail
              ).user_details[`spellcheck_${lang}`] = null;
              onFulfilled?.(newMenuDish);
              return [
                MenuActions.changeMenuDish({
                  id: newMenuDish.id,
                  menuDish: newMenuDish,
                }),
                setCurrentTranslations({ menuDish: newMenuDish }),
              ];
            }),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  addOption$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.addOption),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      switchMap(([{ dish, declaration, menuDish, onFulfilled }, currentMenu]) =>
        this.genericsService
          .patch<DeepPartial<Dish>, Dish>(
            dish.url,
            {
              [declaration]: dish[declaration],
            },
            { current_menu: currentMenu.id },
          )
          .pipe(
            switchMap((dish: Dish) => {
              const newMenuDish = new MenuDish(menuDish).setDish(dish);
              onFulfilled?.(newMenuDish);
              return [
                MenuActions.changeMenuDish({
                  id: newMenuDish.id,
                  menuDish: newMenuDish,
                }),
              ];
            }),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  copyDeclaration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.copyDeclaration),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      mergeMap(([{ target, source, declaration, onFulfilled }, currentMenu]) =>
        this.genericsService
          .post<{ type: 'all' | 'add'; dish: number }, Dish>(
            target.dish_detail.url + 'copy_declarations/',
            {
              type: declaration,
              dish: source.id,
            },
            { current_menu: currentMenu.id },
          )
          .pipe(
            mergeMap((dish: Dish) => {
              const newMenuDish = new MenuDish(target).setDish(dish);
              onFulfilled?.(newMenuDish);
              return [
                MenuActions.changeMenuDish({
                  id: newMenuDish.id,
                  menuDish: newMenuDish,
                }),
              ];
            }),
          ),
      ),
    ),
  );

  synchroniseRecipeDeclarations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.synchroniseRecipeDeclarations),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      mergeMap(([{ menuDish, declaration, onFulfilled }, currentMenu]) =>
        this.genericsService
          .post<
            { type: 'all' | 'add' },
            Dish
          >(menuDish.dish_detail.url + 'synchronise_declarations/', { type: declaration }, { current_menu: currentMenu.id })
          .pipe(
            mergeMap((dish: Dish) => {
              const newMenuDish = new MenuDish(menuDish).setDish(dish);
              onFulfilled?.(newMenuDish);
              return [
                MenuActions.changeMenuDish({
                  id: newMenuDish.id,
                  menuDish: newMenuDish,
                }),
              ];
            }),
          ),
      ),
    ),
  );

  bulkPriceChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.bulkPriceChange),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      tap(() => {
        this.store.dispatch(clearCurrentMenu());
      }),
      mergeMap(([{ percentage_increase, rounding_base }, menu]) =>
        this.genericsService
          .post(menu.url + 'bulk_update_prices/', {
            percentage_increase,
            rounding_base,
          })
          .pipe(
            mergeMap(() => [getCurrentMenu({ id: menu.id, hard: true })]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private genericsService: GenericsService,
    private configService: ConfigService,
    private store: Store<State>,
    private treeManager: TreeManagerService,
    private transloco: TranslocoService,
  ) {}
}
