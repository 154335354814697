import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { SimpleLocation } from 'src/app/shared/Models/location';
import { TranslocoPipe } from '@jsverse/transloco';
import { KeyValuePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { StopPropagationDirective } from '../../Directives/stop-propagation/stop-propagation.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatButtonModule,
    StopPropagationDirective,
    MatIconModule,
    KeyValuePipe,
    TranslocoPipe,
  ],
})
export class SelectLocationComponent {
  @Input() groupedLocations: { [group: string]: SimpleLocation[] };
  @Input() locations: SimpleLocation[];
  @Input() locationControl: UntypedFormControl;

  @Output() clearLocation = new EventEmitter();

  constructor() {}
}
