import { createAction, props } from '@ngrx/store';
import {
  FetchPlansParams,
  PaymentData,
  PaymentErrorData,
  Plan,
  StripePaymentData,
  Subscription,
  SubscriptionTerm,
} from 'src/app/shared/Models/subscription';
import { Payment, PaymentVerification, User } from 'src/app/shared/Models/user';

import { PAYMENT_OPTIONS } from '../../plans/payment-option-cards/payment-option-cards.component';

export const fetchSubscription = createAction(
  `[Settings container, billing tab] Fetch subscription`,
);

export const patchSubscription = createAction(
  `[Billing API] Patch subscription`,
  props<{ subscription: Partial<Subscription>; cid?: number }>(),
);

export const setSubscription = createAction(
  `[Settings container, billing tab] Set subscription into store`,
  props<{ subscription: Subscription; cid?: number }>(),
);

export const setPayment = createAction(
  `[Settings container, billing tab] Set payment`,
  props<{ payment: Payment; doNotRefetchData?: boolean }>(),
);

export const setPaymentURL = createAction(
  `[Settings container, billing API] Set payment URL`,
  props<{ url: string }>(),
);

export const setPaymentVerification = createAction(
  `[Settings container, billing tab] Set payment verification`,
  props<{ verification: PaymentVerification }>(),
);

export const choosePlan = createAction(
  `[Settings container, billing tab, plans component] Choose this plan`,
  props<{ plan: Plan }>(),
);

export const setCurrentPlan = createAction(
  `[Settings container, billing tab, plans component] Set current plan`,
  props<{ plan: Plan }>(),
);

export const fetchPlans = createAction(
  `[Settings container, billing tab, plans component] Fetch plans`,
  props<{ params: FetchPlansParams }>(),
);

export const setPlans = createAction(
  `[Billing API] Set plans after fetching them`,
  props<{ plans: Plan[] }>(),
);

export const addPromotionalCode = createAction(
  `[Settings container, billing tab, promotional code component] Add promotional code`,
  props<{ discount_code: string }>(),
);

export const promotionalCodeChanged = createAction(
  `[Settings container, billing tab, promotional code API] Promo code changed`,
  props<{ cid: number }>(),
);

export const changePlanDebitConfirmed = createAction(
  `[Settings container, billing tab] Change plan using Debit`,
);

export const changePlanStripeConfirmed = createAction(
  `[Settings container, billing tab] Change plan using Stripe`,
);

export const setMandate = createAction(`[Billing API] Set mandate`);

export const setMandateAndProceed = createAction(
  `[Billing API] Set mandate and proceed with payment`,
);

export const setUpdateMandateAndProceed = createAction(
  `[Billing API] Set updated mandate and proceed with payment`,
);

export const subscriptionPlanChangedDebit = createAction(
  `[Billing API] Plan changed with debit (subscription PATCHed)`,
  props<{ plan: Plan }>(),
);

export const setSubscriptionTerm = createAction(
  `[Settings container, billing tab] Set current subscription term`,
  props<{ term: SubscriptionTerm }>(),
);

export const setVatError = createAction(
  `[VAT component, Subscription API] Set VAT error`,
  props<{ value: string | null }>(),
);

export const confirmDetailsDialog = createAction(
  `[Settings container, billing tab] Confirm profile details`,
  props<{ params?: { secondStep: boolean } }>(),
);

export const openCheckoutDialog = createAction(
  `[Settings container, billing tab] Open checkout dialog`,
  props<{ secondStep?: boolean; hideSubscription?: boolean }>(),
);

export const paymentOptionChanged = createAction(
  `[Settings container, billing tab] Payment option card was changed (debit or stripe)`,
  props<{ option: PAYMENT_OPTIONS }>(),
);

export const confirmBusinessDetails = createAction(
  '[Settings container, confirm business details dialog] Confirm details',
  props<{ user: Partial<User>; refetch: boolean }>(),
);

export const businessDetailsConfirmed = createAction(
  '[Settings container, confirm business details dialog] Business details were sucessfuly PATCHed',
);

export const setConfirmDetailsSpinner = createAction(
  '[Settings container, confirm business details dialog] Set spinner state',
  props<{ state: boolean }>(),
);

export const confirmChoosePlan = createAction(
  '[Settings container, choose plan dialog] Confirm subscription plan',
);

export const stripeCardModified = createAction(
  '[Settings container, choose plan dialog] Stripe card was changed',
);

export const stripeCardSaved = createAction(
  '[Settings container, choose plan dialog] Stripe card was saved',
);

export const mandateSet = createAction(
  '[Billing API] Mandate was set',
  props<{ redirect_flow_id: string }>(),
);

export const paymentSet = createAction(
  '[Billing API] Payment set',
  props<{ payment: Payment | PaymentVerification }>(),
);

export const planChanged = createAction(
  '[Billing API] Plan changed',
  props<{ paymentData: PaymentData }>(),
);

export const planUpdated = createAction(
  '[Billing API] Plan updated',
  props<{ paymentData: PaymentData }>(),
);

export const cancelPaymentBtnClicked = createAction(
  '[Settings container, billing-tab, billing] Cancel payment button clicked',
);

export const cancelPaymentConfirmed = createAction(
  '[Settings container, confirm dialog] Payment cancellation confirmed',
);

export const paymentCancelled = createAction(
  '[Settings container, billing API] Payment canceled',
);

export const stripePaymentDataReady = createAction(
  '[Settings container, billing/Stripe API] Data (StripePaymentData) is ready, proceeding with payment...',
  props<{ paymentData: StripePaymentData; subscriptionActive: boolean }>(),
);

export const stripeCardPaymentConfirmed = createAction(
  '[Settings container, Stripe API flow] Card payment confirmed',
);

export const stripeCardPaymentFinished = createAction(
  '[Settings container, Stripe API flow] Stripe card payment finished successfully',
  props<{ fetchPayments: boolean }>(),
);

export const stripeSuccessStatusChanged = createAction(
  '[Settings container, Stripe API] Set stripe status (boolean)',
  props<{ state: boolean }>(),
);

export const changePlanButtonClicked = createAction(
  '[Settings container] Change plan button was clicked',
);

export const showPlans = createAction('[Settings container] Show plans');

export const hidePlans = createAction('[Settings container] Hide plans');

export const stripeErrorOccurred = createAction(
  '[Settings container, Stripe API flow] Stripe error occurred during card payment confirmation',
  props<{ message: string }>(),
);

export const stripeTokenErrorOccurred = createAction(
  '[Settings container, Stripe API flow] Stripe error occurred during creation of the token',
  props<{ message: string }>(),
);

export const stripePurchaseErrorOccurred = createAction(
  '[Settings container, Stripe API flow] Stripe error occurred during purchase (/payment/ endpoint)',
  props<{ message: string }>(),
);

export const cancelSubscriptionBtnClicked = createAction(
  '[Settings container, billing tab] Cancel subscription button was clicked',
);

export const cancelSubscription = createAction(
  '[Settings container, billing tab / subscription API] Cancel subscription',
);

export const subscriptionCancelled = createAction(
  '[Settings container, billing tab / subscription API] Subscription was cancelled',
);

export const paymentErrorOccurred = createAction(
  '[Settings container, billing tab] Payment error occurred',
  props<{ error: PaymentErrorData }>(),
);

export const cancelSubscriptionErrorOccurred = createAction(
  '[Settings container, billing tab] Cancel subscription error occurred',
  props<{ error: PaymentErrorData }>(),
);

export const cancelPaymentErrorOccurred = createAction(
  '[Settings container, billing tab] Cancel payment error occurred',
  props<{ error: PaymentErrorData }>(),
);

export const changeMandateClicked = createAction(
  '[Settings container, billing tab] Update mandate link clicked',
);

export const changeCardClicked = createAction(
  '[Settings container, billing tab] Update card link clicked',
);

export const changeCardConfirmed = createAction(
  '[Settings container, change card dialog] Update card confirmed',
);

export const changeCardDialogClosed = createAction(
  '[Settings container, change card dialog] Change card dialog closed',
);

export const cardDetailsUpdated = createAction(
  '[Settings container, change card dialog] Card details were updated',
);

export const cardDetailsErrorOccurred = createAction(
  '[Settings container, change card dialog] Error occurred while updating the card details',
  props<{ error: string }>(),
);

export const retryPaymentClicked = createAction(
  '[Settings container, billing tab] Retry payment clicked',
);

export const clearChangeCard = createAction(
  '[Settings container, change card dialog] Clear change card',
);

export const changePaymentMethodClicked = createAction(
  '[Settings container, billing tab] Change payment method button clicked',
);

export const addModulesClicked = createAction(
  '[Settings container, billing tab] Add modules clicked',
);

export const previewMandate = createAction(
  '[Settings container, billing tab] Preview mandate',
);

export const setPromoCodeError = createAction(
  '[Settings container, billing tab, promocode] Set promo code error',
  props<{ error: string }>(),
);

export const fetchPayment = createAction(
  '[Settings container, billing tab] Fetch payment',
  props<{ doNotRefetchData?: boolean; sendSuccess?: boolean }>(),
);

export const startCheckout = createAction(
  `[Settings container, billing tab] Start checkout process`,
);

export const purchaseCompleted = createAction(
  `[Settings container, billing tab] Purchase has been completed`,
  props<{ payload: Payment }>(),
);
