@if (ingredient) {
  <div class="wrapper">
    <div class="description">
      {{ "ingredients.info.percentage.header_1" | transloco }}
      <a
        class="title blue"
        [routerLink]="['/ingredients']"
        [queryParams]="{ search: ingredientName }"
        [style.font-style]="ingredientItalic ? 'italic' : ''"
        >{{ ingredientName }}</a
      >
      {{ "ingredients.info.percentage.header_2" | transloco }}
      <a
        class="title blue"
        [routerLink]="['/recipes/' + recipe.id]"
        [style.font-style]="itemItalic ? 'italic' : ''"
        >"{{ recipeName }}"</a
      >:
    </div>
    <div class="fields">
      <mat-form-field class="percentage-field">
        <input
          matInput
          type="number"
          lang="en-150"
          [ngModel]="ingredient?.quantity"
          [placeholder]="
            selectedUnit
              ? ('ingredients.info.percentage.placeholder'
                | transloco: { quantity: selectedUnitName })
              : ('ingredients.info.percentage.placeholder_empty' | transloco)
          "
          (focusout)="changeQuantity($event)"
        />
      </mat-form-field>
      <mat-form-field class="units">
        <mat-select
          (selectionChange)="unitChange($event)"
          [ngModel]="ingredient?.unit"
          [placeholder]="'ingredients.info.unit' | transloco"
        >
          <mat-option stop-propagation [value]="null"> </mat-option>
          @for (unit of units; track unit) {
            <mat-option stop-propagation [value]="unit">
              {{ "ingredients.info.units." + unit | transloco }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>
}
