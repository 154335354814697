<mat-form-field class="icon-button-density-3" subscriptSizing="dynamic">
  <mat-label>{{ "collections.dialog.location" | transloco }}</mat-label>
  <mat-select [formControl]="locationControl">
    @if (groupedLocations) {
      @for (group of groupedLocations | keyvalue; track group) {
        <mat-optgroup [label]="group.key">
          @for (location of group.value; track location) {
            <mat-option [value]="location.id">
              {{ location.name }}
            </mat-option>
          }
        </mat-optgroup>
      }
    } @else {
      @for (location of locations; track location) {
        <mat-option [value]="location.id">
          {{ location.name }}
        </mat-option>
      }
    }
  </mat-select>
  @if (locationControl.value) {
    <button
      aria-label="Clear"
      class="clear"
      matSuffix
      mat-icon-button
      stop-propagation
      (click)="clearLocation.emit()"
    >
      <mat-icon>clear</mat-icon>
    </button>
  }
</mat-form-field>
