import { ActionReducer, combineReducers, createFeature } from '@ngrx/store';
import {
  BillingState,
  billingFeatureKey,
  billingReducer,
} from '../billing/ngrx/billing.reducer';
import {
  InvoicesState,
  invoicesFeatureKey,
  invoicesReducer,
} from '../invoices/ngrx/invoices.reducer';
import {
  InvitationsState,
  invitationsFeatureKey,
  invitationsReducer,
} from '../access/ngrx/invitations.reducer';
import {
  ModulesState,
  modulesFeatureKey,
} from './../modules/ngrx/modules.reducers';
import { modulesReducer } from '../modules/ngrx/modules.reducers';

export const settingsFeatureKey = 'settings';

export interface SettingsState {
  [billingFeatureKey]: BillingState;
  [invitationsFeatureKey]: InvitationsState;
  [invoicesFeatureKey]: InvoicesState;
  [modulesFeatureKey]: ModulesState;
}

export const reducers = {
  [billingFeatureKey]: billingReducer,
  [invitationsFeatureKey]: invitationsReducer,
  [invoicesFeatureKey]: invoicesReducer,
  [modulesFeatureKey]: modulesReducer,
};

export const settingsReducer: ActionReducer<SettingsState> =
  combineReducers(reducers);

export const settingsFeature = createFeature({
  name: settingsFeatureKey,
  reducer: settingsReducer,
});
