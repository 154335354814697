import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { OverlayService } from 'src/app/shared/Services/overlay-service/overlay.service';

@Component({
  selector: 'bottom-block-overlay',
  templateUrl: './bottom-block-overlay.component.html',
  styleUrls: ['./bottom-block-overlay.component.scss'],
  standalone: true,
})
export class BottomBlockOverlayComponent implements OnInit {
  @ViewChild(`container`, { read: ViewContainerRef, static: true })
  container: ViewContainerRef;

  constructor(private overlayService: OverlayService) {}

  ngOnInit() {
    this.overlayService.registerContainer(`bottom-block`, this.container);
  }
}
