import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { MenuDish, SimpleMenuDish } from 'src/app/shared/Models/menudish';

import * as MenuActions from './menu-write.actions';

export const menuWriteFeatureKey = 'menu_write';

export const adapter: EntityAdapter<SimpleMenuDish | MenuDish> =
  createEntityAdapter<SimpleMenuDish | MenuDish>();

export const adapterFull: EntityAdapter<MenuDish> =
  createEntityAdapter<MenuDish>();

export interface MenuWriteState {
  loading: boolean;
  loadingFull: boolean;
  menudishes: EntityState<SimpleMenuDish | MenuDish>;
  menudishesFull: EntityState<MenuDish>;
  search: boolean;
  isSorting: boolean;
}

const initialState: MenuWriteState = {
  loading: false,
  loadingFull: false,
  menudishes: adapter.getInitialState(),
  menudishesFull: adapterFull.getInitialState(),
  search: false,
  isSorting: false,
};

const _menuWriteReducer = createReducer(
  initialState,
  on(MenuActions.clearCurrentMenuDishes, (state) => ({
    ...state,
    menudishes: adapter.getInitialState(),
    menudishesFull: adapterFull.getInitialState(),
  })),
  on(MenuActions.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(MenuActions.setLoadingFull, (state, { loading }) => ({
    ...state,
    loadingFull: loading,
  })),
  on(MenuActions.setSearch, (state, { search }) => ({
    ...state,
    search,
  })),
  on(MenuActions.setMenuDishes, (state, { menuDishes }) => ({
    ...state,
    menudishes: adapter.setAll(menuDishes, state.menudishes),
  })),
  on(MenuActions.setMenuDishesFull, (state, { menuDishes }) => ({
    ...state,
    menudishesFull: adapterFull.setAll(menuDishes, state.menudishesFull),
  })),
  on(MenuActions.changeMenuDish, (state, { id, menuDish }) => ({
    ...state,
    menudishes: adapter.updateOne({ id, changes: menuDish }, state.menudishes),
    menudishesFull: adapterFull.updateOne(
      { id, changes: menuDish },
      state.menudishesFull,
    ),
  })),
  on(MenuActions.addMenuDishAtIndex, (state, { menuDish, index }) => {
    const allItems = [...adapter.getSelectors().selectAll(state.menudishes)];
    allItems.splice(index, 0, menuDish);
    return {
      ...state,
      menudishes: adapter.setAll(allItems, state.menudishes),
    };
  }),
  on(MenuActions.addMenuDish, (state, { menuDish }) => ({
    ...state,
    menudishes: adapter.addOne(menuDish, state.menudishes),
  })),
  on(MenuActions.removeMenuDish, (state, { id }) => ({
    ...state,
    menudishes: adapter.removeOne(id, state.menudishes),
  })),
  on(MenuActions.removeMenuDishes, (state, { ids }) => ({
    ...state,
    menudishes: adapter.removeMany(ids, state.menudishes),
  })),
  on(MenuActions.setIsSorting, (state, { value }) => ({
    ...state,
    isSorting: value,
  })),
);

export function menuWriteReducer(state: MenuWriteState, action: Action) {
  return _menuWriteReducer(state, action);
}
