import { Action, createReducer, on } from '@ngrx/store';
import { MenusResults } from 'src/app/shared/Models/menu';
import * as MenuActions from './menus-overview.actions';

export interface MenusOverviewState {
  loading: boolean;
  menus: MenusResults;
}

const initialState: MenusOverviewState = {
  loading: false,
  menus: null,
};

const _menusOverviewReducer = createReducer(
  initialState,
  on(MenuActions.setTableDataLoading, (state, { loading }) => {
    return {
      ...state,
      loading,
    };
  }),
  on(MenuActions.setMenus, (state, { menus }) => {
    return {
      ...state,
      menus,
    };
  }),
  on(MenuActions.clearMenus, (state) => {
    return {
      ...state,
      menus: null,
    };
  }),
  on(MenuActions.setUpdatedMenu, (state, { menu }) => {
    if (!state.menus) return { ...state };
    const updatedMenu = Object.assign({}, state.menus, {
      results: state.menus.results.map((menuObject) =>
        menuObject.id === menu?.id ? menu : menuObject,
      ),
    });
    return {
      ...state,
      menus: updatedMenu,
    };
  }),
);

export function menusOverviewReducer(
  state: MenusOverviewState,
  action: Action,
) {
  return _menusOverviewReducer(state, action);
}
