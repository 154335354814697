import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keys',
  standalone: true,
})
export class KeysPipe implements PipeTransform {
  transform(value) {
    const keys: any = [];
    for (const key in value) {
      keys.push({ key, value: value[key] });
    }
    return keys;
  }
}
