import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { Dish } from 'src/app/shared/Models/dish';
import { Ingredient } from 'src/app/shared/Models/ingredients';
import {
  Recipe,
  RecipeIngredient,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';
import { selectRecipes } from 'src/app/shared/ngrx/dishes-menu/dishes-menu.selectors';
import { selectIngredientsAutocomplete } from 'src/app/shared/ngrx/shared.selectors';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  Subject,
  takeUntil,
} from 'rxjs';
import { TranslocoPipe } from '@jsverse/transloco';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { RecipeIngredientsComponent } from '../../../../recipes/recipe-ingredients/recipe-ingredients.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-ingredient-select',
  templateUrl: './ingredient-select.component.html',
  styleUrls: ['./ingredient-select.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatCheckboxModule,
    RecipeIngredientsComponent,
    MatDialogActions,
    MatButtonModule,
    MatDialogClose,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class IngredientSelectDialogComponent implements OnInit {
  private destroyed$ = new Subject<void>();
  translatedName: string;
  autoControl = new UntypedFormControl('');
  lastNewIngredientName: string;
  ingredientsAuto: Ingredient[] = [];
  enableSaveButton = false;
  checkBoxValue: boolean;

  ingredientsAuto$ = this.ngrxStore.select(selectIngredientsAutocomplete);
  recipes$ = this.ngrxStore.select(selectRecipes);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private ngrxStore: Store<State>,
    private utils: UtilsService,
  ) {}

  ngOnInit() {
    this.utils
      .getTranslationObject(
        `${this.data.type}.elements.${this.data.option.name}`,
        (v) => (this.translatedName = v),
      )
      .subscribe();
    this.autoControl.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter((search: string) => search?.length >= 2),
        takeUntil(this.destroyed$),
      )
      .subscribe((v: any) => {
        this.lastNewIngredientName = v;
        this.data.searchIngredients(v);
      });

    this.ingredientsAuto$.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      this.ingredientsAuto = data;
    });
  }

  getRecipeName = (recipe: Recipe): { name; italic } =>
    this.utils.tryGetLabel(recipe, this.data.lang);

  updateIngredient(
    recipe: Recipe,
    updatedIngredient: {
      ingredient: Partial<Ingredient>;
      recipeIngredient: RecipeIngredient;
    },
  ) {
    this.data.updateIngredient({
      recipe: recipe,
      updatedIngredient,
    });
  }

  updateRecipeIngredient(
    recipe: Recipe,
    updatedIngredient: {
      url: string;
      recipeIngredient: Partial<RecipeIngredient>;
      onFulfilled: Function;
    },
  ) {
    this.data.updateRecipeIngredient({
      recipe,
      updatedIngredient,
    });
  }

  deleteIngredient(recipe: Recipe, ingredient: RecipeIngredient) {
    this.enableSaveButton = true;
    this.data.deleteIngredient({
      recipe: recipe,
      deletingIngredient: ingredient,
    });
  }

  chooseIngredient(
    recipe: Recipe,
    chooseIngredient: { id: number; ingredient_id: number },
  ) {
    this.enableSaveButton = true;
    this.data.chooseIngredient({
      recipe: recipe,
      ingredient_id: chooseIngredient.ingredient_id,
    });
  }

  searchIngredients(searchTerm: string) {
    this.data.searchIngredients(searchTerm);
  }

  addIngredientToRecipe(recipe: Recipe) {
    this.data.addIngredient(recipe);
  }

  createIngredient(
    recipe: Recipe,
    { ingredient, id }: { ingredient: Ingredient; id: number },
  ) {
    this.enableSaveButton = true;
    this.data.createIngredient({ recipe: recipe, newIngredient: ingredient });
  }

  changeRecipeDeclarativeOption(
    recipe: Recipe,
    data: {
      data: {
        data: Dish | Ingredient;
        type: string;
      };
      recipeIngredient: SimpleRecipeIngredient | RecipeIngredient;
    },
  ) {
    this.enableSaveButton = true;
    this.data.changeRecipeDeclarativeOption({
      recipe: recipe,
      updatedIngredient: {
        ingredient: data.data.data,
        recipeIngredient: data.recipeIngredient,
      },
    });
  }

  toggle(value: boolean) {
    this.checkBoxValue = value;
  }

  save() {
    this.data.save(!this.data.action);
  }
}
