@if (!create) {
  <div
    class="img-wrapper"
    [class.selected]="selected"
    [style.background-image]="
      'url(\'' + (layout.image || '/assets/img/Empty menu.jpg') + '\')'
    "
    (click)="!selected && !loading && selectLayout()"
  >
    @if (loading) {
      <mat-spinner></mat-spinner>
    }
  </div>
  <div class="button-wrapper">
    <button
      mat-flat-button
      class="style-button"
      [class.loading]="loading"
      [disabled]="selected || loading"
      (click)="selectLayout()"
    >
      {{ layout[lang] }}
    </button>
  </div>
} @else {
  <div class="content create">
    <ng-container>
      <mat-icon color="primary">forward_to_inbox</mat-icon>
      {{ "style.choose-layout.request-custom" | transloco }}
    </ng-container>
  </div>
  <button
    mat-flat-button
    color="primary"
    class="create-button"
    (click)="sendRequest.emit()"
  >
    {{ "style.choose-layout.request-send" | transloco }}
  </button>
}
