import { MediaMatcher } from '@angular/cdk/layout';
import { DOCUMENT, NgClass, AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import {
  MatDrawerMode,
  MatSidenav,
  MatSidenavModule,
} from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { logoutUser } from 'src/app/auth/ngrx/auth.actions';
import { selectLoginState } from 'src/app/auth/ngrx/auth.selectors';
import { fetchUserOnboardingTemplates } from 'src/app/dashboard/ngrx/dashboard.actions';
import { HelpScoutBeaconsService } from 'src/app/help-scout-beacons.service';
import { createMenuFromOnboardingTemplate } from 'src/app/menus/menu-edit/ngrx/menu-edit.actions';
import { State } from 'src/app/reducers';
import { SidePanelComponent } from 'src/app/shared/Components/side-panel/side-panel.component';
import { SimpleMenu } from 'src/app/shared/Models/menu';
import { Partner } from 'src/app/shared/Models/partners';
import { Task, ModuleCode, User } from 'src/app/shared/Models/user';
import * as SharedActions from 'src/app/shared/ngrx/shared.actions';
import { FileService } from 'src/app/shared/Services/files/files.service';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import {
  setStatusBar,
  setStatusBarMobile,
} from 'src/app/shared/user/ngrx/user.actions';
import {
  selectMainStatus,
  selectStatusBar,
  selectStatusBarEmail,
  selectStatusBarMobile,
  selectUser,
} from 'src/app/shared/user/ngrx/user.selectors';
import { combineLatest, distinctUntilChanged, of, Subject } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';

import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import {
  fetchFonts,
  fetchPartnerByCode,
  fetchTemplates,
  getAllLocationGroups,
  getAllLocations,
  setGlobalSpinner,
  setRecipesSidenavTopOffset,
} from 'src/app/shared/ngrx/shared.actions';
import {
  selectActiveUserModules,
  selectTasks,
  selectDefaultTemplates,
  selectGlobalSpinnerState,
  selectPartner,
  selectSidePanelOpen,
} from 'src/app/shared/ngrx/shared.selectors';
import { TranslocoPipe } from '@jsverse/transloco';
import { BottomBlockOverlayComponent } from '../shared/Components/bottom-block-overlay/bottom-block-overlay.component';
import { SidePanelComponent as SidePanelComponent_1 } from '../shared/Components/side-panel/side-panel.component';
import { AddClassDirective } from '../shared/Directives/add-class/add-class.directive';
import { FooterComponent } from '../shared/Components/footer/footer.component';
import { SpinnerComponent } from '../shared/Components/spinner/spinner.component';
import { TopNavigationComponent } from '../shared/Components/top-navigation/top-navigation.component';
import { NavigationComponent } from '../shared/Components/navigation/navigation.component';
import { MenutechNavbarComponent } from '../shared/Components/menutech-navbar/menutech-navbar.component';
import { ArchiveBarComponent } from '../shared/Components/archive-bar/archive-bar.component';
import { StatusBarComponent } from '../shared/Components/status-bar/status-bar.component';
import { StaticHeaderMsmComponent } from '../shared/Components/static-header-msm/static-header-msm.component';

const STATUS_BAR_HEIGHT = 50;
const STATUS_BAR_HEIGHT_EMAIL = 60;
const STATUS_BAR_HEIGHT_MOBILE = 120;
const STATUS_BAR_MOBILE = 776;
const FOOTER_HEIGHT = 44;
const HIDE_NAVIGATION = [];
const NAVIGATION_CUSTOM_LEFT = {
  recipes: 240,
};

@Component({
  selector: 'main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss'],
  providers: [MatDialog],
  standalone: true,
  imports: [
    StaticHeaderMsmComponent,
    StatusBarComponent,
    ArchiveBarComponent,
    MenutechNavbarComponent,
    MatSidenavModule,
    NavigationComponent,
    NgClass,
    TopNavigationComponent,
    RouterOutlet,
    SpinnerComponent,
    FooterComponent,
    AddClassDirective,
    SidePanelComponent_1,
    BottomBlockOverlayComponent,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class MainContainerComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  private destroyed$ = new Subject<void>();
  url: string;
  lang: InterfaceLanguage;
  simple = false;
  hideTopNavigation = false;
  topNavigationCustomLeft = 0;
  isOpen = false;
  small = false;
  mode: MatDrawerMode = 'side';
  showFooter = false;
  showStatus = false;
  showSpinner = false;
  simpleMenu: SimpleMenu;
  topOffset: number;
  sidenavContainerHeight: string;
  sidenavTopOffset: number;
  loggedIn: boolean;
  archivesCount: number;
  isRecipes: boolean;
  footerHeight = 30;
  isStatusBarEmail = false;
  isStatusBarMobile = false;
  hideTopBar = false;
  currentUser: User;
  sidePanelOpened: boolean;
  sidePanelBottomOffset: number;
  sidePanelTopOffset: number;
  hideTopNav = false;
  sidePanelVisible = false;
  sidePanelMobileView = false;
  statusBarHeight = 0;
  currentPartner: Partner;
  pageVariable: string;
  statusBarIsShown = false;
  activeModules: ModuleCode[];

  private statusBarQuery: MediaQueryList;
  private currentStatus: any;
  private _sidenavContent: any;

  @ViewChild('sidePanel') sidePanel: SidePanelComponent;
  @ViewChild('sidenav') sidenav: MatSidenav;

  defaultTemplates$ = this.store.select(selectDefaultTemplates);
  tasks$ = this.store.select(selectTasks);
  partner$ = this.store.select(selectPartner);
  sidePanelOpen$ = this.store.select(selectSidePanelOpen);
  user$ = this.store.select(selectUser);
  login$ = this.store.select(selectLoginState);
  status$ = this.store.select(selectMainStatus);
  statusBarState$ = this.store.select(selectStatusBar);
  statusBarEmail$ = this.store.select(selectStatusBarEmail);
  statusBarMobile$ = this.store.select(selectStatusBarMobile);
  showGlobalSpinner$ = this.store.select(selectGlobalSpinnerState);

  private mobileQuery = (data) => {
    const { matches } = data;
    if (this.statusBarEmail$) {
      this.store.dispatch(setStatusBarMobile({ data: matches }));
      this.changeDetector.detectChanges();
    }
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private fileService: FileService,
    private utils: UtilsService,
    public vcRef: ViewContainerRef,
    private dateAdapter: DateAdapter<Date>,
    private renderer2: Renderer2,
    private media: MediaMatcher,
    private matIconRegistry: MatIconRegistry,
    private changeDetector: ChangeDetectorRef,
    @Inject(DOCUMENT) private _document: Document,
    private helpScoutBeacon: HelpScoutBeaconsService,
    private store: Store<State>,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((rt) => rt.firstChild),
        filter((rt) => rt.outlet === 'primary'),
        mergeMap((rt) =>
          rt.firstChild
            ? combineLatest([rt.data, rt.firstChild.data])
            : combineLatest([rt.data, of(null)]),
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe(([parentData, childData]: [any, any]) => {
        const event = childData ?? parentData;
        this.pageVariable = event.title;
        this.hideTopBar = this.handleTopBar(event);
        // this.hideTopNavigation = this.handleTopNavigation(event);
        this.topNavigationCustomLeft =
          this.handleTopNavigationCustomLeft(event);
        this.simple = this.handleSimplePage(event);
        this.showFooter = this.handleFooter(event);
        this.showStatus = this.handleStatus(event);
        this.isRecipes = this.handleIsRecipes(event);
        this.handleSidenav(event);
        this.setStatusBarState();
        this.calcTopOffset();
      });
    this.utils.getLang((lang) => (this.lang = lang));
    this.matIconRegistry.addSvgIcon(
      `qr_code`,
      this.sanitizer.bypassSecurityTrustResourceUrl(
        `../../assets/img/icons/QRcode.svg`,
      ),
    );
    this.matIconRegistry.addSvgIcon(
      `magic_stick`,
      this.sanitizer.bypassSecurityTrustResourceUrl(
        `../../assets/img/icons/magic-stick-icon.svg`,
      ),
    );
  }

  // handleTopNavigation = ({ title }) => {
  //   return HIDE_NAVIGATION.includes(title);
  // };

  handleTopNavigationCustomLeft = ({ title }) => {
    if (title in NAVIGATION_CUSTOM_LEFT) {
      return NAVIGATION_CUSTOM_LEFT[title];
    }
    return 0;
  };

  setPartner(partnerCode: string | null): void {
    this.store.dispatch(fetchPartnerByCode({ code: partnerCode }));
  }

  refreshScroll(): void {
    document.querySelector('mat-sidenav-content').scrollTop = 0;
  }

  partnerHandler(prtnr: Partner | null): void {
    this.currentPartner = prtnr;
    this.calcTopOffset();
  }

  calcTopOffset(): void {
    if (!this.simple && this.loggedIn) {
      this.statusBarHeight = this.isStatusBarEmail
        ? STATUS_BAR_HEIGHT_EMAIL
        : STATUS_BAR_HEIGHT;
      if (this.isStatusBarEmail) {
        this.statusBarHeight = this.isStatusBarMobile
          ? STATUS_BAR_HEIGHT_MOBILE
          : STATUS_BAR_HEIGHT_EMAIL;
      } else {
        this.statusBarHeight = STATUS_BAR_HEIGHT;
      }
      let offsetValue =
        this.archivesCount * this.statusBarHeight +
        64 +
        (this.currentStatus?.description ? this.statusBarHeight : 0);
      this.sidenavTopOffset = offsetValue;
      offsetValue = offsetValue + this.currentPartner?.topOffset;
      this.store.dispatch(setRecipesSidenavTopOffset({ offset: offsetValue }));
      this.topOffset = offsetValue;
      this.sidePanelTopOffset = offsetValue + 60;
      this.sidenavContainerHeight = `calc(100vh - ${offsetValue}px)`;
    } else {
      this.topOffset = 0;
      this.sidePanelTopOffset = 0;
      this.sidenavContainerHeight = `100vh`;
    }

    if (this.sidenav?.[`_elementRef`]) {
      this.renderer2.setStyle(
        this.sidenav[`_elementRef`].nativeElement,
        `top`,
        this.sidePanelTopOffset,
      );
    }
  }

  createMenuFromOnboardingTemplate = ({ date, template }) => {
    this.showSpinner = true;
    if (date) date = this.dateAdapter.format(date, 'yyyy-MM-dd');
    this.store.dispatch(setGlobalSpinner({ value: true }));
    this.store.dispatch(
      createMenuFromOnboardingTemplate({ date: date, id: template }),
    );
  };

  ngAfterViewInit(): void {
    this.sidePanel.maxWidthEvent
      .pipe(takeUntil(this.destroyed$))
      .subscribe((v) => (this.hideTopNav = v));
    this.login$.pipe(takeUntil(this.destroyed$)).subscribe((v) => {
      this.loggedIn = v;
      if (!v) return undefined;
      setTimeout(() => {
        this.calcTopOffset();
        if (this.sidenav) this.hideOrShow(window.innerWidth <= 768);
      });
    });
    this.statusBarQuery = this.media.matchMedia(
      `(max-width: ${STATUS_BAR_MOBILE}px)`,
    );

    // Safari fix
    if (this.statusBarQuery.addEventListener) {
      this.statusBarQuery.addEventListener(`change`, this.mobileQuery);
      // eslint-disable-next-line deprecation/deprecation
    } else if (this.statusBarQuery.addListener) {
      // eslint-disable-next-line deprecation/deprecation
      this.statusBarQuery.addListener((event) => {
        if (event.type === `change`) this.mobileQuery(event);
      });
    }

    this.dispatchStatusBarEvent();
  }

  dispatchStatusBarEvent(): void {
    this.mobileQuery({
      type: `change`,
      matches: window.innerWidth < STATUS_BAR_MOBILE,
    });
  }

  getDefaultData(activeModules: ModuleCode[]) {
    this.store.dispatch(SharedActions.fetchDeclarations());
    this.store.dispatch(SharedActions.fetchArchives());
    this.store.dispatch(fetchTemplates());
    this.store.dispatch(fetchFonts());
    this.store.dispatch(fetchUserOnboardingTemplates());
    if (activeModules.includes('loca')) {
      this.store.dispatch(
        getAllLocations({
          params: { condensed: true, ordering: 'name', pagination: 'off' },
        }),
      );
      this.store.dispatch(
        getAllLocationGroups({
          params: { condensed: true, ordering: 'name', pagination: 'off' },
        }),
      );
    }
    if (activeModules.includes('order')) {
      this.store.dispatch(
        SharedActions.fetchOrderTakingLayouts({
          params: { condensed: true, pagination: 'off' },
        }),
      );
    }
  }

  setStatusBarState() {
    this.store.dispatch(
      setStatusBar({
        data:
          this.currentStatus?.description && (!this.simple || this.showStatus),
      }),
    );
  }

  handleFooter = ({ title }) => {
    switch (title) {
      case '404':
        return true;
      case 'error':
        return true;
      default:
        return false;
    }
  };

  handleTopBar = ({ title }) => {
    switch (title) {
      case '404':
      case 'confirm-email':
      case 'email-pref':
      case 'error':
      case 'registration-steps':
        return true;
      default:
        return false;
    }
  };

  handleStatus = ({ title }) => {
    return false;
  };

  handleSidenav = ({ title }) => {
    if (title === 'recipes') {
      if (!this.small && !this.isStatusBarMobile) this.smaller();
    } else if (this.isStatusBarMobile && this.small) {
      this.smaller();
    }
  };

  handleSimplePage = ({ title }) => {
    switch (title) {
      case 'email-pref':
      case 'login':
      case '404':
      case 'reset-password':
      case 'registration':
      case 'confirm-email':
      case 'error':
      case 'accept-inv':
      case 'registration-steps':
        return true;
      default:
        return false;
    }
  };

  handleIsRecipes = ({ title }): boolean => {
    return title === 'recipes';
  };

  downloadArchive = ({
    task,
    onFulfilled,
  }: {
    task: Task;
    onFulfilled?: Function;
  }) => {
    this.fileService.downloadFile(task.download, {}, undefined, onFulfilled);
  };

  hideArchive = (archive: Task) =>
    this.store.dispatch(
      SharedActions.changeArchive({
        archive: { url: archive.url, hidden: true },
      }),
    );

  logout() {
    if (this.currentUser?.partner_detail?.link) {
      this._document.location.href = this.currentUser.partner_detail.link;
      return undefined;
    }
    this.sidenav.close();
    this.store.dispatch(logoutUser({}));
  }

  ngOnInit(): void {
    this.setupSubscribers();
    this.helpScoutBeacon.init();
    this.helpScoutBeacon.show();
  }

  setupSubscribers(): void {
    this.statusBarState$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((state) => (this.statusBarIsShown = state));
    this.partner$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((p: Partner | undefined) => this.partnerHandler(p));
    this.sidePanelOpen$.pipe(takeUntil(this.destroyed$)).subscribe((v) => {
      setTimeout(() => {
        this.sidePanelOpened = v;
      }, 0);
    });
    this.user$.pipe(takeUntil(this.destroyed$)).subscribe((u: User) => {
      this.currentUser = u;
      this.setPartner(this.currentUser?.partner_code);
    });
    this.statusBarEmail$.pipe(takeUntil(this.destroyed$)).subscribe((v) => {
      this.isStatusBarEmail = v;
      this.calcTopOffset();
    });
    this.statusBarMobile$.pipe(takeUntil(this.destroyed$)).subscribe((v) => {
      this.isStatusBarMobile = v;
    });
    this.status$.pipe(takeUntil(this.destroyed$)).subscribe((status) => {
      this.currentStatus = status;
      this.setStatusBarState();
      this.calcTopOffset();
    });
    // load initial data based on active modules
    combineLatest([
      this.store.select(selectActiveUserModules),
      this.store.select(selectLoginState),
    ])
      .pipe(
        filter((v) => !!v[0] && !!v[1]),
        map((v) => v[0].map((mod) => mod.module_detail.code)),
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
      )
      .subscribe((moduleCodes) => {
        if (!this.activeModules) {
          this.activeModules = moduleCodes;
          this.getDefaultData(moduleCodes);
        }
      });
    this.tasks$.pipe(takeUntil(this.destroyed$)).subscribe((v) => {
      this.archivesCount = v ? v.length : 0;
      this.calcTopOffset();
    });
  }

  toggle() {
    this.isOpen = !this.isOpen;
    this.sidenav.toggle();
  }

  trackByArchive(index, archive: Task): number {
    return archive.id;
  }

  hideOrShow(state: boolean) {
    setTimeout(() => {
      this.calcTopOffset();
      this.mode = state ? 'over' : 'side';
      if (this.sidenav) state ? this.sidenav.close() : this.sidenav.open();
    });
  }

  closeNav = () => (this.isOpen = false);

  onSideNavScroll(event) {
    event.stopPropagation();
  }

  smaller = (isBtn = false) => {
    this.small = !this.small;
    this.utils.navigationCollapsed.next(this.small);
    window.dispatchEvent(new Event('resize'));
  };

  setSidePanelMobileView(value: boolean) {
    this.sidePanelMobileView = value;
  }

  setSidePanelVisible(value: boolean) {
    this.sidePanelVisible = value;
  }

  ngOnDestroy() {
    this.helpScoutBeacon.hide();
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
