@if (
  tabIndex !== null &&
  (userStyles.length || publicStyles.length || eventStyles.length)
) {
  <categories-tabs
    class="categories-tabs"
    translationKey="style"
    [categories]="categories"
    [tabIndex]="userStyles?.length ? tabIndex : tabIndex - 1"
    (tabChange)="tabChange.emit(userStyles?.length ? $event : $event + 1)"
  >
    @if (tabIndex === 0 && userStyles) {
      <styles-carousel
        class="tabUser"
        [styleModified]="styleModified"
        [canModify]="true"
        [lang]="lang"
        [menu]="menu"
        [styles]="userStyles"
        [styleSpinner]="showStyleSpinner"
        (createStyle)="createStyle.emit()"
        (deleteStyle)="deleteStyle.emit($event)"
        (openDialog)="openNameDialog.emit($event)"
        (renameStyle)="renameStyle.emit($event)"
        (selectStyle)="selectStyle.emit($event)"
        (updateStyle)="updateStyle.emit()"
      ></styles-carousel>
    }
    @if (tabIndex === 1 && publicStyles) {
      <styles-carousel
        class="tabPublic"
        [styleModified]="styleModified && userStyles.length === 0"
        [styleSpinner]="showStyleSpinner"
        [menu]="menu"
        [styles]="publicStyles"
        [lang]="lang"
        (createStyle)="createStyle.emit()"
        (selectStyle)="selectStyle.emit($event)"
      ></styles-carousel>
    }
    @if (tabIndex === 2 && eventStyles) {
      <styles-carousel
        class="tabSpecial"
        [styleSpinner]="showStyleSpinner"
        [menu]="menu"
        [styles]="eventStyles"
        [lang]="lang"
        (createStyle)="createStyle.emit()"
        (selectStyle)="selectStyle.emit($event)"
      ></styles-carousel>
    }
  </categories-tabs>
}
