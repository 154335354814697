import {
  ContentProgress,
  DaysProgress,
  Status,
  SubscriptionExtension,
} from 'src/app/shared/Models/user';
import { addDays, differenceInDays } from 'date-fns';

const calculatePercent = (value: number, total: number): number =>
  Math.min((value / total) * 100, 100);

const usageKeys = {
  menus: {
    limit: 'limit_menus_per_month',
    used: 'menus_created_this_month',
    extra: 'extra_menus',
  },
  templates: {
    limit: 'limit_templates_per_month',
    used: 'templates_used_this_month',
    extra: 'extra_templates',
  },
  aiAllergens: {
    limit: 'limit_ai_allergens_per_month',
    used: 'ai_allergens_this_month',
    extra: 'extra_ai_allergens',
  },
  aiDishes: {
    limit: 'limit_ai_dishes_per_month',
    used: 'ai_dishes_this_month',
    extra: 'extra_ai_dishes',
  },
  aiMenuAnalysis: {
    limit: 'limit_ai_menu_analysis_per_month',
    used: 'ai_menu_analysis_this_month',
    extra: 'extra_ai_menu_analysis',
  },
  aiRecipes: {
    limit: 'limit_ai_recipes_per_month',
    used: 'ai_recipes_this_month',
    extra: 'extra_ai_recipes',
  },
};

export const calculateDaysInfo = (status: Status): DaysProgress => {
  if (!status.inactive_on) {
    return {
      days: 0,
      extraDays: 0,
      limitDays: 0,
    };
  }

  const joinedDate = new Date(status.date_joined);
  const inactiveDate = new Date(status.inactive_on);
  const limitDays = differenceInDays(addDays(inactiveDate, 1), joinedDate);
  const extraDays = status.completed_profile ? 5 : 0;

  let diff = differenceInDays(inactiveDate, addDays(new Date(), -1));
  if (status.trial_period && status.completed_profile) {
    diff -= extraDays;
  }

  const days = Math.max(diff, 0);
  const remainingExtraDays =
    diff < 0 && status.completed_profile
      ? Math.max(extraDays + diff, 0)
      : extraDays;

  return {
    days,
    extraDays: remainingExtraDays,
    limitDays,
  };
};

export const calculateUsageInfo = (
  status: Status,
  extra: SubscriptionExtension[],
  itemType:
    | 'menus'
    | 'templates'
    | 'aiAllergens'
    | 'aiDishes'
    | 'aiMenuAnalysis'
    | 'aiRecipes',
): ContentProgress => {
  if (!status.usage_limits) {
    return {
      remaining: 0,
      used: 0,
      extra: 0,
      percentUsed: 0,
      percentRemaining: 0,
      percentExtra: 0,
    };
  }

  const keys = usageKeys[itemType];
  const limit = status.usage_limits[keys.limit];
  const used = status.current_usage[keys.used];
  const extraItems =
    extra && extra.length
      ? extra.reduce((acc, curr) => acc + curr[keys.extra], 0)
      : 0;

  const remaining = Math.max(limit - used - extraItems, 0);
  const remainingExtraItems =
    remaining < 0 ? Math.max(extraItems + remaining, 0) : extraItems;

  return {
    remaining,
    used,
    extra: remainingExtraItems,
    percentUsed: calculatePercent(used, limit),
    percentRemaining: calculatePercent(remaining, limit),
    percentExtra: calculatePercent(remainingExtraItems, limit),
  };
};
