import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { Variant } from 'src/app/shared/Models/dish';
import { KeysPipe } from '../../../../shared/Pipes/keys.pipe';
import { TranslocoPipe } from '@jsverse/transloco';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

export interface TranslatePriceVariant {
  dish: MenuDish;
  variants: Variant[];
}

@Component({
  selector: 'translate-price-variant',
  templateUrl: './translate-price-variant.component.html',
  styleUrls: [
    '../translations.component.scss',
    './translate-price-variant.component.css',
  ],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    TranslocoPipe,
    KeysPipe,
  ],
})
export class TranslatePriceVariantComponent implements OnChanges {
  @Input() baseLang: string;
  @Input() dish: MenuDish;
  @Input() lang: ContentLanguage;
  @Input() variant: Variant;
  @Output() changeName = new EventEmitter<Variant>();

  inputFocused: boolean;
  variantName: string;

  ngOnChanges(changes: SimpleChanges) {
    if (
      ('variant' in changes || 'lang' in changes) &&
      this.variant &&
      (this.variantName === undefined || 'lang' in changes) &&
      !this.inputFocused
    ) {
      this.variantName = this.variant[this.lang];
    }
  }

  changeTranslation() {
    if (this.variant[this.lang] === this.variantName) return undefined;
    const newVariant = {
      ...this.variant,
      [this.lang]: this.variantName,
    } as Variant;
    this.changeName.emit(newVariant);
  }
}
