<div
  class="wrapper"
  [class.otherForm]="isOther"
  [class.active]="optionData?.active"
  [class.custom]="isOther"
  [class.isSafari]="isSafari"
>
  <mat-icon>{{ optionData?.icon }}</mat-icon>
  <span [class.other]="isOther">{{
    isOther
      ? ("auth.registration.steps.other.title" | transloco)
      : view ||
        ("auth.registration.steps.first.options." + optionData["value"]
          | transloco)
  }}</span>
  @if (isOther) {
    <mat-form-field
      stop-propagation
      class="form-field field-density-5"
      [class.isSafari]="isSafari"
      subscriptSizing="dynamic"
    >
      <input
        type="text"
        matInput
        [formControl]="inputCtrl"
        [placeholder]="'auth.registration.steps.other.placeholder' | transloco"
      />
    </mat-form-field>
  }
</div>
