import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { OverlayService } from 'src/app/shared/Services/overlay-service/overlay.service';
import { State } from 'src/app/reducers';
import { setSidePanelOpen } from './../ngrx/shared.actions';
import { selectSidePanelOpen } from './../ngrx/shared.selectors';
import { SidePanelComponent } from '../Components/side-panel/side-panel.component';

@Component({
  selector: 'side-panel-controller',
  template: ``,
  standalone: true,
})
export class SidePanelControllerBase implements OnInit, OnDestroy {
  sidePanel: SidePanelComponent;
  sidePanelOpened$ = this.ngrxStore.select(selectSidePanelOpen);

  @ViewChild(`sidePanel`, { read: TemplateRef, static: true })
  sidePanelRef: TemplateRef<any>;

  constructor(
    protected ngrxStore: Store<State>,
    protected overlayService: OverlayService,
  ) {}

  ngOnInit() {
    this.ngrxStore.dispatch(setSidePanelOpen({ open: true }));
    this.overlayService.insertTemplate(`side-panel`, this.sidePanelRef);
    this.sidePanel =
      this.overlayService.getComponent<SidePanelComponent>(`side-panel`);
    if (!this.sidePanel.isMobile) this.showSidePanel();
    else this.hideSidePanel();
  }

  showSidePanel(): void {
    this.sidePanel.contentSet = true;
  }

  hideSidePanel(): void {
    this.sidePanel.tryHidePanel();
  }

  ngOnDestroy(): void {
    this.ngrxStore.dispatch(setSidePanelOpen({ open: false }));
    this.overlayService.clear(`side-panel`);
    this.sidePanel.contentSet = undefined;
  }
}
