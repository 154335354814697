import { Component, Input } from '@angular/core';
import {
  UntypedFormControl,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { resetPassword } from 'src/app/auth/ngrx/auth.actions';
import { selectResetPasswordSpinnerState } from 'src/app/auth/ngrx/auth.selectors';
import { State } from 'src/app/reducers';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { TranslocoPipe } from '@jsverse/transloco';
import { AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { SpinnerComponent } from '../../../shared/Components/spinner/spinner.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    SpinnerComponent,
    RouterLink,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class ResetPasswordComponent {
  @Input() lang: InterfaceLanguage;

  email = new UntypedFormControl('');
  spinnerState$ = this.ngrxStore.select(selectResetPasswordSpinnerState);

  constructor(private ngrxStore: Store<State>) {}

  submit(): void {
    if (!this.email.value) return undefined;
    this.ngrxStore.dispatch(resetPassword({ email: this.email.value }));
  }
}
