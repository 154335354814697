export const ValidationMessages = {
  first_name: {
    required: 'First name is required.',
    minlength: 'First name is too short',
    maxlength: 'First name is too long',
  },
  last_name: {
    required: 'Last name is required.',
    minlength: 'Last name is too short',
    maxlength: 'Last name is too long',
  },
  email: {
    required: 'Email is required.',
    minlength: '',
    normalEmailRule: 'Email is invalid',
    disposable: 'Disposable email addresses are not allowed',
    mx_found: 'The provided email address does not exist',
  },
  terms: {
    required: 'Please accept the terms and conditions',
    requiredTrue: 'Please accept the terms and conditions',
  },
  password1: {
    required: 'Password is required.',
    minlength: 'Password is too short',
  },
  password2: {
    required: 'Confirm password is required.',
    minlength: 'Confirm password is too short',
    mismatchedPasswords: 'Passwords mismatch',
  },
  old_password: {
    required: 'Password is required.',
    minlength: 'Password is too short',
  },
  new_password1: {
    required: 'Password is required.',
    minlength: 'Password is too short',
  },
  new_password2: {
    required: 'Confirm password is required.',
    minlength: 'Confirm password is too short',
    mismatchedPasswords: 'Passwords mismatch',
  },
};
