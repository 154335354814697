import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Dish } from 'src/app/shared/Models/dish';
import {
  InterfaceLanguage,
  ContentLanguage,
} from 'src/app/shared/constants/languages';
import { Ingredient } from 'src/app/shared/Models/ingredients';
import { CopyDeepPipe } from '../../../../Pipes/copy-deep.pipe';
import { OptionsComponent } from '../../options/options.component';
import { MatButtonModule } from '@angular/material/button';
import { SameAllergensComponent } from './same-allergens/same-allergens.component';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import {
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';

@Component({
  selector: 'same-dishes',
  templateUrl: './same-dishes.component.html',
  styleUrls: ['./same-dishes.component.css'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    TranslocoDirective,
    SameAllergensComponent,
    MatButtonModule,
    OptionsComponent,
    MatDialogActions,
    MatDialogClose,
    TranslocoPipe,
    CopyDeepPipe,
  ],
})
export class SameDishesComponent {
  @Input() dish: Dish;
  @Input() contentLang: ContentLanguage;
  @Input() lang: InterfaceLanguage;
  @Input() filteredDishes: Dish[];
  @Input() allDishesNumber: number;
  @Input() type: 'allergens' | 'additives' | 'labels';

  @Output() addOption = new EventEmitter<{
    data: Dish | Ingredient;
    type: string;
  }>();
  @Output() copyDeclarations = new EventEmitter<{
    source: Dish;
    target: Dish;
    type: 'add' | 'all';
  }>();
  @Output() loadMoreDishes = new EventEmitter<string>();

  changedOption(data: { data: Dish | Ingredient; type: string }) {
    this.addOption.emit(data);
  }
}
