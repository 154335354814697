@if (status?.account_limits?.limit_days) {
  <div class="restriction">
    <div class="written">{{ "plans.user_plan.days_left" | transloco }}:</div>
    <div class="progress">
      {{ daysInfo.days }}
      @if (daysInfo.extraDays && daysInfo.limitDays) {
        <span class="extra"> + {{ daysInfo.extraDays }}</span>
      }
    </div>
    <mat-progress-bar
      class="example-margin"
      mode="determinate"
      [value]="
        100 - ((daysInfo.limitDays - daysInfo.days) * 100) / daysInfo.limitDays
      "
    >
    </mat-progress-bar>
    @if (status?.completed_profile) {
      <button
        mat-mini-fab
        class="icon"
        [mtTooltip]="'dashboard.status_block.5days_added' | transloco"
      >
        <mat-icon>card_giftcard</mat-icon>
      </button>
    }
  </div>
}

@if (isFinite(menuInfo.percentUsed)) {
  <div class="restriction">
    <div class="written">{{ "plans.user_plan.menus_left" | transloco }}:</div>
    <div class="progress">
      {{ status.usage_limits.limit_menus_per_month ? menuInfo.remaining : "∞" }}
      @if (menuInfo.extra) {
        <span class="extra"> + {{ menuInfo.extra }}</span>
      }
    </div>
    <div class="example-margin">
      <span class="left" [style.width]="menuInfo.percentRemaining + '%'"></span>
      <span
        class="left-extra"
        [style.width]="menuInfo.percentExtra + '%'"
      ></span>
      <span class="used" [style.width]="menuInfo.percentUsed + '%'"></span>
    </div>
  </div>
}

@if (isFinite(templateInfo.used)) {
  <div class="restriction">
    <div class="written">
      {{ "plans.user_plan.templates_left" | transloco }}:
    </div>
    <div class="progress">
      {{
        status?.usage_limits?.limit_templates_per_month
          ? templateInfo.remaining
          : "∞"
      }}
      @if (templateInfo.extra) {
        <span class="extra"> + {{ templateInfo.extra }}</span>
      }
    </div>
    <div class="example-margin">
      <span
        class="left"
        [style.width]="templateInfo.percentRemaining + '%'"
      ></span>
      <span
        class="left-extra"
        [style.width]="templateInfo.percentExtra + '%'"
      ></span>
      <span class="used" [style.width]="templateInfo.percentUsed + '%'"></span>
    </div>
  </div>
}

@if (
  status?.usage_limits?.limit_ai_dishes_per_month ||
  status?.usage_limits?.limit_ai_menu_analysis_per_month
) {
  <h4>
    {{ "plans.user_plan.ai_usage" | transloco }}
    <mat-icon class="header-icon">new_releases</mat-icon>
  </h4>
}
@if (isFinite(aiDishesInfo.percentUsed)) {
  <div class="restriction">
    <div class="written">
      {{ "plans.user_plan.ai_dishes_left" | transloco }}:
      <mat-icon
        class="info-icon"
        [mtTooltip]="'plans.user_plan.ai_dishes_help' | transloco"
        >info</mat-icon
      >
    </div>
    <div class="progress">
      {{ aiDishesInfo.remaining }}
      @if (aiDishesInfo.extra) {
        <span class="extra"> + {{ aiDishesInfo.extra }} </span>
      }
    </div>
    <div class="example-margin">
      <span
        class="left"
        [style.width]="aiDishesInfo.percentRemaining + '%'"
      ></span>
      <span
        class="left-extra"
        [style.width]="aiDishesInfo.percentExtra + '%'"
      ></span>
      <span class="used" [style.width]="aiDishesInfo.percentUsed + '%'"></span>
    </div>
  </div>
}

@if (isFinite(aiAllergensInfo.percentUsed)) {
  <div class="restriction">
    <div class="written">
      {{ "plans.user_plan.ai_allergens_left" | transloco }}:
      <mat-icon
        class="info-icon"
        [mtTooltip]="'plans.user_plan.ai_allergens_help' | transloco"
        >info</mat-icon
      >
    </div>
    <div class="progress">
      {{ aiAllergensInfo.remaining }}
      @if (aiAllergensInfo.extra) {
        <span class="extra"> + {{ aiAllergensInfo.extra }} </span>
      }
    </div>
    <div class="example-margin">
      <span
        class="left"
        [style.width]="aiAllergensInfo.percentRemaining + '%'"
      ></span>
      <span
        class="left-extra"
        [style.width]="aiAllergensInfo.percentExtra + '%'"
      ></span>
      <span
        class="used"
        [style.width]="aiAllergensInfo.percentUsed + '%'"
      ></span>
    </div>
  </div>
}

@if (isFinite(aiMenuAnalysisInfo.percentUsed)) {
  <div class="restriction">
    <div class="written">
      {{ "plans.user_plan.ai_menu_analysis_left" | transloco }}:
      <mat-icon
        class="info-icon"
        [mtTooltip]="'plans.user_plan.ai_menu_analysis_help' | transloco"
        >info</mat-icon
      >
    </div>
    <div class="progress">
      {{ aiMenuAnalysisInfo.remaining }}
      @if (aiMenuAnalysisInfo.extra) {
        <span class="extra"> + {{ aiMenuAnalysisInfo.extra }} </span>
      }
    </div>
    <div class="example-margin">
      <span
        class="left"
        [style.width]="aiMenuAnalysisInfo.percentRemaining + '%'"
      ></span>
      <span
        class="left-extra"
        [style.width]="aiMenuAnalysisInfo.percentExtra + '%'"
      ></span>
      <span
        class="used"
        [style.width]="aiMenuAnalysisInfo.percentUsed + '%'"
      ></span>
    </div>
  </div>
}

@if (hasModule("recip") && isFinite(aiRecipesInfo.percentUsed)) {
  <div class="restriction">
    <div class="written">
      {{ "plans.user_plan.ai_recipes_left" | transloco }}:
      <mat-icon
        class="info-icon"
        [mtTooltip]="'plans.user_plan.ai_recipes_help' | transloco"
        >info</mat-icon
      >
    </div>
    <div class="progress">
      {{ aiRecipesInfo.remaining }}
      @if (aiRecipesInfo.extra) {
        <span class="extra"> + {{ aiRecipesInfo.extra }} </span>
      }
    </div>
    <div class="example-margin">
      <span
        class="left"
        [style.width]="aiRecipesInfo.percentRemaining + '%'"
      ></span>
      <span
        class="left-extra"
        [style.width]="aiRecipesInfo.percentExtra + '%'"
      ></span>
      <span class="used" [style.width]="aiRecipesInfo.percentUsed + '%'"></span>
    </div>
  </div>
}
