import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { StopPropagationDirective } from '../../../shared/Directives/stop-propagation/stop-propagation.directive';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'select-image',
  templateUrl: './select-image.component.html',
  styleUrls: ['./select-image.component.scss'],
  standalone: true,
  imports: [MatButtonModule, StopPropagationDirective, MatIconModule],
})
export class SelectImageComponent {
  @Input() canDelete: boolean;
  @Input() disabled: boolean;
  @Input() imageUrl: string;
  @Input() label: string;
  @Input() selected: boolean;

  @Output() imgSelected = new EventEmitter();
  @Output() delete = new EventEmitter();

  deleteButtonDisabled = false;
}
