import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { Dish } from 'src/app/shared/Models/dish';
import {
  Ingredient,
  PreIngredients,
  SimpleIngredient,
} from 'src/app/shared/Models/ingredients';
import {
  Recipe,
  RecipeIngredient,
  RecipeParams,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';
import { IngredientInfoComponent } from './ingredient-info/ingredient-info.component';
import { IngredientsComponent } from './ingredients/ingredients.component';

@Component({
  selector: 'ingredients-tab',
  templateUrl: './ingredients-tab.component.html',
  styleUrls: ['./ingredients-tab.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IngredientsComponent, IngredientInfoComponent],
})
export class IngredientsTabComponent {
  @Input() translations: any;
  @Input() dish: Dish;
  @Input() ingredientsAuto: SimpleIngredient[];
  @Input() ingredientInfo: RecipeIngredient;
  @Input() lang: ContentLanguage;
  @Input() recipe: Recipe;

  @Output() addIngredientToRecipe = new EventEmitter<{
    ingredient: SimpleIngredient;
    recipe: Recipe;
    onFulfilled: Function;
  }>();
  @Output() addMultipleIngredientsToRecipeEvent = new EventEmitter<{
    recipe: Recipe;
    data: PreIngredients;
  }>();
  @Output() createNewIngredientEvent = new EventEmitter<{
    recipe: Recipe;
    data: Partial<Recipe>;
    onError?: Function;
    params?: RecipeParams;
  }>();
  @Output() clearIngredientsAuto = new EventEmitter<void>();
  @Output() clearIngredientInfo = new EventEmitter<void>();
  @Output() deleteDishRecipeIngredientEvent = new EventEmitter<{
    recipe: Recipe;
    recipeIngredient: SimpleRecipeIngredient;
  }>();
  @Output() fetchIngredientsAuto = new EventEmitter<{
    search: string;
    recipeId: number;
  }>();
  @Output() fetchIngredientsInfo = new EventEmitter<{
    url: string;
    params: RecipeParams;
  }>();
  @Output() patchIngredientEvent = new EventEmitter<{
    url: string;
    data: Partial<Ingredient> | Partial<RecipeIngredient>;
    params: RecipeParams;
  }>();
  @Output() refetch = new EventEmitter<void>();

  constructor() {}

  getInfo(url: string): void {
    this.fetchIngredientsInfo.emit({
      url: url,
      params: { current_recipe: this.recipe.id },
    });
  }

  patchIngredient({ data, url }): void {
    this.patchIngredientEvent.emit({
      url,
      data,
      params: { current_recipe: this.recipe.id },
    });
  }
}
