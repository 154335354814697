import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { DateAdapter, MatOptionModule } from '@angular/material/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
  ContentLanguage,
  InterfaceLanguage,
} from 'src/app/shared/constants/languages';
import { LocationGroup, SimpleLocation } from 'src/app/shared/Models/location';
import { OnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import { SimpleUser, User } from 'src/app/shared/Models/user';
import * as _ from 'lodash-es';
import { MenusOverviewParams } from 'src/app/shared/Models/menu';
import { TranslocoPipe } from '@jsverse/transloco';
import { JsonPipe } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { LanguageSelectorComponent } from '../../../../shared/Components/language-selector/language-selector.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'menu-filters',
  templateUrl: './menu-filters.component.html',
  styleUrls: [`./menu-filters.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    LanguageSelectorComponent,
    MatDatepickerModule,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    JsonPipe,
    TranslocoPipe,
  ],
})
export class MenuFiltersComponent implements OnChanges, OnDestroy, OnInit {
  @Input() currentLocation: number;
  @Input() lang: InterfaceLanguage;
  @Input() languages: ContentLanguage[];
  @Input() locationGroups: LocationGroup[];
  @Input() locations: SimpleLocation[];
  @Input() templates: OnboardingTemplate[];
  @Input() organisationUsers: SimpleUser[];
  @Input() params: Partial<MenusOverviewParams> = {};
  @Input() user: User;
  @Output() chooseFilter = new EventEmitter<Partial<MenusOverviewParams>>();

  private destroyed$ = new Subject<void>();
  filtersForm: FormGroup<{
    name: FormControl<string>;
    date_from: FormControl<Date>;
    date_to: FormControl<Date>;
    onboarding_template: FormControl<number>;
    location_group: FormControl<number>;
    location: FormControl<number>;
    created_by: FormControl<number>;
    hide_location_menus: FormControl<boolean>;
    enable_ordertaking: FormControl<boolean>;
    enable_automated_orders: FormControl<boolean>;
  }>;
  hideLocationMenus = false;
  showOrdertakingOnly = false;
  showOrdertakingAutomatedOnly = false;
  nameController = new FormControl<string>('');
  originalLanguageList: ContentLanguage[];
  updatedParams: Partial<MenusOverviewParams>;

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private fb: FormBuilder,
    private utils: UtilsService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      'params' in changes &&
      changes.params.firstChange &&
      changes.params.currentValue
    ) {
      let anyChanged = false;
      if (changes.params.currentValue.hide_location_menus === 'true') {
        this.hideLocationMenus = true;
        anyChanged = true;
      }
      if (changes.params.currentValue.enable_ordertaking === 'true') {
        this.showOrdertakingOnly = true;
        anyChanged = true;
      }
      if (changes.params.currentValue.enable_automated_orders === 'true') {
        this.showOrdertakingAutomatedOnly = true;
        anyChanged = true;
      }
      if (anyChanged) {
        this.chooseFilter.emit();
      }
    }
    if (
      'languages' in changes &&
      (this.originalLanguageList === undefined ||
        !this.params.base_language ||
        this.languages.length >= this.originalLanguageList.length)
    ) {
      this.originalLanguageList = this.languages;
    }
  }

  ngOnInit(): void {
    this.initForm();
  }

  applyFiltersToView(filters: Partial<MenusOverviewParams>, emit = true): void {
    if (!this.filtersForm) return undefined;
    const currentForm: Partial<MenusOverviewParams> = {
      ...this.filtersForm.value,
      date_from: this.filtersForm.value.date_from
        ? this.dateAdapter.format(
            this.filtersForm.value.date_from,
            'yyyy-MM-dd',
          )
        : null,
      date_to: this.filtersForm.value.date_to
        ? this.dateAdapter.format(this.filtersForm.value.date_to, 'yyyy-MM-dd')
        : null,
    };
    this.updatedParams = _.pickBy(filters, (_, key) => key in currentForm);
    if (!_.isMatch(currentForm, this.updatedParams)) {
      this.filtersForm.patchValue(
        {
          ...this.updatedParams,
          date_from: this.updatedParams.date_from
            ? new Date(this.updatedParams.date_from)
            : null,
          date_to: this.updatedParams.date_to
            ? new Date(this.updatedParams.date_to)
            : null,
        },
        { emitEvent: emit },
      );
    }
  }

  changeCheckbox($event, field: keyof MenusOverviewParams) {
    if (field === 'hide_location_menus')
      this.hideLocationMenus = $event.checked;
    if (field === 'enable_ordertaking')
      this.showOrdertakingOnly = $event.checked;
    if (field === 'enable_automated_orders')
      this.showOrdertakingAutomatedOnly = $event.checked;
    if ($event.checked) {
      this.updatedParams = {
        ...this.params,
        [field]: $event.checked,
      };
      this.params = this.updatedParams;
    } else {
      const { [field]: _, ...rest } = this.params;
      this.updatedParams = rest;
      this.params = rest;
    }
    this.filtersForm.controls[field]?.patchValue(null, { emitEvent: false });
    this.chooseFilter.emit(this.updatedParams);
  }

  clearFilter = (field: keyof MenusOverviewParams, event: MouseEvent) => {
    event.stopPropagation();
    const { [field]: _, ...rest } = this.params;
    this.updatedParams = rest;
    this.filtersForm.controls[field]?.patchValue(null, { emitEvent: false });
    this.chooseFilter.emit(this.updatedParams);
  };

  initForm(): void {
    this.chooseFilter.emit();
    this.filtersForm = this.fb.group({
      name: [],
      date_from: [],
      date_to: [],
      onboarding_template: [],
      location_group: [],
      location: [],
      created_by: [],
      hide_location_menus: [],
      enable_ordertaking: [],
      enable_automated_orders: [],
    });

    this.applyFiltersToView(this.params, false);
    this.filtersForm.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(400),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        const updatedFormValues = { ...this.params };
        this.filtersForm['_forEachChild'](
          (control: FormControl, name: string) => {
            if (control.dirty && control.valid) {
              updatedFormValues[name] = control.value;
            }
          },
        );
        ['date_from', 'date_to']
          .filter((f) => f in updatedFormValues && !!updatedFormValues[f])
          .forEach((field) => {
            updatedFormValues[field] = this.dateAdapter.format(
              updatedFormValues[field],
              'yyyy-MM-dd',
            );
          });
        this.updatedParams = updatedFormValues;
        this.chooseFilter.emit(updatedFormValues);
      });
  }

  hasModule = (code: string): boolean => this.utils.hasModules(code);

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
