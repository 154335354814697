import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { TranslocoPipe } from '@jsverse/transloco';
import { StopPropagationDirective } from '../../../../../../shared/Directives/stop-propagation/stop-propagation.directive';
import { MatButtonModule } from '@angular/material/button';
import { NgClass } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { Dish } from 'src/app/shared/Models/dish';

@Component({
  selector: 'nutrition-rating',
  templateUrl: './nutrition-rating.component.html',
  styleUrls: ['./nutrition-rating.component.css'],
  standalone: true,
  imports: [
    MatIconModule,
    MatTooltipModule,
    NgClass,
    MatButtonModule,
    StopPropagationDirective,
    TranslocoPipe,
  ],
})
export class NutritionRatingComponent {
  @Input() dish: MenuDish;
  @Output() updateDish = new EventEmitter<{
    menuDish: MenuDish;
    data: DeepPartial<Dish> & { onFulfilled?: () => void };
  }>();

  getClasses(rating: number) {
    const r = this.getRating();
    return {
      ['mat-mdc-elevation-z3']: rating === r,
      notactive: rating !== r,
    };
  }

  getRating(): number {
    if (this.dish?.dish_detail?.user_details) {
      return this.dish.dish_detail.user_details.nutrition_rating;
    }
    return undefined;
  }

  setRating(rating: number): void {
    this.updateDish.emit({
      menuDish: this.dish,
      data: {
        user_details: {
          nutrition_rating: this.getRating() === rating ? null : rating,
        },
      },
    });
  }
}
