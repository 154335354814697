import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreviewComponent } from 'src/app/shared/Components/pdf-preview/pdf-preview.component';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { Dish } from 'src/app/shared/Models/dish';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'fiche-technique',
  templateUrl: './fiche-technique.component.html',
  styleUrls: ['./fiche-technique.component.css'],
  standalone: true,
  imports: [MatIconModule, TranslocoPipe],
})
export class FicheTechniqueComponent {
  @Input() dish: Dish;
  @Input() lang: ContentLanguage;

  constructor(
    public dialog: MatDialog,
    private router: Router,
  ) {}

  showPreview = () => {
    const pdfPreview = this.dialog.open(PreviewComponent, {
      autoFocus: false,
      width: '890px',
      maxWidth: '90vw',
      height: '95%',
    });
    const instance = pdfPreview.componentInstance;
    instance.url = this.dish.preview;
  };

  navigateToDish() {
    this.router.navigate(['/dishes'], {
      queryParams: { search: this.dish[this.lang] },
    });
  }
}
