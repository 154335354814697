import {
  Component,
  DestroyRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ValidatorFn,
  Validators,
  ReactiveFormsModule,
  FormBuilder,
} from '@angular/forms';
import { TERMS_LINKS } from 'src/app/app.config';
import { ValidationMessages } from 'src/app/shared/constants/validation';
import { RegistrationForm } from 'src/app/shared/Models/authentication';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { EmailValidators, PasswordValidators } from 'ngx-validators';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { checkEmailRemotely, register } from 'src/app/auth/ngrx/auth.actions';
import { TranslocoPipe } from '@jsverse/transloco';
import { SpinnerComponent } from '../../../shared/Components/spinner/spinner.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    SpinnerComponent,
    TranslocoPipe,
  ],
})
export class RegistrationComponent implements OnInit, OnChanges {
  @Input() affiliate: string;
  @Input() lang: InterfaceLanguage;
  @Input() emailVerification: any = {};
  @Input() registerButtonState: boolean;
  @Input() isEmailExist: boolean;

  emailCustomErrors = ['disposable', 'did_you_mean_blank', 'mx_found'];
  formErrors = {
    first_name: '',
    last_name: '',
    email: '',
    password1: '',
    password2: '',
    terms: '',
  };
  links = TERMS_LINKS;
  previousEmail: string = null;
  registrationForm: FormGroup;
  registration = new RegistrationForm();
  validationMessages = ValidationMessages;

  static emailValidator(obj): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (
        `disposable` in obj.emailVerification &&
        obj.emailVerification.disposable
      ) {
        return { disposable: true };
      } else if (
        `did_you_mean` in obj.emailVerification &&
        obj.emailVerification.did_you_mean
      ) {
        return {
          did_you_mean_blank: obj.emailVerification.did_you_mean,
        };
      } else if (
        `mx_found` in obj.emailVerification &&
        !obj.emailVerification.mx_found
      ) {
        return { mx_found: true };
      } else {
        return null;
      }
    };
  }

  constructor(
    private destroyRef: DestroyRef,
    private fb: FormBuilder,
    private store: Store<State>,
    private utils: UtilsService,
  ) {
    const keys = Object.keys(this.validationMessages).flatMap((el) =>
      Object.keys(this.validationMessages[el])
        .filter((value) => Object.keys(this.formErrors).includes(value))
        .map((res) => `auth.registration.fields.${el}.validation.${res}`),
    );
    this.utils.getTranslation(keys, (res) => this.handleLang(keys, res));
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('emailVerification' in changes && this.registrationForm) {
      this.registrationForm.get('email').updateValueAndValidity();
    }
  }

  handleLang = (keys, res) => {
    keys.forEach((key: string, index: number) => {
      const spl = key.split('.');
      this.validationMessages[spl[3]][spl[5]] = res[index];
    });
    this.validationMessages.email['did_you_mean_blank'] = '';
    this.onValueChanged();
  };

  ngOnInit(): void {
    this.buildForm();
    this.registrationForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.onValueChanged());
  }

  onSubmit(event: Event): void {
    event.preventDefault();
    this.registration = this.registrationForm.value;
    this.isTermsEnable(this.registrationForm.get('terms'));
    const errors = Object.keys(
      Object.values(this.registrationForm.controls)
        .map((control) => control.errors)
        .reduce((acc, curr) => {
          acc = { ...acc, ...curr };
          return acc;
        }, {}),
    );
    if (this.affiliate) {
      this.registration.affiliate = this.affiliate;
    }
    return (
      (this.registrationForm.valid ||
        (errors.length === 1 && errors[0] === 'did_you_mean_blank')) &&
      this.store.dispatch(
        register({ data: this.registration, lang: this.lang }),
      )
    );
  }

  isTermsEnable(control: AbstractControl): void {
    if (control && !control.valid) {
      const messages = this.validationMessages.terms;
      for (const key in control.errors) {
        this.formErrors.terms = messages[key];
      }
    }
  }

  onValueChanged(): void {
    if (!this.registrationForm) return undefined;
    const form = this.registrationForm;
    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  errorsLength(element): number {
    if (!element || !element.errors) return 0;
    return Object.keys(element.errors).length;
  }

  replaceEmail(): void {
    const emailField = this.registrationForm.get('email');
    emailField.setValue(emailField?.errors?.did_you_mean_blank);
    this.checkEmailRemotely(emailField.value);
  }

  checkEmail(target: EventTarget): void {
    const { value: email } = <HTMLInputElement>target;
    if (!this.registrationForm || !email) return undefined;
    if (email === this.previousEmail) return undefined;
    if (
      !this.registrationForm.controls.email.valid &&
      !Object.keys(this.registrationForm.get('email').errors).some((key) =>
        this.emailCustomErrors.includes(key),
      )
    )
      return undefined;
    this.checkEmailRemotely(email);
    this.previousEmail = email;
  }

  checkEmailRemotely(email: string): void {
    this.store.dispatch(checkEmailRemotely({ email }));
  }

  buildForm(): void {
    this.registrationForm = this.fb.group({
      first_name: [
        this.registration.first_name,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(24),
        ],
      ],
      last_name: [
        this.registration.last_name,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(24),
        ],
      ],
      email: [
        this.registration.email,
        [
          Validators.required,
          EmailValidators.normal,
          RegistrationComponent.emailValidator(this),
        ],
      ],
      terms: [
        this.registration.terms,
        [Validators.required, Validators.requiredTrue],
      ],
      password1: [this.registration.password],
      password2: [this.registration.confirm_password],
    });
    this.registrationForm.setValidators(
      PasswordValidators.mismatchedPasswords('password1', 'password2'),
    );
  }
}
