import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  MatDatepickerInput,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { StopPropagationDirective } from '../../Directives/stop-propagation/stop-propagation.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-download-stats',
  templateUrl: './download-stats.component.html',
  styleUrls: ['./download-stats.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    StopPropagationDirective,
    MatIconModule,
    TranslocoPipe,
  ],
})
export class DownloadStatsComponent implements OnChanges {
  @Input() created: string;
  @Input() lang: InterfaceLanguage;
  @Output() download = new EventEmitter<{
    date_from: string;
    date_to: string;
  }>();

  @ViewChild('inputStart')
  startDate: MatDatepickerInput<Date>;
  @ViewChild('inputEnd')
  endDate: MatDatepickerInput<Date>;

  minDate: Date;
  maxDate = new Date();

  constructor(private dateAdapter: DateAdapter<Date>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('created' in changes && this.created) {
      this.minDate = new Date(this.created);
    }
  }

  downloadStats() {
    // add one day to endDate to include the whole day
    const editedDate = new Date(this.endDate.value);
    editedDate.setDate(editedDate.getDate() + 1);
    this.download.emit({
      date_from: this.dateAdapter.format(this.startDate.value, 'yyyy-MM-dd'),
      date_to: this.dateAdapter.format(editedDate, 'yyyy-MM-dd'),
    });
  }
}
