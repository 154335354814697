import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { TranslocoPipe } from '@jsverse/transloco';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ExpressTranslationComponent } from 'src/app/shared/Components/express-translation/express-translation.component';
import { MagicStickComponent } from 'src/app/shared/Components/magic-stick/magic-stick.component';
import { Categories } from 'src/app/shared/constants/categories';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { SearchForm } from 'src/app/shared/Models/translation';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { TranslationFilters } from '../translations.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-translate-actions',
  standalone: true,
  imports: [
    ExpressTranslationComponent,
    MagicStickComponent,
    MatExpansionModule,
    TranslocoPipe,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './translate-actions.component.html',
  styleUrl: './translate-actions.component.scss',
})
export class TranslateActionsComponent implements OnInit {
  @Input() blockExpressTranslationLangs: string[];
  @Input() blockGrammarCheckLangs: string[];
  @Input() filter: TranslationFilters;
  @Input() filteredDishes: MenuDish[];
  @Input() isTrial: boolean;
  @Input() lang: ContentLanguage;
  @Input() menuDishes: MenuDish[];
  @Input() menuDishesLoading: boolean;
  @Input() misssingTranslationsCount: number;
  @Input() profileComplete: boolean;
  @Input() unverifiedTranslationsCount: number;

  @Output() expressTranslate = new EventEmitter<void>();
  @Output() filterChanged = new EventEmitter<MatSelectChange>();
  @Output() grammarCheck = new EventEmitter<void>();
  @Output() searchAndFilter = new EventEmitter<{
    [key: string]: string | boolean;
  }>();

  dishFilters = [
    {
      id: TranslationFilters.SEE_ALL,
      value: 'shared.buttons.see-all',
    },
    {
      id: TranslationFilters.SEE_ALL_REQUIRED,
      value: 'dishes.translation.filter.required',
    },
    {
      id: TranslationFilters.SEE_MISSING_ONLY,
      value: 'dishes.translation.filter.missing',
    },
    {
      id: TranslationFilters.SEE_AUTO_TRANSLATED,
      value: 'dishes.translation.filter.auto-translated',
    },
  ];
  filters = [Categories.DISH, Categories.BEVERAGE, Categories.WINE];
  searchForm = new FormGroup({
    filter: new FormControl('default'),
  });

  constructor(
    private destroyRef: DestroyRef,
    private utils: UtilsService,
  ) {}

  ngOnInit(): void {
    this.searchForm.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(400),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((data: SearchForm) => {
        const params: { [key: string]: string | boolean } = {};
        if (data.filter === 'default') {
          params[`category`] = undefined;
        } else {
          params[`category`] = data.filter;
        }
        if (data.search) params[this.lang] = data.search;
        if (this.filter) params[`${this.lang}_isempty`] = true;
        this.searchAndFilter.emit(params);
      });
  }

  showTrialBanner(): void {
    this.utils.showTrialBlockedBox();
  }
}
