@for (item of options; track item) {
  <div class="o-block">
    <option-item
      [type]="type"
      [color]="color"
      [hidden]="showAfter.includes(item.name) && !parentEnable(item.name)"
      [disabled]="hasEnableChild(item.name)"
      (triggerOption)="changeOption($event)"
      (showDialog)="showDialog($event)"
      [showConfirmDialog]="showConfirmDialog"
      [lang]="lang"
      [option]="item"
      [items]="
        type === 'allergens'
          ? allergens
          : type === 'additives'
            ? additives
            : labels
      "
    >
    </option-item>
    @if (
      (hasModuleSetting("all", "extended_declaration", true) ||
        hasModuleSetting("all", "lactose_declaration", true)) &&
      item.dependants &&
      item.value
    ) {
      <div class="dependants">
        @for (dep of item.dependants; track dep) {
          <option-item
            class="allergen-type"
            [type]="type"
            [color]="color"
            [hidden]="showAfter.includes(dep.name) && !parentEnable(dep.name)"
            [disabled]="hasEnableChild(dep.name)"
            (triggerOption)="changeOption($event)"
            (showDialog)="showDialog($event)"
            [showConfirmDialog]="showConfirmDialog"
            [lang]="lang"
            [option]="dep"
            [items]="
              type === 'allergens'
                ? allergens
                : type === 'additives'
                  ? additives
                  : labels
            "
          >
          </option-item>
        }
      </div>
    }
  </div>
}
