<div class="slidedown">
  <div class="message">
    @if (task?.ready) {
      <span
        [innerHTML]="
          'user.archive.ready.' + taskMessageTranslationKey
            | transloco: { name: task.task_name }
        "
      ></span>
      <button
        mat-flat-button
        color="primary"
        class="download-button"
        [disabled]="taskDownloading"
        (click)="downloadArchive()"
      >
        <mat-icon [class.hidden]="taskDownloading">file_download</mat-icon>
        <spinner
          [config]="{ radius: 7 }"
          class="download-spinner"
          [class.hidden]="!taskDownloading"
        ></spinner>
        {{ "user.archive.ready.actions.download" | transloco }}
      </button>
    } @else if (task.failed) {
      <span
        [innerHTML]="
          'user.archive.failed.' + taskMessageTranslationKey
            | transloco: { name: task.task_name }
        "
      ></span>
    } @else {
      @if (task.processing) {
        <div class="description-message">
          <span
            [innerHTML]="
              'user.archive.building.' + taskMessageTranslationKey
                | transloco: { name: task.task_name }
            "
          ></span>
        </div>
        <mat-icon
          class="info-icon"
          [mtTooltip]="
            expectedDurationRemaining >= 0
              ? ('user.archive.building.expected-duration'
                | transloco
                  : {
                      time: expectedDuration | date: 'mm:ss',
                      remaining: expectedDurationRemaining | date: 'mm:ss',
                    })
              : ('user.archive.building.expected-duration-soon' | transloco)
          "
          >info</mat-icon
        >
        @if (task.expected_duration) {
          <mat-progress-bar
            class="progress-bar"
            mode="determinate"
            [value]="expectedDurationPercentage"
            [mtTooltip]="
              expectedDurationPercentage < 100
                ? (expectedDurationPercentage | number: '1.0-0') + '%'
                : '100%'
            "
          ></mat-progress-bar>
        } @else {
          <mat-progress-bar
            class="progress-bar"
            mode="indeterminate"
          ></mat-progress-bar>
        }
      } @else {
        <img class="spinner" src="assets/img/loading.svg" alt="loading" />
        <span
          [innerHTML]="
            'user.archive.waiting.' + taskMessageTranslationKey
              | transloco: { name: task.task_name }
          "
        ></span>
      }
    }
  </div>
  @if (task?.ready || task?.failed) {
    <button mat-icon-button (click)="closed.emit()">
      <mat-icon>close</mat-icon>
    </button>
  }
</div>
