<div
  class="ordertaking-settings field-density-1"
  [class.narrow]="showCheckboxes"
>
  <div class="enable-ordertaking">
    @if (!showCheckboxes) {
      <mat-slide-toggle
        color="primary"
        [formControl]="enableOrdertakingControl"
        >{{ "shared.ordertaking.label" | transloco }}</mat-slide-toggle
      >
    } @else {
      <mat-checkbox color="primary" [formControl]="enableOrdertakingControl">{{
        "shared.ordertaking.label" | transloco
      }}</mat-checkbox>
    }
    <mat-hint class="hint">
      {{ "shared.ordertaking.description" | transloco }}
    </mat-hint>
  </div>

  @if (enableOrdertakingControl.value) {
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ "shared.ordertaking.ordertaking-layout" | transloco }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-hint class="hint">{{
        "shared.ordertaking.layout.explanation" | transloco
      }}</mat-hint>
      <form class="ordertaking-settings" [formGroup]="ordertakingOptions">
        <mat-form-field class="layout-select" subscriptSizing="dynamic">
          <mat-select [formControl]="layoutControl">
            @for (layout of layouts; track layout) {
              <mat-option [value]="layout?.id">
                {{ layout?.[lang] }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </form>
    </mat-expansion-panel>
  }

  @if (enableOrdertakingControl.value) {
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ "shared.ordertaking.settings.title" | transloco }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        <mat-hint class="hint">{{
          "shared.ordertaking.settings.explanation" | transloco
        }}</mat-hint>
      </p>
      <form class="ordertaking-settings" [formGroup]="ordertakingOptions">
        <div class="settings-group">
          @if (!showCheckboxes) {
            <mat-slide-toggle
              color="primary"
              class="settings-toggle"
              [disabled]="!item?.url"
              [formControl]="ordertakingCutoffControl"
            >
              {{ "shared.ordertaking.settings.cutoff.toggle" | transloco }}
            </mat-slide-toggle>
          } @else {
            <mat-checkbox
              color="primary"
              [disabled]="!item?.url"
              [formControl]="ordertakingCutoffControl"
            >
              {{ "shared.ordertaking.settings.cutoff.toggle" | transloco }}
            </mat-checkbox>
          }
          @if (ordertakingCutoffControl.value) {
            <div class="options-group">
              <mat-form-field>
                <mat-label>{{
                  "shared.ordertaking.settings.cutoff.days" | transloco
                }}</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="order_by_days_before"
                  name="order_by_days_before"
                />
                <mat-hint>{{
                  "shared.ordertaking.settings.cutoff.hint" | transloco
                }}</mat-hint>
              </mat-form-field>
              <mat-form-field class="time-input">
                <input
                  matInput
                  name="order_by_time"
                  formControlName="order_by_time"
                  placeholder="12:00"
                />
              </mat-form-field>
              <button
                mat-icon-button
                class="time-picker-button"
                (click)="pickerH.open()"
              >
                <mat-icon>watch_later</mat-icon>
              </button>
              <ngx-mat-timepicker
                [defaultTime]="item?.order_by_time ?? '09:00'"
                [format]="24"
                (timeSet)="updateTime($event, 'order_by_time')"
                #pickerH
              ></ngx-mat-timepicker>
            </div>
          }
        </div>
        <div class="settings-group">
          @if (!showCheckboxes) {
            <mat-slide-toggle
              color="primary"
              class="settings-toggle"
              [disabled]="!item?.url"
              [formControl]="ordertakingCancelControl"
            >
              {{ "shared.ordertaking.settings.cancel.toggle" | transloco }}
            </mat-slide-toggle>
          } @else {
            <mat-checkbox
              color="primary"
              [disabled]="!item?.url"
              [formControl]="ordertakingCancelControl"
            >
              {{ "shared.ordertaking.settings.cancel.toggle" | transloco }}
            </mat-checkbox>
          }
          @if (ordertakingCancelControl.value) {
            <div class="options-group">
              <mat-form-field>
                <mat-label>{{
                  "shared.ordertaking.settings.cancel.days" | transloco
                }}</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="cancel_by_days_before"
                  name="cancel_by_days_before"
                />
                <mat-hint>{{
                  "shared.ordertaking.settings.cancel.hint" | transloco
                }}</mat-hint>
              </mat-form-field>
              <mat-form-field class="time-input">
                <input
                  matInput
                  name="cancel_by_time"
                  formControlName="cancel_by_time"
                  placeholder="12:00"
                />
              </mat-form-field>
              <button
                mat-icon-button
                class="time-picker-button"
                (click)="pickerH.open()"
              >
                <mat-icon>watch_later</mat-icon>
              </button>
              <ngx-mat-timepicker
                [defaultTime]="item?.cancel_by_time ?? '09:00'"
                [format]="24"
                (timeSet)="updateTime($event, 'cancel_by_time')"
                #pickerH
              ></ngx-mat-timepicker>
            </div>
          }
        </div>
        <div class="settings-group">
          @if (!showCheckboxes) {
            <mat-slide-toggle
              color="primary"
              class="settings-toggle"
              [disabled]="!item?.url"
              [formControl]="ordertakingDaysUntilControl"
            >
              {{ "shared.ordertaking.settings.daysuntil.toggle" | transloco }}
            </mat-slide-toggle>
          } @else {
            <mat-checkbox
              color="primary"
              [disabled]="!item?.url"
              [formControl]="ordertakingDaysUntilControl"
            >
              {{ "shared.ordertaking.settings.daysuntil.toggle" | transloco }}
            </mat-checkbox>
          }
          @if (ordertakingDaysUntilControl.value) {
            <div class="options-group">
              <mat-form-field class="full-width">
                <mat-label>{{
                  "shared.ordertaking.settings.daysuntil.days" | transloco
                }}</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="order_days_until"
                  name="order_days_until"
                />
                <mat-hint>{{
                  "shared.ordertaking.settings.daysuntil.hint" | transloco
                }}</mat-hint>
              </mat-form-field>
            </div>
          }
        </div>
        <div class="settings-group">
          @if (!showCheckboxes) {
            <mat-slide-toggle
              color="primary"
              class="settings-toggle"
              [disabled]="!item?.url"
              [formControl]="ordertakingLastminuteControl"
            >
              {{ "shared.ordertaking.settings.warning.toggle" | transloco }}
            </mat-slide-toggle>
          } @else {
            <mat-checkbox
              color="primary"
              [disabled]="!item?.url"
              [formControl]="ordertakingLastminuteControl"
            >
              {{ "shared.ordertaking.settings.warning.toggle" | transloco }}
            </mat-checkbox>
          }
          @if (ordertakingLastminuteControl.value) {
            <div class="options-group">
              <mat-form-field>
                <mat-label>{{
                  "shared.ordertaking.settings.warning.days" | transloco
                }}</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="warning_lastminute_days_before"
                  name="warning_lastminute_days_before"
                />
                <mat-hint>{{
                  "shared.ordertaking.settings.warning.hint" | transloco
                }}</mat-hint>
              </mat-form-field>
              <mat-form-field class="time-input">
                <input
                  matInput
                  name="warning_lastminute_time"
                  formControlName="warning_lastminute_time"
                  placeholder="12:00"
                />
              </mat-form-field>
              <button
                mat-icon-button
                class="time-picker-button"
                (click)="pickerH.open()"
              >
                <mat-icon>watch_later</mat-icon>
              </button>
              <ngx-mat-timepicker
                [defaultTime]="item?.warning_lastminute_time ?? '09:00'"
                [format]="24"
                (timeSet)="updateTime($event, 'warning_lastminute_time')"
                #pickerH
              ></ngx-mat-timepicker>
            </div>
          }
        </div>
        <div class="settings-group">
          @if (!showCheckboxes) {
            <mat-slide-toggle
              color="primary"
              class="settings-toggle"
              [disabled]="!item?.url"
              [formControl]="maximumComponentsControl"
            >
              {{
                "shared.ordertaking.settings.maximum-components.toggle"
                  | transloco
              }}
            </mat-slide-toggle>
          } @else {
            <mat-checkbox
              color="primary"
              [disabled]="!item?.url"
              [formControl]="maximumComponentsControl"
            >
              {{
                "shared.ordertaking.settings.maximum-components.toggle"
                  | transloco
              }}
            </mat-checkbox>
          }
          @if (maximumComponentsControl.value) {
            <div class="options-group">
              <mat-form-field class="full-width">
                <mat-label>{{
                  "shared.ordertaking.settings.maximum-components.days"
                    | transloco
                }}</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="maximum_components"
                  name="maximum_components"
                />
                <mat-hint>{{
                  "shared.ordertaking.settings.maximum-components.hint"
                    | transloco
                }}</mat-hint>
              </mat-form-field>
            </div>
          }
        </div>
        <div class="settings-group">
          @if (!showCheckboxes) {
            <mat-slide-toggle
              color="primary"
              class="settings-toggle"
              [disabled]="!item?.url"
              [formControl]="checkinDayAutomatedOrdersControl"
            >
              {{
                "shared.ordertaking.settings.automated_checkin_day.toggle"
                  | transloco
              }}
            </mat-slide-toggle>
          } @else {
            <mat-checkbox
              color="primary"
              [formControl]="checkinDayAutomatedOrdersControl"
            >
              {{
                "shared.ordertaking.settings.automated_checkin_day.toggle"
                  | transloco
              }}
            </mat-checkbox>
          }
          @if (
            enableOrdertakingControl.value &&
            checkinDayAutomatedOrdersControl.value
          ) {
            <div class="options-group">
              <p>
                {{
                  "shared.ordertaking.settings.automated_checkin_day.hint"
                    | transloco
                }}
              </p>
              <mat-form-field class="time-input wide">
                <input
                  matInput
                  name="automated_orders_checkin_time"
                  formControlName="automated_orders_checkin_time"
                  [placeholder]="
                    'shared.ordertaking.settings.automated_checkin_day.default'
                      | transloco
                  "
                />
              </mat-form-field>
              <button
                mat-icon-button
                class="time-picker-button"
                (click)="pickerH.open()"
              >
                <mat-icon>watch_later</mat-icon>
              </button>
              <ngx-mat-timepicker
                [defaultTime]="item?.automated_orders_checkin_time ?? '09:00'"
                [format]="24"
                (timeSet)="updateTime($event, 'automated_orders_checkin_time')"
                #pickerH
              ></ngx-mat-timepicker>
            </div>
          }
        </div>
        <div class="settings-group">
          @if (!showCheckboxes) {
            <mat-slide-toggle
              color="primary"
              class="settings-toggle"
              [disabled]="!item?.url"
              [formControl]="checkoutDayAutomatedOrdersControl"
            >
              {{
                "shared.ordertaking.settings.automated_checkout_day.toggle"
                  | transloco
              }}
            </mat-slide-toggle>
          } @else {
            <mat-checkbox
              color="primary"
              [formControl]="checkoutDayAutomatedOrdersControl"
            >
              {{
                "shared.ordertaking.settings.automated_checkout_day.toggle"
                  | transloco
              }}
            </mat-checkbox>
          }
          @if (
            enableOrdertakingControl.value &&
            checkoutDayAutomatedOrdersControl.value
          ) {
            <div class="options-group">
              <p>
                {{
                  "shared.ordertaking.settings.automated_checkout_day.hint"
                    | transloco
                }}
              </p>
              <mat-form-field class="time-input wide">
                <input
                  matInput
                  name="automated_orders_checkout_time"
                  formControlName="automated_orders_checkout_time"
                  [placeholder]="
                    'shared.ordertaking.settings.automated_checkout_day.default'
                      | transloco
                  "
                />
              </mat-form-field>
              <button
                mat-icon-button
                class="time-picker-button"
                (click)="pickerH.open()"
              >
                <mat-icon>watch_later</mat-icon>
              </button>
              <ngx-mat-timepicker
                [defaultTime]="item?.automated_orders_checkout_time ?? '09:00'"
                [format]="24"
                (timeSet)="updateTime($event, 'automated_orders_checkout_time')"
                #pickerH
              ></ngx-mat-timepicker>
            </div>
          }
        </div>
        <div class="settings-group">
          @if (!showCheckboxes) {
            <mat-slide-toggle
              color="primary"
              class="settings-toggle"
              [disabled]="!item?.url"
              [formControl]="mealSortingControl"
            >
              {{ "shared.ordertaking.settings.mealsorting.toggle" | transloco }}
            </mat-slide-toggle>
          } @else {
            <mat-checkbox
              color="primary"
              [disabled]="!item?.url"
              [formControl]="mealSortingControl"
            >
              {{ "shared.ordertaking.settings.mealsorting.toggle" | transloco }}
            </mat-checkbox>
          }
          @if (mealSortingControl.value) {
            <div class="options-group">
              <mat-form-field class="full-width">
                <mat-label>{{
                  "shared.ordertaking.settings.mealsorting.order" | transloco
                }}</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="meal_sorting"
                  name="meal_sorting"
                />
                <mat-hint>{{
                  "shared.ordertaking.settings.mealsorting.hint" | transloco
                }}</mat-hint>
              </mat-form-field>
            </div>
          }
        </div>
      </form>
    </mat-expansion-panel>
  }

  @if (enableOrdertakingControl.value) {
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ "shared.ordertaking.automated.title" | transloco }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        <mat-hint class="hint">{{
          "shared.ordertaking.automated.explanation" | transloco
        }}</mat-hint>
      </p>
      <form class="ordertaking-settings" [formGroup]="ordertakingOptions">
        @if (!showCheckboxes) {
          <mat-slide-toggle
            color="primary"
            [formControl]="enableAutomatedOrdersControl"
          >
            {{ "shared.ordertaking.automated.toggle" | transloco }}
          </mat-slide-toggle>
        } @else {
          <mat-checkbox
            color="primary"
            [formControl]="enableAutomatedOrdersControl"
          >
            {{ "shared.ordertaking.automated.toggle" | transloco }}
          </mat-checkbox>
        }
        <div class="settings-group">
          @if (enableAutomatedOrdersControl.value) {
            <div class="options-group">
              <mat-form-field>
                <mat-label>{{
                  "shared.ordertaking.settings.automated.days" | transloco
                }}</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="automated_orders_days_before"
                  name="automated_orders_days_before"
                />
                <mat-hint>{{
                  "shared.ordertaking.settings.automated.hint" | transloco
                }}</mat-hint>
              </mat-form-field>
              <mat-form-field class="time-input">
                <input
                  matInput
                  name="automated_orders_time"
                  formControlName="automated_orders_time"
                  placeholder="12:00"
                />
              </mat-form-field>
              <button
                mat-icon-button
                class="time-picker-button"
                (click)="pickerH.open()"
              >
                <mat-icon>watch_later</mat-icon>
              </button>
              <ngx-mat-timepicker
                [defaultTime]="item?.automated_orders_time ?? '09:00'"
                [format]="24"
                (timeSet)="updateTime($event, 'automated_orders_time')"
                #pickerH
              ></ngx-mat-timepicker>
            </div>
            <mat-slide-toggle
              color="primary"
              class="settings-toggle"
              formControlName="repeated_orders_consumers"
              [disabled]="!item?.url"
            >
              {{
                "shared.ordertaking.settings.automated.repeat_orders_consumers"
                  | transloco
              }}
            </mat-slide-toggle>
          }
        </div>
      </form>
    </mat-expansion-panel>
  }
</div>
