<div class="wrapper">
  <h2 mat-dialog-title class="header">
    @if (data?.adding) {
      <span class="title">{{ data?.title | transloco }}</span>
    } @else {
      <span class="title"
        >{{ "shared.dialogs.item-name.title" | transloco }} </span
      ><span [style.font-style]="italic ? 'italic' : ''">{{ itemName }}</span>
    }
  </h2>
  <mat-dialog-content class="content">
    @if (
      data?.locations &&
      (data.locations | async)?.length &&
      !(data.user | async)?.location
    ) {
      <div class="form-field-wrapper location">
        <app-select-location
          class="location-select"
          [groupedLocations]="data.groupedLocations | async"
          [locations]="data.locations | async"
          [locationControl]="locationControl"
          (clearLocation)="clearLocation()"
        ></app-select-location>
      </div>
    }
    <name-description
      [description]="data.description"
      [allLanguages]="!data.onlyInterfaceLangs"
      [hideProgress]="data.hideProgress"
      [item]="data.item"
      [lang]="lang"
      (update)="patchItem($event)"
    ></name-description>
    @if (data.showArticleNumber) {
      <div class="lang-block">
        <mat-form-field class="text">
          <mat-label>{{ "ingredients.table.article" | transloco }}</mat-label>
          <input type="text" matInput [(ngModel)]="data.item.article_number" />
        </mat-form-field>
      </div>
    }
    @if (data.image) {
      <show-and-upload-image
        [class.has-image]="data.item[data.image]"
        [imageUrl]="
          data.item[data.image + '_small']
            ? data.item[data.image + '_small']
            : data.item[data.image]
        "
        [showSpinner]="showImageSpinner"
        (deleteImage)="data.clearImage()"
        (fileChoosen)="uploadImage($event)"
      ></show-and-upload-image>
    }
  </mat-dialog-content>
</div>
<mat-dialog-actions class="actions">
  <button mat-button class="cancel" (click)="dialogRef.close()">
    {{ "shared.buttons.cancel" | transloco }}
  </button>
  <button
    mat-button
    class="done"
    color="primary"
    [disabled]="allEmpty"
    (click)="updateAndClose()"
  >
    {{ "shared.buttons.done" | transloco }}
  </button>
</mat-dialog-actions>
