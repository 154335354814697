import { MediaMatcher } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  OnDeletedFromOverlay,
  OverlayService,
} from 'src/app/shared/Services/overlay-service/overlay.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
  standalone: true,
})
export class SidePanelComponent
  implements AfterViewInit, OnInit, OnDeletedFromOverlay
{
  @Input() topOffset: number;

  statusBarQuery: MediaQueryList;
  maxWidth = 900;
  maxWidthEvent = new BehaviorSubject<boolean>(false);
  set contentSet(value: boolean) {
    this._contentSet = value;
    setTimeout(() => this.updateVisibility());
  }
  get contentSet(): boolean {
    return this._contentSet;
  }

  @HostBinding('style.top.px') get yOffset(): number {
    return this.isMobile
      ? this._topOffsetMobile
      : this._yOffset + this.topOffset;
  }

  set yOffset(px: number | undefined) {
    this._yOffset = px;
  }

  set topOffsetMobile(px: number | undefined) {
    this._topOffsetMobile = px;
  }

  private _yOffset = 0;
  private _topOffsetMobile = 0;
  private _contentSet = undefined;
  public isMobile: boolean = false;

  @ViewChild(`container`, { read: ViewContainerRef, static: true })
  container: ViewContainerRef;

  @Output() mobileViewSubject = new BehaviorSubject<boolean>(
    window.innerWidth <= this.maxWidth,
  );

  @Output() visibleEvent = new EventEmitter<boolean>();
  @Output() mobileViewEvent = new EventEmitter<boolean>();

  constructor(
    private media: MediaMatcher,
    private overlayService: OverlayService,
  ) {}

  ngOnInit() {
    this.overlayService.registerContainer(`side-panel`, this.container);
    this.overlayService.registerComponent(`side-panel`, this);
  }

  ngAfterViewInit(): void {
    this.isMobile = window.innerWidth <= this.maxWidth;
    this.statusBarQuery = this.media.matchMedia(
      `(max-width: ${this.maxWidth}px)`,
    );
    // Safari fix
    if (this.statusBarQuery.addEventListener) {
      this.statusBarQuery.addEventListener(`change`, this.handleQuery);
    } else if (this.statusBarQuery.addListener) {
      this.statusBarQuery.addListener((event) => {
        if (event.type === `change`) this.handleQuery(event);
      });
    }

    this.dispatchStatusBarEvent();
  }

  handleQuery = ({ matches }: { matches: boolean; type: string }): void => {
    this.mobileViewEvent.emit(matches);
    this.mobileViewSubject.next(matches);
    this.maxWidthEvent.next(matches);
    this.isMobile = matches;

    if (this.contentSet === undefined) return undefined;
    this.contentSet = matches ? false : true;
  };

  updateVisibility(): void {
    this.visibleEvent.emit(this.contentSet);
  }

  dispatchStatusBarEvent(): void {
    this.handleQuery({
      type: `change`,
      matches: window.innerWidth <= this.maxWidth,
    });
  }

  tryHidePanel(): void {
    this.contentSet = this.isMobile ? false : true;
  }

  onDeletedFromOverlay() {
    this.yOffset = 0;
    this._contentSet = undefined;
  }
}
