import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import {
  authFeatureKey,
  authReducer,
  AuthState,
} from 'src/app/auth/ngrx/auth.reducer';
import {
  dashboardFeatureKey,
  dashboardReducer,
} from 'src/app/dashboard/ngrx/dashboard.reducer';
import {
  menusFeatureKey,
  menusReducer,
  MenusState,
} from 'src/app/menus/ngrx/menus.store';
import { eventsMap } from 'src/app/reducers/tagmanager';
import { Dashboard } from 'src/app/shared/Models/dashboard';
import {
  dishesMenuFeatureKey,
  dishesMenuReducer,
  DishesMenuState,
} from 'src/app/shared/ngrx/dishes-menu/dishes-menu.reducer';
import {
  sharedFeatureKey,
  sharedReducer,
  SharedState,
} from 'src/app/shared/ngrx/shared.reducer';
import {
  suppliersFeatureKey,
  suppliersReducer,
  SuppliersState,
} from 'src/app/shared/ngrx/suppliers/suppliers.reducer';
import {
  userFeatureKey,
  userReducer,
  UserState,
} from 'src/app/shared/user/ngrx/user.reducer';
import { createMetaReducer } from 'redux-beacon';

import { environment } from '../../environments/environment';
import { routerFeatureKey } from 'src/app/shared/router/router.selectors';

export function getMetaReducers(): MetaReducer<State>[] {
  const gtmMetaReducer = createMetaReducer(eventsMap, GoogleTagManager());
  return [gtmMetaReducer];
}

export interface State {
  [authFeatureKey]: AuthState;
  [dashboardFeatureKey]: Dashboard;
  [dishesMenuFeatureKey]: DishesMenuState;
  [menusFeatureKey]: MenusState;
  [routerFeatureKey]: RouterReducerState<any>;
  [sharedFeatureKey]: SharedState;
  [suppliersFeatureKey]: SuppliersState;
  [userFeatureKey]: UserState;
}

export const reducers: ActionReducerMap<State> = {
  [authFeatureKey]: authReducer,
  [dashboardFeatureKey]: dashboardReducer,
  [dishesMenuFeatureKey]: dishesMenuReducer,
  [menusFeatureKey]: menusReducer,
  [routerFeatureKey]: routerReducer,
  [sharedFeatureKey]: sharedReducer,
  [suppliersFeatureKey]: suppliersReducer,
  [userFeatureKey]: userReducer,
};

export const metaReducers: MetaReducer<State>[] = environment.production
  ? getMetaReducers()
  : [];
