import { Action, createReducer, on } from '@ngrx/store';
import { Style, StyleCategories } from 'src/app/shared/Models/menu';
import { Separator } from 'src/app/shared/Models/separator';
import { Layout } from 'src/app/shared/Models/template';

import * as MenuActions from './menu-style.actions';

export const menuStyleFeatureKey = 'menu_style';

export interface MenuStyleState {
  categories: StyleCategories;
  event_layouts: Layout[];
  public_layouts: Layout[];
  show_tab: number;
  user_layouts: Layout[];
  styleCategories: StyleCategories;
  user_styles: Style[];
  public_styles: Style[];
  event_styles: Style[];
  show_style_tab: number;
  show_style_spinner: 'create' | 'select' | 'update';
  separator_options: Separator[];
  separator_courses: Separator[];
}

const initialState: MenuStyleState = {
  categories: [],
  event_layouts: [],
  public_layouts: [],
  show_tab: 0,
  user_layouts: [],
  styleCategories: [],
  user_styles: [],
  public_styles: [],
  event_styles: [],
  show_style_tab: null,
  show_style_spinner: null,
  separator_options: [],
  separator_courses: [],
};

const _menuStyleReducer = createReducer(
  initialState,
  on(MenuActions.setLayoutCategories, (state, { payload }) => {
    return {
      ...state,
      categories: payload,
    };
  }),
  on(MenuActions.setUserLayouts, (state, { payload }) => {
    return {
      ...state,
      user_layouts: payload,
    };
  }),
  on(MenuActions.setPublicLayouts, (state, { payload }) => {
    return {
      ...state,
      public_layouts: payload,
    };
  }),
  on(MenuActions.setSpecialLayouts, (state, { payload }) => {
    return {
      ...state,
      event_layouts: payload,
    };
  }),
  on(MenuActions.switchTab, (state, { payload }) => {
    return {
      ...state,
      show_tab: payload,
    };
  }),

  on(MenuActions.setStyleCategories, (state, { categories }) => {
    return {
      ...state,
      styleCategories: categories,
    };
  }),
  on(MenuActions.setUserStyles, (state, { styles }) => {
    return {
      ...state,
      user_styles: styles,
    };
  }),
  on(MenuActions.setPublicStyles, (state, { styles }) => {
    return {
      ...state,
      public_styles: styles,
    };
  }),
  on(MenuActions.setSpecialStyles, (state, { styles }) => {
    return {
      ...state,
      event_styles: styles,
    };
  }),
  on(MenuActions.moveStyleToFront, (state, { styleId, list }) => {
    const key = list + '_styles';
    if (!state[key]) {
      return state; // Return the current state if the key does not exist to avoid TypeError
    }
    const arr = [...state[key]]; // Clone the array to avoid mutating the state directly
    const index = arr.findIndex((item) => item.id === styleId);
    if (index > -1) {
      [arr[0], arr[index]] = [arr[index], arr[0]]; // Swap only if the styleId is found
      return {
        ...state,
        [key]: arr,
      };
    }
    return state; // Return the current state if the styleId is not found
  }),
  on(MenuActions.switchStyleTab, (state, { index }) => {
    return {
      ...state,
      show_style_tab: index,
    };
  }),
  on(MenuActions.showStyleSpinner, (state, { spinnerState }) => {
    return {
      ...state,
      show_style_spinner: spinnerState,
    };
  }),
  on(MenuActions.removeStyle, (state, { styleId }) => {
    const filteredStyles = state?.user_styles?.filter(
      ({ id }) => id !== styleId,
    );
    const filteredCategories = {};
    if (!filteredStyles.length)
      filteredCategories['styleCategories'] = state.styleCategories.filter(
        (cat) => cat !== 'user',
      );
    return {
      ...state,
      ...filteredCategories,
      user_styles: filteredStyles,
    };
  }),
  on(MenuActions.changeStyle, (state, { newStyle }) => {
    return {
      ...state,
      user_styles: state?.user_styles?.map((style) =>
        style.id === newStyle?.id ? newStyle : style,
      ),
    };
  }),
  on(MenuActions.addCreatedStyle, (state, { style }) => {
    const filteredCategories = {};
    if (!state.styleCategories.includes('user'))
      filteredCategories['styleCategories'] = [
        'user',
        ...state.styleCategories,
      ];
    return {
      ...state,
      ...filteredCategories,
      user_styles: [style, ...state.user_styles],
      show_style_spinner: null,
      show_style_tab: 1,
    };
  }),
  on(MenuActions.setOptions, (state, { separators }) => {
    return {
      ...state,
      separator_options: separators,
    };
  }),
  on(MenuActions.setCourses, (state, { separators }) => {
    return {
      ...state,
      separator_courses: separators,
    };
  }),
);

export function menuStyleReducer(state: MenuStyleState, action: Action) {
  return _menuStyleReducer(state, action);
}
