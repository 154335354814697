<h3 class="mat-h3">
  {{ "menus.overview.duplicate.dialog.title" | transloco }}
</h3>
<div class="duplicate-options">
  @if (menu.date_range_min || menu.date_range_max) {
    <h4>
      {{ "menus.overview.duplicate.dialog.filter_dates" | transloco }}
    </h4>
  }
  <div class="date-range">
    @if (menu.date_range_min) {
      <mat-form-field>
        <mat-label>{{
          "menus.overview.duplicate.dialog.date.date_from" | transloco
        }}</mat-label>
        <input
          matInput
          [ngModel]="copyWithOptions.date_from"
          (click)="pickerFrom.open()"
          [matDatepickerFilter]="dayFilter"
          [matDatepicker]="pickerFrom"
          (dateChange)="dateChange($event, 'date_from')"
        />
        @if (copyWithOptions.date_from) {
          <button
            stop-propagation
            matSuffix
            mat-icon-button
            (click)="copyWithOptions.date_from = null"
          >
            <mat-icon>clear</mat-icon>
          </button>
        }
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerFrom"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom [startAt]="startFromDate"></mat-datepicker>
      </mat-form-field>
    }
    @if (menu.date_range_max) {
      <mat-form-field>
        <mat-label>{{
          "menus.overview.duplicate.dialog.date.date_to" | transloco
        }}</mat-label>
        <input
          matInput
          [ngModel]="copyWithOptions.date_to"
          (click)="pickerTo.open()"
          [matDatepickerFilter]="dayFilter"
          [matDatepicker]="pickerTo"
          (dateChange)="dateChange($event, 'date_to')"
        />
        @if (copyWithOptions.date_to) {
          <button
            stop-propagation
            matSuffix
            mat-icon-button
            (click)="copyWithOptions.date_to = null"
          >
            <mat-icon>clear</mat-icon>
          </button>
        }
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerTo"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerTo [startAt]="startToDate"></mat-datepicker>
      </mat-form-field>
    }
  </div>

  @if (hasModuleAllergens) {
    <h4>
      {{ "menus.overview.duplicate.dialog.filter_allergens" | transloco }}
    </h4>
    <div class="declarations">
      <div class="all-image">
        <span class="allergen-symbol blue symbol">{{ getSymbols() }}</span>
      </div>
      <mat-form-field class="highlights">
        <mat-select
          [placeholder]="
            'menus.linked_templates.highlights.placeholder' | transloco
          "
          [formControl]="highlights"
          multiple
          [attr.title]="getHighlighted()"
        >
          <mat-select-trigger> {{ getHighlighted() }} </mat-select-trigger>
          @for (allergen of allergens; track allergen) {
            <mat-option class="allergen" [value]="allergen.code">
              <span
                transloco="menus.linked_templates.highlights.without"
                [translocoParams]="{ name: allergen[lang] }"
              ></span>
              @if (isAllergenSelected(allergen.code)) {
                <mat-icon class="allergen-checked">checked</mat-icon>
              }
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <mat-checkbox
      color="primary"
      class="duplicate-option-item"
      [(ngModel)]="copyWithOptions.replace_with_empty"
    >
      {{
        "menus.overview.duplicate.dialog.options.replace_with_empty" | transloco
      }}
    </mat-checkbox>
  }
</div>

<div class="actions">
  <button mat-button class="cancel" (click)="cancelAction.emit()">
    {{ "menus.overview.duplicate.dialog.actions.cancel" | transloco }}
  </button>
  <button
    mat-flat-button
    color="primary"
    class="done"
    (click)="triggerCopyWithOptions()"
  >
    {{ "menus.overview.duplicate.dialog.actions.confirm" | transloco }}
  </button>
</div>
