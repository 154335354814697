export const unitsUs = [
  'oz',
  'lb',
  'us_cup',
  'us_oz',
  'us_gal',
  'us_pint',
  'us_qt',
  'us_tbsp',
  'us_tsp',
];

export const unitsImperial = [
  'imperial_cup',
  'imperial_oz',
  'imperial_pint',
  'imperial_qt',
  'imperial_tbsp',
  'imperial_tsp',
];

export const unitsMetric = [
  'g',
  'kg',
  'l',
  'dl',
  'cl',
  'ml',
  'imperial_tbsp',
  'imperial_tsp',
];

export const unitsAll = [...unitsUs, ...unitsImperial, ...unitsMetric] as const;
