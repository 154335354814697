import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export enum SharedEventTypes {
  TriggerCreateMenuButton,
}

export interface SharedEvent {
  type: SharedEventTypes;
  value?: any;
}

@Injectable({
  providedIn: 'root',
})
export class EventBusService {
  private subject = new Subject<SharedEvent>();

  constructor() {}

  emit(event: SharedEvent): void {
    this.subject.next(event);
  }

  on(eventType: SharedEventTypes, action: (value?: any) => void): void {
    this.subject
      .pipe(
        filter((event: SharedEvent) => event.type === eventType),
        map((event: SharedEvent) => event.value),
      )
      .subscribe(action);
  }
}
