import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { Menu, MenuBackup, MenuPreviewData } from 'src/app/shared/Models/menu';
import { User } from 'src/app/shared/Models/user';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BackupMenuComponent } from './backup-menu/backup-menu.component';
import { ContentBlockComponent } from '../../../../shared/Components/content-block/content-block.component';

@Component({
  selector: 'backup-menus',
  templateUrl: './backup-menus.component.html',
  styleUrls: ['./backup-menus.component.scss'],
  standalone: true,
  imports: [
    ContentBlockComponent,
    BackupMenuComponent,
    MatTooltipModule,
    MatButtonModule,
    TranslocoPipe,
  ],
})
export class BackupMenusComponent implements OnInit, OnChanges {
  @Input() limit: number;
  @Input() lang: ContentLanguage;
  @Input() menu: Menu;
  @Input() user: User;

  @Output() create = new EventEmitter<void>();
  @Output() delete = new EventEmitter<MenuBackup>();
  @Output() preview = new EventEmitter<MenuPreviewData>();
  @Output() restore = new EventEmitter<MenuBackup>();

  disabled: boolean;
  loading: boolean;

  constructor() {}

  ngOnInit(): void {
    this.setDisabled();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menu) this.loading = false;
    this.setDisabled();
  }

  setDisabled(): void {
    this.disabled =
      this.limit &&
      this.menu?.backups &&
      this.limit === this.menu?.backups.length;
  }

  saveNewVersion(): void {
    this.loading = true;
    this.create.emit();
  }
}
