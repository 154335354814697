<mat-dialog-content class="info-wrapper">
  <header class="header" [ngClass]="textClasses">
    {{ "shared.dialogs.bounced-email.header" | transloco }}
  </header>
  <div class="email" [ngClass]="emailClasses">{{ data?.email }}</div>
  <div class="description" [ngClass]="textClasses">
    {{ "shared.dialogs.bounced-email.description" | transloco }}
  </div>
</mat-dialog-content>
<mat-dialog-actions class="actions">
  <button [mat-dialog-close]="true" mat-flat-button color="primary">
    {{ "shared.dialogs.bounced-email.update-btn" | transloco }}
    <mat-icon>arrow_forward</mat-icon>
  </button>
  <button mat-dialog-close mat-flat-button>
    {{ "shared.dialogs.bounced-email.correct-btn" | transloco
    }}<mat-icon>arrow_forward</mat-icon>
  </button>
</mat-dialog-actions>
