import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { Menu } from 'src/app/shared/Models/menu';
import { Layout } from 'src/app/shared/Models/template';
import { SelectLayoutComponent } from '../select-layout/select-layout.component';

@Component({
  selector: 'layouts-wrapper',
  templateUrl: './layouts-wrapper.component.html',
  styleUrls: ['./layouts-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SelectLayoutComponent],
})
export class LayoutsWrapperComponent {
  @Input() canModify: boolean;
  @Input() layouts: Layout[];
  @Input() menu: Menu;
  @Input() lang: InterfaceLanguage;

  @Output() selectLayout = new EventEmitter<number>();
  @Output() sendRequest = new EventEmitter();
}
