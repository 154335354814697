import { createSelector } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { dashboardFeatureKey } from './dashboard.reducer';

const selectState = (state: State) => state[dashboardFeatureKey];

export const selectLatestTemplates = createSelector(
  selectState,
  (state) => state?.latest_templates,
);

export const selectMenusCreated = createSelector(
  selectState,
  (state) => state?.menus_created,
);

export const selectIsDashboardDataLoading = createSelector(
  selectState,
  (state) => state?.is_loading,
);

export const selectLastMenu = createSelector(
  selectState,
  (state) => state?.last_menu,
);

export const selectMostPopularDish = createSelector(
  selectState,
  (state) => state?.most_popular_dish,
);

export const selectMenusByDate = createSelector(
  selectState,
  (state) => state?.menus_by_date,
);

export const selectDishesCurrentMenuLoading = createSelector(
  selectState,
  (state) => state?.dishes.is_loading,
);

export const selectDishesCurrentMenu = createSelector(
  selectState,
  (state) => state?.dishes.list,
);

export const selectCourseLoading = createSelector(
  selectState,
  (state) => state?.courses.is_loading,
);

export const selectCourses = createSelector(
  selectState,
  (state) => state?.courses.results,
);

export const selectUserOnboardingTemplates = createSelector(
  selectState,
  (state) => state.user_onboarding_templates,
);
