export class LoginForm {
  email: string;
  password: string;
  constructor(email: string = null, password: string = null) {
    this.email = email;
    this.password = password;
  }
}

export class RegistrationForm {
  first_name: string;
  last_name: string;
  password: string;
  confirm_password: string;
  email: string;
  terms: boolean;
  language?: string;
  affiliate?: string;
}

export class AcceptInvitationForm {
  email: string;
  first_name: string;
  last_name: string;
  password1: string;
  password2: string;
  terms: boolean;
  language?: string;
}

export interface NonFieldError {
  form_id: string;
  message: string;
}

export type NonFieldErrors = NonFieldError[];

export interface LoginSuccess {
  access_token: string;
  refresh_token: string;
  detail?: string;
}

export interface EmailVerification {
  catch_all: boolean | null;
  did_you_mean: string;
  disposable: boolean;
  email: string;
  format_valid: boolean;
  free: boolean;
  id: number;
  last_lookup: string;
  mx_found: boolean;
  role: boolean;
  score: number;
  smtp_check: boolean;
}

export interface GeneralFormErrors {
  email?: string;
  first_name?: string;
  last_name?: string;
  password1?: string;
  password2?: string;
  new_password1?: string;
  new_password2?: string;
  non_field_errors?: NonFieldErrors;
}

export const emailVerificationErrorFields = ['disposable'];
export const emailRegexPattern = new RegExp(
  '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$',
);
