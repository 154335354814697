import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { OverlayService } from 'src/app/shared/Services/overlay-service/overlay.service';

@Component({
  selector: 'top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIconModule],
})
export class TopNavigationComponent implements OnInit {
  @Input() title: string;
  @Output() toggle = new EventEmitter<void>();

  @ViewChild(`container`, { read: ViewContainerRef, static: true })
  container: ViewContainerRef;

  constructor(private overlayService: OverlayService) {}

  ngOnInit(): void {
    this.overlayService.registerContainer(`change-menu-name`, this.container);
    this.overlayService.registerContainer(`view-as-location`, this.container);
    this.overlayService.registerContainer(
      `create-button-container`,
      this.container,
    );
  }
}
