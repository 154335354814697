import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Menu } from 'src/app/shared/Models/menu';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { KeysPipe } from '../../../../../shared/Pipes/keys.pipe';
import { MenuViewSettingsItemComponent } from './menu-view-settings-item/menu-view-settings-item.component';

@Component({
  selector: 'menu-view-settings',
  template: `
    @for (item of localSettings | keys; track item) {
      <p>
        <menu-view-settings-item
          class="view-settings-item"
          [data]="{
            label: item.key,
            value: item.value,
            disabled: !menu?.id,
          }"
          (changed)="changeHandler($event)"
        ></menu-view-settings-item>
      </p>
    }
  `,
  styleUrls: ['./menu-view-settings.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MenuViewSettingsItemComponent, KeysPipe],
})
export class MenuViewSettingsComponent implements OnChanges, OnDestroy {
  @Input() menu: Menu;
  @Output() changed = new EventEmitter<Partial<Menu>>();

  beforeChanged = new EventEmitter<Partial<Menu>>();
  private destroyed$ = new Subject<void>();
  fields = [
    'add_beverages',
    'add_courses',
    'add_days',
    'add_dishes',
    'add_sections',
    'add_wines',
    'add_ai',
  ];
  localSettings: Partial<Menu> = {};

  constructor() {
    this.beforeChanged
      .pipe(debounceTime(500), takeUntil(this.destroyed$))
      .subscribe((v) => {
        this.changed.emit(v);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.menu) {
      this.localSettings = {};
      const settings = {};
      this.fields.forEach(
        (field: string) => (settings[field] = this.menu[field]),
      );
      this.localSettings = settings;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  changeHandler(value) {
    this.localSettings = { ...this.localSettings, ...value };
    this.beforeChanged.emit(this.localSettings);
  }
}
