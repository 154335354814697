import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash-es';

@Pipe({
  name: 'copyDeep',
  standalone: true,
})
export class CopyDeepPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value ? _.cloneDeep(value) : value;
  }
}
