import { Component, EventEmitter, Input, Output } from '@angular/core';
import { debounceTime } from '../../../../../node_modules/rxjs/operators';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ColorPickerModule } from 'ngx-color-picker';
import { MtTooltipDirective } from '../../Directives/mt-tooltip/mt-tooltip.directive';

@Component({
  selector: 'mt-color-picker',
  templateUrl: './mt-color-picker.component.html',
  styleUrls: ['./mt-color-picker.component.css'],
  standalone: true,
  imports: [
    MtTooltipDirective,
    ColorPickerModule,
    MatButtonModule,
    MatIconModule,
    TranslocoPipe,
  ],
})
export class MtColorPickerComponent {
  @Input() className: string;
  @Input() color: string;
  @Input() disabled: boolean;
  @Input() isLocked: boolean;
  @Input() showClear: boolean;

  @Output() colorChange = new EventEmitter<string>();
  @Output() update = new EventEmitter<any>();
  @Output() clearColor = new EventEmitter();

  oldColor: string;
  colorChangeEvent = new EventEmitter<string>();

  constructor() {
    this.colorChangeEvent
      .pipe(debounceTime(300))
      .subscribe((color) => this.changed(color));
  }

  changed(value) {
    if (this.disabled) return undefined;
    this.color = value || this.color;
    this.colorChange.emit(this.color);
    this.update.emit();
  }

  change(color) {
    this.colorChangeEvent.emit(color);
  }
}
