<h3 class="block-title cell-info">
  {{ "style.choose-template.title" | transloco }}
  <mat-icon
    class="info-icon"
    [mtTooltip]="'style.choose-template.tooltip' | transloco"
    >info</mat-icon
  >
</h3>

<mat-card class="templates-wrapper">
  @if (menu?.onboarding_template === null) {
    <button mat-button class="primary-lightest" (click)="createTemplate()">
      {{ "style.choose-template.create-template" | transloco }}
    </button>
    <span class="link-menu" [matMenuTriggerFor]="templates">{{
      "style.choose-template.link-menu" | transloco
    }}</span>
    <mat-menu #templates="matMenu" [overlapTrigger]="false">
      @for (group of onboardingTemplates | groupBy: "category"; track group) {
        <mat-optgroup [label]="'templates.headers.' + group.key | transloco">
          @for (template of group.value; track template) {
            <mat-option
              (click)="linkMenuToTemplate.emit(template.id)"
              [value]="template.id"
            >
              {{ template[lang] }}
            </mat-option>
          }
        </mat-optgroup>
      }
    </mat-menu>
  } @else {
    <div class="item">
      <div class="input-wrapper icon-button-density-3">
        <mat-form-field
          class="name-field field-density-5"
          (click)="$event.stopPropagation()"
        >
          <input #inputField type="text" matInput [formControl]="nameControl" />
        </mat-form-field>
        @if (menu?.onboarding_template_detail?.can_delete) {
          <button mat-icon-button (click)="openEditTemplateDialog()">
            <mat-icon>edit</mat-icon>
          </button>
        }
        @if (menu?.onboarding_template_detail?.can_delete) {
          <button mat-icon-button [matMenuTriggerFor]="deleteMenu">
            <mat-icon>delete</mat-icon>
          </button>
        }
      </div>
      <div
        class="img-wrapper"
        [style.background-image]="
          'url(\'' +
          (menu?.onboarding_template_detail?.image ||
            '/assets/img/Empty menu.jpg') +
          '\')'
        "
      ></div>
      <div
        [mtTooltip]="
          menu?.onboarding_template_detail?.can_delete
            ? ('style.choose-template.update-tooltip' | transloco)
            : ('style.choose-template.update-tooltip-disallowed' | transloco)
        "
      >
        <button
          mat-button
          class="primary-lightest update-button"
          [disabled]="!menu?.onboarding_template_detail?.can_delete"
          (click)="updateStyleAndLayout.emit()"
        >
          {{ "style.choose-template.update-style-layout" | transloco }}
        </button>
      </div>
    </div>
    <div class="empty-item">
      <span class="unlink-text">
        {{ "style.choose-template.unlink-text-create" | transloco }}
        <span class="title-unlink" (click)="unlink.emit()">{{
          "style.choose-template.unlink" | transloco
        }}</span>
        {{ "style.choose-template.unlink-text-current" | transloco }}
      </span>
    </div>
    <mat-menu #deleteMenu class="delete-style-menu">
      <span class="delete-style-menu-label" mat-menu-item>
        {{ "style.choose-template.are-you-sure" | transloco }}</span
      >
      <button
        class="delete-style-menu-button"
        mat-menu-item
        (click)="
          deleteTemplate.emit({
            url: menu?.onboarding_template_detail?.url,
            id: menu?.onboarding_template,
          })
        "
      >
        <mat-icon>delete</mat-icon>
        {{ "style.choose-template.delete" | transloco }}
      </button>
    </mat-menu>
  }
</mat-card>
