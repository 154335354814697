import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { ContentLanguage, rtlLangs } from 'src/app/shared/constants/languages';
import { Dish, Spellcheck } from 'src/app/shared/Models/dish';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { BaseNameExtended } from 'src/app/shared/Models/models';
import { Separator } from 'src/app/shared/Models/separator';
import { User } from 'src/app/shared/Models/user';
import { showSnackbarMessage } from 'src/app/shared/ngrx/shared.actions';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';

import { addWordToUserDictionary } from '../../write/ngrx/menu-write.actions';
import { SidePanelPlaceholderComponent } from '../../../../shared/Components/sidepanel/side-panel-placeholder/side-panel-placeholder.component';
import { SpinnerComponent } from '../../../../shared/Components/spinner/spinner.component';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { CopyDataLineComponent } from '../../../../shared/Components/copy-data-line/copy-data-line.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { GrammarCorrectionsComponent } from '../../../../shared/Components/grammar-corrections/grammar-corrections.component';
import { ReactiveFormsModule, FormsModule, FormControl } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SidePanelHeaderComponent } from '../../../../shared/Components/sidepanel/side-panel-header/side-panel-header.component';
import { GoBackComponent } from '../../../../shared/Components/sidepanel/go-back/go-back.component';

@Component({
  selector: 'app-translate-sidebar',
  templateUrl: './translate-sidebar.component.html',
  styleUrls: ['./translate-sidebar.component.scss'],
  standalone: true,
  imports: [
    GoBackComponent,
    SidePanelHeaderComponent,
    MatSlideToggleModule,
    ReactiveFormsModule,
    FormsModule,
    GrammarCorrectionsComponent,
    MatButtonModule,
    MatIconModule,
    CopyDataLineComponent,
    TranslocoDirective,
    SpinnerComponent,
    SidePanelPlaceholderComponent,
    TranslocoPipe,
  ],
})
export class TranslateSidebarComponent implements OnChanges {
  @Input() baseLanguage: ContentLanguage;
  @Input() currentMenuDish: MenuDish;
  @Input() description: string;
  @Input() dishes: BaseNameExtended[];
  @Input() dishTitle: string;
  @Input() isDescription = false;
  @Input() isOther = false;
  @Input() isLoading: boolean;
  @Input() isSecondary = false;
  @Input() lang: ContentLanguage;
  @Input() numberOfItems: number;
  @Input() spellcheckItem: Spellcheck;
  @Input() tags: { value: string; selected: boolean }[] = [];
  @Input() user: User;

  @Output() ignoreSpellcheck = new EventEmitter<void>();
  @Output() changeItem = new EventEmitter<boolean>();
  @Output() fetchMoreItems = new EventEmitter<void>();
  @Output() similar = new EventEmitter<BaseNameExtended>();
  @Output() openTranslations = new EventEmitter<MenuDish>();
  @Output() updateSimilar = new EventEmitter<MenuDish>();
  @Output() clearSelectedDish = new EventEmitter<void>();
  @Output() fetchSpellcheck = new EventEmitter<number>();

  details: Separator | Dish;
  isSelectedAll = true;
  rest: number;
  rtl = false;
  showGrammar: boolean;
  showMore = false;
  showPlaceholder = false;
  translationDisabledControl = new FormControl(false);

  constructor(
    private ngrxStore: Store<State>,
    private utils: UtilsService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('lang' in changes && this.lang) {
      this.rtl = rtlLangs.includes(this.lang);
    }
    if (
      ('numberOfItems' in changes && this.numberOfItems) ||
      ('dishes' in changes && this.dishes)
    ) {
      this.rest = this.numberOfItems - (this.dishes?.length ?? 0);
    }
    if ('currentMenuDish' in changes && this.currentMenuDish) {
      this.details =
        this.currentMenuDish.dish_detail ||
        this.currentMenuDish.separator_detail;

      this.updateTranslationControl();

      const spellcheckId = this.details?.[`spellcheck_` + this.lang];
      this.showGrammar = !!spellcheckId;
      if (this.showGrammar) this.fetchSpellcheck.emit(spellcheckId);
    }
  }

  private updateTranslationControl(): void {
    let value = false;
    if (this.isDescription && this.isOther && this.isSecondary) {
      value =
        this.currentMenuDish?.user_details
          .translation_disabled_description_secondary;
    } else if (this.isDescription && this.isSecondary) {
      value =
        this.details?.user_details[
          'translation_disabled_description_secondary'
        ];
    } else if (this.isDescription && this.isOther) {
      value =
        this.currentMenuDish?.user_details.translation_disabled_description;
    } else if (this.details && this.isDescription) {
      value = this.details?.user_details.translation_disabled_description;
    } else if (this.details) {
      value = this.details?.user_details.translation_disabled;
    }
    this.translationDisabledControl.setValue(value, { emitEvent: false });

    // Subscribe to value changes
    this.translationDisabledControl.valueChanges.subscribe((checked) => {
      this.changeItem.emit(checked);
    });
  }

  addWord(word: string): void {
    if (!this.currentMenuDish) return;
    const onFulfilled = (md: MenuDish) => {
      this.utils.getTranslation(
        'menus.shared.grammar-added',
        (message: string) => {
          this.ngrxStore.dispatch(showSnackbarMessage({ message }));
        },
      );
    };
    const text = (this.currentMenuDish.dish_detail ||
      this.currentMenuDish.separator_detail)[this.lang];
    this.ngrxStore.dispatch(
      addWordToUserDictionary({
        word,
        text,
        lang: this.lang,
        menuDish: this.currentMenuDish,
        onFulfilled,
      }),
    );
  }

  getTitle = (): string =>
    this.tags
      .filter((t) => t.selected)
      .map((t) => t.value)
      .join(' ');

  chooseSimilar(item: BaseNameExtended): void {
    this.similar.emit(item);
  }

  getTags = (): { value: string; selected: boolean }[] =>
    this.showMore ? this.tags : this.tags.slice(0, 15);

  changeTag(tag: { value: string; selected: boolean }): void {
    const deselected = this.tags.filter((v) => !v.selected);
    if (deselected.length === 0) {
      this.tags.forEach((t) => (t.selected = false));
    }
    tag.selected = !tag.selected;

    if (!tag.selected || deselected.length === 0) {
      this.isSelectedAll = false;
    }
    if (deselected.length === this.tags.length - 1 && !tag.selected) {
      this.showPlaceholder = true;
    } else {
      this.showPlaceholder = false;
      this.updateSimilar.emit(this.currentMenuDish);
    }
  }

  selectAll(): void {
    if (this.isSelectedAll) {
      this.tags.forEach((t) => (t.selected = false));
      this.showPlaceholder = true;
      this.isSelectedAll = false;
    } else {
      this.isSelectedAll = true;
      this.showPlaceholder = false;
      this.tags.forEach((t) => (t.selected = true));
    }
    this.updateSimilar.emit(this.currentMenuDish);
  }
}
