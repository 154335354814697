import { createAction, props } from '@ngrx/store';
import { OnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import { TemplateParams } from 'src/app/shared/Models/template';

export const fetchTemplates = createAction(
  `[Templates container] Fetch templates`,
  props<{ params: Partial<TemplateParams> }>(),
);

export const deleteTemplate = createAction(
  `[Templates container] Delete template`,
  props<{ templateId: number }>(),
);

export const updateTemplate = createAction(
  `[Templates container] Update template`,
  props<{ template: Partial<OnboardingTemplate>; url?: string }>(),
);

export const setUpdatedTemplate = createAction(
  `[Templates container] Set updated template locally`,
  props<{ template: OnboardingTemplate }>(),
);

export const removeDeletedTemplate = createAction(
  `[Templates container] Remove deleted template locally`,
  props<{ templateId: number }>(),
);

export const setTemplates = createAction(
  `[Templates container] Set templates locally`,
  props<{ payload: OnboardingTemplate[] }>(),
);

export const restrictTemplates = createAction(
  `[Templates container] Restrict templates`,
  props<{ templates: OnboardingTemplate[] }>(),
);

export const setTemplatesDataLoading = createAction(
  `[Templates container] Set templates data loading`,
  props<{ loading: boolean }>(),
);
