import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { OverlayService } from 'src/app/shared/Services/overlay-service/overlay.service';

import { SimpleLocation } from './../../Models/location';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'view-as-location',
  templateUrl: './view-as-location.component.html',
  styleUrls: ['./view-as-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    TranslocoPipe,
  ],
})
export class ViewAsLocationComponent implements OnDestroy, OnInit {
  @Input() locations: SimpleLocation[];
  @Output() locationChanged = new EventEmitter<MatSelectChange>();

  @ViewChild('selectLocation', { static: true })
  selectLocationRef: TemplateRef<any>;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private overlayService: OverlayService,
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.overlayService.insertTemplate(
        `view-as-location`,
        this.selectLocationRef,
      );
      this.changeDetector.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.locationChanged.emit({ source: null, value: null });
    this.overlayService.clear(`view-as-location`);
  }
}
