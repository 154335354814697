<h2 mat-dialog-title class="header">
  <span class="title">{{
    "write.import-export.dialog-title" | transloco
  }}</span>
</h2>

<mat-dialog-content>
  <p>{{ "write.import-export.dialog-description" | transloco }}</p>
  <file-upload class="file-upload" [control]="fileUploadControl"> </file-upload>
</mat-dialog-content>

<mat-dialog-actions class="actions">
  <button mat-button mat-dialog-close>
    {{ "shared.buttons.cancel" | transloco }}
  </button>
  <button
    mat-button
    color="primary"
    [disabled]="!fileUploadControl.valid || !fileUploadControl.value?.length"
    (click)="dispatchFile()"
  >
    {{ "menus.create.upload.title" | transloco }}
  </button>
</mat-dialog-actions>
