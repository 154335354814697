export const displayOptions = [
  {
    value: null,
    text: 'all',
  },
  {
    value: 1,
    text: 'hidden',
  },
  {
    value: 2,
    text: 'pdf',
  },
  {
    value: 3,
    text: 'html',
  },
  {
    value: 7,
    text: 'hide-name',
  },
];

export const displayOptionsComplete = [
  ...displayOptions,
  {
    value: 4,
    text: 'orders',
  },
  {
    value: 5,
    text: 'orders-admin',
  },
  {
    value: 6,
    text: 'orders-description',
  },
];

export const ordertakingOptions = [
  {
    value: null,
    text: 'none',
  },
  {
    value: 1,
    text: 'disable',
  },
  {
    value: 2,
    text: 'default',
  },
  {
    value: 3,
    text: 'vip',
  },
];
