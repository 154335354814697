import { createSelector } from '@ngrx/store';
import { State } from 'src/app/reducers';

const selectState = (state: State) => state?.menus?.menus_overview;

export const selectMenus = createSelector(selectState, (state) => state?.menus);

export const selectIsTableDataLoading = createSelector(
  selectState,
  (state) => state?.loading,
);
