@if (error) {
  <p
    class="auth-title"
    [innerHTML]="
      user?.first_name
        ? ('auth.registration.reactivation.title_name'
          | transloco: { name: user?.first_name })
        : ('auth.registration.reactivation.title'
          | transloco: { name: user?.first_name })
    "
  ></p>
  <p class="description">
    {{
      ((login$ | async)
        ? "auth.registration.reactivation.error.logged-in.description"
        : "auth.registration.reactivation.error.logged-out.description"
      ) | transloco
    }}
  </p>
  <mat-icon color="primary" [inline]="true" class="link-icon"
    >link_off</mat-icon
  >
  <p
    class="contact"
    [innerHTML]="
      ((login$ | async)
        ? 'auth.registration.reactivation.error.logged-in.contact'
        : 'auth.registration.reactivation.error.logged-out.contact'
      ) | transloco
    "
  ></p>
} @else {
  <p
    class="auth-title"
    [innerHTML]="
      'auth.registration.reactivation.title'
        | transloco: { name: user?.first_name }
    "
  ></p>
  <p class="description">
    {{ "auth.registration.reactivation.success.description" | transloco }}
  </p>
  @if (!done) {
    <div class="spinner-wrapper">
      <mat-progress-spinner
        mode="determinate"
        [diameter]="115"
        [value]="values$ | async"
        [strokeWidth]="4"
      >
      </mat-progress-spinner>
      <div class="spinner-value">{{ values$ | async }}%</div>
    </div>
  } @else {
    <img
      class="success-image"
      src="../../../../assets/img/confirm/confirmed.gif"
      alt="success"
    />
  }
  <p
    class="contact"
    [innerHTML]="'auth.registration.reactivation.success.contact' | transloco"
  ></p>
}
