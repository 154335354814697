import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  Invitation,
  InvitationsParams,
} from 'src/app/shared/Models/invitation';

import * as InvitationsActions from './invitations.actions';

export const invitationsFeatureKey = 'invitations';

export const invitationsAdapter: EntityAdapter<Invitation> =
  createEntityAdapter<Invitation>();

export interface InvitationsState {
  invitations: EntityState<Invitation>;
  currentPageIndex: number;
  count: number;
  queryParams: InvitationsParams;
  sentInvitationId: number;
  showSpinner: boolean;
}

const initialState: InvitationsState = {
  invitations: invitationsAdapter.getInitialState(),
  queryParams: {},
  currentPageIndex: 0,
  count: 0,
  sentInvitationId: null,
  showSpinner: false,
};

const _invitationsReducer = createReducer(
  initialState,
  on(InvitationsActions.setInvitations, (state, { invitations }) => ({
    ...state,
    invitations: invitationsAdapter.setAll(invitations, state.invitations),
    showSpinner: false,
  })),
  on(InvitationsActions.setCurrentPageIndex, (state, { index }) => ({
    ...state,
    currentPageIndex: index,
  })),
  on(InvitationsActions.setInvitationsCount, (state, { count }) => ({
    ...state,
    count,
  })),
  on(InvitationsActions.paramsChanged, (state, { params }) => ({
    ...state,
    queryParams: params,
    showSpinner: true,
  })),
  on(InvitationsActions.updateInvitation, (state, { invitation }) => ({
    ...state,
    invitations: invitationsAdapter.updateOne(
      { id: invitation.id, changes: invitation },
      state.invitations,
    ),
  })),
  on(InvitationsActions.invitationSent, (state, { id }) => ({
    ...state,
    sentInvitationId: id,
    showSpinner: true,
  })),
  on(InvitationsActions.invitationDeleted, (state) => ({
    ...state,
    showSpinner: true,
  })),
  on(InvitationsActions.invitationError, (state) => ({
    ...state,
    showSpinner: false,
  })),
);

export function invitationsReducer(state: InvitationsState, action: Action) {
  return _invitationsReducer(state, action);
}
