<form [formGroup]="otherTabForm">
  <p>
    {{ "write.blocks.dish-overview.other-tab.information.title" | transloco }}
  </p>
  <div class="price-wrapper" [class.hidden]="dish?.separator || showVariants">
    <div class="simple-price">
      <mat-form-field class="price-input">
        <span matPrefix>{{ currencySymbol }}&nbsp;</span>
        <mat-label>{{
          "write.blocks.dish-overview.information.prices.variant-price.price.placeholder"
            | transloco
        }}</mat-label>
        <input
          matInput
          autocomplete="off"
          class="price"
          formControlName="price"
          name="price"
          max="999999"
          maxlength="6"
          min="0"
          pattern="[0-9]*(\.|,){0,1}[0-9]+"
          (keydown.enter)="blurInput($event.target)"
        />
      </mat-form-field>
      @if (!(dish?.separator || showVariants)) {
        <button
          mat-button
          class="add-variant-button blue"
          (click)="addVariant()"
        >
          <mat-icon class="plus-icon">add_circle</mat-icon>
          {{
            "write.blocks.dish-overview.information.prices.actions.add-variant"
              | transloco
          }}
        </button>
      }
    </div>
    @if (loadingBars.price) {
      <mat-progress-bar
        class="price-progress-bar"
        mode="indeterminate"
      ></mat-progress-bar>
    }
  </div>
  @if (dish?.separator || showVariants) {
    <app-variants
      [currencySymbol]="currencySymbol"
      [initialValue]="initialVariantValue"
      [item]="dish"
      [lang]="lang"
      (changed)="variantsChanged($event)"
      (cleared)="variantsCleared($event)"
    ></app-variants>
  }
  <mat-form-field class="bar-wrapper" [attr.dir]="rtl ? 'rtl' : 'ltr'">
    <mat-label>{{
      translations[
        "write.blocks.dish-overview.other-tab.information.description"
      ] ||
        ("write.blocks.dish-overview.other-tab.information.description"
          | transloco)
    }}</mat-label>
    <textarea
      matInput
      matTextareaAutosize
      matAutosizeMinRows="2"
      maxlength="2000"
      type="text"
      class="popover-textarea"
      [name]="'description'"
      [formControlName]="'description'"
    >
    </textarea>
    @if (loadingBars.description) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
  </mat-form-field>
  @if (
    dish?.separator_detail &&
    ["dow", "sec"].includes(dish?.separator_detail?.category)
  ) {
    <mat-form-field class="bar-wrapper" [attr.dir]="rtl ? 'rtl' : 'ltr'">
      <mat-label>{{
        translations[
          "write.blocks.dish-overview.information.description_secondary"
        ] ||
          ("write.blocks.dish-overview.information.description_secondary"
            | transloco)
      }}</mat-label>
      <textarea
        matInput
        matTextareaAutosize
        matAutosizeMinRows="2"
        maxlength="2000"
        type="text"
        class="popover-textarea"
        [name]="'description_secondary'"
        [formControlName]="'description_secondary'"
      >
      </textarea>
      @if (loadingBars.description_secondary) {
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      }
    </mat-form-field>
  }
  <mat-form-field class="bar-wrapper" [attr.dir]="rtl ? 'rtl' : 'ltr'">
    <mat-label>{{
      translations[
        "write.blocks.dish-overview.other-tab.information.wine-recommendation"
      ] ||
        ("write.blocks.dish-overview.other-tab.information.wine-recommendation"
          | transloco)
    }}</mat-label>
    <textarea
      matInput
      matTextareaAutosize
      matAutosizeMinRows="2"
      maxlength="2000"
      class="popover-textarea"
      type="text"
      name="wine"
      formControlName="wine"
    >
    </textarea>
    @if (loadingBars.wine) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
  </mat-form-field>
  <div
    [hidden]="
      !(dish?.separator_detail && dish?.separator_detail.category === 'dow')
    "
  >
    <div class="bar-wrapper select-wrapper">
      <date-field
        [dish]="dish"
        [field]="'date'"
        [form]="otherTabForm"
      ></date-field>
      @if (loadingBars.date) {
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      }
    </div>
  </div>
  <section class="info-piece image">
    <div class="bg-images">
      <!-- Temporary deprecated background -->
      @if (dish?.user_details?.image) {
        <p>
          {{
            "write.blocks.dish-overview.uploads.image.deprecated-field"
              | transloco
          }}:
        </p>
      }
      @if (dish?.user_details?.image) {
        <show-and-upload-image
          [label]="
            'write.blocks.dish-overview.uploads.image.deprecated-field'
              | transloco
          "
          [imageUrl]="dish?.user_details.image_small"
          (deleteImage)="
            patchFields({
              user_details: { background: null },
            })
          "
        ></show-and-upload-image>
      }
      @if (dish?.user_details?.image) {
        <p></p>
      }

      @if (backgroundImages?.length) {
        <p>
          {{ "write.blocks.dish-overview.uploads.image.choose" | transloco }}:
        </p>
      }
      <div class="bg-images-wrapper">
        @if (dish?.user_details?.background) {
          <select-image
            [imageUrl]="
              dish?.user_details?.background_detail?.background_image_small
            "
            [selected]="true"
            (imgSelected)="
              patchFields({
                user_details: { background: null, image: null },
              })
            "
          ></select-image>
        }
        @for (bgImage of backgroundImages; track bgImage; let last = $last) {
          @if (
            bgImage.id !== dish?.user_details?.background &&
            !(hideLastImage && last)
          ) {
            <select-image
              [imageUrl]="bgImage.background_image_small"
              (imgSelected)="selectImage(bgImage.id)"
            ></select-image>
          }
        }
      </div>
      @if (backgroundImages?.length) {
        <button
          class="show-library"
          mat-button
          color="primary"
          (click)="showAllBackgrounds.emit()"
        >
          {{ "shared.buttons.see-all" | transloco }}
        </button>
      }
    </div>
    <show-and-upload-image
      [label]="'write.blocks.dish-overview.uploads.image.add' | transloco"
      [showSpinner]="showImageSpinner"
      (fileChoosen)="backgroundUpdating = true; uploadImage($event)"
      (deleteImage)="clearBackgroundImage()"
    ></show-and-upload-image>
  </section>
  <div class="checkbox-wrapper">
    @if (dish?.separator_detail && dish?.user_details?.background) {
      <boolean-field
        [field]="'background_span_page'"
        [form]="otherTabForm"
      ></boolean-field>
    }
    @for (
      field of ["highlight", "spacer", "pagebreak", "empty_page"];
      track field
    ) {
      <boolean-field [field]="field" [form]="otherTabForm"></boolean-field>
    }
  </div>
  <hr />
  <div class="select-wrapper">
    <h4>
      {{
        "write.blocks.dish-overview.information.display_options.title"
          | transloco
      }}
    </h4>
    <p>
      {{
        "write.blocks.dish-overview.information.display_options.description"
          | transloco
      }}
    </p>
    <select-field
      [data]="
        selectOptions[
          hasModules('order') ? 'displayOptionsComplete' : 'displayOptions'
        ]
      "
      [dish]="dish"
      [field]="'display_options'"
      [form]="otherTabForm"
    ></select-field>
    @if (loadingBars.display_options) {
      <mat-progress-bar
        class="select-progress"
        mode="indeterminate"
      ></mat-progress-bar>
    }
  </div>
  @if (hasModules("order")) {
    <div class="select-wrapper">
      <h4>
        {{
          "write.blocks.dish-overview.information.ordertaking_options.title"
            | transloco
        }}
      </h4>
      <select-field
        [data]="selectOptions.ordertakingOptions"
        [dish]="dish"
        [field]="'ordertaking_options'"
        [form]="otherTabForm"
      ></select-field>
      @if (loadingBars.ordertaking_options) {
        <mat-progress-bar
          class="select-progress"
          mode="indeterminate"
        ></mat-progress-bar>
      }
    </div>
    <div class="price-wrapper">
      <mat-form-field class="min-quantity-input">
        <mat-label>{{
          "write.blocks.dish-overview.information.ordertaking_minimum"
            | transloco
        }}</mat-label>
        <input
          matInput
          type="number"
          autocomplete="off"
          class="price"
          formControlName="ordertaking_min_quantity"
          name="ordertaking_min_quantity"
          max="999999"
          maxlength="6"
          min="1"
          steps="1"
          (keydown.enter)="blurInput($event.target)"
        />
      </mat-form-field>
      @if (loadingBars.ordertaking_min_quantity) {
        <mat-progress-bar
          class="price-progress-bar"
          mode="indeterminate"
        ></mat-progress-bar>
      }
    </div>
  }
</form>
