import { Component, Input } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.css'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, TranslocoPipe],
})
export class SimpleDialogComponent {
  @Input() title: string;
  @Input() text: string;
  @Input() link: string;
  @Input() linkText: string;
  @Input() cancelable: boolean;
  @Input() confirmText = 'shared.buttons.confirm';
  @Input() cancelText = 'shared.buttons.cancel';
}
