import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  NavigationStart,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store, select } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { selectLoginState } from 'src/app/auth/ngrx/auth.selectors';
import { filter, take } from 'rxjs/operators';
import { of } from 'rxjs';

export const registrationStepsCanDeactivateGuard = (
  _component: any,
  _currentRoute: ActivatedRouteSnapshot,
  currState: RouterStateSnapshot,
  nextState: RouterStateSnapshot,
) => {
  let lastTrigger: 'imperative' | 'popstate' | 'hashchange';

  const router = inject(Router);
  router.events
    .pipe(filter((ev) => ev instanceof NavigationStart))
    .subscribe(
      (event: NavigationStart) => (lastTrigger = event?.navigationTrigger),
    );
  if (nextState.url === '/registration/1') return of(true);

  if (lastTrigger === 'popstate' && currState.url === '/registration/1') {
    router.navigate(['registration', '1']);
    return of(false); // prevent navigation
  }
  return of(true);
};

export const registrationStepsGuard = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
) => {
  const { step } = route.params;
  const router = inject(Router);
  const store = inject(Store) as Store<State>;
  return store.pipe(select(selectLoginState), take(1), (loggedIn) => {
    if (!loggedIn || (step && step > 3)) {
      router.navigate(['/']);
      return of(false); // prevent navigation
    }
    return of(true);
  });
};
