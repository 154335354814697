import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { TranslocoPipe } from '@jsverse/transloco';
import { MagicStickComponent } from '../magic-stick/magic-stick.component';

@Component({
  selector: 'express-translation',
  templateUrl: './express-translation.component.html',
  styleUrls: ['./express-translation.component.scss'],
  standalone: true,
  imports: [MagicStickComponent, TranslocoPipe],
})
export class ExpressTranslationComponent {
  @Input() loading = false;
  @Input() disabled = false;
  @Input() isTrial = false;
  @Input() profileComplete = false;

  @Output() expressTranslate = new EventEmitter();

  constructor(private utils: UtilsService) {}

  handleClick(): void {
    if (this.isTrial && !this.profileComplete) {
      this.utils.showTrialBlockedBox();
    } else {
      this.expressTranslate.emit();
    }
  }
}
