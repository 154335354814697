import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Supplier, SupplierItem } from 'src/app/shared/Models/procurements';

import * as SuppliersActions from './suppliers.actions';

export const suppliersFeatureKey = 'suppliers';

export const adapter: EntityAdapter<SupplierItem> =
  createEntityAdapter<SupplierItem>();

export interface SuppliersState {
  suppliers_autocomplete: Supplier[];
  supplier_items: EntityState<SupplierItem>;
}

export const initialState: SuppliersState = {
  suppliers_autocomplete: [],
  supplier_items: adapter.getInitialState(null),
};

const _suppliersReducer = createReducer(
  initialState,
  on(SuppliersActions.setSuppliersAutocomplete, (state, { payload }) => {
    return {
      ...state,
      suppliers_autocomplete: payload.results,
    };
  }),
  on(SuppliersActions.addEmptySupplierItem, (state) => {
    return {
      ...state,
      supplier_items: adapter.addOne(
        new SupplierItem(null, true),
        state.supplier_items,
      ),
    };
  }),
  on(SuppliersActions.changeSupplierItem, (state, { id, supplierItem }) => {
    return {
      ...state,
      supplier_items: adapter.updateOne(
        { id: id, changes: supplierItem },
        state?.supplier_items,
      ),
    };
  }),
  on(SuppliersActions.removeDeletedSupplierItem, (state, { supplierItem }) => {
    return {
      ...state,
      supplier_items: adapter.removeOne(
        supplierItem?.id,
        state?.supplier_items,
      ),
    };
  }),
  on(SuppliersActions.setSupplierItems, (state, { data }) => {
    return {
      ...state,
      supplier_items: adapter.setAll(data, state.supplier_items),
    };
  }),
);

export function suppliersReducer(state: SuppliersState, action: Action) {
  return _suppliersReducer(state, action);
}
