import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { SpinnerComponent } from '../../spinner/spinner.component';

// tslint:disable-next-line:ordered-imports
// tslint:disable-next-line:ordered-imports
@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styles: [
    `
      ul {
        margin-top: 0;
      }
      .buttons {
        display: block;
        text-align: end;
      }
      button {
        margin-left: 5px;
      }
      .spacer {
        width: 377px;
        height: 100px;
      }
      .content {
        padding: 5px 0;
      }
    `,
  ],
  standalone: true,
  imports: [
    MatDialogTitle,
    SpinnerComponent,
    MatDialogContent,
    MatDialogActions,
    MatButtonModule,
    AsyncPipe,
  ],
})
export class ConfirmDialogComponent implements OnInit, OnChanges {
  confirmed = false;
  loading;
  isList = false;
  errorMessage: string;

  @Input() title: string;
  @Input() content: string | string[];
  @Input() additionalContent: Observable<string>;
  @Input() confirmMessage: string;
  @Input() cancelMessage: string;
  @Input() warning: boolean;
  @Input() closed = true;
  @Input() errorEvent: EventEmitter<string>;
  @Output() dialogConfirmed = new EventEmitter<boolean>();

  close() {
    this.dialogRef.close();
    this.dialogConfirmed.emit(false);
  }

  confirm() {
    if (this.closed) {
      this.dialogRef.close(true);
      this.dialogConfirmed.emit(true);
    } else {
      this.dialogConfirmed.emit(true);
      this.confirmed = true;
      this.loading = true;
    }
  }

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {}

  ngOnInit() {
    if (this.additionalContent) {
      this.additionalContent.subscribe((el) => {
        this.loading = false;
      });
    }
    if (this.errorEvent) {
      this.errorEvent.subscribe((errorMsg) => {
        this.errorMessage = errorMsg;
        this.loading = false;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('content' in changes) {
      this.isList = Array.isArray(this.content);
    }
  }
}
