import { InterfaceLanguage } from 'src/app/shared/constants/languages';
export class Feedback {
  message?: string;
  email?: string;
  feedback?: boolean;
}

export const RatingChoices = [0, 1, 2, 3] as const;

export type RatingChoice = (typeof RatingChoices)[number];

export class UserRequest {
  email?: string;
  name?: string;
  language?: InterfaceLanguage;
  message: string;
  phone?: string;
  type: 'feedback' | 'template' | 'type';
}

export class SupportMessage {
  message?: string;
  phone?: string;
  files?: File[];
  subject?: string;
}
