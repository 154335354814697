<header>
  <h1>{{ "auth.registration.steps.first.title" | transloco: titleParams }}</h1>
  <p>
    {{ "auth.registration.steps.two.subtitle" | transloco }}
  </p>
  <navigation-buttons
    [currentStep]="2"
    [disabled]="!enableNextStep"
    (prev)="prev()"
    (next)="next()"
  >
  </navigation-buttons>
</header>

<form class="form">
  @for (option of options; track option) {
    <registration-step-option
      class="option"
      [option]="option"
      [isSafari]="isSafari"
      [lang]="lang"
      (click)="chooseOption(option)"
      (chooseOption)="chooseOption(option, $event)"
    >
    </registration-step-option>
  }
  <registration-step-option
    class="option"
    [option]="other"
    [lang]="lang"
    [isSafari]="isSafari"
    (click)="chooseOption(other)"
    (chooseOption)="chooseOption(other, $event)"
  >
  </registration-step-option>
</form>

<ng-template #button>
  <div
    class="btn-container"
    matTooltipClass="registration-step-tooltip"
    [mtTooltip]="
      !enableNextStep ? ('auth.registration.steps.two.tooltip' | transloco) : ''
    "
  >
    <button
      mat-flat-button
      color="primary"
      class="footer-button"
      (click)="next()"
      [disabled]="!enableNextStep"
    >
      {{ "auth.registration.steps.two.button" | transloco }}
      <mat-icon class="footer-button-icon">arrow_forward</mat-icon>
    </button>
  </div>
</ng-template>
