import { Component, Input } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'boolean-field',
  templateUrl: './boolean-field.component.html',
  styleUrls: ['./boolean-field.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, MatCheckboxModule, TranslocoPipe],
})
export class BooleanFieldComponent {
  @Input() field: string;
  @Input() form: UntypedFormGroup;
}
