import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { AccountStatusMessage } from 'src/app/shared/Models/models';
import { User } from 'src/app/shared/Models/user';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { addDays, differenceInDays, isSameDay, isTomorrow } from 'date-fns';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const subscriptionExpiredMessage = 'Your subscription expired';
@Injectable({
  providedIn: 'root',
})
export class StatusService {
  sharedTranslations = {
    today: 'today',
    tomorrow: 'tomorrow',
    on: 'on',
  };

  userStatus = {
    email: {
      full: 'Please check your email inbox: Some features will be disabled until your confirm your email.',
      additional: 'A confirmation email was sent to {{email}}.',
      part1: 'Confirmation email sent to {{email}}',
      update: 'Click here to update your email',
      part2: 'Please check your inbox.',
      part3: 'Some features will be disabled until your confirm your email',
      resend: '(Click here to resend the confirmation email)',
      simple: 'Please confirm your email address',
      confirm:
        '(Please click on the link in the confirmation email we sent you).',
      getParams: (_, user: User) => {
        return { email: user?.email };
      },
    },
    expired: {
      full: 'Your subscription expired. Choose a new subscription',
      short: subscriptionExpiredMessage,
      getParams: (_) => {
        return {};
      },
    },
    expired_trial: {
      full: 'Wecome back! To continue working on your menus choose a subscription',
      recent: 'Your free trial expired. Choose your subscription',
      short: 'Your free trial expired',
      fullStatus: (status) =>
        differenceInDays(new Date(status.inactive_on), new Date()) < -7
          ? this.userStatus.expired_trial.full
          : this.userStatus.expired_trial.recent,
      getParams: (_) => {
        return {};
      },
    },
    onetime_plan: {
      full: 'Your subscription expired, you have used {{usage}} of {{limit}} days this month. Choose your subscription',
      short: subscriptionExpiredMessage,
      getParams: (status) => {
        return {
          used: status.current_usage.number_edited_this_month,
          limit: status.usage_limits.limit_edits_per_month,
        };
      },
    },
    subscription: {
      full: 'Wecome back! To continue working on your menus choose a subscription',
      paymentFailed:
        'The payment for your subscription failed. Update your details',
      paymentFailedShort: 'Please check your subscription',
      recent: 'Your subscription was cancelled. Choose a new subscription',
      short: subscriptionExpiredMessage,
      fullStatus: (status) =>
        differenceInDays(new Date(status.inactive_on), new Date()) < -1
          ? this.userStatus.subscription.full
          : this.userStatus.subscription.recent,
      getParams: (_) => {
        return {};
      },
    },
    trialperiod: {
      full: 'Your free trial expires in {{days}} days. Choose a subscription',
      tomorrow: 'Your free trial expires tomorrow. Choose a subscription',
      today: 'Your free trial expires today. Choose a subscription',
      fullStatus: (status) => {
        if (isSameDay(new Date(status.inactive_on), new Date())) {
          return this.userStatus.trialperiod.today;
        }
        return isSameDay(new Date(status.inactive_on), addDays(new Date(), 1))
          ? this.userStatus.trialperiod.tomorrow
          : this.userStatus.trialperiod.full;
      },
      getParams: (status) =>
        isSameDay(new Date(status.inactive_on), addDays(new Date(), 1))
          ? {}
          : {
              days: differenceInDays(
                addDays(new Date(status.inactive_on), 1),
                new Date(),
              ),
            },
    },
    inactive: {
      full: 'Your subscription expires on {{expiry}}. Choose a new subscription',
      today: 'Your subscription expires today. Choose a new subscription',
      tomorrow: 'Your subscription expires tomorrow. Choose a new subscription',
      short: 'Your account is inactive',
      fullStatus: (status) => {
        let expiryDate = new Date(status.inactive_on);
        if (isSameDay(expiryDate, new Date()))
          return this.userStatus.inactive.today;
        if (isTomorrow(expiryDate)) return this.userStatus.inactive.tomorrow;
        return this.userStatus.inactive.full;
      },
      getParams: (status) => {
        const expiryDate = new Date(status.inactive_on);
        return {
          expiry: formatDate(
            expiryDate,
            'mediumDate',
            this.transloco.getActiveLang(),
          ),
        };
      },
    },
  };

  actionStatus = {
    menulimit: {
      short: 'You wrote {{used}}/{{limit}} menus this month',
      getParams: (status) => {
        return {
          used: status.current_usage.menus_created_this_month,
          limit: status.usage_limits.limit_menus_per_month,
        };
      },
    },
    templatelimit: {
      short: 'You used {{used}}/{{limit}} templates this month',
      getParams: (status) => {
        return {
          used: status.current_usage.templates_used_this_month,
          limit: status.usage_limits.limit_templates_per_month,
        };
      },
    },
  };

  constructor(
    private transloco: TranslocoService,
    private utils: UtilsService,
  ) {}

  isInPast = (date: any) => differenceInDays(new Date(date), new Date()) < 0;

  translateStatus(obj: Object, val: string, user: User): Observable<any> {
    const keys = Object.keys(obj[val])
      .filter((v) => typeof obj[val][v] !== 'function')
      .map((el) => `user.${val}.${el}`);

    const streams: Observable<any>[] = [];

    keys.forEach((key, index) => {
      const spl = key.split('.');
      streams.push(
        this.utils
          .getTranslation(
            keys,
            (res) => {
              obj[val][spl[2]] = res[index];
            },
            obj[val].getParams(user.status, user),
          )
          .pipe(
            catchError((err) => {
              return err;
            }),
          ),
      );
    });
    if (streams.length) return forkJoin(streams);
    return of();
  }

  handleAccountStatus(user: User): Observable<AccountStatusMessage> {
    const usage = {
      current_usage: user.status.current_usage,
      usage_limits: user.status.usage_limits,
    };

    // non-verified email
    if (!user.status.email_verified) {
      return this.translateStatus(this.userStatus, 'email', user).pipe(
        map(() =>
          new AccountStatusMessage(
            false,
            this.userStatus.email.simple,
            `email string`,
            null,
            null,
            {
              full: this.userStatus.email.full,
              additional: this.userStatus?.email?.additional?.replace(
                `{{email}}`,
                user.email,
              ),
              update: this.userStatus.email.update,
              link1: {
                route: ['/settings', 'account'],
              },
            },
          ).setBgColor('error'),
        ),
      );
    }

    /* TRIAL PERIODS */
    if (user.status.trial_period) {
      // expired trial period
      if (user.status.trial_period && this.isInPast(user.status.inactive_on)) {
        return this.translateStatus(
          this.userStatus,
          'expired_trial',
          user,
        ).pipe(
          map(() =>
            new AccountStatusMessage(
              false,
              this.userStatus.expired_trial.short,
              this.userStatus.expired_trial.fullStatus(user.status),
              { route: ['/settings', 'billing'], query: { change: true } },
            ).setBgColor(
              differenceInDays(new Date(user.status.inactive_on), new Date()) <
                -7
                ? 'success'
                : 'error',
            ),
          ),
        );
      }

      // active trial period
      return this.translateStatus(this.userStatus, 'trialperiod', user).pipe(
        catchError((err) => {
          return err;
        }),
        map(() => {
          return new AccountStatusMessage(
            true,
            null,
            this.userStatus.trialperiod.fullStatus(user.status),
            {
              route: ['/settings', 'billing'],
              query: { change: true },
            },
            usage,
          );
        }),
      );
    }

    /* INACTIVE SUBSCRIPTIONS */
    if (!user.status.subscription_active) {
      // expired one-off subscription with allowed edits
      if (
        user.status.subscription_oneoff &&
        user.status.usage_limits.limit_edits_per_month
      ) {
        return this.translateStatus(this.userStatus, 'onetime_plan', user).pipe(
          map(() =>
            new AccountStatusMessage(
              false,
              this.userStatus.onetime_plan.short,
              this.userStatus.onetime_plan.full,
              { route: ['/settings', 'billing'], query: { change: true } },
              usage,
            ).setBgColor(
              user.status.privileges.edit_menu ? 'success' : 'error',
            ),
          ),
        );
      }

      // expired time-limited subscription
      if (
        user.status.payment_date_upcoming &&
        this.isInPast(user.status.payment_date_upcoming)
      ) {
        return this.translateStatus(this.userStatus, 'subscription', user).pipe(
          map(() =>
            new AccountStatusMessage(
              false,
              this.userStatus.subscription.short,
              this.userStatus.subscription.fullStatus(user.status),
              {
                route: ['/settings', 'billing'],
                query: { change: true },
              },
            ).setBgColor('error'),
          ),
        );
      }

      // expired/past subscription
      return this.translateStatus(this.userStatus, 'subscription', user).pipe(
        map(() =>
          new AccountStatusMessage(
            false,
            this.userStatus.subscription.short,
            this.userStatus.subscription.fullStatus(user.status),
            {
              route: ['/settings', 'billing'],
              query: { change: true },
            },
          ).setBgColor(
            differenceInDays(new Date(user.status.inactive_on), new Date()) < -1
              ? 'success'
              : 'error',
          ),
        ),
      );
    }

    // active subscription with expiry date
    if (user.status.inactive_on) {
      return this.translateStatus(this.userStatus, 'inactive', user).pipe(
        map(
          () =>
            new AccountStatusMessage(
              true,
              null,
              this.userStatus.inactive.fullStatus(user.status),
              { route: ['/settings', 'billing'], query: { change: true } },
              usage,
            ),
        ),
      );
    }

    // payment failure
    if (user.status.payment_failure) {
      return this.translateStatus(this.userStatus, 'subscription', user).pipe(
        map(() =>
          new AccountStatusMessage(
            false,
            this.userStatus.subscription.paymentFailedShort,
            this.userStatus.subscription.paymentFailed,
            {
              route: ['/settings', 'billing'],
              query: {},
            },
          ).setBgColor('error'),
        ),
      );
    }

    // active subscription
    return of(new AccountStatusMessage(true, null, null, null, usage));
  }

  handleMenuLimits(user: User): Observable<AccountStatusMessage> {
    // Menus limit
    if (
      user.status &&
      user.status.current_usage.menus_created_this_month >=
        user.status.usage_limits.limit_menus_per_month &&
      user.status.usage_limits.limit_menus_per_month
    ) {
      return this.translateStatus(this.actionStatus, 'menulimit', user).pipe(
        map(
          () =>
            new AccountStatusMessage(true, this.actionStatus.menulimit.short),
        ),
      );
    }
    return of();
  }

  handleTemplateLimits(user: User): Observable<AccountStatusMessage> {
    // Templates limit
    if (
      user.status &&
      user.status.usage_limits.limit_templates_per_month <=
        user.status.current_usage.templates_used_this_month &&
      user.status.usage_limits.limit_templates_per_month
    ) {
      return this.translateStatus(
        this.actionStatus,
        'templatelimit',
        user,
      ).pipe(
        map(
          () =>
            new AccountStatusMessage(
              true,
              this.actionStatus.templatelimit.short,
            ),
        ),
      );
    }
    return of();
  }
}
