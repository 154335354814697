import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MtTooltipTemplateComponent } from './mt-tooltip-template/mt-tooltip-template.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({
  selector: '[mtTooltip]',
  exportAs: 'mtTooltip',
  standalone: true,
})
export class MtTooltipDirective implements OnInit, OnDestroy, OnChanges {
  @Input(`mtTooltip`) text: string;
  @Input(`mtTooltipHoriziontal`) horizontal: boolean;
  @Input() canClose: boolean;
  @Input() isHtml: boolean;

  @Output() closed = new EventEmitter();

  private destroyed$ = new Subject<void>();

  overlayRef: OverlayRef;
  portal: ComponentPortal<MtTooltipTemplateComponent>;
  portalRef: ComponentRef<MtTooltipTemplateComponent>;

  @HostListener(`mouseenter`, ['$event'])
  show(event?: MouseEvent): void {
    if (!this.text) return undefined;
    event?.stopPropagation();
    this.portal =
      this.portal || new ComponentPortal(MtTooltipTemplateComponent);
    if (this.overlayRef.hasAttached()) {
      this.portalRef.instance.state = `show`;
    } else {
      this.portalRef = this.overlayRef.attach(this.portal);
      if (this.isHtml) {
        this.portalRef.instance.html = this.text;
      } else {
        this.portalRef.instance.text = this.text;
      }
      this.portalRef.instance.animationEndCallback = () => {
        this.overlayRef.detach();
      };
      this.portalRef.instance.hide
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => this.hide());
    }
    this.portalRef.instance.canClose = this.canClose;
  }

  @HostListener(`mouseleave`, ['$event'])
  hide(event?: MouseEvent): void {
    if (!this.portalRef || (this.canClose && event)) return undefined;
    event?.stopPropagation();
    this.portalRef.instance.state = `hide`;
    this.closed.emit();
  }

  constructor(
    private overlay: Overlay,
    private element: ElementRef,
    private overlayPosBuilder: OverlayPositionBuilder,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const isHtmlChanges = changes['isHtml'];
    if (
      isHtmlChanges &&
      isHtmlChanges.currentValue !== isHtmlChanges.previousValue
    )
      this.init();
  }

  ngOnInit(): void {
    this.init();
  }

  init(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
    const posStrategyHorizonal = this.overlayPosBuilder
      .flexibleConnectedTo(this.element)
      .withPositions([
        {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
          panelClass: `mt-tooltip-after${this.isHtml ? '-html' : ''}`,
          offsetX: 15,
        },
      ]);
    const posStrategy = this.overlayPosBuilder
      .flexibleConnectedTo(this.element)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          panelClass: `mt-tooltip-bottom${this.isHtml ? '-html' : ''}`,
          offsetY: 15,
        },
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          panelClass: `mt-tooltip-top${this.isHtml ? '-html' : ''}`,
          offsetY: -15,
        },
      ]);
    this.overlayRef = this.overlay.create({
      positionStrategy: this.horizontal ? posStrategyHorizonal : posStrategy,
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
