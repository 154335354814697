import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  SubscriptSizing,
  MatFormFieldModule,
} from '@angular/material/form-field';
import {
  ContentLanguage,
  interfaceLangs,
  langs,
} from 'src/app/shared/constants/languages';
import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, takeUntil } from 'rxjs/operators';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-translatable-field',
  templateUrl: './translatable-field.component.html',
  styleUrls: ['./translatable-field.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TextFieldModule,
    MatProgressBarModule,
    MatButtonModule,
    MatMenuModule,
    TranslocoPipe,
  ],
})
export class TranslatableFieldComponent implements OnChanges, OnDestroy {
  @Input() allLangs = false;
  @Input() field: string;
  @Input() isTextarea: boolean;
  @Input() initialLang: ContentLanguage;
  @Input() item: any;
  @Input() loadingBars: { [key: string]: boolean };
  @Input() subscriptSizing: SubscriptSizing = 'fixed';
  @Output() fieldChanged = new EventEmitter<{ item: any; data: any }>();

  existingLangs: Set<string> = new Set();
  interfaceLangs = interfaceLangs;
  contentLangs = langs;
  unusedLangs: Set<string> = new Set();
  form: FormGroup;
  private destroyed$ = new Subject<void>();

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      'item' in changes &&
      this.item &&
      (this.item.id !== changes.item.previousValue?.id || !this.item.id)
    ) {
      this.existingLangs = new Set();
      this.unusedLangs = new Set();
      this.filterUnusedLangs();
      this.buildForm();
    }
    if (!this.form) {
      this.buildForm();
    }
  }

  buildForm(): void {
    this.form = this.fb.group({});
    this.existingLangs.forEach((lang) => {
      this.form.addControl(
        lang,
        new FormControl(
          this.item[this.field ? this.field + '_' + lang : lang] || '',
        ),
      );
    });
    this.form.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(400),
        takeUntil(this.destroyed$),
      )
      .subscribe((data) => this.onValueChanged(data));
  }

  filterUnusedLangs() {
    (this.allLangs ? this.contentLangs : this.interfaceLangs).forEach(
      (lang) => {
        if (!this.item[this.field ? `${this.field}_${lang}` : lang]) {
          this.unusedLangs.add(lang);
        } else {
          this.existingLangs.add(lang);
        }
      },
    );
    if (this.initialLang && !this.existingLangs.has(this.initialLang)) {
      this.existingLangs.add(this.initialLang);
    }
  }

  onValueChanged(data: any): void {
    const newData = {};
    Object.keys(data).forEach((key: string) => {
      newData[this.field ? this.field + '_' + key : key] = data[key];
    });
    this.fieldChanged.emit({ item: this.item, data: newData });
  }

  addLang(lang: string): void {
    this.unusedLangs.delete(lang);
    this.existingLangs.add(lang);
    this.form.addControl(
      lang,
      this.fb.control(
        this.item[this.field ? this.field + '_' + lang : lang] || '',
      ),
      { emitEvent: false },
    );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
