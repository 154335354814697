import { DeclarationKey } from '../constants/declarations';
import { BaseName } from './models';

export class GenericDeclaration {
  name: DeclarationKey;
  value: boolean;
  order: number;
  dependants: GenericDeclaration[];

  constructor(
    name: DeclarationKey,
    value: boolean,
    order: number,
    dependants: GenericDeclaration[],
  ) {
    this.name = name;
    this.value = value;
    this.order = order;
    this.dependants = dependants;
  }
}

export class DeclarationBase extends BaseName {
  id: string;
  url: string;
  code: string;
  order: number;
  symbol: string;
  pictogram: any;
  depends_on?: string;
  description_de: string;
  description_it: string;
  description_en: string;
  description_fr: string;
  description_es: string;
  legend_symbol: number;
  app_symbol: string;
}

export class Additive extends DeclarationBase {}

export class Allergen extends DeclarationBase {
  colour: string;

  constructor(obj?: any) {
    super();
    Object.assign(this, obj);
  }
}

export class Label extends DeclarationBase {}

export type Declaration = Additive | Allergen | Label;
