import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteModule,
} from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { ItemNameDialogComponent } from 'src/app/shared/Components/dialogs/item-name-dialog/item-name-dialog.component';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { InputProgressBarDirective } from 'src/app/shared/Directives/input-progress-bar/input-progress-bar.directive';
import { Dish } from 'src/app/shared/Models/dish';
import { Ingredient } from 'src/app/shared/Models/ingredients';
import {
  RecipeIngredient,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { pick } from 'lodash-es';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  takeUntil,
} from 'rxjs/operators';
import { TranslocoPipe } from '@jsverse/transloco';
import { OptionsComponent } from '../../../shared/Components/declarations/options/options.component';
import { OptionPopoverComponent } from '../../../shared/Components/declarations/option-popover/option-popover.component';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { InputProgressBarDirective as InputProgressBarDirective_1 } from '../../../shared/Directives/input-progress-bar/input-progress-bar.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'recipe-ingredients-line',
  templateUrl: './recipe-ingredients-line.component.html',
  styleUrls: ['./recipe-ingredients-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatOptionModule,
    InputProgressBarDirective_1,
    MatProgressBarModule,
    MatSelectModule,
    OptionPopoverComponent,
    TranslocoPipe,
  ],
})
export class RecipeIngredientsLineComponent
  implements OnDestroy, OnChanges, OnInit
{
  @Input() calcBasis: 'gross' | 'net';
  @Input() recipeIngredient: RecipeIngredient | SimpleRecipeIngredient;
  @Input() ingredients: RecipeIngredient[];
  @Input() ingredientsAuto: Ingredient[];
  @Input() isLocked = false;
  @Input() lang: InterfaceLanguage;
  @Input() showNetQuantity: boolean;
  @Input() showIcons = false;
  @Input() allergensView: boolean;
  @Input() additivesView: boolean;

  @Output() addLineEvent = new EventEmitter();
  @Output() chooseIngredient = new EventEmitter<{
    id: number;
    ingredient_id: number;
  }>();
  @Output() createIngredient = new EventEmitter<{
    ingredient: Ingredient;
    id: number;
  }>();
  @Output() deleteIngredient = new EventEmitter<RecipeIngredient>();
  @Output() searchIngredients = new EventEmitter<string>();
  @Output() updateIngredient = new EventEmitter<{
    url: string;
    id: number;
    ingredient: Partial<Ingredient>;
    recipeIngredient: RecipeIngredient;
  }>();
  @Output() updateRecipeIngredient = new EventEmitter<{
    url: string;
    id: number;
    recipeIngredient: Partial<RecipeIngredient>;
    onFulfilled: () => void;
  }>();
  @Output() changed = new EventEmitter<{
    data: Dish | Ingredient;
    type: string;
  }>();

  @ViewChild('auto', { static: false })
  ingredientAutocomplete: ElementRef<MatAutocomplete>;
  @ViewChild('ingredientName')
  nameInput: ElementRef<HTMLInputElement>;
  @ViewChild('ingredientQuantity', { static: true })
  quantityInput: ElementRef<HTMLInputElement>;
  @ViewChildren(InputProgressBarDirective)
  progressBars: QueryList<InputProgressBarDirective>;

  private destroyed$ = new Subject<void>();
  addLineOnCompletion = false;
  autoControl = new FormControl('');
  disableDelete = false;
  focusQuantity = false;
  ingredientGroup = new FormGroup({
    quantity: new FormControl<number>(null, {
      updateOn: 'blur',
      validators: [Validators.min(0)],
    }),
    quantity_net: new FormControl<number>(null, {
      updateOn: 'blur',
      validators: [Validators.min(0)],
    }),
    weight_loss: new FormControl<number>(null, {
      updateOn: 'blur',
    }),
    weight_loss_perc: new FormControl<number>(null, {
      updateOn: 'blur',
    }),
    unit: new FormControl<string>(null),
  });
  lastNewIngredientName: string;
  name: { name: string; italic: boolean };
  units: string[];
  showDeclarationsView = false;

  constructor(
    private utils: UtilsService,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
  ) {
    this.units = this.utils.getUnits();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // disable form when isLocked is true
    if (
      'isLocked' in changes &&
      changes.isLocked.currentValue !== changes.isLocked.previousValue
    ) {
      this.isLocked
        ? this.ingredientGroup.disable({ emitEvent: false })
        : this.ingredientGroup.enable({ emitEvent: false });
    }
    // update weight loss fields when calcBasis is changed
    if (
      !this.isLocked &&
      'calcBasis' in changes &&
      changes.calcBasis.currentValue !== changes.calcBasis.previousValue
    ) {
      this.initWeightLossCalcBasis();
    }
    // update form when recipe ingredient is changed
    if ('recipeIngredient' in changes && this.recipeIngredient?.url) {
      if (
        changes.recipeIngredient.previousValue?.id !==
        changes.recipeIngredient.currentValue.id
      ) {
        this.initForm();
      }
      this.name = this.utils.tryGetLabel(
        this.recipeIngredient.ingredient_detail,
        this.lang,
      );
      this.initFormValue();
      if (!this.isLocked) this.initWeightLossQuantity();
    }
    // focus on quantity field when a new row is added
    if (
      !this.isLocked &&
      'recipeIngredient' in changes &&
      changes.recipeIngredient.firstChange &&
      !changes.recipeIngredient.currentValue.url
    ) {
      this.focusQuantity = false;
      this.utils.runOutsideWithTimer(0, this.focusNameOrQuantity.bind(this));
    }
  }

  ngOnInit(): void {
    this.autoControl.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter((search: string) => search?.length >= 2),
        takeUntil(this.destroyed$),
      )
      .subscribe((v: any) => {
        this.lastNewIngredientName = v;
        this.searchIngredients.emit(v);
      });
  }

  initFormValue(): void {
    const data = pick(this.recipeIngredient, [
      'quantity',
      'quantity_net',
      'weight_loss',
      'weight_loss_perc',
      'unit',
    ]);
    this.ingredientGroup.patchValue(data, {
      emitEvent: false,
    });
  }

  initWeightLossCalcBasis(): void {
    if (this.calcBasis === 'net') {
      this.ingredientGroup.controls.weight_loss_perc.disable({
        emitEvent: false,
      });
    } else {
      this.ingredientGroup.controls.weight_loss_perc.enable({
        emitEvent: false,
      });
    }
    this.ingredientGroup.updateValueAndValidity({ emitEvent: false });
  }

  initWeightLossQuantity(): void {
    if (this.ingredientGroup.value.quantity === null) {
      this.ingredientGroup.controls.weight_loss.disable({ emitEvent: false });
      this.ingredientGroup.controls.weight_loss_perc.disable({
        emitEvent: false,
      });
      this.ingredientGroup.updateValueAndValidity({ emitEvent: false });
    } else {
      this.ingredientGroup.controls.weight_loss.enable({ emitEvent: false });
      if (this.calcBasis === 'gross')
        this.ingredientGroup.controls.weight_loss_perc.enable({
          emitEvent: false,
        });
      this.ingredientGroup.updateValueAndValidity({ emitEvent: false });
    }
  }

  addLine(event: Event): void {
    (<HTMLElement>event.target).blur();
    this.addLineOnCompletion = true;
  }

  toggleDeclarationsView() {
    this.showDeclarationsView = !this.showDeclarationsView;
  }

  createNewIngredient(value?: string): void {
    if (typeof value !== 'string') return undefined;
    const ingredientName = value || this.lastNewIngredientName;
    if (!this.recipeIngredient.url) {
      const newIngredient = new Ingredient();
      newIngredient[this.lang] = ingredientName;
      this.createIngredient.emit({
        ingredient: newIngredient,
        id: this.recipeIngredient.id,
      });
    } else {
      this.recipeIngredient.ingredient_detail[this.lang] = ingredientName;
      this.createIngredient.emit({
        ingredient: this.recipeIngredient.ingredient_detail,
        id: this.recipeIngredient.id,
      });
    }
    this.autoControl.setValue(value || this.lastNewIngredientName);
    this.disableNameInput(ingredientName);
  }

  delete(): void {
    this.deleteIngredient.emit(this.recipeIngredient as RecipeIngredient);
    this.disableDelete = true;
    this.autoControl.disable();
    for (let control in this.ingredientGroup.controls) {
      this.ingredientGroup.controls[control].disable({ emitEvent: false });
    }
  }

  displayFn(ingr: Ingredient): string | undefined {
    return ingr ? ingr[this.lang] : undefined;
  }

  disableNameInput(value?: string): void {
    if (value) this.autoControl.setValue(value, { emitEvent: false });
    this.autoControl.disable({ emitEvent: false });
    this.nameInput.nativeElement.blur();
    this.focusQuantity = true;
  }

  focusNameOrQuantity(): void {
    if (this.nameInput) {
      this.nameInput.nativeElement.focus();
    } else {
      this.quantityInput.nativeElement.focus();
    }
  }

  hasModuleSetting = (code: string, setting: string, value: any): boolean =>
    this.utils.hasModuleSetting(code, setting, value);

  ingredientSelected(ingredient: Ingredient): void {
    this.chooseIngredient.emit({
      id: this.recipeIngredient.id,
      ingredient_id: ingredient.id,
    });
    this.disableNameInput(ingredient[this.lang]);
  }

  openNameDialog(recipeIngredient: RecipeIngredient | SimpleRecipeIngredient) {
    this.dialog.open(ItemNameDialogComponent, {
      data: {
        item: recipeIngredient.ingredient_detail,
        lang: this.lang,
        showArticleNumber: this.hasModuleSetting(
          'man',
          'article_numbers',
          true,
        ),
        close: (value: Partial<Ingredient>) => {
          this.updateIngredient.emit({
            url: recipeIngredient.ingredient_detail.url,
            id: recipeIngredient.ingredient,
            ingredient: value,
            recipeIngredient: recipeIngredient as RecipeIngredient,
          });
        },
      },
      width: '600px',
      autoFocus: false,
    });
  }

  initForm(): void {
    Object.keys(this.ingredientGroup.controls).forEach((key) => {
      this.ingredientGroup.controls[key].valueChanges
        .pipe(
          debounceTime(50),
          distinctUntilChanged(),
          takeUntil(this.destroyed$),
        )
        .subscribe((value) => {
          const progressBar = this.progressBars.filter(
            (item) => item.nameIdentifier === key,
          )?.[0];
          progressBar?.changeBarVisibility(true);

          if (this.ingredientGroup.valid) {
            // When quantity_net is set and quantity is empty, quantity should be set to the same value as quantity_net
            if (
              key === 'quantity_net' &&
              this.ingredientGroup.value.quantity_net &&
              !this.ingredientGroup.value.quantity
            ) {
              this.ingredientGroup.patchValue(
                {
                  quantity: this.ingredientGroup.value.quantity_net,
                },
                { emitEvent: false },
              );
            }

            // When quantity is set to null, quantity_net should also be set to null
            if (
              key === 'quantity' &&
              this.ingredientGroup.value.quantity === null
            ) {
              this.ingredientGroup.patchValue(
                {
                  quantity_net: null,
                },
                { emitEvent: false },
              );
            }

            // When quantity is changed and calc_basis is gross, then quantity_net should be set to quantity * (1-weight_loss_perc)
            if (
              key === 'quantity' &&
              value &&
              this.calcBasis === 'gross' &&
              this.ingredientGroup.value.weight_loss_perc
            ) {
              this.ingredientGroup.patchValue(
                {
                  quantity_net: +(
                    value *
                    (1 - this.ingredientGroup.value.weight_loss_perc / 100)
                  ).toFixed(2),
                },
                { emitEvent: false },
              );
            }

            // When quantity is changed and calc_basis is net and quantity is less than quantity_net, then quantity_net should be set to quantity
            if (
              key === 'quantity' &&
              value &&
              this.ingredientGroup.value.quantity_net &&
              value < this.ingredientGroup.value.quantity_net
            ) {
              this.ingredientGroup.patchValue(
                {
                  quantity_net: value,
                },
                { emitEvent: false },
              );
            }

            // When quantity_net is changed and calc_basis is net, then quantity should be set to quantity_net / (1-weight_loss_perc)
            if (
              key === 'quantity_net' &&
              value &&
              this.calcBasis === 'net' &&
              this.ingredientGroup.value.weight_loss_perc
            ) {
              this.ingredientGroup.patchValue(
                {
                  quantity: +(
                    value /
                    (1 - this.ingredientGroup.value.weight_loss_perc / 100)
                  ).toFixed(2),
                },
                { emitEvent: false },
              );
            }

            // When quantity_net is changed and calc_basis is gross and quantity is less than quantity_net, then quantity should be set to quantity_net
            if (
              key === 'quantity_net' &&
              value &&
              this.ingredientGroup.value.quantity &&
              value > this.ingredientGroup.value.quantity
            ) {
              this.ingredientGroup.patchValue(
                {
                  quantity: value,
                },
                { emitEvent: false },
              );
            }

            // When weight_loss is changed and the calc_basis is gross, then quantity_net should be set to quantity - weight_loss
            if (key === 'weight_loss' && this.calcBasis === 'gross') {
              this.ingredientGroup.patchValue(
                {
                  quantity_net: this.ingredientGroup.value.quantity - value,
                },
                { emitEvent: false },
              );
            }

            // When weight_loss_perc is set and calc_basis is gross, then quantity_net should be set to to quantity * (1-weight_loss_perc)
            if (key === 'weight_loss_perc' && this.calcBasis === 'gross') {
              this.ingredientGroup.patchValue(
                {
                  quantity_net: +(
                    this.ingredientGroup.value.quantity *
                    (1 - value / 100)
                  ).toFixed(2),
                },
                { emitEvent: false },
              );
            }

            // When weight_loss is changed and calc_basis is set to net, then quantity should be set to quantity_net + weight_loss
            if (key === 'weight_loss' && this.calcBasis === 'net') {
              this.ingredientGroup.patchValue(
                {
                  quantity: this.ingredientGroup.value.quantity_net + value,
                },
                { emitEvent: false },
              );
            }

            // When weight_loss_perc is changed and calc_basis is set to net, then quantity should be set to quantity_net * (1+weight_loss_perc)
            if (key === 'weight_loss_perc' && this.calcBasis === 'net') {
              this.ingredientGroup.patchValue(
                {
                  quantity: +(
                    this.ingredientGroup.value.quantity_net *
                    (1 + value / 100)
                  ).toFixed(2),
                },
                { emitEvent: false },
              );
            }

            // When weight_loss is set to null, then quantity_net should be set to null
            if (key === 'weight_loss' && value === null) {
              this.ingredientGroup.patchValue(
                {
                  quantity_net: null,
                },
                { emitEvent: false },
              );
            }

            // When weight_loss_perc is set to null, then weight_loss and quantity_net should be set to null
            if (key === 'weight_loss_perc' && value === null) {
              this.ingredientGroup.patchValue(
                {
                  weight_loss: null,
                  quantity_net: null,
                },
                { emitEvent: false },
              );
            }

            this.updateRecipeIngredient.emit({
              url: this.recipeIngredient.url,
              id: this.recipeIngredient.id,
              recipeIngredient: { ...this.ingredientGroup.value },
              onFulfilled: () => {
                progressBar?.changeBarVisibility();
                if (this.addLineOnCompletion) {
                  this.addLineOnCompletion = false;
                  this.addLineEvent.emit();
                }
              },
            });
          } else {
            progressBar?.changeBarVisibility(false);
            this.ingredientGroup.controls[key].patchValue(
              this.recipeIngredient[key],
              { emitEvent: false },
            );
          }
        });
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
