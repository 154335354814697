import { Portal, PortalModule } from '@angular/cdk/portal';
import { Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { ObservablePortal } from 'src/app/shared/Models/registration-steps';
import { User } from 'src/app/shared/Models/user';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { from, of, Subscription, Subject } from 'rxjs';
import { concatAll, delay, map, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import {
  fetchPartnerByCode,
  fetchTemplates,
} from 'src/app/shared/ngrx/shared.actions';
import {
  selectTemplates,
  selectTemplateState,
} from 'src/app/shared/ngrx/shared.selectors';
import { selectUser } from 'src/app/shared/user/ngrx/user.selectors';
import { CopyDeepPipe } from '../../../shared/Pipes/copy-deep.pipe';
import { AsyncPipe } from '@angular/common';
import { RegistrationStepThreeComponent } from './registration-step-three/registration-step-three.component';
import { RegistrationStepTwoComponent } from './registration-step-two/registration-step-two.component';
import { RegistrationStepOneComponent } from './registration-step-one/registration-step-one.component';
import { SpinnerComponent } from '../../../shared/Components/spinner/spinner.component';

export enum STEPS {
  FIRST = 1,
  SECOND,
  THIRD,
}

@Component({
  selector: 'registration-steps',
  templateUrl: './registration-steps.container.html',
  styleUrls: ['./registration-steps.container.scss'],
  standalone: true,
  imports: [
    SpinnerComponent,
    RegistrationStepOneComponent,
    RegistrationStepTwoComponent,
    RegistrationStepThreeComponent,
    PortalModule,
    AsyncPipe,
    CopyDeepPipe,
  ],
})
export class RegistrationStepsContainer implements OnDestroy {
  spinnerState$ = this.ngrxStore.select(selectTemplateState);
  templates$ = this.ngrxStore.select(selectTemplates);
  user$ = this.ngrxStore.select(selectUser);

  private destroyed$ = new Subject<void>();
  lang: InterfaceLanguage;
  user: User;
  STEPS = STEPS;
  currentStep: STEPS;
  stepsButtonPortal: Portal<any>;
  clickSteam$: Subscription;
  done = false;
  revealImage = false;
  revealLogo = false;
  revealContent = false;
  revealDoneImage = false;
  isSafari = false;

  countries: { label: string[]; value: string }[] = [];
  filteredCountries = [];
  rawCountries = [];

  step1Group: UntypedFormGroup;
  searchCountryCtrl = new UntypedFormControl('');

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslocoService,
    private utils: UtilsService,
    protected ngrxStore: Store<State>,
  ) {
    this.isSafari = this.utils.isSafari();
    this.lang = this.translateService.getActiveLang() as InterfaceLanguage;
    this.translateService.langChanges$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((lang) => {
        this.lang = lang as InterfaceLanguage;
      });
    this.user = this.activatedRoute.snapshot.data[`user`] as User;
    this.ngrxStore.dispatch(
      fetchPartnerByCode({ code: this.user.partner_code }),
    );
    this.ngrxStore.dispatch(fetchTemplates());
    this.activatedRoute.params
      .pipe(takeUntil(this.destroyed$))
      .subscribe((params) => this.handleParams(params));
  }

  handleParams(params: Params): void {
    const { step } = params;
    this.currentStep = +step;
  }

  imgLoaded(): void {
    this.revealImage = true;
    from([() => (this.revealLogo = true), () => (this.revealContent = true)])
      .pipe(
        map((func) => of(func).pipe(delay(400))),
        concatAll<any>(),
        takeUntil(this.destroyed$),
      )
      .subscribe((func: Function) => func());
  }

  thirdStepDone(): void {
    this.done = true;
    setTimeout(() => (this.revealDoneImage = true), 0);
  }

  setPortal({ portal, eventStream }: ObservablePortal): void {
    if (this.clickSteam$) {
      this.clickSteam$.unsubscribe();
    }
    this.stepsButtonPortal = portal;
    this.clickSteam$ = eventStream.subscribe((goBack) => {
      let nextLocation: string;
      let options = { relativeTo: this.activatedRoute };
      if (goBack) {
        nextLocation = `../${Math.max(1, this.currentStep - 1)}`;
      } else {
        nextLocation = `../${this.currentStep + 1}`;
        if (this.currentStep === 3) {
          nextLocation = 'dashboard';
          options = undefined;
        }
      }
      this.router.navigate([nextLocation], options);
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
