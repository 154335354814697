<mat-card class="menu-overview">
  @if (loadingSpinner) {
    <spinner></spinner>
  }
  @if (!loadingSpinner) {
    <div>
      <div class="table-headers wrapper">
        <div class="col date take-space">
          {{ "menus.overview.table_headers.date" | transloco }}
        </div>
        <div class="col name take-space">
          {{ "menus.overview.table_headers.name" | transloco }}
        </div>
        <div class="actions"></div>
      </div>
      @for (menu of menus?.results; track menu; let isLastMenu = $last) {
        <menu-overview-item
          [consumerTypesByLocation]="consumerTypesByLocation"
          [currentMenu]="currentMenu"
          [dietsLocationsMap]="dietsLocationsMap"
          [isLastMenu]="isLastMenu"
          [locations]="locations"
          [menu]="menu"
          [privileges]="privileges"
          [rulesets]="rulesets"
          [user]="user"
          [userStatusMessage]="userStatusBlockMessage"
          (changeMenu)="changeMenu.emit($event)"
          (childDelete)="deleteMenu.emit($event)"
          (childCopied)="showCopyMenu.emit($event)"
          (childShowPreview)="previewMenu.emit($event)"
          (childDeleteChildren)="deleteChildren.emit($event)"
          (copied)="showCopyMenu.emit(menu)"
          (copyWithDiets)="copyWithDiets.emit($event)"
          (copyWithOptions)="copyWithOptions.emit($event)"
          (createArchive)="
            generateArchive.emit({
              rulesetId: $event?.rulesetId,
              menu: menu,
              taskType: $event?.taskType,
            })
          "
          (createMenu)="createMenu.emit({ menu: menu, data: $event })"
          (showSnackbarMessageEvent)="showSnackbarMessageEvent.emit($event)"
          (delete)="deleteMenu.emit(menu)"
          (deleteChildren)="deleteChildren.emit(menu)"
          (regenerateMenus)="regenerateMenus.emit(menu)"
          (shareMenu)="shareMenu.emit($event)"
          (refreshSubMenu)="refreshSubMenu.emit($event)"
          (showPreview)="previewMenu.emit($event)"
        >
        </menu-overview-item>
      }
      @if (!menus?.results?.length) {
        <div
          #tooltip2="matTooltip"
          class="empty-menus-container"
          matTooltipClass="top-navbar-tooltip"
          [matTooltip]="userStatusBlockMessage"
          (click)="
            createBtnClicked.emit({ btn: createButton2, tooltip: tooltip2 })
          "
        >
          <button
            #createButton2
            mat-flat-button
            color="primary"
            class="add-entity-button create"
            [disabled]="!privileges?.create_menu || !userStatus?.ready"
            (click)="openCreateMenu.emit()"
          >
            {{ "menus.overview.actions.create" | transloco }}
          </button>
        </div>
      }
      <mat-divider></mat-divider>
      <mat-paginator
        #paginator
        [length]="menus?.count"
        [pageIndex]="params?.page ? params?.page - 1 : 0"
        [pageSize]="params?.page_size"
        [pageSizeOptions]="[10, 20, 50]"
        (page)="handlePaginator($event)"
      >
      </mat-paginator>
    </div>
  }
</mat-card>
