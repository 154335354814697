import { UserOnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Status, User, UserModule } from 'src/app/shared/Models/user';
import { AccountStatusMessage } from 'src/app/shared/Models/models';
import { TranslocoPipe } from '@jsverse/transloco';
import { PlanLimitComponent } from '../../shared/Components/plan-limit/plan-limit.component';
import { MatCardModule } from '@angular/material/card';
import { StatusBlockComponent } from '../../shared/Components/status-block/status-block.component';

@Component({
  selector: 'app-user-status',
  templateUrl: './user-status.component.html',
  styleUrls: ['./user-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    StatusBlockComponent,
    MatCardModule,
    PlanLimitComponent,
    TranslocoPipe,
  ],
})
export class UserStatusComponent {
  @Input() complete: boolean;
  @Input() isLoading: boolean;
  @Input() lang: InterfaceLanguage;
  @Input() menusCreated: {
    this_month: number;
    all_time: number;
    this_week: number;
    this_year: number;
  };
  @Input() status: Status;
  @Input() user: User;
  @Input() userOnboardingTemplates: UserOnboardingTemplate[];
  @Input() statusMessages: AccountStatusMessage;

  @Output() createMenuFromOnboardingTemplate = new EventEmitter<{
    date: Date;
    template: number;
  }>();

  constructor() {}
}
