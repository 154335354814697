import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { selectLoginState } from 'src/app/auth/ngrx/auth.selectors';
import { State } from 'src/app/reducers/index';
import { User } from 'src/app/shared/Models/user';
import { TranslocoPipe } from '@jsverse/transloco';
import { AsyncPipe } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'reactivation',
  templateUrl: './reactivation.component.html',
  styleUrls: ['./reactivation.component.scss'],
  standalone: true,
  imports: [MatIconModule, MatProgressSpinnerModule, AsyncPipe, TranslocoPipe],
})
export class ReactivationComponent {
  @Input() error: string;
  @Input() user: User;
  @Input() done = false;

  support = `mailto: support@menutech.com?subject=Reactivate my free trial account&body=Please reactivate the trial period for my account`;
  values$ = interval(150).pipe(take(101));
  enableNextStep: boolean;

  login$ = this.store.select(selectLoginState);

  constructor(private store: Store<State>) {}
}
