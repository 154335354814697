import { BlockEmbed } from 'quill/blots/block';
import Inline from 'quill/blots/inline';
import Scroll from 'quill/blots/scroll';

interface ImageInlineValue {
  url: string;
  style?: string;
  dataAlign?: string;
  width?: string;
  height?: string;
}

export class ImageInline extends BlockEmbed {
  static create(value: ImageInlineValue): HTMLElement {
    const node = super.create() as HTMLElement;
    node.setAttribute('src', value.url); // Assuming value is an object with the URL
    if (value.style) {
      node.setAttribute('style', value.style);
    }
    if (value.dataAlign) {
      node.setAttribute('data-align', value.dataAlign);
    }
    if (value.width) {
      node.setAttribute('width', value.width);
    }
    if (value.height) {
      node.setAttribute('height', value.height);
    }
    return node;
  }

  static value(node: HTMLElement): ImageInlineValue {
    return {
      url: node.getAttribute('src'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      style: node.getAttribute('style'),
      dataAlign: node.getAttribute('data-align'),
    };
  }
}

ImageInline.blotName = 'styled';
ImageInline.tagName = 'IMG';

export class DividerBlot extends BlockEmbed {}
DividerBlot.blotName = 'divider';
DividerBlot.tagName = 'HR';
DividerBlot.className = 'pagebreak';

export class AllergenBlot extends Inline {
  static readonly toolbarOptionSelected = false;

  static formats(t: HTMLElement, e: Scroll): any {
    if (
      t.tagName === AllergenBlot.tagName &&
      t.classList.contains(AllergenBlot.className) &&
      t.innerText?.length
    ) {
      return true;
    }
    return super.formats(t, e);
  }

  static format(node: HTMLElement): string {
    return node.innerText;
  }
  static value(node: HTMLElement): string {
    return node.innerText;
  }
}
AllergenBlot.blotName = 'allergen';
AllergenBlot.tagName = 'SPAN';
AllergenBlot.className = 'allergen-symbol';
