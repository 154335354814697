import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { UserOnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'user-onboarding-template',
  templateUrl: './user-onboarding-template.component.html',
  styleUrls: ['./user-onboarding-template.component.scss'],
  standalone: true,
  imports: [MatTooltipModule, MatIconModule],
})
export class UserOnboardingTemplateComponent implements OnChanges {
  @Input() disabled: boolean;
  @Input() lang: InterfaceLanguage;
  @Input() template: UserOnboardingTemplate;
  @Input() tooltip: string;
  tooltipMessage = '';

  ngOnChanges(changes: SimpleChanges) {
    if ('tooltip' in changes) this.tooltipMessage = this.tooltip;
  }
}
