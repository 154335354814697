import { Component, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TwoColumnsModalConfig } from './two-columns-dialog.model';
import { AsyncPipe } from '@angular/common';
import { StopPropagationDirective } from '../../../Directives/stop-propagation/stop-propagation.directive';
import { SameDishesComponent } from './same-dishes/same-dishes.component';

@Component({
  selector: 'app-two-columns-dialog',
  templateUrl: './two-columns-dialog.component.html',
  styleUrls: ['./two-columns-dialog.component.css'],
  standalone: true,
  imports: [SameDishesComponent, StopPropagationDirective, AsyncPipe],
})
export class TwoColumnsDialogComponent {
  public loadMoreDishes = new EventEmitter<string>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: TwoColumnsModalConfig) {}
}
