import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { Menu } from 'src/app/shared/Models/menu';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'lock-item',
  templateUrl: './lock-item.component.html',
  styleUrls: ['./lock-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIconModule],
})
export class LockItemComponent implements OnChanges {
  @Input() field: string | string[];
  @Input() lockedFields: string[];
  @Output() updateMenu = new EventEmitter<DeepPartial<Menu>>();

  isLocked = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      ('field' in changes || 'lockedFields' in changes) &&
      this.field &&
      this.lockedFields
    ) {
      if (Array.isArray(this.field)) {
        this.isLocked = this.field.every((field) =>
          this.lockedFields.includes(field),
        );
      } else {
        this.isLocked = this.lockedFields.includes(this.field);
      }
    }
  }

  lockChanged() {
    this.isLocked = !this.isLocked;
    let value = [...this.lockedFields];
    if (Array.isArray(this.field)) {
      if (this.isLocked) {
        value = [...new Set([...value, ...this.field])];
      } else {
        value = value.filter((field) => !this.field.includes(field));
      }
    } else {
      if (this.lockedFields.includes(this.field)) {
        value = value.filter((field) => field !== this.field);
      } else {
        value.push(this.field);
      }
    }
    this.updateMenu.emit({
      style: {
        locked_fields: value,
      },
    });
  }
}
