import { TemplatePortal } from '@angular/cdk/portal';
import { UserCategory } from 'src/app/shared/Models/user';
import { Observable } from 'rxjs';

export interface RegistrationOption {
  view?: string;
  value?: UserCategory;
  icon?: string;
  active?: boolean;
  other?: boolean;
  payload?: string;
}

export interface ObservablePortal {
  portal: TemplatePortal;
  eventStream: Observable<true | undefined>;
}

export function isRegistrationOption(item: any): item is RegistrationOption {
  return item?.other;
}
