import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as StyleConstants from 'src/app/shared/constants/style';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { BackgroundImage, Menu } from 'src/app/shared/Models/menu';
import { Fulfillable } from 'src/app/shared/Models/models';
import { MTFont } from 'src/app/shared/Models/mtfont';
import { Separator } from 'src/app/shared/Models/separator';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { TranslocoPipe } from '@jsverse/transloco';
import { CustomDataFormComponent } from './custom-data-form/custom-data-form.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ShowAndUploadImageComponent } from '../../../../../shared/Components/show-and-upload-image/show-and-upload-image.component';
import { RouterLink } from '@angular/router';
import { StopPropagationDirective } from '../../../../../shared/Directives/stop-propagation/stop-propagation.directive';
import { SelectItemComponent } from '../select-item/select-item.component';
import { NumberItemComponent } from '../number-item/number-item.component';
import { ToggleItemComponent } from '../toggle-item/toggle-item.component';
import { LockItemComponent } from '../lock-item/lock-item.component';
import { MtColorPickerComponent } from '../../../../../shared/Components/mt-color-picker/mt-color-picker.component';
import { FontItemComponent } from '../font-item/font-item.component';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { SelectImageComponent } from '../../../../shared/select-image/select-image.component';

@Component({
  selector: 'style-general-tab',
  templateUrl: './style-general-tab.component.html',
  styleUrls: [
    '../../style.component.scss',
    './style-general-tab.component.scss',
  ],
  standalone: true,
  imports: [
    SelectImageComponent,
    MatExpansionModule,
    MatIconModule,
    FontItemComponent,
    MtColorPickerComponent,
    LockItemComponent,
    ToggleItemComponent,
    NumberItemComponent,
    SelectItemComponent,
    StopPropagationDirective,
    RouterLink,
    ShowAndUploadImageComponent,
    MatSlideToggleModule,
    ReactiveFormsModule,
    FormsModule,
    CustomDataFormComponent,
    TranslocoPipe,
  ],
})
export class StyleOptionsTabComponent implements OnChanges, OnInit {
  isLockedMap: {
    [key: string]: boolean;
  } = {};

  printDescriptions: boolean;
  printImages: boolean;

  additiveFormatsView: { name: string; value: number }[];
  allergensFormatsView: { name: string; value: number }[];
  labelsFormatsView: { name: string; value: number }[];
  showImageSpinner = false;
  styleConstants = StyleConstants;
  customData: any;

  @Input() disabledFields: string[] = [];
  @Input() lang: InterfaceLanguage;
  @Input() lockedFields: string[];
  @Input() menu: Menu;
  @Input() options: Separator[];
  @Input() courses: Separator[];
  @Input() mtFonts: MTFont[];
  @Input() showLocks: boolean;
  @Input() backgroundImages: BackgroundImage[];

  @Output() updateMenu = new EventEmitter<DeepPartial<Menu>>();
  @Output() uploadLogoImage = new EventEmitter<Fulfillable<File>>();

  constructor(private utils: UtilsService) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('menu' in changes && this.menu) {
      if (
        this.menu.style?.print_description_dishes ||
        this.menu.style?.print_description_sections
      ) {
        this.printDescriptions = true;
      }
      if (
        this.menu.style?.print_images_dishes ||
        this.menu.style?.print_images_sections
      ) {
        this.printImages = true;
      }
    }
  }

  ngOnInit(): void {
    this.additiveFormatsView = this.getDeclarationTypes(
      'add',
      StyleConstants.additiveFormatOptions,
    );
    this.allergensFormatsView = this.getDeclarationTypes(
      'all',
      StyleConstants.allergenFormatOptions,
    );
    this.labelsFormatsView = this.getDeclarationTypes(
      'label',
      StyleConstants.labelFormatOptions,
    );

    this.generateIsLockedFields();
  }

  getDeclarationTypes(
    type: 'add' | 'all' | 'label',
    model: { name: string; value: number }[],
  ): { name: string; value: number }[] {
    const customDeclarations = this.hasModuleSetting(
      type,
      'custom_declaration',
      true,
    );
    return customDeclarations ? model : model.slice(0, -1);
  }

  getShowLogoOptions(): any[] {
    return this.menu && this.menu.location
      ? StyleConstants.showLogoOptions
      : [StyleConstants.showLogoOptions[0]];
  }

  updateDescriptionDisplay = ({ checked }) => {
    if (!this.menu || !this.menu.style) return undefined;
    if (!checked) {
      this.printDescriptions = false;
      this.updateMenu.emit({
        style: {
          print_description_dishes: false,
          print_description_sections: false,
        },
      });
    }
  };

  updateImagesDisplay = ({ checked }) => {
    if (!this.menu || !this.menu.style) return undefined;
    if (!checked) {
      this.printImages = false;
      this.updateMenu.emit({
        style: {
          print_images_dishes: false,
          print_images_sections: false,
        },
      });
    }
  };

  uploadLogo(file: File): void {
    if (file === undefined) return undefined;
    this.showImageSpinner = true;
    this.uploadLogoImage.emit({
      payload: file,
      onFulfilled: () => (this.showImageSpinner = false),
    });
  }

  hasModuleSetting(code: string, setting: string, value: any): boolean {
    return this.utils.hasModuleSetting(code, setting, value);
  }

  hasModule(code: string): boolean {
    return this.utils.hasModules(code);
  }

  generateIsLockedFields(): void {
    StyleConstants.backgroundTextFields.forEach((key) => {
      this.isLockedMap[key] = this.isLocked(`background_${key}`);
    });

    StyleConstants.coloursOtherFields.forEach((key) => {
      this.isLockedMap[key] = this.isLocked(`colour_${key}`);
    });
  }

  isLocked(field: string): boolean {
    return (
      this.menu &&
      this.menu.template_detail &&
      this.menu.template_detail.locked_fields &&
      this.menu.template_detail.locked_fields.indexOf(field) > -1
    );
  }

  clearColor(field: string): void {
    this.updateMenu.emit({
      style: {
        [field]: null,
      },
    });
  }

  clearLogoImage(): void {
    this.updateMenu.emit({ style: { logo: null } });
  }

  colorChanged(field: string, color: string): void {
    if (this.menu.style[field] === color) return undefined;
    this.updateMenu.emit({
      style: {
        [field]: color,
      },
    });
  }

  selectedChange({ order }: { order: number }) {
    if (order && this.menu && this.menu.style) {
      this.updateMenu.emit({
        style: {
          style_variant: order,
        },
      });
    }
  }

  getObjectSize = (obj: any) => Object.keys(obj).length;

  patchStyleCustomData(data: any) {
    this.updateMenu.emit({
      style: {
        custom_data: data,
      },
    });
  }
}
