import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { Subscription } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { StopPropagationDirective } from '../../Directives/stop-propagation/stop-propagation.directive';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'grammar-check-button',
  templateUrl: './grammar-check-button.component.html',
  styleUrls: ['./grammar-check-button.component.scss'],
  standalone: true,
  imports: [MatButtonModule, StopPropagationDirective, MatIconModule],
})
export class GrammarCheckButtonComponent implements OnChanges {
  @Input() fontSize: string;
  @Input() value: string;
  @Output() clicked = new EventEmitter<Event>();

  leftOffset = 0;
  subscription: Subscription;

  constructor(private utils: UtilsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.calcLeftOffset();
    }
  }

  calcLeftOffset(): void {
    this.leftOffset = this.value
      ? this.utils.calcWidthOfString(this.value, this.fontSize)
      : 0;
  }
}
