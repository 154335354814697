import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { OnboardingTemplate } from '../../../Models/onboarding_template';
import { MtTooltipDirective } from '../../../Directives/mt-tooltip/mt-tooltip.directive';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'write-new-menu-item',
  templateUrl: './write-new-menu-item.component.html',
  styleUrls: ['./write-new-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIconModule, MatMenuModule, MtTooltipDirective],
})
export class WriteNewMenuItemComponent {
  @Input() disabled: boolean;
  @Input() lang: InterfaceLanguage;
  @Input() template: OnboardingTemplate;
  @Input() statusMessage: string;
  @Output() showDatepicker = new EventEmitter<OnboardingTemplate>();
}
