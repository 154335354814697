<div class="menudish-overview-view simple-theme">
  <go-back
    class="sidepanel-go-back"
    [text]="'write.blocks.dish-overview.go-back' | transloco"
    (clicked)="clearSelectedDish.emit()"
  ></go-back>
  <side-panel-header
    class="side-panel-header"
    [lang]="lang"
    [menuDish]="dish"
  ></side-panel-header>
  <mat-tab-group [dynamicHeight]="true">
    <mat-tab
      [label]="
        translations['write.blocks.dish-overview.title.' + category] ||
        ('write.blocks.dish-overview.title.' + category | transloco)
      "
    >
      <ng-template matTabContent>
        <div class="tab-padding">
          @if (!dish) {
            <spinner></spinner>
          } @else {
            <item-tab
              [aiCreditsRemaining]="aiCreditsRemaining"
              [aiAllergensLoading]="aiAllergensLoading"
              [aiDescriptionLoading]="aiDescriptionLoading"
              [backgroundImages]="backgroundImages"
              [currencySymbol]="currencySymbol"
              [dish]="dish"
              [lang]="lang"
              [interfaceLang]="interfaceLang"
              [isTrial]="isTrial"
              [profileComplete]="profileComplete"
              [recipes]="recipes"
              [translations]="translations"
              [userSettings]="userSettings"
              (uploadBackgroundImage)="uploadBackgroundImage.emit($event)"
              (showAllBackgrounds)="
                showAllBackgrounds.emit({ menudish: false, dish: this.dish })
              "
              (fetchDishRecipes)="fetchDishRecipes.emit($event)"
              (changeField)="changeItemField.emit($event)"
              (changeOption)="optionChanged.emit($event)"
              (loadMoreDishes)="loadMoreDishes.emit($event)"
              (modalOpened)="modalOpened.emit($event)"
              (synchroniseRecipeDeclarations)="
                synchroniseRecipeDeclarations.emit($event)
              "
              (uploadDishImage)="uploadDishImage.emit($event)"
              (addIngredientEvent)="addIngredientEvent.emit($event)"
              (deleteIngredientEvent)="deleteIngredientEvent.emit($event)"
              (searchIngredients)="searchIngredients.emit($event)"
              (selectedIngredientEvent)="selectedIngredientEvent.emit($event)"
              (createIngredientEvent)="createIngredientEvent.emit($event)"
              (updateRecipeIngredientEvent)="
                updateRecipeIngredientEvent.emit($event)
              "
              (updateIngredientEvent)="updateIngredientEvent.emit($event)"
              (generateAiAllergens)="
                generateAiAllergens.emit(dish?.dish_detail)
              "
              (generateAiDescription)="
                generateAiDescription.emit(dish?.dish_detail)
              "
            >
            </item-tab>
          }
        </div>
      </ng-template>
    </mat-tab>
    @if (dish?.dish_detail?.category === "dis" && hasModule("recip")) {
      <mat-tab
        [label]="
          translations['write.blocks.dish-overview.recipes.title'] ||
          ('write.blocks.dish-overview.recipes.title' | transloco)
        "
      >
        <ng-template matTabContent>
          <div class="tab-padding">
            @if (!dish) {
              <spinner></spinner>
            } @else {
              <recipes-tab
                [dish]="dish?.dish_detail"
                [lang]="lang"
                [aiCreditsRemaining]="aiCreditsRemaining"
                [aiRecipesLoading]="aiRecipesLoading"
                [autoRecipes]="autoRecipes"
                [isTrial]="isTrial"
                [profileComplete]="profileComplete"
                [translations]="translations"
                (addRecipe)="addRecipe.emit($event)"
                (createRecipe)="createRecipe.emit($event)"
                (fetchDishRecipes)="fetchDishRecipes.emit($event)"
                (refreshDish)="refreshDish.emit(dish)"
                (removeRecipe)="removeRecipe.emit($event)"
                (searchRecipe)="searchRecipe.emit($event)"
                (patchRecipe)="patchRecipe.emit($event)"
                (generateAiRecipes)="generateAiRecipes.emit(dish?.dish_detail)"
              >
              </recipes-tab>
            }
          </div>
        </ng-template>
      </mat-tab>
    }
    <mat-tab
      [label]="
        translations['write.blocks.dish-overview.other-tab.title'] ||
        ('write.blocks.dish-overview.other-tab.title' | transloco)
      "
    >
      <ng-template matTabContent>
        <div class="tab-padding">
          @if (!dish) {
            <spinner></spinner>
          } @else {
            <other-tab
              [currencySymbol]="currencySymbol"
              [dish]="dish"
              [lang]="lang"
              [translations]="translations"
              [backgroundImages]="backgroundImages"
              (uploadBackgroundImage)="
                uploadBackgroundImageOtherTab.emit($event)
              "
              (showAllBackgrounds)="
                showAllBackgrounds.emit({ menudish: true, dish: this.dish })
              "
              (changeField)="changeMenudishField.emit($event)"
            >
            </other-tab>
          }
        </div>
      </ng-template>
    </mat-tab>
    @if (showGrammar && spellcheckItem) {
      <mat-tab [label]="'dishes.grammar-list.title' | transloco">
        <ng-template matTabContent>
          @if (!dish) {
            <spinner></spinner>
          } @else {
            <grammar-corrections
              class="grammar-corrections"
              [attr.dir]="rtl ? 'rtl' : 'ltr'"
              [spellcheck]="spellcheckItem"
              (addWord)="addWord.emit($event)"
              (ignoreSpellcheck)="ignoreWord.emit()"
            ></grammar-corrections>
          }
        </ng-template>
      </mat-tab>
    }
  </mat-tab-group>
</div>
