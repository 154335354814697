<div matDialogTitle>
  <h2>
    {{ "write.ai.dialog.here-is" | transloco }}
    <strong>{{
      "write.ai.analysis-types." + data.analysis_type + ".title" | transloco
    }}</strong>
    {{ "write.ai.dialog.analysis" | transloco }}
    <small class="subtitle"
      >{{ "write.ai.credits.you-now" | transloco }}
      {{ data.remaining_credits }}/{{ data.credits }}
      {{ "write.ai.credits.remaining" | transloco }}</small
    >
  </h2>
</div>
<mat-dialog-content>
  <div class="panel">
    <div class="score mat-h1">
      <strong>{{ data.score }} / 10</strong>
    </div>
    <div class="score-assessment">{{ data.justification }}</div>
  </div>
  <div class="recommendations">
    @for (rec of data.recommendations; track rec; let i = $index) {
      <div class="recommendation">
        <p class="recommendation-number mat-body-2">
          {{ "write.ai.dialog.suggested-change" | transloco }} #{{ i + 1 }}
        </p>
        <p class="recommendation-text mat-body-1">{{ rec }}</p>
      </div>
    }
  </div>
</mat-dialog-content>
<mat-dialog-actions class="actions">
  <button mat-button class="cancel" [mat-dialog-close]>
    {{ "shared.buttons.close" | transloco }}
  </button>
  <button
    mat-flat-button
    color="primary"
    class="download"
    (click)="data.download_analysis()"
  >
    {{ "write.ai.dialog.download" | transloco }}
  </button>
</mat-dialog-actions>
