import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-menus-counter',
  template: `
    <mat-card color="primary">
      <div class="content">
        <p>
          {{ 'dashboard.statistics.created_menus.title' | transloco }}
        </p>
        <p class="counter">
          {{ selectedFilter?.value }}
        </p>
      </div>
      <mat-card-footer>
        <mat-menu #appMenu="matMenu">
          @for (item of filters; track item) {
            <button mat-menu-item class="filter" (click)="changeFilter(item)">
              {{ item.field }}
            </button>
          }
        </mat-menu>
        <hr />
        <button
          mat-flat-button
          color="primary"
          class="button"
          [matMenuTriggerFor]="appMenu"
        >
          <span class="element-action"
            ><mat-icon class="icon">date_range</mat-icon>
            {{ selectedFilter?.field }}</span
          >
        </button>
      </mat-card-footer>
    </mat-card>
  `,
  styleUrls: [`./menus-counter.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatCardModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    TranslocoPipe,
  ],
})
export class MenusCounterComponent implements OnChanges {
  @Input() counter: {
    this_month: number;
    all_time: number;
    this_week: number;
    this_year: number;
  };

  filters: { field: string; value: number }[] = [];
  selectedFilter: { field: string; value: number };

  constructor(private translate: TranslocoService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('counter' in changes && this.counter) {
      this.translate
        .selectTranslateObject(`dashboard.statistics.created_menus.actions`)
        .subscribe((val) => {
          this.filters = Object.keys(val).map((v) => {
            return {
              field: val[v],
              value: this.counter[v],
            };
          });
          this.selectedFilter = this.filters[0];
        });
    }
  }

  changeFilter = (item: { field: string; value: number }) => {
    this.selectedFilter = item;
  };
}
