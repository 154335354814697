import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import {
  ContentLanguage,
  InterfaceLanguage,
  rtlLangs,
} from 'src/app/shared/constants/languages';
import { Dish, Spellcheck } from 'src/app/shared/Models/dish';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { Ingredient } from 'src/app/shared/Models/ingredients';
import { BackgroundImage } from 'src/app/shared/Models/menu';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { Fulfillable } from 'src/app/shared/Models/models';
import {
  Recipe,
  RecipeIngredient,
  RecipeParams,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';
import { Separator } from 'src/app/shared/Models/separator';
import { AiCreditsRemaining, User } from 'src/app/shared/Models/user';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { take } from 'rxjs/operators';
import { GrammarCorrectionsComponent } from '../../../../shared/Components/grammar-corrections/grammar-corrections.component';
import { OtherTabComponent } from './other-tab/other-tab.component';
import { RecipesTabComponent } from '../../../../shared/Components/recipes-tab/recipes-tab.component';
import { DishInformationComponent } from './item-tab/item-tab.component';
import { MatTabsModule } from '@angular/material/tabs';
import { SidePanelHeaderComponent } from '../../../../shared/Components/sidepanel/side-panel-header/side-panel-header.component';
import { GoBackComponent } from '../../../../shared/Components/sidepanel/go-back/go-back.component';
import { SpinnerComponent } from 'src/app/shared/Components/spinner/spinner.component';

@Component({
  selector: 'write-sidebar',
  templateUrl: './write-sidebar.component.html',
  styleUrls: ['./write-sidebar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    GoBackComponent,
    SidePanelHeaderComponent,
    MatTabsModule,
    DishInformationComponent,
    RecipesTabComponent,
    OtherTabComponent,
    GrammarCorrectionsComponent,
    SpinnerComponent,
    TranslocoPipe,
  ],
})
export class WriteSidebarComponent implements OnChanges {
  @Input() aiCreditsRemaining: AiCreditsRemaining;
  @Input() aiAllergensLoading: boolean;
  @Input() aiDescriptionLoading: boolean;
  @Input() aiRecipesLoading: boolean;
  @Input() autoRecipes: Recipe[];
  @Input() recipes: Recipe[];
  @Input() user: User;
  @Input() dish: MenuDish;
  @Input() refreshedDish: MenuDish;
  @Input() lang: ContentLanguage;
  @Input() userSettings: any;
  @Input() currencySymbol: string;
  @Input() similarDishes: Dish[];
  @Input() similarDishesAdditives: Dish[];
  @Input() numberOfDishes: number;
  @Input() numberOfDishesAdditives: number;
  @Input() interfaceLang: InterfaceLanguage;
  @Input() backgroundImages: BackgroundImage[];
  @Input() spellcheckItem: Spellcheck;
  @Input() profileComplete: boolean;
  @Input() isTrial: boolean;

  @Output() addWord = new EventEmitter<string>();
  @Output() ignoreWord = new EventEmitter<void>();
  @Output() uploadDishImage = new EventEmitter<Fulfillable<FormData>>();
  @Output() otherChanged = new EventEmitter<Fulfillable<MenuDish>>();
  @Output() optionChanged = new EventEmitter<{
    data: Dish | Ingredient;
    type: 'allergens' | 'additives' | 'labels';
  }>();
  @Output() modalOpened = new EventEmitter<{
    item: Dish | Ingredient;
    type: 'allergens' | 'additives';
    contentLang: ContentLanguage;
  }>();
  @Output() loadMoreDishes = new EventEmitter<string>();
  @Output() refreshDish = new EventEmitter<MenuDish>();
  @Output() fetchDishRecipes = new EventEmitter<string>();
  @Output() createRecipe = new EventEmitter<{
    data: Partial<Recipe>;
    url: string;
  }>();
  @Output() patchRecipe = new EventEmitter<{
    url: string;
    payload: Partial<Recipe>;
    onFulfilled: Function;
  }>();
  @Output() searchRecipe = new EventEmitter<Partial<RecipeParams>>();
  @Output() addRecipe = new EventEmitter<{ dish: Dish; recipeId: number }>();
  @Output() removeRecipe = new EventEmitter<any>();
  @Output() changeItemField = new EventEmitter<{
    menuDish: MenuDish;
    data: DeepPartial<Dish | Separator> & { onFulfilled?: () => void };
  }>();
  @Output() changeMenudishField = new EventEmitter<{
    dish: MenuDish;
    data: DeepPartial<MenuDish> & { onFulfilled?: () => void };
  }>();
  @Output() synchroniseRecipeDeclarations = new EventEmitter<{
    dish: MenuDish;
    type: 'all' | 'add';
  }>();
  @Output() clearSelectedDish = new EventEmitter();
  @Output() uploadBackgroundImage = new EventEmitter<Fulfillable<File>>();
  @Output() uploadBackgroundImageOtherTab = new EventEmitter<
    Fulfillable<File>
  >();
  @Output() showAllBackgrounds = new EventEmitter<{
    menudish: boolean;
    dish: MenuDish;
  }>();
  @Output() addIngredientEvent = new EventEmitter<Recipe>();
  @Output() deleteIngredientEvent = new EventEmitter<{
    deletingIngredient: SimpleRecipeIngredient;
    recipe: Recipe;
  }>();
  @Output() searchIngredients = new EventEmitter<string>();
  @Output() selectedIngredientEvent = new EventEmitter<{
    ingredient_id: number;
    recipe: Recipe;
  }>();
  @Output() createIngredientEvent = new EventEmitter<{
    newIngredient: Partial<Recipe>;
    recipe: Recipe;
  }>();
  @Output() updateRecipeIngredientEvent = new EventEmitter<{
    recipe: Recipe;
    updatedIngredient: {
      url: string;
      recipeIngredient: Partial<RecipeIngredient>;
      onFulfilled: Function;
    };
  }>();
  @Output() fetchSpellcheck = new EventEmitter<number>();
  @Output() updateIngredientEvent = new EventEmitter<{
    recipe: Recipe;
    updatedIngredient: {
      ingredient: Partial<Ingredient>;
      recipeIngredient: RecipeIngredient;
    };
  }>();
  @Output() generateAiAllergens = new EventEmitter<Dish>();
  @Output() generateAiDescription = new EventEmitter<Dish>();
  @Output() generateAiRecipes = new EventEmitter<Dish>();

  allDishesAllergensNumber = 0;
  restAllergens = 0;
  allDishesAdditivesNumber = 0;
  restAdditives = 0;
  category = 'dish';
  private categories = {
    dis: 'dish',
    win: 'wine',
    sec: 'section',
    dow: 'day',
    bev: 'beverage',
  };
  translations: any = {};
  rtl = false;
  showGrammar = false;

  constructor(
    private translate: TranslocoService,
    private utils: UtilsService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('lang' in changes && this.lang) {
      this.rtl = rtlLangs.includes(this.lang);
    }
    this.allDishesAllergensNumber = this.numberOfDishes - 1;
    this.restAllergens =
      this.allDishesAllergensNumber -
      (this.similarDishes ? this.similarDishes.length : 0);
    this.allDishesAdditivesNumber = this.numberOfDishesAdditives - 1;
    this.restAdditives =
      this.allDishesAdditivesNumber -
      (this.similarDishesAdditives ? this.similarDishesAdditives.length : 0);
    if ('dish' in changes && this.dish) {
      const details: Dish | Separator =
        this.dish.dish_detail || this.dish.separator_detail;
      this.category = this.categories[details.category];

      const spellcheckId = details?.[`spellcheck_` + this.lang];
      this.showGrammar = !!spellcheckId;
      if (this.showGrammar && changes.dish.previousValue?.id !== this.dish.id) {
        this.fetchSpellcheck.emit(spellcheckId);
      }
    }
    if ('interfaceLang' in changes && this.interfaceLang) {
      this.translate
        .selectTranslation(this.interfaceLang)
        .pipe(take(1))
        .subscribe((translations) => (this.translations = translations));
    }
  }

  hasModule = (code): boolean => this.utils.hasModules(code);
}
