import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuAnalysisType } from 'src/app/shared/Models/menu';
import { TranslocoPipe } from '@jsverse/transloco';
import { SpinnerComponent } from '../../../../../shared/Components/spinner/spinner.component';
import { MatButtonModule } from '@angular/material/button';
import { MtTooltipDirective } from '../../../../../shared/Directives/mt-tooltip/mt-tooltip.directive';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-menu-analysis-item',
  templateUrl: './menu-analysis-item.component.html',
  styleUrls: ['./menu-analysis-item.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MtTooltipDirective,
    MatButtonModule,
    SpinnerComponent,
    TranslocoPipe,
  ],
})
export class MenuAnalysisItemComponent {
  @Input() analysisType: MenuAnalysisType;
  @Input() aiAnalysisLoading: boolean;
  @Output() runAnalysis = new EventEmitter<void>();
}
