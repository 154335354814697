<div class="file-info">
  <span class="file-name">{{ file.name }}</span>
  <button
    mat-icon-button
    class="remove-file"
    (click)="control.removeFile(file)"
  >
    <mat-icon>delete</mat-icon>
  </button>
</div>
