import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { Diet } from 'src/app/shared/Models/diet';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'create-diet',
  templateUrl: './create-diet.component.html',
  styleUrls: [
    `../regenerate-menu/regenerate-menu.component.scss`,
    `./create-diet.component.scss`,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatButtonModule,
    TranslocoPipe,
  ],
})
export class CreateDietComponent implements OnChanges {
  @Input() diets: Diet[];
  @Input() lang: InterfaceLanguage;
  @Input() locationId: number;
  @Output() cancelAction = new EventEmitter<void>();
  @Output() createNewDiet = new EventEmitter<number[]>();

  dietIds = new FormControl<number[]>([]);
  filteredDiets: Diet[] = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('diets' in changes && this.diets) {
      this.filteredDiets = this.diets.filter(
        (d) => d.location === this.locationId,
      );
    }
  }
}
