import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  requestNewContent,
  sendOnboardingTemplates,
} from 'src/app/auth/ngrx/auth.actions';
import { State } from 'src/app/reducers';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { OnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import {
  isRegistrationOption,
  ObservablePortal,
  RegistrationOption,
} from 'src/app/shared/Models/registration-steps';
import { User } from 'src/app/shared/Models/user';
import { fetchTemplates } from 'src/app/shared/ngrx/shared.actions';
import { Subject } from 'rxjs';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RegistrationStepOptionComponent } from '../registration-step-option/registration-step-option.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NavigationButtonsComponent } from '../navigation-buttons/navigation-buttons.component';
import { MtTooltipDirective } from 'src/app/shared/Directives/mt-tooltip/mt-tooltip.directive';

@Component({
  selector: 'registration-step-two',
  templateUrl: './registration-step-two.component.html',
  styleUrls: [
    '../registration-step-one/registration-step-one.component.scss',
    './registration-step-two.component.scss',
  ],
  standalone: true,
  imports: [
    NavigationButtonsComponent,
    ReactiveFormsModule,
    FormsModule,
    RegistrationStepOptionComponent,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    TranslocoPipe,
    MtTooltipDirective,
  ],
})
export class RegistrationStepTwoComponent
  implements AfterViewInit, OnChanges, OnInit
{
  @Input() user: User;
  @Input() options: OnboardingTemplate[];
  @Input() lang: InterfaceLanguage;
  @Input() isSafari: boolean;

  @Output() buttonPortal = new EventEmitter<ObservablePortal>();

  @ViewChild('button', { static: true }) buttonPortalContent: TemplateRef<any>;

  clickEventsStream = new Subject<true | undefined>();
  enableNextStep = false;
  other: RegistrationOption = {
    icon: 'question_answer',
    other: true,
  };
  titleParams: { firstName: string };

  constructor(
    private viewContainerRef: ViewContainerRef,
    protected ngrxStore: Store<State>,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('user' in changes && !changes.user.firstChange && this.user) {
      this.ngrxStore.dispatch(fetchTemplates());
    }
  }

  ngOnInit(): void {
    this.titleParams = { firstName: this.user.first_name };
  }

  ngAfterViewInit(): void {
    this.buttonPortal.emit({
      portal: new TemplatePortal(
        this.buttonPortalContent,
        this.viewContainerRef,
      ),
      eventStream: this.clickEventsStream.asObservable(),
    });
  }

  chooseOption(
    option: RegistrationOption | OnboardingTemplate,
    forceState?: boolean,
  ): void {
    if (isRegistrationOption(option)) {
      this.options.forEach((opt) => {
        opt.active = opt.active || false;
      });
    } else {
      this.other.active = false;
    }
    if (forceState !== undefined) {
      option.active = forceState;
    } else {
      option.active = !option.active;
    }
    this.validateStep();
  }

  validateStep(): void {
    const selected = this.options.filter((opt) => opt.active);
    this.enableNextStep = !!(
      (selected && selected.length >= 2) ||
      (this.other.active && this.other.payload)
    );
  }

  next(): void {
    if (this.other.active) {
      this.ngrxStore.dispatch(
        requestNewContent({
          message: this.other.payload,
          content: 'template',
          user: this.user,
        }),
      );
    } else {
      this.ngrxStore.dispatch(
        sendOnboardingTemplates({
          data: {
            items: this.options
              .filter((opt) => opt.active)
              .map((opt) => opt.id),
          },
        }),
      );
    }

    this.clickEventsStream.next(undefined);
  }

  prev(): void {
    this.clickEventsStream.next(true);
  }
}
