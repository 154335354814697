import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { Menu } from 'src/app/shared/Models/menu';
import { OnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { GroupByPipe } from '../../../../shared/Pipes/group-by.pipe';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MtTooltipDirective } from '../../../../shared/Directives/mt-tooltip/mt-tooltip.directive';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'select-template',
  templateUrl: './select-template.component.html',
  styleUrls: ['./select-template.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MtTooltipDirective,
    MatCardModule,
    MatButtonModule,
    MatMenuModule,
    MatOptionModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslocoPipe,
    GroupByPipe,
  ],
})
export class SelectTemplateComponent implements OnChanges, OnInit, OnDestroy {
  @Input() isTrial: boolean;
  @Input() lang: InterfaceLanguage;
  @Input() menu: Menu;
  @Input() onboardingTemplates: OnboardingTemplate[];
  @Input() profileComplete: boolean;

  @Output() createNewTemplateDialog = new EventEmitter();
  @Output() deleteTemplate = new EventEmitter<{
    url: string;
    id: number;
  }>();
  @Output() editTemplateDialog = new EventEmitter<{
    templateDetail: OnboardingTemplate;
  }>();
  @Output() linkMenuToTemplate = new EventEmitter<number>();
  @Output() unlink = new EventEmitter();
  @Output() updateStyleAndLayout = new EventEmitter();
  @Output() updateTemplateDetail = new EventEmitter<
    Partial<OnboardingTemplate>
  >();

  nameControl = new UntypedFormControl('', {
    updateOn: 'blur',
  });
  private destroyed$ = new Subject<void>();

  constructor(private utils: UtilsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      'menu' in changes &&
      this.menu &&
      this.menu.onboarding_template_detail
    ) {
      this.nameControl.setValue(
        this.menu.onboarding_template_detail[this.lang],
        { emitEvent: false },
      );
    }
  }

  ngOnInit(): void {
    this.nameControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe((newName) => this.updateOnboardingTemplateDetail(newName));
  }

  updateOnboardingTemplateDetail(updatedTemplateData) {
    this.updateTemplateDetail.emit({ [this.lang]: updatedTemplateData });
  }

  openEditTemplateDialog() {
    this.editTemplateDialog.emit({
      templateDetail: this.menu.onboarding_template_detail,
    });
  }

  createTemplate(): void {
    if (this.isTrial && !this.profileComplete) {
      this.utils.showTrialBlockedBox();
    } else {
      this.createNewTemplateDialog.emit();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
