import { Component, Input } from '@angular/core';
import { SpinnerComponent } from '../spinner/spinner.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'content-block',
  templateUrl: './content-block.component.html',
  styleUrls: ['./content-block.component.css'],
  standalone: true,
  imports: [MatCardModule, MatIconModule, SpinnerComponent],
})
export class ContentBlockComponent {
  @Input() color = '#009fed';
  @Input() icon: string;
  @Input() title: string;
  @Input() mainTitle: string;
  @Input() secondTitle: string;
  @Input() hideTitle = false;

  @Input() translations = false;
  @Input() loading = false;
}
