// import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { EventDefinition, EventsMap } from 'redux-beacon';
import {
  notifyAcceptInvitationSuccess,
  notifyEmailConfirmationSuccess,
  notifyLoginSuccess,
  notifyRegistrationSuccess,
} from 'src/app/auth/ngrx/auth.actions';
import { Payment } from 'src/app/shared/Models/user';
import { State } from './index';
import {
  purchaseCompleted,
  startCheckout,
} from 'src/app/settings/billing/ngrx/billing.actions';
import {
  createFirstDishSuccess,
  createFirstMenuSuccess,
  completeMenuWriteTutorial,
} from 'src/app/menus/menu-edit/ngrx/menu-edit.actions';
import { settingsFeatureKey } from 'src/app/settings/ngrx/settings.store';
import { billingFeatureKey } from 'src/app/settings/billing/ngrx/billing.reducer';

const registerEvent: EventDefinition = (
  action: { [key: string]: any },
  prevState: State,
  nextState: State,
) => ({
  event: 'sign_up',
  method: 'email',
  category: 'authentication',
});

const acceptInvitationEvent: EventDefinition = (
  action: { [key: string]: any },
  prevState: State,
  nextState: State,
) => ({
  event: 'join_group',
  method: 'email',
  category: 'authentication',
});

const loginEvent: EventDefinition = (
  action: { [key: string]: any },
  prevState: State,
  nextState: State,
) => ({
  event: 'login',
  method: 'email',
  category: 'authentication',
});

const emailConfirmationEvent: EventDefinition = (
  action: { [key: string]: any },
  prevState: State,
  nextState: State,
) => ({
  event: 'email_confirmed',
  category: 'authentication',
});

const startCheckoutEvent: EventDefinition = (
  action: { [key: string]: any },
  prevState: State,
  nextState: State,
) => ({
  event: 'begin_checkout',
  category: 'conversion',
  currency:
    nextState[settingsFeatureKey][billingFeatureKey].subscription
      .subscription_currency,
  value: 0,
  coupon:
    nextState[settingsFeatureKey][billingFeatureKey].subscription.discount_code,
  items: [],
});

const purchaseEvent: EventDefinition = (
  action: { payload: Payment },
  prevState: State,
  nextState: State,
) => ({
  event: 'purchase',
  category: 'conversion',
  currency:
    nextState[settingsFeatureKey][billingFeatureKey].subscription
      .subscription_currency,
  transaction_id:
    nextState[settingsFeatureKey][billingFeatureKey].subscription
      .subscription_created_at,
  value: +(
    (nextState[settingsFeatureKey][billingFeatureKey].subscription
      .subscription_next_payment_amount /
      100) *
    12
  ).toFixed(2),
  coupon:
    nextState[settingsFeatureKey][billingFeatureKey].subscription.discount_code,
  method: action.payload.payment_method_name,
  items: [
    {
      item_id: action.payload.subscription_plan_detail.id,
      item_name: action.payload.subscription_plan_detail.en,
    },
    ...action.payload.modules_detail.map((mod) => ({
      item_id: mod.id,
      item_name: mod.en,
    })),
  ],
});

const createFirstMenuEvent: EventDefinition = (
  action: { [key: string]: any },
  prevState: State,
  nextState: State,
) => ({
  event: 'create_content',
  category: 'engagement',
  type: 'menu',
});

const createFirstDishEvent: EventDefinition = (
  action: { [key: string]: any },
  prevState: State,
  nextState: State,
) => ({
  event: 'create_content',
  category: 'engagement',
  type: 'dish',
});

const completeMenuWriteTutorialEvent: EventDefinition = (
  action: { [key: string]: any },
  prevState: State,
  nextState: State,
) => ({
  event: 'complete_tutorial',
  category: 'engagement',
  type: 'menu_write',
});

/**
 * Method from @redux-beacon/google-analytics
 */
export const trackPageView =
  <A = { [key: string]: any }, S = any>(
    eventDef: EventDefinition<
      {
        page: string;
        title?: string;
        location?: string;
      },
      A,
      S
    >,
    tracker?: string[] | string,
  ): EventDefinition<any, A, S> =>
  (action, prevState, nextState) => {
    const event = eventDef(action, prevState, nextState);
    const { page, title, location } = event;

    return {
      hitType: 'pageview',
      customTrackerId: tracker,
      page,
      title,
      location,
    };
  };

export const eventsMap: EventsMap = {
  // NOTE: sending pageviews is not required when GA4 Enhanced Measurement is enabled
  // [ROUTER_NAVIGATION]: trackPageView((action) => ({
  //   page: action.payload.event.url,
  // })),
  [notifyRegistrationSuccess.type]: registerEvent,
  [notifyEmailConfirmationSuccess.type]: emailConfirmationEvent,
  [notifyLoginSuccess.type]: loginEvent,
  [notifyAcceptInvitationSuccess.type]: acceptInvitationEvent,
  [startCheckout.type]: startCheckoutEvent,
  [purchaseCompleted.type]: purchaseEvent,
  [createFirstMenuSuccess.type]: createFirstMenuEvent,
  [createFirstDishSuccess.type]: createFirstDishEvent,
  [completeMenuWriteTutorial.type]: completeMenuWriteTutorialEvent,
};
