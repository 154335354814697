import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { TranslocoPipe } from '@jsverse/transloco';
import {
  ReactiveFormsModule,
  FormsModule,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Dish } from 'src/app/shared/Models/dish';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { MatProgressBar } from '@angular/material/progress-bar';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wine-information',
  templateUrl: './wine-information.component.html',
  styleUrls: ['./wine-information.component.css'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatProgressBar,
    ReactiveFormsModule,
    FormsModule,
    TranslocoPipe,
  ],
})
export class WineInformationComponent implements OnChanges, OnInit {
  @Input() dish: MenuDish;
  @Input() lang: ContentLanguage;
  @Output() changeDish = new EventEmitter<{
    menuDish: MenuDish;
    data: DeepPartial<Dish> & { onFulfilled?: () => void };
  }>();
  @Input() translations: object = {};

  public maxYear: number;
  public minYear: number;

  constructor(private destroyRef: DestroyRef) {
    this.minYear = 1850;
    this.maxYear = new Date().getFullYear();
  }

  currentMenuDish: MenuDish;
  form = new FormGroup({
    winery: new FormControl<string>(null),
    vintage: new FormControl<number>(null),
  });
  loading = {
    winery: false,
    vintage: false,
  };

  ngOnChanges(changes: SimpleChanges): void {
    if ('dish' in changes && this.dish) {
      Object.keys(this.loading).forEach((key) => {
        this.loading[key] = false;
      });
      if (changes.dish.currentValue.id !== changes.dish.previousValue?.id) {
        this.form.reset(null, { emitEvent: false });
        this.form.patchValue(
          {
            winery: this.dish.dish_detail.winery,
            vintage: this.dish.dish_detail.vintage,
          },
          { emitEvent: false },
        );
      }
    }
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        tap(() => {
          this.currentMenuDish = this.dish;
        }),
        distinctUntilChanged(),
        debounceTime(400),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value) => this.onValueChange(value));
  }

  onValueChange(value: typeof this.form.value): void {
    // If the values are the same, return null
    if (
      this.currentMenuDish?.dish_detail.vintage === value.vintage &&
      this.currentMenuDish?.dish_detail.winery === value.winery
    ) {
      return null;
    }

    // Set the data to update and set the loading state
    const fieldsUpdated = [];
    const fieldsToUpdate = ['winery', 'vintage'];
    fieldsToUpdate.forEach((field) => {
      if (value[field] !== this.currentMenuDish.dish_detail[field]) {
        this.loading[field] = true;
        fieldsUpdated.push(field);
      }
    });

    // Selectively update the currentMenuDish with the new data
    const newDish = new Dish({
      ...this.currentMenuDish.dish_detail,
      winery: value.winery,
      vintage: value.vintage,
    });
    this.currentMenuDish = new MenuDish(this.currentMenuDish).setDish(newDish);

    // Emit the changeDish event
    this.changeDish.emit({
      menuDish: this.currentMenuDish,
      data: {
        [this.lang]: this.currentMenuDish.dish_detail[this.lang],
        ...value,
        onFulfilled: () => {
          Object.keys(fieldsUpdated).forEach((key) => {
            this.loading[key] = false;
          });
        },
      },
    });
  }
}
