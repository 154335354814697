import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { Menu, MenuBackup, MenuPreviewData } from 'src/app/shared/Models/menu';
import { TranslocoPipe } from '@jsverse/transloco';
import { DatePipe } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'backup-menu',
  templateUrl: './backup-menu.component.html',
  styleUrls: ['../backup-menus.component.scss', './backup-menu.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatTooltipModule, DatePipe, TranslocoPipe],
})
export class BackupMenuComponent {
  @Input() backup: MenuBackup;
  @Input() blockDelete: boolean;
  @Input() blockRestore: boolean;
  @Input() lang: ContentLanguage;
  @Input() menu: Menu;

  @Output() delete = new EventEmitter<MenuBackup>();
  @Output() preview = new EventEmitter<MenuPreviewData>();
  @Output() restore = new EventEmitter<MenuBackup>();

  constructor() {}

  showPreview(): void {
    this.preview.emit({
      url: this.backup.preview,
      baseLanguage: this.backup.base_language,
      langs: this.menu.translations_list.map((lang) => ({
        lang,
        activated: lang === this.menu.base_language,
        order: lang === this.menu.base_language ? 0 : null,
      })),
      numberLanguages: this.menu.template_detail.number_languages,
      multiRequired: this.menu.template_detail.multilingual_required,
      params: { base_lang: this.backup.base_language },
    });
  }
}
