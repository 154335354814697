import { createSelector } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { authFeatureKey } from './auth.reducer';

const selectState = (state: State) => state[authFeatureKey];

export const selectAffiliates = createSelector(
  selectState,
  (state) => state.affiliates,
);

export const selectAffiliatesError = createSelector(
  selectState,
  (state) => state.affiliates_error,
);

export const selectAuthErrorState = createSelector(
  selectState,
  (state) => state.show_error,
);

export const selectEmailPreferencesErrorState = createSelector(
  selectState,
  (state) => state.email_preferences_error,
);

export const selectEmailVerification = createSelector(
  selectState,
  (state) => state.email_verification,
);

export const selectEmailVerifiedState = createSelector(
  selectState,
  (state) => state.email_verified,
);

export const selectIsMagicLink = createSelector(
  selectState,
  (state) => state.magic_link,
);

export const selectLoginSpinnerState = createSelector(
  selectState,
  (state) => state.login_spinner,
);

export const selectLoginState = createSelector(
  selectState,
  (state) => state.logged_in,
);

export const selectNextPage = createSelector(
  selectState,
  (state) => state.next,
);

export const selectRegistrationSpinnerState = createSelector(
  selectState,
  (state) => state.register_spinner,
);

export const selectReactivationDone = createSelector(
  selectState,
  (state) => state.show_reactivation_done,
);

export const selectReactivationError = createSelector(
  selectState,
  (state) => state.show_reactivation_error,
);

export const selectResetPasswordSpinnerState = createSelector(
  selectState,
  (state) => state.reset_spinner,
);

export const selectAcceptButtonSpinnerState = createSelector(
  selectState,
  (state) => state.accept_button_spinner,
);

export const selectInvitee = createSelector(
  selectState,
  (state) => state.invitee,
);

export const selectInviteeSpinnerState = createSelector(
  selectState,
  (state) => state.invitee_spinner,
);

export const selectExistingEmailError = createSelector(
  selectState,
  (state) => state.show_existing_email_error,
);
