import { createAction, props } from '@ngrx/store';
import { Invoice, InvoicesParams } from 'src/app/shared/Models/subscription';

export const fetchInvoices = createAction(
  '[Settings container / Billing tab / Invoices] Fetch invocies',
);

export const paramsChanged = createAction(
  '[Settings container / Billing tab / Invoices] Query params changed',
  props<{ params?: InvoicesParams }>(),
);

export const updateParams = createAction(
  '[Settings container / Billing tab / Invoices] Updating query params',
  props<{ paramsSlice: Partial<InvoicesParams> }>(),
);

export const setInvoices = createAction(
  '[Settings container / Billing tab / Invoices] Set fetched invoices',
  props<{ invoices: Invoice[] }>(),
);

export const setCurrentPageIndex = createAction(
  '[Settings container / Billing tab / Invoices] Set current page index',
  props<{ index: number }>(),
);

export const setInvoicesCount = createAction(
  '[Settings container / Billing tab / Invoices] Set invoices count',
  props<{ count: number }>(),
);

export const pageParamsChanged = createAction(
  '[Settings container / Billing tab / Invoices] Page index or page size were changed',
  props<{ pageIndex: number; pageSize: InvoicesParams['page_size'] }>(),
);

export const invoicesError = createAction(
  '[Settings container / Billing tab / Invoices] Invoiced error happened',
);
