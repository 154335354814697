import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { Dish } from 'src/app/shared/Models/dish';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgClass } from '@angular/common';

@Component({
  selector: 'same-allergens',
  templateUrl: './same-allergens.component.html',
  styleUrls: ['./same-allergens.component.css'],
  standalone: true,
  imports: [NgClass, MatButtonModule, MatIconModule],
})
export class SameAllergensComponent {
  @Input() dishes: Dish[];
  @Input() lang: ContentLanguage;
  @Input() type: string;

  @Output() copyDeclarations = new EventEmitter();

  constructor() {}

  apply = (dish: Dish) => this.copyDeclarations.emit(dish);
}
