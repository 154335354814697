import { createAction, props } from '@ngrx/store';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { Results } from 'src/app/shared/Models/generics';
import { Menu } from 'src/app/shared/Models/menu';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { BaseNameExtended } from 'src/app/shared/Models/models';
import { SimilarTypes, Translation } from 'src/app/shared/Models/translation';

export const clearCurrentMenuTranslations = createAction(
  `[Menu Translate container] Clear current menu translations`,
);

export const expressTranslateMenu = createAction(
  `[Menu Translate] Express translate menu`,
  props<{ menuId: number; menuTranslationId: number; params }>(),
);

export const setMenuTranslationLoading = createAction(
  `[Menu Translate] Set Menu translation loading`,
  props<{ loading: boolean }>(),
);

export const fetchMenuTranslations = createAction(
  `[Menu Translate] Fetch Menu translation`,
  props<{ menu: Menu }>(),
);

export const setMenuTranslations = createAction(
  `[Menu Translate] Set Menu translation locally`,
  props<{ translations: Translation[] }>(),
);

export const deleteMenuTranslation = createAction(
  `[Menu Translate] Delete Menu translation`,
  props<{ url: string }>(),
);

export const removeMenuTranslation = createAction(
  `[Menu Translate] Remove deleted menu translation locally`,
  props<{ url: string }>(),
);

export const createMenuTranslation = createAction(
  `[Menu Translate] Create menu translation`,
  props<{ lang: ContentLanguage }>(),
);

export const addTranslationForLanguage = createAction(
  `[Menu Translate]  Add created menu translation locally`,
  props<{ translation: Translation }>(),
);

export const setCurrentTranslations = createAction(
  `[Menu Translate]  Set current translation`,
  props<{ menuDish: MenuDish }>(),
);

export const setCurrentTranslationLoading = createAction(
  `[Menu Translate]  Set current translation loading`,
  props<{ loading: boolean }>(),
);

export const setSimilar = createAction(
  `[Menu Translate] Set similar translations`,
  props<{ result: Results<BaseNameExtended> }>(),
);

export const setMoreSimilar = createAction(
  `[Menu Translate]  Set more similar translations`,
  props<{ result: Results<BaseNameExtended> }>(),
);

export const fetchSimilarTranslations = createAction(
  `[Menu Translate]  Fetch similar translation`,
  props<{
    name: string;
    translationType: SimilarTypes;
    lang: ContentLanguage;
    currentLang: string;
  }>(),
);

export const fetchMoreSimilarTranslations = createAction(
  `[Menu Translate] Fetch more similar translations`,
);

export const setTranslationToMenuDish = createAction(
  `[Menu Translate]  Set translation to menu dish`,
  props<{
    dish: BaseNameExtended;
    lang: ContentLanguage;
    append?: boolean;
    isDescription?: boolean;
    isOther?: boolean;
    isSecondary?: boolean;
  }>(),
);
