<div class="languages">
  @for (lang of langButtons; track lang) {
    <button
      class="lang-button"
      [class.activated]="lang.activated"
      [class.base]="lang.order === 0"
      [disabled]="!lang.activated && disable()"
      (click)="toggleLang(lang)"
      mat-button
    >
      {{ lang.lang }}
    </button>
  }
</div>
