<div class="registration-page">
  <side-info class="side" [lang]="language.current" [login]="false"></side-info>
  <div class="wrapper">
    <div class="g-top-nav">
      <language-selector
        class="lang"
        [model]="language"
        [simple]="true"
        [left]="true"
        [longLang]="true"
        changeValue="current"
        (changed)="changeLanguage()"
      ></language-selector>
      <div class="btn-wrapper">
        <div class="trial">
          <span class="trial-text"
            >{{ "auth.registration.login" | transloco }}</span
          >
          <button class="trial-button" mat-button>
            <a routerLink="/login" [queryParams]="{lang: language?.current}"
              >{{ "auth.registration.login-button" | transloco }}</a
            >
          </button>
        </div>
      </div>
    </div>
    <div class="auth-bar">
      @if (!(reactivation || restore)) {
      <div class="trial-period">
        {{ "auth.registration.register_trial" | transloco }}
      </div>
      } @if (affiliationLogo) {
      <div class="affiliate-logo">
        <img [src]="affiliationLogo" [alt]="affiliationTitle" />
      </div>
      } @if (!(reactivation || restore)) {
      <h1 [class.affiliation]="affiliationTitle" class="registration-title">
        {{ affiliationTitle ? ("auth.registration.affiliation-title" |
        transloco: {name: affiliationTitle}): ("auth.registration.title" |
        transloco) }}
      </h1>
      } @if (reactivation || restore) {
      <reactivation
        [error]="reactivationError$ | async"
        [done]="reactivationDone$ | async"
        [user]="user$ | async"
      >
      </reactivation>
      } @else {
      <registration
        [lang]="language.current"
        [emailVerification]="emailVerification$ | async"
        [affiliate]="affiliate"
        [isEmailExist]="isEmailExist$ | async"
        [registerButtonState]="registerButtonState$ | async"
      ></registration>
      }
    </div>
    <app-footer [lang]="language" [backgroundColor]="'white'"></app-footer>
  </div>
</div>
