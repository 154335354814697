<div class="navbar">
  <mat-icon aria-hidden="true" class="burger" (click)="toggle.emit()"
    >dehaze</mat-icon
  >
  @if (title) {
    <div class="title-container">
      <h1 class="title">{{ title }}</h1>
    </div>
  }
  <ng-content></ng-content>
  <ng-container #container></ng-container>
</div>
