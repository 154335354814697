import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { State } from 'src/app/reducers';
import { ObservablePortal } from 'src/app/shared/Models/registration-steps';
import { User } from 'src/app/shared/Models/user';
import { interval, Observable, Subject } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { completeSetup } from 'src/app/auth/ngrx/auth.actions';
import { TranslocoPipe } from '@jsverse/transloco';
import { AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'registration-step-three',
  templateUrl: './registration-step-three.component.html',
  styleUrls: [
    '../registration-step-one/registration-step-one.component.scss',
    './registration-step-three.component.scss',
  ],
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class RegistrationStepThreeComponent implements AfterViewInit, OnInit {
  @Input() user: User;

  @Output() buttonPortal = new EventEmitter<ObservablePortal>();
  @Output() doneEvent = new EventEmitter();

  @ViewChild('button', { static: true }) buttonPortalContent: TemplateRef<any>;

  titleParams: { firstName: string };
  clickEventsStream = new Subject<true | undefined>();
  enableNextStep = false;
  enableNextStep$ = new Subject<boolean>();
  values$: Observable<number>;

  constructor(
    private ngrxStore: Store<State>,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
  ) {}

  ngAfterViewInit(): void {
    this.buttonPortal.emit({
      portal: new TemplatePortal(
        this.buttonPortalContent,
        this.viewContainerRef,
      ),
      eventStream: this.clickEventsStream.asObservable(),
    });
  }

  ngOnInit(): void {
    let delay = 100;
    this.titleParams = { firstName: this.user.first_name };
    if (this.user && !this.user?.status?.completed_setup) {
      this.ngrxStore.dispatch(completeSetup());
    } else {
      delay = 50;
    }
    this.values$ = interval(delay).pipe(
      take(101),
      finalize(() => this.finalizeCounter(this.user?.status?.completed_setup)),
      takeUntil(this.enableNextStep$),
    );
    if (
      this.user?.status?.email_verified &&
      this.user?.status?.completed_setup
    ) {
      this.enableNextStep = true;
      this.enableNextStep$.next(this.enableNextStep);
      this.enableNextStep$.complete();
    }
  }

  finalizeCounter(redirect = false): void {
    this.enableNextStep = true;
    this.doneEvent.emit();
    if (redirect) {
      this.router.navigate(['/']);
    }
  }
}
