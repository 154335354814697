<mat-form-field class="select">
  <mat-select
    (selectionChange)="handleChange($event)"
    name="currency"
    [value]="model"
    [disabled]="!model"
  >
    @for (currency of currencies; track currency) {
      <mat-option [value]="currency" stop-propagation>
        {{ currency }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
