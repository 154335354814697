import { Component, Input, OnChanges } from '@angular/core';
import {
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { Menu } from 'src/app/shared/Models/menu';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'print-instruction',
  templateUrl: './print-instruction.component.html',
  styleUrls: ['./print-instruction.component.css'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButtonModule,
    MatDialogClose,
    TranslocoPipe,
  ],
})
export class PrintInstructionComponent implements OnChanges {
  instructions = [];
  currentIns;
  prevValue;

  @Input() menu: Menu;

  constructor(
    public dialog: MatDialogRef<PrintInstructionComponent>,
    private utils: UtilsService,
  ) {
    this.utils.getTranslationObject(
      'menus.complete.instructions.steps',
      (el) => {
        let inst = [];
        for (const step in el) {
          inst = [...inst, el[step]];
        }
        this.instructions = inst;
        this.currentIns = this.instructions.slice(0, 3);
      },
    );
  }

  ngOnChanges(changes: any) {
    this.handleChanges();
  }

  handleChanges() {
    if (!this.menu || !this.menu.style) return undefined;
    if (this.instructions && this.instructions.length > 0) {
      this.currentIns = [
        ...this.currentIns,
        this.menu.style.paper_width > this.menu.style.paper_height
          ? this.instructions[3]
          : this.instructions[5],
      ];
    }
  }
}
