import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslocoPipe } from '@jsverse/transloco';
import { FeedbackComponent } from '../../../../shared/Components/feedback/feedback.component';

@Component({
  selector: 'delete-feedback',
  templateUrl: './delete-feedback.component.html',
  styleUrls: ['./delete-feedback.component.css'],
  standalone: true,
  imports: [FeedbackComponent, TranslocoPipe],
})
export class DeleteFeedbackComponent {
  @Input() showEmail = false;

  constructor(private dialog: MatDialogRef<DeleteFeedbackComponent>) {}

  close = (): void => this.dialog.close();
}
