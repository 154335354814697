<div class="confirm-error">
  <h1 class="title">
    {{ "auth.email_confirmation.error.title" | transloco }}
  </h1>
  <p>
    {{ "auth.email_confirmation.error.problem" | transloco }} <br />{{
      "auth.email_confirmation.error.solve" | transloco
    }}
  </p>
  <hr />
  <p class="note">
    {{ "auth.email_confirmation.error.not_working" | transloco }}
    <a [href]="'mailto:' + supportEmail">{{
      "auth.email_confirmation.error.link" | transloco
    }}</a>
    {{ "auth.email_confirmation.error.link_description" | transloco }}
  </p>
</div>
