@if (!showSpinner) {
  <span
    ><mat-icon
      [class.upload-icon-mini]="control?.size && control?.valid"
      class="file-upload-icon"
      >file_upload</mat-icon
    >
    @if (!control?.size || !control?.valid) {
      <span class="file-upload-text" [innerHTML]="placeholder"> </span>
    }
    @if (!control?.size || !control?.valid) {
      <div class="formats">
        {{ formats }}
      </div>
    }
  </span>
} @else {
  <mat-spinner
    class="spinner"
    color="primary"
    (click)="$event.stopPropagation()"
  ></mat-spinner>
}
