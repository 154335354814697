import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { Language } from 'src/app/shared/Models/models';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { setInterfaceLang } from 'src/app/shared/ngrx/shared.actions';
import { FooterComponent } from '../../../shared/Components/footer/footer.component';
import { ResetPasswordConfirmComponent } from '../../Components/reset-password-confirm/reset-password-confirm.component';
import { ResetPasswordComponent } from '../../Components/reset-password/reset-password.component';
import { MatButtonModule } from '@angular/material/button';
import { LanguageSelectorComponent } from '../../../shared/Components/language-selector/language-selector.component';
import { SideInfoComponent } from '../../../shared/Components/side-info/side-info.component';

@Component({
  selector: 'reset-password-container',
  templateUrl: './reset-password.container.html',
  styleUrls: ['./reset-password.container.css'],
  standalone: true,
  imports: [
    SideInfoComponent,
    LanguageSelectorComponent,
    MatButtonModule,
    RouterLink,
    ResetPasswordComponent,
    ResetPasswordConfirmComponent,
    FooterComponent,
    TranslocoPipe,
  ],
})
export class ResetPasswordContainer implements OnInit {
  uid: string;
  token: string;
  language: { current: InterfaceLanguage } = { current: 'en' };

  constructor(
    private activatedRoute: ActivatedRoute,
    private translate: TranslocoService,
    private ngrxStore: Store<State>,
  ) {
    translate.langChanges$.subscribe(
      (el) =>
        (this.language.current =
          translate.getActiveLang() as InterfaceLanguage),
    );
    translate.getActiveLang() &&
      (this.language.current = translate.getActiveLang() as InterfaceLanguage);
    this.activatedRoute.params.subscribe((params: any) => {
      this.uid = params['uid'];
      this.token = params['token'];
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(({ lang }) => {
      if (lang) {
        const l = Language(lang);
        this.translate.setActiveLang(l);
        this.ngrxStore.dispatch(setInterfaceLang({ lang: l }));
      }
    });
  }

  changeLanguage() {
    this.translate.setActiveLang(this.language.current);
    this.ngrxStore.dispatch(setInterfaceLang({ lang: this.language.current }));
  }
}
