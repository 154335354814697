@if (showPopover) {
  <div class="ml-er">
    <div class="close-er" (click)="close($event)">
      <button mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="ml-er-content">
      <ng-content> </ng-content>
    </div>
  </div>
}
