import { createAction, props } from '@ngrx/store';
import { Dish } from 'src/app/shared/Models/dish';
import { Results } from 'src/app/shared/Models/generics';
import {
  Ingredient,
  InventorySupplier,
} from 'src/app/shared/Models/ingredients';
import { Supplier, SupplierItem } from 'src/app/shared/Models/procurements';

export const fetchSuppliersAutocomplete = createAction(
  `[Suppliers tab] Fetch autocomplete suppliers`,
  props<{ name?: string }>(),
);

export const setSuppliersAutocomplete = createAction(
  `[Suppliers tab] Set autocomplete suppliers`,
  props<{ payload: Results<Supplier> }>(),
);

export const createSupplier = createAction(
  `[Suppliers tab] Create supplier`,
  props<{ sup: Partial<InventorySupplier> }>(),
);

export const deleteSupplier = createAction(
  `[Suppliers tab] Delete supplier`,
  props<{ sup: InventorySupplier }>(),
);

export const updateSupplier = createAction(
  `[Suppliers tab] Edit supplier`,
  props<{ sup: InventorySupplier }>(),
);

export const fetchSupplierItems = createAction(
  `[Suppliers tab] Fetch supplier items `,
  props<{ ingredient: Ingredient | Dish; dishSupplier?: boolean }>(),
);

export const setSupplierItems = createAction(
  `[Suppliers tab] Set supplier items locally`,
  props<{ data: SupplierItem[] }>(),
);

export const addEmptySupplierItem = createAction(
  `[Suppliers tab] Add empty supplier item`,
);

export const createSupplierItem = createAction(
  `[Suppliers tab] Create supplier item`,
  props<{ id: number; supplierItem: Partial<SupplierItem> }>(),
);

export const deleteSupplierItem = createAction(
  `[Suppliers tab] Delete supplier item`,
  props<{ supplierItem: SupplierItem }>(),
);

export const removeDeletedSupplierItem = createAction(
  `[Suppliers tab] Remove deleted supplier item locally`,
  props<{ supplierItem: SupplierItem }>(),
);

export const updateSupplierItem = createAction(
  `[Suppliers tab] Update supplier items`,
  props<{
    supplierItem: SupplierItem;
  }>(),
);

export const updatePreferredSupplierItem = createAction(
  `[Suppliers tab] Update preferred supplier`,
  props<{ supplierUrl: string }>(),
);

export const changeSupplierItem = createAction(
  `[Suppliers tab] Set updated supplier item locally`,
  props<{ id: number; supplierItem: SupplierItem }>(),
);
