import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Allergen } from 'src/app/shared/Models/declarations';
import {
  ContentLanguage,
  InterfaceLanguage,
} from 'src/app/shared/constants/languages';
import { Diet } from 'src/app/shared/Models/diet';
import {
  CopyAsOptions,
  CopyMenuOptions,
  GenerateArchiveOptions,
  MenusOverviewParams,
  OverviewMenu,
  ShareMenuDetail,
} from 'src/app/shared/Models/menu';
import { OnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import { SimpleLocation } from 'src/app/shared/Models/location';
import { SimpleUser, User } from 'src/app/shared/Models/user';
import { ShareMenuComponent } from './share-menu/share-menu.component';
import { RefreshSubMenuComponent } from './refresh-submenu/refresh-submenu.component';
import { RegenerateMenuComponent } from './regenerate-menu/regenerate-menu.component';
import { CopyWithOptionsComponent } from './copy-with-options/copy-with-options.component';
import { DeleteMenuComponent } from './delete-menu/delete-menu.component';
import { DeleteChildrenComponent } from './delete-children/delete-children.component';
import { GenerateArchiveComponent } from './generate-archive/generate-archive.component';
import { CreateDietComponent } from './create-diet/create-diet.component';
import { MenuFiltersComponent } from './menu-filters/menu-filters.component';

@Component({
  selector: 'menu-overview-sidebar',
  templateUrl: './menu-overview-sidebar.component.html',
  styleUrls: ['./menu-overview-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MenuFiltersComponent,
    CreateDietComponent,
    GenerateArchiveComponent,
    DeleteChildrenComponent,
    DeleteMenuComponent,
    CopyWithOptionsComponent,
    RegenerateMenuComponent,
    RefreshSubMenuComponent,
    ShareMenuComponent,
  ],
})
export class MenuOverviewSidebarComponent {
  @Input() actionName: string;
  @Input() allergens: Allergen[];
  @Input() currentLocation: number;
  @Input() deleteChildrenList: string[];
  @Input() deleteMenuWarning: string;
  @Input() diets: Diet[];
  @Input() hasModuleAllergens: boolean;
  @Input() hasModuleOrders: boolean;
  @Input() lang: InterfaceLanguage;
  @Input() languages: ContentLanguage[];
  @Input() locations: SimpleLocation[];
  @Input() menu: OverviewMenu;
  @Input() menuDate: string;
  @Input() menuName: string;
  @Input() organisationUsers: SimpleUser[];
  @Input() params: Partial<MenusOverviewParams> = {};
  @Input() showMenuFilter: boolean;
  @Input() templates: OnboardingTemplate[];
  @Input() user: User;

  @Output() cancelAction = new EventEmitter<void>();
  @Output() chooseFilter = new EventEmitter<Partial<MenusOverviewParams>>();
  @Output() copyAsAction = new EventEmitter<CopyAsOptions>();
  @Output() copyMenuWithOptions = new EventEmitter<CopyMenuOptions>();
  @Output() createArchive = new EventEmitter<GenerateArchiveOptions>();
  @Output() createNewDiet = new EventEmitter<number[]>();
  @Output() deleteChildrenMenu = new EventEmitter<void>();
  @Output() deleteSelectedMenu = new EventEmitter<void>();
  @Output() refreshSubMenuActions = new EventEmitter<void>();
  @Output() regenerateMenusActions = new EventEmitter<void>();
  @Output() shareMenuAction = new EventEmitter<ShareMenuDetail>();

  constructor() {}
}
