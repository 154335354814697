import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { Layout } from 'src/app/shared/Models/template';
import { Menu, StyleCategories } from 'src/app/shared/Models/menu';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { LayoutsWrapperComponent } from './layouts-wrapper/layouts-wrapper.component';
import { CategoriesTabsComponent } from '../categories-tabs/categories-tabs.component';

@Component({
  selector: 'select-layouts',
  templateUrl: './select-layouts.component.html',
  styleUrls: ['./select-layouts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CategoriesTabsComponent, LayoutsWrapperComponent],
})
export class SelectLayoutsComponent implements OnDestroy {
  @Input() categories: StyleCategories = [];
  @Input() eventsLayouts: Layout[];
  @Input() lang: InterfaceLanguage;
  @Input() menu: Menu;
  @Input() publicLayouts: Layout[];
  @Input() tabIndex: number;
  @Input() userLayouts: Layout[];
  @Output() selectLayoutEvent = new EventEmitter<number>();

  private destroyed$ = new Subject<void>();

  constructor(private utils: UtilsService) {}

  sendRequest(): void {
    this.utils.sendLayoutRequest.next(0);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
