@if (status?.description) {
  <div
    class="slidedown"
    [class.is-email]="isEmail"
    [class.is-mobile]="isMobile"
    [class]="status?.barBg"
  >
    @if (!status?.email) {
      <div class="text">
        {{ status?.description }}
        @if (status?.link) {
          <span class="link">
            <a (click)="statusAction(status.link)">{{
              "user.link" | transloco
            }}</a>
          </span>
        }
      </div>
    } @else {
      <span class="second-row" [innerHTML]="status?.email?.full"></span>
      <span [innerHTML]="status?.email?.additional"></span>
      <a class="link-action" (click)="statusAction(status?.email?.link1)">{{
        status?.email?.update
      }}</a>
    }
  </div>
}
