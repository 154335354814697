import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { Menu } from 'src/app/shared/Models/menu';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TranslocoPipe } from '@jsverse/transloco';
import { LockItemComponent } from '../lock-item/lock-item.component';
import { StopPropagationDirective } from '../../../../../shared/Directives/stop-propagation/stop-propagation.directive';
import { MtTooltipDirective } from '../../../../../shared/Directives/mt-tooltip/mt-tooltip.directive';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'number-item',
  templateUrl: './number-item.component.html',
  styleUrls: ['./number-item.component.css'],
  standalone: true,
  imports: [
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MtTooltipDirective,
    FormsModule,
    StopPropagationDirective,
    LockItemComponent,
    TranslocoPipe,
  ],
})
export class NumberItemComponent implements OnDestroy {
  @Input() disabled = false;
  @Input() disabledFields: string[] = [];
  @Input() field: string;
  @Input() hideLabel: boolean;
  @Input() lockedFields: string[];
  @Input() icon: string;
  @Input() narrow = false;
  @Input() type: 'number' | 'perc' | 'value' = 'number';
  @Input() unit = '';
  @Input() step = 1;
  @Input() min: number = null;
  @Input() max: number = null;
  @Input() menu: Menu;
  @Input() placeholder: string;
  @Input() showLock: boolean;
  @Input() showNull = true;

  @Output() updateMenu = new EventEmitter<DeepPartial<Menu>>();
  debouncer = new Subject<DeepPartial<Menu>>();
  private destroyed$ = new Subject<void>();

  constructor() {
    this.debouncer
      .pipe(debounceTime(500), takeUntil(this.destroyed$))
      .subscribe((value: DeepPartial<Menu>) => {
        this.updateMenu.emit(value);
      });
  }

  decValue(target) {
    let value = parseFloat(target.value);
    if (this.min && value <= this.min) return undefined;
    value =
      this.type === 'perc'
        ? this.parsePercDec(value)
        : this.parseValueDec(value, this.step);
    target.value =
      this.type === 'perc'
        ? value + '?'
        : this.type === 'value'
          ? value + this.unit
          : value;
    this.debouncer.next({
      style: {
        [this.field]: value,
      },
    });
  }

  incValue(target) {
    let value = parseFloat(target.value);
    if (this.max && value >= this.max) return undefined;
    value =
      this.type === 'perc'
        ? this.parsePercInc(value)
        : this.parseValueInc(value, this.step);
    target.value =
      this.type === 'perc'
        ? value + '?'
        : this.type === 'value'
          ? value + this.unit
          : value;
    this.debouncer.next({
      style: {
        [this.field]: value,
      },
    });
  }

  /* FIXME: This does not work when step is a decimat, e.g. 0.1 => throws a console exception */
  parseValueInc = (value, step) =>
    parseFloat((isNaN(value) || value < 0 ? step : value + step).toFixed(2));

  parseValueDec = (value, step) =>
    parseFloat(
      (isNaN(value) || value < 0 ? step : Math.max(0, value - step)).toFixed(2),
    );

  parsePercInc = (value) => Math.min(100, value + 1);

  parsePercDec = (value) => Math.max(0, value - 1);

  valueChanged(event: FocusEvent, fieldName: string, preferedUnits = 'mm') {
    const target = event.target as HTMLInputElement;
    let value = parseFloat(target.value);
    value = parseFloat((isNaN(value) || value < 0 ? 0 : value).toFixed(2));
    target.value = value + preferedUnits;
    this.updateMenu.emit({
      style: {
        [fieldName]: value,
      },
    });
  }

  numberChanged(event: FocusEvent, fieldName: string) {
    const target = event.target as HTMLInputElement;
    let value = parseInt(target.value);
    value = Math.max(Math.min(100, value), 0);
    target.value = value.toString();
    this.updateMenu.emit({
      style: {
        [fieldName]: value,
      },
    });
  }

  percChanged(event: FocusEvent, fieldName: string) {
    const target = event.target as HTMLInputElement;
    let value = parseInt(target.value);
    value = Math.max(Math.min(100, value), 0);
    target.value = value + '%';
    this.updateMenu.emit({
      style: {
        [fieldName]: value,
      },
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
