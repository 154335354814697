import { langs } from '../constants/languages';
import { BaseDescription } from './models';

export class Procurements {
  count: number;
  next: string;
  previous: string;
  results: Procurement[];
}

const ORDERING = [
  ...langs,
  'quantity',
  'storage_area',
  'supplier',
  'category',
  'created_at',
  ...langs.map((l) => `-${l}`),
  '-category',
  '-quantity',
  '-storage_area',
  '-supplier',
  '-created_at',
] as const;

export type INVENTORY_ORDERING = (typeof ORDERING)[number];

export interface InventoryParams {
  ordering: INVENTORY_ORDERING;
  page_size: number;
  search: string;
  current_location: number;
}

export class Procurement extends BaseDescription {
  id: number;
  url: string;
  vendor: string;
  vendor_article_number: string;
  vendor_product: string;
  cost: number;
  cost_quantity: number;
  cost_unit: string;
  cost_vat: number;
  created_at: string;
  last_modified_at: string;
  created_by: number;
  last_modified_by: number;

  setValue(obj: any): Procurement {
    Object.assign(this, obj);
    return this;
  }
}
export class Supplier {
  id: number;
  url: string;
  name: string;
}
export class SupplierItem {
  id: number;
  url: string;
  supplier: number;
  supplier_name: string;
  supplier_article_number: string;
  vendor_product: string;
  cost: number;
  quantity: number;
  unit: string;
  vat: number;
  created_at: string;
  last_modified_at: string;
  created_by: number;
  last_modified_by: number;
  organisation: number;
  location: number;
  preferred: boolean;

  constructor(item?: any, setTempId = false) {
    Object.assign(this, item);
    if (setTempId) this.id = Date.now() + Math.floor(Math.random() * 100);
  }
}
export class InventoryOut {
  id: number;
  url: string;
  quantity: number;
  location: number;
  organisation: number;
  dish: number;
  ingredient: number;
  inventory: number;
  date: string;
  dish_detail: any;
  ingredient_detail: any;
}

export class InventoryItem {
  inventoryItemCategory: string;
  inventoryItemName: string;
  invetoryItemId: number;
  preSelectStockAdjustment: boolean;
}
export class InventoryIn {
  id: number;
  url: string;
  quantity: number;
  location: number;
  organisation: number;
  dish: number;
  ingredient: number;
  inventory: number;
  date: string;
  dish_detail: any;
  ingredient_detail: any;
  reason: 1 | 2 | 3 | 4;
  supplier_name: string;
  supplier_article_number: string;
  supplier_product: string;
}

export class Inventory {
  id: number;
  url: string;
  quantity: number;
  location: number;
  organisation: number;
  dish: number;
  ingredient: number;
  dish_detail: any;
  ingredient_detail: any;
  expiry: string[];
}
