<div
  class="wrapper"
  [class.html]="html"
  [@tooltip]="state"
  (@tooltip.done)="end($event)"
>
  <div class="arrow"></div>
  @if (html) {
    <span [innerHTML]="html | safe: true"></span>
  } @else {
    {{ text }}
  }

  @if (canClose) {
    <mat-icon class="close" (click)="hide.emit()">close</mat-icon>
  }
</div>
