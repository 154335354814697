import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { SimpleMenu } from 'src/app/shared/Models/menu';
import { LastMenuComponent } from './last-menu/last-menu.component';
import { MenusCounterComponent } from './menus-counter/menus-counter.component';

@Component({
  selector: 'app-user-stats',
  templateUrl: './user-stats.component.html',
  styleUrls: ['./user-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MenusCounterComponent, LastMenuComponent],
})
export class UserStatsComponent {
  @Input() lastMenu: SimpleMenu;
  @Input() menusCreated: {
    this_month: number;
    all_time: number;
    this_week: number;
    this_year: number;
  };
  @Output() previewMenu = new EventEmitter<SimpleMenu>();

  constructor() {}
}
