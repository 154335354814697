import { Categories } from '../constants/categories';
import { MenuDish, MenuDishLevel, SimpleMenuDish } from './menudish';

export interface MaxLevel {
  level: MenuDishLevel;
  isSection: boolean;
}

export class MenuDishNode {
  children: MenuDishNode[];
  dish: SimpleMenuDish | MenuDish;
  _isExpanded: boolean;
  removeId: number;
  realIndex: number;
  dottedLineMultiplier = 0;
  index?: number;
  maxChildLevel: MenuDishLevel = 1;
  lastIndexMaxChild = -1;
  parentNode?: MenuDishNode;
  focused: boolean;
  deleted?: boolean;
  childInCreation?: boolean;

  private isLastSection = false;

  get level(): MenuDishLevel {
    return this.dish.level;
  }

  set level(newLevel: MenuDishLevel) {
    this.dish.level = newLevel;
  }

  constructor(
    children: MenuDishNode[],
    dish: SimpleMenuDish | MenuDish,
    realIndex: number,
    parentNode?: MenuDishNode,
  ) {
    this.children = children;
    this.dish = dish;
    this.isExpanded = false;
    this.realIndex = realIndex;
    this.parentNode = parentNode;
  }

  get isExpanded(): boolean {
    return this._isExpanded;
  }

  set isExpanded(value: boolean) {
    this._isExpanded = value;
    if (!value && this.children?.length)
      this.children.forEach((child) => (child.isExpanded = value));
  }

  revealRealIndex(indices: any[]): void {
    indices.push({
      realIndex: this.realIndex,
      isDay: this.isDay(),
      isSection: this.isSection(),
      level: this.level,
    });
    if (this.children)
      this.children.forEach((item) => item.revealRealIndex(indices));
  }

  findChildIndex(node: MenuDishNode): number {
    return this.children?.findIndex(
      (n: MenuDishNode) => n.dish.id === node.dish.id,
    );
  }

  changeLevelRecursively(value: -3 | -2 | -1 | 0 | 1 | 2 | 3): void {
    this.level += value;
    this.children?.forEach((child: MenuDishNode) => {
      child.changeLevelRecursively(value);
    });
  }

  removeChild(node: MenuDishNode): void {
    const index = this.findChildIndex(node);
    if (this.children && index >= 0 && this.children.length > index)
      this.children.splice(index, 1);
  }

  setRealIndices(startIndex: number): number {
    this.realIndex = startIndex;
    startIndex++;
    if (this.children) {
      this.children.forEach((child) => {
        startIndex = child.setRealIndices(startIndex);
      });
    }
    return startIndex;
  }

  hasParentWithId(menuDishId: number): boolean {
    if (this.parentNode) {
      if (this.parentNode.dish.id === menuDishId) return true;
      return this.parentNode.hasParentWithId(menuDishId);
    }
    return false;
  }

  getLastDescendant(): MenuDishNode {
    if (this.children?.length) {
      return this.children[this.children.length - 1].getLastDescendant();
    } else {
      return this;
    }
  }

  stretchLineRecursively(value: number): void {
    this.dottedLineMultiplier += value;

    if (this.parentNode) this.parentNode.stretchLineRecursively(value);
  }

  shrinkLineRecursively(value: number, setToZero = false): void {
    this.dottedLineMultiplier = setToZero
      ? 0
      : Math.max(this.dottedLineMultiplier - value, 0);
    if (this.parentNode) this.parentNode.shrinkLineRecursively(value);
  }

  clearMaxChildLevel(): void {
    this.maxChildLevel = 1;
    this.isLastSection = false;
    if (this.parentNode) this.parentNode.clearMaxChildLevel();
  }

  getMaxChildLevel(dragIndex: number): MaxLevel {
    let maxLevel = { level: this.maxChildLevel, isSection: this.isLastSection };
    if (this.lastIndexMaxChild !== dragIndex || !this.maxChildLevel) {
      maxLevel = this._getMaxChildLevel({ level: 1, isSection: false });
      this.maxChildLevel = maxLevel.level;
      this.isLastSection = maxLevel.isSection;
    }
    this.lastIndexMaxChild = dragIndex;
    return maxLevel;
  }

  _getMaxChildLevel(maxLevel: MaxLevel, lookingFor: 2 | 3 | 4 = 2): MaxLevel {
    if (this.children && this.children.length > 0) {
      const currentLevel: 2 | 3 = (maxLevel.level = lookingFor) as 2 | 3;
      this.children.forEach((child) => {
        maxLevel = child._getMaxChildLevel(
          maxLevel,
          (currentLevel + 1) as 3 | 4,
        );
      });
    } else {
      maxLevel.isSection = this.isSection();
    }
    return maxLevel;
  }

  getChildrenLength(onlyExpanded = false): number {
    let length = 0;
    if (this.children) {
      if (!onlyExpanded || (onlyExpanded && this.isExpanded)) {
        length += this.children.length;
      }
      this.children.forEach((child: MenuDishNode) => {
        length += child.getChildrenLength(onlyExpanded);
      });
    }
    this.dottedLineMultiplier = length;
    return length;
  }

  flattenIDs(array = []): number[] {
    array.push(this.dish.id);
    if (!this.children) return array;
    this.children.forEach((child) => child.flattenIDs(array));
    return array;
  }

  getOwnChildrenLength(): number {
    return this.children ? this.children.length : 0;
  }

  getTopParent(): MenuDishNode | undefined {
    return this.parentNode ? this.parentNode.getTopParent() : this;
  }

  isSeparator(): boolean {
    return this.dish && !!this.dish.separator_detail;
  }

  isExpandable(): boolean {
    return this.isSection() || this.isDay();
  }

  isSection(): boolean {
    return this.isSectionOrDay(Categories.SECTION);
  }

  isDay(): boolean {
    return this.isSectionOrDay(Categories.DAY);
  }

  private isSectionOrDay(category: string): boolean {
    return this.dish?.separator_detail?.category === category;
  }

  hasFoldedChildren(): boolean {
    return this.children?.some((child) => child.isSection());
  }

  hasSimpleChildren(): boolean {
    return this.children?.some((child) => !child.isSection());
  }

  containsFoldedChildren(): boolean {
    return this.children?.some(
      (child) => child.isSection() || child.containsFoldedChildren(),
    );
  }

  containsSimpleChildren(): boolean {
    return this.children?.some(
      (child) => !child.isSection() && !child.containsSimpleChildren(),
    );
  }

  containsOnlyFoldedChildren(): boolean {
    return this.containsFoldedChildren() && !this.containsSimpleChildren();
  }

  containsOnlySimpleChildren(): boolean {
    return this.containsSimpleChildren() && !this.containsFoldedChildren();
  }

  /** Checks if node has at least 1 child */
  hasChildren(): boolean {
    return this.children?.length > 0;
  }
}
