<div class="wrapper icon-button-density-3">
  <div class="header">
    {{ "write.blocks.dish-overview.information.rating.title" | transloco }}:
    @if (false) {
      <mat-icon
        class="info"
        [matTooltip]="
          'write.blocks.dish-overview.information.rating.tooltip' | transloco
        "
        >info</mat-icon
      >
    }
  </div>
  <div class="rating">
    <span class="green" [ngClass]="getClasses(0)" (click)="setRating(0)"></span>
    <span
      class="yellow"
      [ngClass]="getClasses(1)"
      (click)="setRating(1)"
    ></span>
    <span class="red" [ngClass]="getClasses(2)" (click)="setRating(2)"></span>
  </div>
  @if (getRating() !== null) {
    <button
      stop-propagation
      class="clear-button"
      mat-icon-button
      (click)="setRating(null)"
    >
      <mat-icon class="clear-icon" aria-label="Clear">clear</mat-icon>
    </button>
  }
</div>
