import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { Menu } from 'src/app/shared/Models/menu';
import { TranslocoPipe } from '@jsverse/transloco';
import { LockItemComponent } from '../lock-item/lock-item.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MtTooltipDirective } from '../../../../../shared/Directives/mt-tooltip/mt-tooltip.directive';

@Component({
  selector: 'toggle-item',
  templateUrl: './toggle-item.component.html',
  styles: [
    `
      lock-item {
        display: flex;
      }
    `,
  ],
  standalone: true,
  imports: [
    MatSlideToggleModule,
    MtTooltipDirective,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    LockItemComponent,
    TranslocoPipe,
  ],
})
export class ToggleItemComponent {
  @Input() checkbox = false;
  @Input() disabled = false;
  @Input() disabledFields: string[] = [];
  @Input() field: string;
  @Input() lockedFields: string[];
  @Input() menu: Menu;
  @Input() showLock: boolean;
  @Output() updateMenu = new EventEmitter<DeepPartial<Menu>>();

  toggleChanged(
    event: MatSlideToggleChange | MatCheckboxChange,
    fieldName: string,
  ) {
    this.updateMenu.emit({
      style: {
        [fieldName]: event.checked,
      },
    });
  }

  lockChanged() {
    if (!this.menu) return undefined;
    let value = this.lockedFields ?? [];
    if (this.lockedFields.includes(this.field)) {
      value = value.filter((f) => f !== this.field);
    } else {
      value.push(this.field);
    }
    this.updateMenu.emit({
      style: {
        locked_fields: value,
      },
    });
  }
}
