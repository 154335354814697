import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  FileUploadControl,
  FileUploadValidators,
  FileUploadModule,
} from '@iplab/ngx-file-upload';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { StopPropagationDirective } from '../../Directives/stop-propagation/stop-propagation.directive';
import { SpinnerComponent } from '../spinner/spinner.component';
import { FileUploadPlaceholderComponent } from '../file-upload-placeholder/file-upload-placeholder.component';
import { MtTooltipDirective } from '../../Directives/mt-tooltip/mt-tooltip.directive';

@Component({
  selector: 'show-and-upload-image',
  templateUrl: './show-and-upload-image.component.html',
  styleUrls: ['./show-and-upload-image.component.scss'],
  standalone: true,
  imports: [
    MtTooltipDirective,
    FileUploadModule,
    FileUploadPlaceholderComponent,
    SpinnerComponent,
    StopPropagationDirective,
    MatIconModule,
    TranslocoPipe,
  ],
})
export class ShowAndUploadImageComponent implements OnDestroy {
  @Input() disabled: boolean;
  @Input() set imageUrl(value: string) {
    this._imageUrl = value;
    if (value) {
      this.deleting = false;
      this.fileUploadControl.clear();
    }
  }
  get imageUrl(): string {
    return this._imageUrl;
  }
  @Input() set showSpinner(value: boolean) {
    this._showSpinner = value;
    if (!value) {
      this.fileUploadControl.clear();
    }
  }
  get showSpinner(): boolean {
    return this._showSpinner;
  }
  @Input() label: string;
  @Input() overwritten = false;
  @Input() showTitle = true;

  @Output() fileChoosen = new EventEmitter<File>();
  @Output() deleteImage = new EventEmitter<void>();

  _imageUrl: string;
  _showSpinner: boolean;
  deleting = false;
  private destroyed$ = new Subject<void>();
  error: string;

  public fileUploadControl = new FileUploadControl(null, [
    FileUploadValidators.fileSize(100000000),
    FileUploadValidators.accept(['image/jpeg', 'image/png']),
  ]);

  constructor(private translate: TranslocoService) {
    this.fileUploadControl.valueChanges
      .pipe(
        filter((files) => !!files.length),
        tap(() => {
          this.error = undefined;
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe((files) => {
        this.fileUploadControl.valid
          ? this.fileChoosen.emit(files[0])
          : this.onError();
      });
  }

  delete(): void {
    this.deleting = true;
    this.deleteImage.emit();
    this.fileUploadControl.clear();
    this.fileUploadControl.enable();
  }

  onError(): void {
    const errorKey = Object.keys(this.fileUploadControl.getError()[0])[0];
    this.error = this.translate.translate(
      `shared.errors.${
        errorKey === 'fileSize' ? 'file-size' : 'unsupported-file'
      }`,
    );
    // this.fileUploadControl.clear();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
