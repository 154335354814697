<div class="input-block">
  <form [formGroup]="form">
    @for (lang of existingLangs; track lang) {
      <div
        class="lang-block"
        [class.space-above]="subscriptSizing === 'dynamic'"
      >
        <mat-form-field
          class="bar-wrapper text"
          color="primary"
          [subscriptSizing]="subscriptSizing"
        >
          <mat-label>{{ "shared.languages." + lang | transloco }}</mat-label>
          @if (!isTextarea) {
            <input
              matInput
              type="text"
              [name]="lang"
              [formControlName]="lang"
            />
          }
          @if (isTextarea) {
            <textarea
              matInput
              #autosize="cdkTextareaAutosize"
              cdkTextareaAutosize
              cdkAutosizeMinRows="2"
              cdkAutosizeMaxRows="5"
              [name]="lang"
              [formControlName]="lang"
            ></textarea>
          }
          @if (loadingBars?.[field ? field : lang]) {
            <mat-progress-bar
              class="progress"
              mode="indeterminate"
            ></mat-progress-bar>
          }
        </mat-form-field>
      </div>
    }
  </form>
  @if (unusedLangs.size > 0) {
    <button
      mat-button
      class="add-translation"
      color="primary"
      [matMenuTriggerFor]="langMenu"
    >
      {{
        "shared.dialogs.item-name.add" + (!existingLangs.size ? "_simple" : "")
          | transloco
      }}
    </button>
  }
</div>

<mat-menu #langMenu="matMenu" [overlapTrigger]="false">
  @for (lang of unusedLangs; track lang) {
    <button mat-menu-item (click)="addLang(lang)">
      {{ "shared.languages." + lang | transloco }}
    </button>
  }
</mat-menu>
