import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { LangButton } from 'src/app/shared/Models/langButton';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'language-buttons',
  templateUrl: './language-buttons.component.html',
  styleUrls: ['./language-buttons.component.scss'],
  standalone: true,
  imports: [MatButtonModule],
})
export class LanguageButtonsComponent implements OnChanges {
  @Input() langs: LangButton[] = [];
  @Input() multiRequired = false;
  @Input() langsAllowed: number;
  @Input() hideLabel = false;

  @Output() langsChange = new EventEmitter<LangButton[]>();

  langButtons: LangButton[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if ('langs' in changes) {
      this.langButtons = this.langs;
    }
  }

  toggleLang(lang: LangButton) {
    // get currently active lang, cannot disable the only remaining lang
    const langs = this.langButtons.filter((l) => l.activated);
    if (langs.length === 1 && langs.includes(lang)) return undefined;

    // check if a lang needs to be disabled (if > number_languages limit)
    let firstActiveLang = this.langButtons.find((v) => v.activated);
    if (
      !lang.activated &&
      langs.length >= this.langsAllowed &&
      firstActiveLang
    ) {
      firstActiveLang = {
        ...firstActiveLang,
        activated: false,
      };
    }

    // update activated property, optionally update lang to be disabled
    const activateLang = !lang.activated;
    this.langButtons = this.langButtons.map((l) => {
      if (l.lang === lang.lang)
        return {
          ...lang,
          activated: activateLang,
          order: activateLang ? langs.length : null,
        };
      if (l.lang === firstActiveLang.lang) return firstActiveLang;
      return l;
    });

    // update order property
    const updatedLangButtonOrder = {};
    this.langButtons
      .filter((l) => l.activated)
      .sort((a, b) => a.order - b.order)
      .forEach((l, index) => {
        updatedLangButtonOrder[l.lang] = {
          ...l,
          order: index,
        };
      });
    this.langButtons = this.langButtons.map((l) => {
      if (l.lang in updatedLangButtonOrder)
        return updatedLangButtonOrder[l.lang];
      return l;
    });
    this.langsChange.emit(this.langButtons);
  }

  disable(): boolean {
    return (
      this.multiRequired &&
      this.langs.filter((v) => v.activated).length === this.langsAllowed
    );
  }
}
