import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { selectCurrentMenuOnboardingTemplateDetail } from 'src/app/menus/menu-edit/ngrx/menu-edit.selectors';
import { State } from 'src/app/reducers';
import {
  ContentLanguage,
  InterfaceLanguage,
  langs,
} from 'src/app/shared/constants/languages';
import { Template } from 'src/app/shared/Models/menu';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatRadioModule } from '@angular/material/radio';
import { ShowAndUploadImageComponent } from '../../../../../shared/Components/show-and-upload-image/show-and-upload-image.component';
import { NameDescriptionComponent } from '../../../../../shared/Components/name-description/name-description.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'template-dialog',
  templateUrl: './template-dialog.component.html',
  styleUrls: ['./template-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatMenuModule,
    NameDescriptionComponent,
    ShowAndUploadImageComponent,
    MatRadioModule,
    MatDialogActions,
    TranslocoPipe,
  ],
})
export class TemplateDialogComponent implements OnDestroy, OnInit {
  choosenDefaultOption = false;
  containsImage = false;
  filteredLangs = [];
  italic: boolean;
  itemName: string;
  lang: InterfaceLanguage;
  langs = langs;
  private destroyed$ = new Subject<void>();
  showImageSpinner = false;
  template = new Template();
  templateData = new FormData();
  unusedLangs = [];

  onboardingTemplateDetail$ = this.ngrxStore.select(
    selectCurrentMenuOnboardingTemplateDetail,
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslocoService,
    private utils: UtilsService,
    public dialogRef: MatDialogRef<TemplateDialogComponent>,
    protected ngrxStore: Store<State>,
  ) {}

  ngOnInit() {
    this.onboardingTemplateDetail$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((template) => {
        this.showImageSpinner = false;
        if (template) {
          this.data.item = template;
        }
      });
    this.langs.forEach((lang) => {
      if (this.template[lang] || lang === this.data.lang) this.addLang(lang);
    });
    this.filterUnusedLangs();
    if (this.data.item) {
      this.choosenDefaultOption = this.data.item.has_preset;
      const { name, italic } = this.utils.tryGetLabel(
        this.data.item,
        this.lang,
      );
      this.itemName = name;
      this.italic = italic;
    }
    this.translate.langChanges$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((lang) => (this.lang = lang as InterfaceLanguage));
  }

  addLang(lang: ContentLanguage) {
    if (this.filteredLangs.includes(lang)) return undefined;
    this.filteredLangs.push(lang);
    this.filterUnusedLangs();
  }

  filterUnusedLangs() {
    this.unusedLangs = this.langs.filter(
      (lang) => !this.filteredLangs.includes(lang),
    );
  }

  defaultOptionChanged(event: { value: boolean }): void {
    this.template.create_preset = event.value;
    if (!this.data.adding) {
      this.data.item.has_preset = event.value;
    }
  }

  isAllEmpty = (): boolean => {
    const templateData = _.omitBy(this.template, _.isEmpty);
    return Object.keys(templateData).length ? false : true;
  };

  isEmpty = (lang: InterfaceLanguage): boolean =>
    this.data && this.data.item && !this.data.item[lang];

  patchItem(item) {
    this.data.item = { ...this.data.item, ...item };
  }

  updateItem() {
    this.dialogRef.close();
    if (this.data.adding) {
      if (this.containsImage) {
        for (var key in _.omitBy(this.template, _.isEmpty)) {
          this.templateData.append(key, this.template[key]);
        }
        if (this.template.create_preset) {
          this.templateData.append(
            'create_preset',
            JSON.stringify(this.template.create_preset),
          );
        }
        this.data.createTemplateWithImage(this.templateData);
      } else {
        this.data.done(this.template);
      }
    } else {
      delete this.data.item.image;
      this.data.update(this.data.item);
    }
  }

  uploadImage(file: File) {
    this.showImageSpinner = true;
    if (this.data.adding) {
      this.containsImage = true;
      this.templateData.append('image', file);
    } else {
      this.data.uploadImage({
        payload: file,
        onFulfilled: () => (this.showImageSpinner = false),
      });
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
