import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { SimpleMenu } from 'src/app/shared/Models/menu';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { fetchDishesCurrentMenu } from '../ngrx/dashboard.actions';
import {
  selectDishesCurrentMenu,
  selectDishesCurrentMenuLoading,
} from '../ngrx/dashboard.selectors';
import { TranslocoPipe } from '@jsverse/transloco';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { SpinnerComponent } from '../../shared/Components/spinner/spinner.component';
import { QuickOverviewDishesComponent } from './quick-overview-dishes/quick-overview-dishes.component';
import { QuickOverviewItemComponent } from './quick-overview-item/quick-overview-item.component';
import { NgClass, AsyncPipe } from '@angular/common';

export enum DASHBOARD_SCREEN_WIDTH {
  mobile = 1,
  desktop = 2,
}

export const QUICK_OVERVIEW_MAX_DISHES = 15;

export type QuickOverviewItem = {
  day: string;
  empty: boolean;
  menus: SimpleMenu[];
};

@Component({
  selector: 'quick-overview',
  templateUrl: './quick-overview.component.html',
  styleUrls: ['./quick-overview.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    QuickOverviewItemComponent,
    QuickOverviewDishesComponent,
    SpinnerComponent,
    MatButtonModule,
    RouterLink,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class QuickOverviewComponent implements OnChanges, OnInit {
  @Input() menus: SimpleMenu[];
  @Output() previewMenu = new EventEmitter<SimpleMenu>();

  allMenus: QuickOverviewItem[];
  currentMenu: QuickOverviewItem;
  currentMenus: QuickOverviewItem[];
  currentMonth: string;
  dishes: Observable<MenuDish[]>;
  selectedMenu: SimpleMenu;
  selectedMenuPagination = 1;
  width = window.innerWidth;

  dishesCurrentMenu$ = this.ngrxStore.select(selectDishesCurrentMenu);
  dishesCurrentMenuLoading$ = this.ngrxStore.select(
    selectDishesCurrentMenuLoading,
  );

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private ngrxStore: Store<State>,
    private previewModal: MatDialog,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('menus' in changes && this.menus) {
      this.allMenus = this.menus.map((el) => ({
        day: this.dateAdapter.format(
          new Date(Object.keys(el)[0]),
          'yyyy-MM-dd',
        ),
        empty: !el[Object.keys(el)[0]].length,
        menus: el[Object.keys(el)[0]],
      }));
      this.currentMenus = this.allMenus;
      this.currentMenu = this.findMenuWithDishes(this.allMenus);
      this.selectDate(this.currentMenu);
      this.currentMenus = this.changeSize(this.allMenus);
      this.currentMonth = this.currentMenu
        ? this.dateAdapter.format(
            new Date(this.currentMenu.menus[0].date),
            'MMMM',
          )
        : this.dateAdapter.format(new Date(), 'MMMM');
    }
  }

  ngOnInit(): void {
    window.onresize = () => {
      const pastWidth = this.width;
      this.width = window.innerWidth;
      this.currentMenus = this.changeSize(this.allMenus);
      if (this.width > 768 && pastWidth < 768)
        this.currentMenu = this.findMenuWithDishes(this.allMenus);
    };
    this.makeDishesVisible();
  }

  makeDishesVisible = (): void => {
    this.dishes = this.dishesCurrentMenu$.pipe(
      map((el) =>
        el.slice(0, this.selectedMenuPagination * QUICK_OVERVIEW_MAX_DISHES),
      ),
    );
  };

  showMoreDishes = (): void => {
    this.selectedMenuPagination++;
    this.makeDishesVisible();
  };

  changeSize = (menus: QuickOverviewItem[]): QuickOverviewItem[] =>
    this.generateMenus(
      menus,
      this.width < 768
        ? DASHBOARD_SCREEN_WIDTH.mobile
        : DASHBOARD_SCREEN_WIDTH.desktop,
    );

  findMenuWithDishes(dates: QuickOverviewItem[]): QuickOverviewItem {
    const current = dates.filter((el) =>
      this.dateAdapter.sameDate(new Date(), new Date(el.day)),
    )[0];
    return current && !current.empty
      ? current
      : dates.filter((el) => !el.empty).slice(-1)[0];
  }

  selectDate(item: QuickOverviewItem): void {
    if (!item || item.empty) return undefined;
    this.currentMenu = item;
    this.setCurrentMenu(item.menus[0]);
  }

  getMonth = (day): string => this.dateAdapter.format(new Date(day), 'MMM');

  getDate = (day: string): string =>
    this.dateAdapter.format(new Date(day), 'd');

  showPreview(): void {
    if (!this.currentMenu) return undefined;
    this.previewMenu.emit(this.selectedMenu);
  }

  setCurrentMenu(menu: SimpleMenu): void {
    if (this.selectedMenu === menu) return;
    if (!menu) {
      this.currentMenu = null;
      return undefined;
    }
    this.selectedMenu = menu;
    this.ngrxStore.dispatch(
      fetchDishesCurrentMenu({
        url: menu.dishes,
        params: {
          type: 'dish',
          empty: false,
          condensed: true,
        },
      }),
    );
    this.makeDishesVisible();
  }

  generateMenus(dates: QuickOverviewItem[], type: number): QuickOverviewItem[] {
    if (!dates) return undefined;
    if (type === DASHBOARD_SCREEN_WIDTH.mobile) {
      const newDates = dates.slice(5, 10);
      this.currentMenu = this.findMenuWithDishes(newDates);
      return newDates;
    }
    return dates;
  }
}
