<form class="simple-theme" (submit)="submit($event, data)">
  @if (showEmail) {
    <mat-form-field class="full">
      <mat-label>{{ "shared.feedback.form.email" | transloco }}</mat-label>
      <input matInput class="full" name="email" [(ngModel)]="feedback.email" />
    </mat-form-field>
  }
  <mat-form-field class="full">
    <mat-label>{{ "shared.feedback.form.message" | transloco }}</mat-label>
    <textarea
      #data="ngModel"
      class="full"
      matInput
      required
      minlength="2"
      maxlength="1000"
      type="text"
      name="message"
      [(ngModel)]="feedback.message"
      rows="10"
    ></textarea>
  </mat-form-field>
  <div class="rating">
    <p>{{ "shared.feedback.title" | transloco }}</p>
    @for (item of ratingChoices; track item) {
      <img
        class="star"
        [alt]="'Smiley ' + item"
        [ngClass]="{ choosen: item === rating }"
        [src]="'./../../assets/img/feedback/' + item + '.svg'"
        (click)="setRating(item)"
      />
    }
  </div>
  <hr />
  <div class="actions">
    <button
      mat-button
      class="send"
      color="primary"
      [disabled]="!data.valid || loading"
      (click)="submit($event, data)"
    >
      @if (!loading) {
        <span>
          {{ "shared.feedback.actions.send" | transloco }}
        </span>
      }
      @if (loading) {
        <spinner [config]="{ radius: 12 }"></spinner>
      }
    </button>
  </div>
</form>
