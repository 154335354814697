import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { Menu } from 'src/app/shared/Models/menu';
import { MTFont } from 'src/app/shared/Models/mtfont';
import { get } from 'lodash-es';
import {
  Font,
  FontInterface,
  FontPickerDirective,
  FontPickerModule,
} from 'ngx-font-picker-filtered';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TranslocoPipe } from '@jsverse/transloco';
import { ToggleItemComponent } from '../toggle-item/toggle-item.component';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MtTooltipDirective } from '../../../../../shared/Directives/mt-tooltip/mt-tooltip.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MtColorPickerComponent } from '../../../../../shared/Components/mt-color-picker/mt-color-picker.component';
import { SelectItemComponent } from '../select-item/select-item.component';
import { NumberItemComponent } from '../number-item/number-item.component';
import { LockItemComponent } from '../lock-item/lock-item.component';

@Component({
  selector: 'font-item',
  templateUrl: './font-item.component.html',
  styleUrls: ['./font-item.component.scss'],
  standalone: true,
  imports: [
    LockItemComponent,
    NumberItemComponent,
    SelectItemComponent,
    MtColorPickerComponent,
    MatFormFieldModule,
    MtTooltipDirective,
    FontPickerModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    ToggleItemComponent,
    TranslocoPipe,
  ],
})
export class FontItemComponent implements OnChanges, OnDestroy {
  @Input() disabledFields: string[] = [];
  @Input() field: string;
  @Input() hideFonts = false;
  @Input() hideCapitalisation = false;
  @Input() lockedFields: string[];
  @Input() menu: Menu;
  @Input() mtFonts: MTFont[];
  @Input() showLock: boolean;
  @Output() updateMenu = new EventEmitter<DeepPartial<Menu>>();

  @ViewChild('fontPickerInput', { static: false })
  fontPicker: FontPickerDirective;

  debouncer = new Subject<DeepPartial<Menu>>();
  private destroyed$ = new Subject<void>();

  fontCapitals = [
    {
      name: 'none',
      value: 0,
    },
    {
      name: 'uppercase',
      value: 1,
    },
    {
      name: 'smallcaps',
      value: 2,
    },
    {
      name: 'lowercase',
      value: 3,
    },
    {
      name: 'capitalise',
      value: 4,
    },
  ];
  fontWeights: { name: string; value: number }[] = [];
  italic = true; // FIXME: need to lookup if italic is supported
  mtFontList = [];
  private _font: Font | FontInterface;
  get font() {
    return this._font;
  }
  set font(value) {
    this.fontPicker && this.fontPicker.closeDialog();
    if (value !== this._font) {
      this._font = value;
      this.fontWeights = [];
      value.styles.forEach((weight) => {
        if (weight.length === 3 || weight === 'regular') {
          const currentWeight = weight === 'regular' ? '400' : weight;
          this.fontWeights.push({
            name: currentWeight,
            value: parseInt(currentWeight),
          });
        }
      });
      // this.italic = value.italics;
      const existingWeights = this.fontWeights.map((weight) => weight.value);
      const currentWeight = this.menu.style['weight_' + this.field] as number;
      if (!existingWeights.includes(this.menu.style['weight_' + this.field])) {
        const closest = existingWeights.reduce((prev, curr) =>
          Math.abs(curr - currentWeight) < Math.abs(prev - currentWeight)
            ? curr
            : prev,
        );
        this.updateMenu.emit({
          style: {
            ['weight_' + this.field]: closest,
          },
        });
      }
    }
  }

  constructor() {
    this.debouncer
      .pipe(debounceTime(500), takeUntil(this.destroyed$))
      .subscribe((value: DeepPartial<Menu>) => {
        this.updateMenu.emit(value);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('mtFonts' in changes && this.mtFonts) {
      this.mtFontList = this.mtFonts.map((font: MTFont) => font.name);
    }
    if (!this.fontWeights.length && this.menu?.style && this.mtFonts) {
      const currentFont = this.mtFonts.filter(
        (font: MTFont) => font.name === this.menu.style['font_' + this.field],
      );
      if (currentFont.length) {
        currentFont[0].variants.forEach((variant: string) => {
          this.fontWeights.push({
            name: variant,
            value: parseInt(variant),
          });
        });
      }
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  colorChanged(color: string): void {
    if (this.menu.style['colour_' + this.field] === color) return undefined;
    this.debouncer.next({
      style: {
        ['colour_' + this.field]: color,
      },
    });
  }

  fontChanged(font: Font | FontInterface, fieldName: string): void {
    const fontFamily = get(font, 'family');
    if (this.menu.style[fieldName] === fontFamily) return undefined;
    this.debouncer.next({
      style: {
        [fieldName]: fontFamily,
      },
    });
  }

  isLocked(field: string): boolean {
    return !this.menu || this.lockedFields.indexOf(field) > -1;
  }
}
