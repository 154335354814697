import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { State } from 'src/app/reducers';
import { User } from 'src/app/shared/Models/user';
import { selectUser } from 'src/app/shared/user/ngrx/user.selectors';

export const userResolver: ResolveFn<User> = () => {
  const ngrxStore = inject(Store) as Store<State>;

  const user$ = ngrxStore.select(selectUser);

  return user$.pipe(
    filter((usr) => !!usr?.url),
    take(1),
  );
};
