<same-dishes
  stop-propagation
  stopPropClassName="cdk-overlay-container"
  [dish]="data.dish"
  [allDishesNumber]="
    data.type === 'allergens'
      ? (data.numberOfDishes | async)
      : (data.numberOfDishesAdditives | async)
  "
  [filteredDishes]="
    data.type === 'allergens'
      ? (data.sameDishes | async)
      : (data.sameDishesAdditives | async)
  "
  [contentLang]="data.contentLang"
  [lang]="data.lang"
  [type]="data.type"
  (addOption)="data.addOption($event)"
  (copyDeclarations)="data.copyDeclarations($event)"
  (loadMoreDishes)="loadMoreDishes.emit($event)"
>
</same-dishes>
