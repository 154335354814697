import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'option-popover',
  templateUrl: './option-popover.component.html',
  styleUrls: ['./option-popover.component.css'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
})
export class OptionPopoverComponent {
  @Input() element;
  @Input() data;
  @Input()
  get visible() {
    return this.showPopover;
  }
  set visible(val) {
    this.showPopover = val;
    this.visibleChange.emit(this.showPopover);
  }

  @Output() visibleChange = new EventEmitter();
  showPopover = false;

  close(event) {
    event.stopPropagation();
    this.visible = false;
  }

  constructor(private elementRef: ElementRef) {}
}
