import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {
  MatExpansionPanel,
  MatExpansionModule,
} from '@angular/material/expansion';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { SimpleLocation } from 'src/app/shared/Models/location';
import { TaskType, OverviewMenu } from 'src/app/shared/Models/menu';
import { Ruleset } from 'src/app/shared/Models/ruleset';
import { Type } from 'src/app/shared/Models/type';
import { User, UserStatusPrivileges } from 'src/app/shared/Models/user';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { LocationsMenuContextItemComponent } from '../../../shared/locations-menu-context-item/locations-menu-context-item.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { StopPropagationDirective } from '../../../../shared/Directives/stop-propagation/stop-propagation.directive';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, DatePipe } from '@angular/common';

@Component({
  selector: 'menu-overview-item',
  templateUrl: './menu-overview-item.component.html',
  styleUrls: ['./menu-overview-item.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    MatExpansionModule,
    MatButtonModule,
    RouterLink,
    MatIconModule,
    StopPropagationDirective,
    MatMenuModule,
    MatTooltipModule,
    LocationsMenuContextItemComponent,
    DatePipe,
    TranslocoPipe,
  ],
})
export class MenuOverviewItemComponent implements OnChanges {
  lang: InterfaceLanguage;
  updateParent = new EventEmitter<boolean>();

  @Input() consumerTypesByLocation: { [locationId: number]: Type[] };
  @Input() currentMenu: OverviewMenu;
  @Input() dietsLocationsMap: { [locationId: number]: boolean };
  @Input() isChild = false;
  @Input() isLastChild: boolean;
  @Input() isLastMenu: boolean;
  @Input() locations: SimpleLocation[];
  @Input() menu: OverviewMenu;
  @Input() privileges: UserStatusPrivileges;
  @Input() rulesets: Ruleset[];
  @Input() user: User;
  @Input() userStatusMessage: string;
  @Output() changeMenu = new EventEmitter<{
    menu: OverviewMenu;
    featured: boolean;
  }>();
  @Output() childCopied = new EventEmitter<OverviewMenu>();
  @Output() childCopyAs = new EventEmitter<{
    event: {
      rule: number;
      enable_ordertaking?: boolean;
    };
    menu: OverviewMenu;
  }>();
  @Output() childDelete = new EventEmitter<OverviewMenu>();
  @Output() childDeleteChildren = new EventEmitter<OverviewMenu>();
  @Output() childShowPreview = new EventEmitter<OverviewMenu>();
  @Output() copied = new EventEmitter<void>();
  @Output() copyWithDiets = new EventEmitter<OverviewMenu>();
  @Output() copyWithOptions = new EventEmitter<OverviewMenu>();
  @Output() createArchive = new EventEmitter<{
    rulesetId?: number;
    taskType: TaskType;
  }>();
  @Output() createMenu = new EventEmitter<{
    id: number;
    entity: 'rule' | 'type';
  }>();
  @Output() delete = new EventEmitter<void>();
  @Output() deleteChildren = new EventEmitter<OverviewMenu>();
  @Output() refreshSubMenu = new EventEmitter<OverviewMenu>();
  @Output() regenerateMenus = new EventEmitter<void>();
  @Output() shareMenu = new EventEmitter<OverviewMenu>();
  @Output() showPreview = new EventEmitter<OverviewMenu>();
  @Output() showSnackbarMessageEvent = new EventEmitter<{ message: string }>();

  showShareMenuOption = false;

  constructor(
    private translate: TranslocoService,
    private utils: UtilsService,
  ) {
    this.translate.langChanges$.subscribe(this.handleLang);
    this.handleLang();
  }

  ngOnChanges() {
    if (this.locations) {
      const userLocation = this.user?.location;
      const userRole = this.user?.role;
      const locationsExcludingUserLocation = userLocation
        ? this.locations?.filter((item) => item.id !== userLocation)
        : this.locations;
      if (
        locationsExcludingUserLocation.length > 0 &&
        [0, 1].includes(userRole)
      ) {
        this.showShareMenuOption = true;
      }
    }
  }

  expandPanel(matExpansionPanel: MatExpansionPanel, event: Event): void {
    event.stopPropagation(); // Preventing event bubbling

    if (!this.isExpansionIndicator(event.target as HTMLElement)) {
      // matExpansionPanel.close(); // Here's the magic
    }
  }

  // tslint:disable-next-line:prefer-function-over-method
  private isExpansionIndicator(target: HTMLElement): boolean {
    const expansionIndicatorClass = 'mat-mdc-expansion-indicator';
    return (
      target.classList && target.classList.contains(expansionIndicatorClass)
    );
  }

  hasModule = (code: string): boolean => this.utils.hasModules(code);

  handleLang = () =>
    (this.lang = this.translate.getActiveLang() as InterfaceLanguage);

  featureThisMenu() {
    const featured = !this.menu.featured;
    this.changeMenu.emit({ menu: this.menu, featured });
  }

  getAlternativeLanguage = (item: Ruleset) =>
    this.utils.tryGetLabel(item, this.lang).name;

  showSnack() {
    this.showSnackbarMessageEvent.emit({ message: this.userStatusMessage });
  }
}
