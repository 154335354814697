import { createAction, props } from '@ngrx/store';
import {
  Invitation,
  InvitationsParams,
} from 'src/app/shared/Models/invitation';

export const paramsChanged = createAction(
  '[Settings container / Accesses tab] Query params changed',
  props<{ params?: InvitationsParams }>(),
);

export const updateParams = createAction(
  '[Settings container / Accesses API] Updating query params',
  props<{ paramsSlice: Partial<InvitationsParams> }>(),
);

export const setInvitations = createAction(
  '[Settings container / Accesses API] Set fetched invitations',
  props<{ invitations: Invitation[] }>(),
);

export const setCurrentPageIndex = createAction(
  '[Settings cotnainer / Accesses API] Set current page index',
  props<{ index: number }>(),
);

export const setInvitationsCount = createAction(
  '[Settings container / Accesses API] Set invitations count',
  props<{ count: number }>(),
);

export const pageParamsChanged = createAction(
  '[Settings container / Accesses tab] Page index or page size were changed',
  props<{ pageIndex: number; pageSize: InvitationsParams['page_size'] }>(),
);

export const orderingChanged = createAction(
  '[Settings container / Accesses tab] Ordering parameter changed',
  props<{ ordering: InvitationsParams['ordering'] }>(),
);

export const locationChanged = createAction(
  '[Settings container / Accesses tab] Location changed',
  props<{ location: InvitationsParams['location'] }>(),
);

export const acceptedChanged = createAction(
  '[Settings container / Accesses tab] Accepted changed',
  props<{ accepted: InvitationsParams['accepted'] }>(),
);

export const searchChanged = createAction(
  '[Settings container / Accesses tab] Search changed',
  props<{ search: InvitationsParams['search'] }>(),
);

export const updateStatus = createAction(
  '[Settings container / Accesses tab] Update invitation status',
  props<{ invitation: Invitation }>(),
);

export const deleteInvitation = createAction(
  '[Settings container / Accesses tab] Delete invitation',
  props<{ invitation: Invitation }>(),
);

export const invitationDeleted = createAction(
  '[Settings container / Accesses tab] Invitation was deleted',
);

export const resendInvitation = createAction(
  '[Settings container / Accesses tab] Resend invitation',
  props<{ invitation: Invitation }>(),
);

export const updateInvitation = createAction(
  '[Settings container / Accesses API] Update invitation',
  props<{ invitation: Invitation }>(),
);

export const sendInvitation = createAction(
  '[Settings container / Accesses tab] Send invitation',
  props<{ invitation: Invitation; id: number }>(),
);

export const invitationSent = createAction(
  '[Settings container / Accesses tab] Invitation has been sent',
  props<{ id: number }>(),
);

export const invitationError = createAction(
  '[Settings container / Accesses tab] An error happened during a request',
);
