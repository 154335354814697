import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { DeepPartial } from 'src/app/shared/Models/generics';
import {
  BaseDescriptionExtended,
  BaseItem,
  BaseNameExtended,
} from 'src/app/shared/Models/models';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { Subject } from 'rxjs';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslatableFieldComponent } from '../translatable-field/translatable-field.component';

@Component({
  selector: 'name-description',
  templateUrl: './name-description.component.html',
  styleUrls: ['./name-description.component.scss'],
  standalone: true,
  imports: [
    TranslatableFieldComponent,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    TranslocoPipe,
  ],
})
export class NameDescriptionComponent implements OnChanges, OnDestroy {
  @Input() allLanguages: boolean;
  @Input() description: boolean;
  @Input() hideProgress: boolean;
  @Input() lang: InterfaceLanguage;
  @Input() item: any;
  @Output() update = new EventEmitter<any>();

  filteredDescriptionLangs = [];
  filteredLangs = [];
  italic: boolean;
  itemName: string;
  maxYear: number;
  minYear: number;
  unusedDescriptionLangs = [];
  unusedLangs = [];
  initialFormValue;
  currentItem: BaseItem | BaseNameExtended | BaseDescriptionExtended;
  private destroyed$ = new Subject<void>();

  loadingBars = {};

  constructor(
    private utils: UtilsService,
    private fb: UntypedFormBuilder,
  ) {
    this.maxYear = new Date().getFullYear();
    this.minYear = 1850;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      'item' in changes &&
      (!changes.item.previousValue ||
        changes.item.previousValue.id !== changes.item.currentValue.id)
    ) {
      Object.keys(this.loadingBars).forEach(
        (key) => (this.loadingBars[key] = false),
      );
      this.initialFormValue = { ...this.item };
    }
  }

  onValueChanged({ data, item }: { data: any; item: any }): void {
    const { ...otherData }: { otherData: any } = data;
    const loading = [];
    const newData = {};
    Object.keys(data)
      .filter(
        (key) =>
          key in otherData && this.initialFormValue[key] !== otherData[key],
      )
      .forEach((key: string) => {
        newData[key] = otherData[key];
        loading.push(...this.setLoadingBar(item, key, true));
      });
    this.initialFormValue = otherData;
    if (Object.keys(newData).length) this.patchFields(newData, loading);
  }

  setLoadingBar(item: any, field: string, value: boolean): string[] {
    const loading = [];
    const loadingField = field;
    if (this.item?.['id']) {
      const showLoading = item['id'] === this.item['id'] && !this.hideProgress;
      if (showLoading) this.loadingBars[loadingField] = value;
      if (showLoading) loading.push(loadingField);
    }
    return loading;
  }

  patchFields(
    data: DeepPartial<BaseItem | BaseNameExtended | BaseDescriptionExtended>,
    clearableLoaders: string[] = [],
  ): void {
    this.update.emit({
      ...data,
      onFulfilled: () => {
        clearableLoaders.forEach((key) => (this.loadingBars[key] = false));
      },
    });
  }

  patchItem({ target }: FocusEvent) {
    const { value, name } = target as HTMLInputElement;
    const newObj = { [name]: name === 'vintage' ? parseInt(value) : value };
    this.update.emit(newObj);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
