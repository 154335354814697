import { registerLocaleData } from '@angular/common';
import { environment } from '../environments/environment';

import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIT from '@angular/common/locales/it';
import {
  provideHttpClient,
  withInterceptors,
  withXsrfConfiguration,
} from '@angular/common/http';
import {
  ApplicationConfig,
  APP_INITIALIZER,
  isDevMode,
  importProvidersFrom,
  ErrorHandler,
} from '@angular/core';
import {
  DateFnsAdapter,
  MAT_DATE_FNS_FORMATS,
} from '@angular/material-date-fns-adapter';
import {
  MAT_DATE_LOCALE,
  DateAdapter,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withViewTransitions,
  withInMemoryScrolling,
  withRouterConfig,
  Router,
} from '@angular/router';
import { provideTransloco } from '@jsverse/transloco';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import { enUS } from 'date-fns/locale';
import { routes } from './app.routes';
import { AuthEffects } from './auth/ngrx/auth.effects';
import { extModules } from './build-specifics';
import { SharedEffects } from './shared/ngrx/shared.effects';
import { UserEffects } from './shared/user/ngrx/user.effects';
import { metaReducers, reducers } from './reducers';
import { TranslocoHttpLoader } from './transloco-loader';
import { authInterceptor } from './shared/Services/http-interceptor/http-interceptor.service';
import { DashboardEffects } from './dashboard/ngrx/dashboard.effects';
import { DishesMenuEffects } from './shared/ngrx/dishes-menu/dishes-menu.effects';
import { MenuEditEffects } from './menus/menu-edit/ngrx/menu-edit.effects';
import { MenuStyleEffects } from './menus/menu-edit/style/ngrx/menu-style.effects';
import { MenusTranslateEffects } from './menus/menu-edit/translate/ngrx/menu-translate.effects';
import { SuppliersEffects } from './shared/ngrx/suppliers/suppliers.effects';
import { MenusOverviewEffects } from './menus/menus-overview/ngrx/menus-overview.effects';
import { MenuWriteEffects } from './menus/menu-edit/write/ngrx/menu-write.effects';
import {
  MatSelectCountryLangToken,
  MatSelectCountryModule,
} from '@angular-material-extensions/select-country';
import { provideNgxStripe } from 'ngx-stripe';
import {
  FONT_PICKER_CONFIG,
  FontPickerConfigInterface,
} from 'ngx-font-picker-filtered';

export interface AppConfig {
  value: string;
  title: string;
}

export const API_CONFIG: AppConfig = {
  value: environment.apiUrl,
  title: environment.production ? 'MenutechAPI' : 'MenutechDevelopment API',
};

export const LOCAL_STORAGE_KEY = 'session_token';

const DEFAULT_FONT_PICKER_CONFIG: FontPickerConfigInterface = {
  apiKey: 'AIzaSyDY1ctxLGciwhsSj43VrYeEoysAOWbwchc',
};

export const STRIPE_CONFIG = {
  key: environment.production
    ? `pk_live_ChmoPsLXgjOZLiErxrJojhTn`
    : `pk_test_mDUHR0hAfSnFtFrBGNmv6y5S`,
};

export function getThemeColour(partnerCode: string): string {
  if (partnerCode === 'msm') return '#DC0019';
  return '#009fed';
}

export const LANDIN_CONFIG: AppConfig = {
  value: 'https://menutech.com',
  title: 'MenutechMarketing Website',
};

export const SUPPORT_EMAIL: AppConfig = {
  value: 'support@menutech.com',
  title: 'MenutechSupport Email',
};

export const HELPDESK = {
  en: `https://help.menutech.com`,
  de: `https://hilfe.menutech.com`,
  fr: `https://assistance.menutech.com`,
  it: `https://assistenza.menutech.com`,
  es: `https://ayuda.menutech.com`,
};

export const TERMS_LINKS = {
  en: 'https://menutech.com/en/terms-and-conditions',
  de: 'https://menutech.com/de/allgemeine-geschaeftsbedingungen',
  it: 'https://menutech.com/it/condizioni-generali',
  fr: 'https://menutech.com/fr/termes-et-conditions',
  es: 'https://menutech.com/es/terminos-y-condiciones-generales',
};

const MENUWRITING_VIDEO_URL =
  'https://www.youtube-nocookie.com/embed/96qFz4NpfeY';

export const MENUWRITING_VIDEO = {
  de: 'https://www.youtube-nocookie.com/embed/tqt1DHY9dEU',
  en: MENUWRITING_VIDEO_URL,
  es: 'https://www.youtube-nocookie.com/embed/hpKxq2BVtys',
  fr: 'https://www.youtube-nocookie.com/embed/kftIq2iH3P4',
  it: MENUWRITING_VIDEO_URL, // EN

  ca: 'https://www.youtube-nocookie.com/embed/hpKxq2BVtys', // ES
  hr: MENUWRITING_VIDEO_URL, // EN
  nl: MENUWRITING_VIDEO_URL, // EN
  pl: MENUWRITING_VIDEO_URL, // EN
  pt: MENUWRITING_VIDEO_URL, // EN
  ru: MENUWRITING_VIDEO_URL, // EN
  el: MENUWRITING_VIDEO_URL, // EN
  da: MENUWRITING_VIDEO_URL, // EN
  sv: MENUWRITING_VIDEO_URL, // EN
  ar: MENUWRITING_VIDEO_URL, // EN
  eu: MENUWRITING_VIDEO_URL, // EN
  gl: MENUWRITING_VIDEO_URL, // EN
  et: MENUWRITING_VIDEO_URL, // EN
  fi: MENUWRITING_VIDEO_URL, // EN
};

registerLocaleData(localeFr);
registerLocaleData(localeDe);
registerLocaleData(localeEs);
registerLocaleData(localeIT);

export const appConfig: ApplicationConfig = {
  providers: [
    // Angular providers
    provideRouter(
      routes,
      withViewTransitions(),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
      }),
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
    ),
    provideHttpClient(
      withInterceptors([authInterceptor]),
      withXsrfConfiguration({
        cookieName: 'csrftoken',
        headerName: 'X-CSRFToken',
      }),
    ),
    provideAnimations(),

    // NgRx providers
    provideStore(reducers, { metaReducers }),
    provideRouterStore(),
    provideEffects([
      SharedEffects,
      AuthEffects,
      UserEffects,
      DashboardEffects,
      DishesMenuEffects,
      MenusOverviewEffects,
      MenuEditEffects,
      MenuWriteEffects,
      MenuStyleEffects,
      MenusTranslateEffects,
      SuppliersEffects,
    ]),
    extModules,

    // Sentry providers
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: !environment.production,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },

    // Transloco providers
    provideTransloco({
      config: {
        availableLangs: ['en', 'de', 'it', 'es', 'fr'],
        defaultLang: 'en',
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoMessageformat(),

    // Providers for various dependencies
    provideNgxStripe(STRIPE_CONFIG.key),
    importProvidersFrom(MatSelectCountryModule.forRoot('en')),
    {
      provide: MatSelectCountryLangToken,
      useValue: MatSelectCountryLangToken,
    },
    {
      provide: FONT_PICKER_CONFIG,
      useValue: DEFAULT_FONT_PICKER_CONFIG,
    },

    // DateFns providers
    { provide: MAT_DATE_LOCALE, useValue: enUS },
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },

    // Angular Material providers
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
  ],
};
