import { createAction, props } from '@ngrx/store';
import { Dish } from 'src/app/shared/Models/dish';
import {
  Ingredient,
  IngredientsParams,
  PreIngredients,
} from 'src/app/shared/Models/ingredients';
import {
  AIRecipe,
  Recipe,
  RecipeIngredient,
  RecipeParams,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';
import { ContentLanguage } from 'src/app/shared/constants/languages';

export const fetchRecipes = createAction(
  `[DishMenu action] Fetch recipes`,
  props<{ url: string; queryParams?: RecipeParams }>(),
);

export const setRecipes = createAction(
  `[DishMenu action] Set recipes`,
  props<{ recipes: Recipe[] }>(),
);

export const createRecipe = createAction(
  `[DishMenu action] Create recipe for a dish`,
  props<{
    url: string;
    data: Partial<Recipe>;
    onFulfilled?: Function;
    params?: RecipeParams;
  }>(),
);

export const addRecipe = createAction(
  `[DishMenu action] Add recipe locally`,
  props<{ recipe: Recipe }>(),
);

export const setNewRecipes = createAction(
  `[DishMenu action] Set new recipes`,
  props<{ recipes: Recipe[] }>(),
);

export const setUpdatedRecipe = createAction(
  `[DishMenu action] Set updated recipes`,
  props<{ recipe: Recipe }>(),
);

export const chooseRecipe = createAction(
  `[DishMenu action] Choose recipe for dish`,
  props<{
    url: string;
    recipeId: number;
    onFulfilled?: Function;
    params?: RecipeParams;
  }>(),
);

export const updateRecipe = createAction(
  `[DishMenu action] Update recipe`,
  props<{
    url: string;
    data: Partial<Recipe & { update_quantities: boolean }>;
    onFulfilled?: Function;
    params?: RecipeParams;
  }>(),
);

export const setLoading = createAction(
  `[DishMenu action] Set recipes tab loading`,
  props<{ loading: boolean }>(),
);

export const removeRecipe = createAction(
  `[DishMenu action] Remove recipe`,
  props<{
    dish: Dish;
    data: { recipe: number };
    onFulfilled?: Function;
    queryParams?: RecipeParams;
  }>(),
);

export const updateRecipesAfterRemoval = createAction(
  `[DishMenu action] Update recipes after removal`,
  props<{ id: number }>(),
);

export const addMultipleIngredientsToRecipe = createAction(
  `[DishMenu action] Add multiple ingredients to recipe`,
  props<{ recipe: Recipe; data: PreIngredients }>(),
);

export const replaceRecipeIngredients = createAction(
  `[DishMenu action] Replace recipe ingredients`,
  props<{ updatedRecipe: Recipe; ingredients: SimpleRecipeIngredient[] }>(),
);

export const clearRecipes = createAction(`[DishMenu action] Clear recipes`);

export const addIngredientToRecipe = createAction(
  `[DishMenu action] Add ingredient to recipe`,
  props<{ recipe: Recipe; ingredientId: number; onFulfilled?: Function }>(),
);

export const createNewIngredient = createAction(
  `[DishMenu action] Create new ingredient to recipe`,
  props<{
    recipe: Recipe;
    data: Partial<Recipe>;
    onError?: Function;
    params?: RecipeParams;
  }>(),
);

export const setIngredientToRecipe = createAction(
  `[DishMenu action] Set newly added ingredient to recipe locally`,
  props<{ recipe: Recipe; ingredient: RecipeIngredient }>(),
);

export const uncollapseRecipe = createAction(
  `[DishMenu action] Uncollapse recipe`,
  props<{ recipe: Recipe }>(),
);

export const deleteDishRecipeIngredient = createAction(
  `[DishMenu action] Delete dish recipe ingredient`,
  props<{ recipe: Recipe; recipeIngredient: SimpleRecipeIngredient }>(),
);

export const removeDeletedDishRecipeIngredient = createAction(
  `[DishMenu action] Remove deleted dish recipe ingredient locally`,
  props<{ recipe: Recipe; ingredientId: number }>(),
);

export const addIngredientToDishRecipe = createAction(
  `[DishMenu action] Add ingredient to dish recipe`,
  props<{ recipe: Recipe; ingredient: RecipeIngredient }>(),
);

export const updateIngredientOfRecipe = createAction(
  `[DishMenu action] Update ingredient of recipe`,
  props<{ recipe_id: number; recipe_ingredient: RecipeIngredient }>(),
);

export const removeEmptyIngredientFromDish = createAction(
  `[DishMenu action] Remove empty ingredient from dish`,
  props<{ recipe: Recipe }>(),
);

export const fetchIngredientInfo = createAction(
  `[DishMenu action] Fetch ingredient info`,
  props<{
    url: string;
    params?: RecipeParams;
  }>(),
);

export const setIngredientInfo = createAction(
  `[DishMenu action] Set ingredient info locally`,
  props<{ info: RecipeIngredient }>(),
);

export const updateIngredientInfo = createAction(
  `[DishMenu action] Update ingredient info`,
  props<{
    url: string;
    data: Partial<Ingredient>;
    params?: Partial<RecipeParams>;
    callBack?: Function;
  }>(),
);

export const updateRecipeIngredient = createAction(
  `[DishMenu action] Update recipe ingredient`,
  props<{
    recipe_id: number;
    data: Partial<RecipeIngredient>;
    url: string;
    params?: Partial<RecipeParams>;
    onFulfilled?: Function;
  }>(),
);

export const updateIngredient = createAction(
  `[DishMenu action] Update ingredient`,
  props<{
    url: string;
    id: number;
    data: Partial<Ingredient>;
    recipe_id: number;
    recipe_ingredient: RecipeIngredient;
    params?: Partial<IngredientsParams>;
    onFulfilled?: Function;
  }>(),
);

export const generateAiRecipes = createAction(
  `[DishMenu action] Generate ai recipes`,
  props<{
    url: string;
    lang: ContentLanguage;
    onFulfilled?: ({
      dish,
      recipes,
    }: {
      dish: Dish;
      recipes: Recipe[];
    }) => void;
  }>(),
);

export const generateAiAllergens = createAction(
  `[DishMenu action] Generate ai allergens`,
  props<{
    url: string;
    lang: ContentLanguage;
    onFulfilled?: (dish: Dish) => void;
  }>(),
);

export const generateAiDescription = createAction(
  `[DishMenu action] Generate ai description`,
  props<{
    url: string;
    lang: ContentLanguage;
    onFulfilled?: (dish: Dish) => void;
  }>(),
);

export const setAiRecipes = createAction(
  `[DishMenu action] Set ai recipes`,
  props<{ recipes: AIRecipe[] }>(),
);

export const setAiRecipesLoading = createAction(
  `[DishMenu action] Set ai recipes loading`,
  props<{ loading: boolean }>(),
);

export const setAiAllergensLoading = createAction(
  `[DishMenu action] Set ai allergens loading`,
  props<{ loading: boolean }>(),
);

export const setAiDescriptionLoading = createAction(
  `[DishMenu action] Set ai recipes loading`,
  props<{ loading: boolean }>(),
);
