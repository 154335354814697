<header>
  <h1>{{ "auth.registration.steps.first.title" | transloco: titleParams }}</h1>
  <p>
    {{ "auth.registration.steps.first.subtitle" | transloco: titleParams }}
  </p>
  <navigation-buttons
    [currentStep]="1"
    [first]="true"
    [disabled]="!enableNextStep"
    (next)="next()"
    (prev)="prev()"
  >
  </navigation-buttons>
</header>
<form class="form" [formGroup]="step1Group">
  <mat-select-country
    class="country-select"
    [itemsLoadSize]="10"
    [label]="'settings.profile.business.country.placeholder' | transloco"
    [language]="lang"
    [placeHolder]="
      'settings.profile.business.country.search-placeholder' | transloco
    "
    [required]="true"
    formControlName="country"
  >
  </mat-select-country>
  @for (option of options; track option) {
    <registration-step-option
      class="option"
      [option]="option"
      [isSafari]="isSafari"
      [lang]="lang"
      (click)="chooseOption(option)"
      (chooseOption)="chooseOption(option, $event)"
    >
    </registration-step-option>
  }
</form>

<ng-template #button>
  <div
    class="btn-container"
    matTooltipClass="registration-step-tooltip"
    [mtTooltip]="
      !enableNextStep
        ? ('auth.registration.steps.first.tooltip' | transloco)
        : ''
    "
  >
    <button
      mat-flat-button
      color="primary"
      class="footer-button"
      (click)="next()"
      [disabled]="!enableNextStep"
    >
      {{ "auth.registration.steps.first.button" | transloco }}
      <mat-icon class="footer-button-icon">arrow_forward</mat-icon>
    </button>
  </div>
</ng-template>
