import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ClipboardModule } from 'ngx-clipboard';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'copy-data-line',
  templateUrl: './copy-data-line.component.html',
  styleUrls: ['./copy-data-line.component.scss'],
  standalone: true,
  imports: [MatButtonModule, ClipboardModule, MatIconModule],
})
export class CopyDataLineComponent {
  @Input() upperData: string;
  @Input() lowerData: string;

  @Output() triggerCopy = new EventEmitter<string>();

  stopFocusOut(e) {
    e.stopPropagation();
    e.preventDefault();
    return false;
  }
}
