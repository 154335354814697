import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store, select } from '@ngrx/store';
import { selectLoginState } from 'src/app/auth/ngrx/auth.selectors';
import { State } from 'src/app/reducers';
import { map, Observable } from 'rxjs';
import { inject } from '@angular/core';

export const unauthenticatedGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const store = inject(Store) as Store<State>;
  const login$ = store.select(selectLoginState);

  return login$.pipe(
    map((loggedIn) => {
      if (loggedIn) {
        router.navigate(['/']);
        return false; // Prevent navigation
      }
      return true;
    }),
  );
};
