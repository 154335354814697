<div id="menus" class="menus-container">
  <menu-steps
    class="progress"
    [currentStep]="currentStep"
    [id]="id"
    [menu]="menu$ | async"
    [newLanguages]="newLanguages"
    [privileges]="privileges$ | async"
    [stage]="stage"
    [userStatus]="userStatus$ | async"
    (addTranslation)="addTranslation($event)"
    (goNext)="nextStep(currentStep)"
    (goPrev)="prevStep(currentStep)"
    (navigateToTranslate)="navigateToTranslate($event)"
    (showTooltip)="showTooltip($event)"
  ></menu-steps>
  @switch (currentStep) { @case (1) { @if ((menu$ | async) !== null) {
  <write
    class="write-new-dish"
    [autoRecipes]="autoRecipes$ | async"
    [backgroundImages]="backgroundWriteImages$ | async"
    [interfaceLang]="lang"
    [isTrial]="
          (user$ | async)?.status?.trial_period
        "
    [menu]="menu$ | async"
    [menuDishes]="menuDishes$ | async"
    [menuDishesLoading]="menuDishesLoading$ | async"
    [menuDishesSearch]="menuDishesSearch$ | async"
    [partner]="partner$ | async"
    [privileges]="privileges$ | async"
    [profileComplete]="
          (user$ | async)?.status?.completed_profile
        "
    [spellcheckItem]="spellcheckItem$ | async"
    [user]="user$ | async"
    (deleteBackground)="deleteBackground($event)"
    (fetchSpellcheck)="fetchSpellcheck($event)"
    (nextStep)="nextStep(1)"
    (patchMenu)="patchMenu($event)"
    (presetAction)="presetAction($event)"
    (regenerateMenu)="regenerateMenu()"
    (showMenuAnalysis)="showMenuAnalysis($event)"
    (showPreview)="showPreview($event)"
    (updateUser)="updateUser($event)"
    (dataExport)="dataExport($event)"
    (dataImport)="dataImport($event)"
  >
  </write>
  } @else {
  <div class="spinner-wrapper"><mat-spinner></mat-spinner></div>
  } } @case (2) { @if ((menu$ | async) !== null) {
  <mt-style
    [backgroundImages]="backgroundStyleImages$ | async"
    [isTrial]="(user$ | async)?.status?.trial_period"
    [menu]="menu$ | async"
    [lang]="lang"
    [lockedFields]="currentLockedFields"
    [profileComplete]="(user$ | async)?.status?.completed_profile"
    (createNewTemplateDialog)="createNewTemplateDialog()"
    (editTemplateDialog)="editTemplateDialog($event)"
    (deleteBackground)="deleteBackground($event)"
    (deleteTemplate)="deleteTemplate($event)"
    (linkMenuToTemplate)="linkMenuToTemplate($event)"
    (updateTemplateDetail)="updateTemplateDetail($event)"
    (nextStep)="nextStep(2)"
    (previousStep)="previousStep(2)"
    (updateMenu)="patchMenu($event)"
    (updateStyleAndLayout)="updateStyleAndLayout()"
    (unlink)="unlink()"
  >
  </mt-style>
  } @else {
  <div class="spinner-wrapper"><mat-spinner></mat-spinner></div>
  } } @case (3) { @if ((menu$ | async) !== null) {
  <translations
    class="right-left-direction translations"
    [isTrial]="(user$ | async)?.status?.trial_period"
    [lang]="language"
    [menu]="menu$ | async"
    [menuDishes]="menuDishesFull$ | async"
    [menuDishesLoading]="menuDishesLoadingFull$ | async"
    [spellcheckItem]="spellcheckItem$ | async"
    (fetchSpellcheck)="fetchSpellcheck($event)"
    [profileComplete]="(user$ | async)?.status?.completed_profile"
    (nextStep)="nextStep(3)"
    (previousStep)="previousStep(3)"
    (patchMenu)="patchMenu($event)"
  >
  </translations>
  } @else {
  <div class="spinner-wrapper"><mat-spinner></mat-spinner></div>
  } } @case (4) { @if ((menu$ | async) !== null) {
  <review
    class="completed-menu"
    [customUrl]="customUrl$ | async"
    [lang]="lang"
    [menu]="menu$ | async"
    [user]="user$ | async"
    [orderTakingLayouts]="orderTakingLayouts$ | async"
    [menuTranslations]="menuTranslations$ | async"
    [privileges]="privileges$ | async"
    [userStatus]="userStatus$ | async"
    (archiveMenu)="archiveMenu($event)"
    (createMenuBackup)="createMenuBackup()"
    (deleteMenuBackup)="deleteMenuBackup($event)"
    (enableOrdertaking)="enableOrdertaking($event)"
    (patchMenu)="patchMenu($event, true)"
    (restoreMenuBackup)="restoreMenuBackup($event)"
    (showPreview)="showPreview($event)"
    (downloadReport)="downloadReport($event)"
    (downloadStats)="downloadStats($event)"
  ></review>
  } @else {
  <div class="spinner-wrapper"><mat-spinner></mat-spinner></div>
  } } }
</div>

<ng-template #menuNameHeader>
  <div class="change-menu-wrapper field-density-5">
    <mat-form-field class="menu-name-field" subscriptSizing="dynamic">
      <input
        #menuInput
        matInput
        type="text"
        [disabled]="!(privileges$ | async)?.edit_menu || !currentMenu"
        [ngModel]="menuName"
        (keydown.enter)="blurField(menuInput)"
        (focusout)="changeMenuName(menuInput.value)"
      />
      @if ((privileges$ | async)?.edit_menu) {
      <button mat-icon-button matSuffix>
        <mat-icon>edit</mat-icon>
      </button>
      }
    </mat-form-field>
    @if (hasRuleType) {
    <div class="menu-rule-tag" [mtTooltip]="'menus.rule-info' | transloco">
      <mat-icon>bolt</mat-icon>{{ ((menu$ | async).type_detail || (menu$ |
      async)?.rule_detail)[lang] }}
    </div>
    } @if (hasDiet) {
    <div
      class="menu-rule-tag"
      [mtTooltip]="('menus.diet-info' | transloco) + ': ' + (menu$ | async).diets_list.join(', ')"
    >
      <mat-icon>spa</mat-icon>{{ (menu$ | async).diets_list.join(", ") }}
    </div>
    }
  </div>
</ng-template>
