<mat-form-field [hidden]="!variant[baseLang]" class="price-input">
  <mat-label>{{ variant[baseLang] }}</mat-label>
  <input
    matInput
    type="text"
    maxlength="100"
    autocomplete="off"
    name="text"
    #textModel="ngModel"
    [(ngModel)]="variantName"
    (focusin)="inputFocused = true"
    (focusout)="changeTranslation(); inputFocused = false"
  />
</mat-form-field>
@for (item of textModel?.errors | keys; track item) {
  <div class="input-error">
    {{
      "write.blocks.dish-overview.information.prices.variant-price.price.errors." +
        item.key | transloco
    }}
  </div>
}
