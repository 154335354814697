import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as StyleConstants from 'src/app/shared/constants/style';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { BackgroundImage, Menu } from 'src/app/shared/Models/menu';
import { Fulfillable } from 'src/app/shared/Models/models';
import { TranslocoPipe } from '@jsverse/transloco';
import { MtColorPickerComponent } from '../../../../../shared/Components/mt-color-picker/mt-color-picker.component';
import { ShowAndUploadImageComponent } from '../../../../../shared/Components/show-and-upload-image/show-and-upload-image.component';
import { MatButtonModule } from '@angular/material/button';
import { SelectImageComponent } from '../../../../shared/select-image/select-image.component';
import { MtTooltipDirective } from '../../../../../shared/Directives/mt-tooltip/mt-tooltip.directive';
import { LockItemComponent } from '../lock-item/lock-item.component';
import { ToggleItemComponent } from '../toggle-item/toggle-item.component';
import { NumberItemComponent } from '../number-item/number-item.component';
import { SelectItemComponent } from '../select-item/select-item.component';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'style-print-tab',
  templateUrl: './style-print-tab.component.html',
  styleUrls: ['../../style.component.scss', './style-print-tab.component.scss'],
  standalone: true,
  imports: [
    MatExpansionModule,
    MatIconModule,
    SelectItemComponent,
    NumberItemComponent,
    ToggleItemComponent,
    LockItemComponent,
    MtTooltipDirective,
    SelectImageComponent,
    MatButtonModule,
    ShowAndUploadImageComponent,
    MtColorPickerComponent,
    TranslocoPipe,
  ],
})
export class StylePrintTabComponent implements OnChanges {
  @Input() backgroundImages: BackgroundImage[];
  @Input() disabledFields: string[] = [];
  @Input() lockedFields: string[];
  @Input() menu: Menu;
  @Input() showLocks: boolean;

  @Output() updateMenu = new EventEmitter<DeepPartial<Menu>>();
  @Output() uploadBackgroundImage = new EventEmitter<
    Fulfillable<File> & { field: string }
  >();
  @Output() showAllBackgrounds = new EventEmitter<
    'background' | 'background_coverpage'
  >();

  backgroundUpdating = false;
  backgroundCoverpageUpdating = false;
  hideLastImage = false;
  showImageSpinner = false;
  styleConstants = StyleConstants;

  ngOnChanges(changes: SimpleChanges) {
    if ('menu' in changes) {
      this.backgroundUpdating = false;
      this.backgroundCoverpageUpdating = false;
    }
    if (
      ('backgroundImages' in changes || 'menu' in changes) &&
      this.backgroundImages &&
      this.menu &&
      this.menu.style
    ) {
      this.hideLastImage =
        !!this.menu.style.background &&
        this.backgroundImages.length > 2 &&
        this.backgroundImages.filter(
          (img: BackgroundImage) => img.id === this.menu.style.background,
        ).length === 0;
    }
  }

  clearColor(field: string): void {
    this.updateMenu.emit({
      style: {
        [field]: null,
      },
    });
  }

  colorChanged(field: string, color: string): void {
    if (this.menu.style[field] === color) return undefined;
    this.updateMenu.emit({
      style: {
        [field]: color,
      },
    });
  }

  uploadImage(file: File, field: 'background' | 'background_coverpage'): void {
    this.showImageSpinner = true;
    this.uploadBackgroundImage.emit({
      payload: file,
      field,
      onFulfilled: () => {
        this.showImageSpinner = false;
      },
    });
  }

  clearBackgroundImage(): void {
    this.updateMenu.emit({ style: { background: null } });
  }
}
