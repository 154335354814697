import { Action, createReducer, on } from '@ngrx/store';
import {
  PaymentErrorData,
  Plan,
  Subscription,
  SubscriptionTerm,
} from 'src/app/shared/Models/subscription';
import { Payment, PaymentVerification } from 'src/app/shared/Models/user';

import { PAYMENT_OPTIONS } from '../../plans/payment-option-cards/payment-option-cards.component';
import * as BillingActions from './billing.actions';

export const billingFeatureKey = 'billing';

const initialState: BillingState = {
  subscription: null,
  payment: null,
  paymentURL: null,
  payment_verification: null,
  current_plan: null,
  plans: null,
  currentSubscriptionTerm: null,
  vatError: null,
  currentPaymentOption: undefined,
  confirmDetailsSpinner: null,
  changeStripeCard: null,
  stripeSuccess: null,
  showPlans: null,
  stripeError: null,
  stripePurchaseError: null,
  paymentError: null,
  cancelSubscriptionError: null,
  cancelPaymentError: null,
  cardDetailsError: null,
  cardDetailsUpdated: false,
  promoCodeError: null,
};

export interface BillingState {
  subscription: Subscription;
  payment: Payment;
  paymentURL: string;
  payment_verification: PaymentVerification;
  current_plan: Plan;
  plans: Plan[];
  currentSubscriptionTerm: SubscriptionTerm;
  vatError: string | null;
  currentPaymentOption: PAYMENT_OPTIONS | undefined;
  confirmDetailsSpinner: boolean;
  changeStripeCard: boolean;
  stripeSuccess: boolean;
  showPlans: boolean;
  stripeError: string;
  stripePurchaseError: string;
  paymentError: PaymentErrorData;
  cancelSubscriptionError: PaymentErrorData;
  cancelPaymentError: PaymentErrorData;
  cardDetailsError: string;
  cardDetailsUpdated: boolean;
  promoCodeError: string;
}

const _billingReducer = createReducer(
  initialState,
  on(BillingActions.clearChangeCard, (state) => ({
    ...state,
    cardDetailsUpdated: false,
    cardDetailsError: undefined,
  })),
  on(BillingActions.cardDetailsUpdated, (state) => ({
    ...state,
    cardDetailsUpdated: true,
  })),
  on(BillingActions.cardDetailsErrorOccurred, (state, { error }) => ({
    ...state,
    cardDetailsError: error,
  })),
  on(BillingActions.paymentErrorOccurred, (state, { error }) => ({
    ...state,
    paymentError: error,
  })),
  on(BillingActions.cancelPaymentErrorOccurred, (state, { error }) => ({
    ...state,
    cancelPaymentError: error,
  })),
  on(BillingActions.cancelSubscriptionErrorOccurred, (state, { error }) => ({
    ...state,
    cancelSubscriptionError: error,
  })),
  on(BillingActions.changePlanStripeConfirmed, (state) => ({
    ...state,
    stripePurchaseError: null,
  })),
  on(BillingActions.stripePaymentDataReady, (state) => ({
    ...state,
    stripeError: null,
  })),
  on(
    BillingActions.stripeTokenErrorOccurred,
    BillingActions.stripePurchaseErrorOccurred,
    (state, { message }) => ({
      ...state,
      stripePurchaseError: message,
    }),
  ),
  on(BillingActions.stripeErrorOccurred, (state, { message }) => ({
    ...state,
    stripeError: message,
  })),
  on(BillingActions.showPlans, (state) => ({ ...state, showPlans: true })),
  on(BillingActions.hidePlans, (state) => ({ ...state, showPlans: false })),
  on(
    BillingActions.stripeSuccessStatusChanged,
    (state, { state: newState }) => ({ ...state, stripeSuccess: newState }),
  ),
  on(BillingActions.setCurrentPlan, (state, { plan }) => ({
    ...state,
    current_plan: plan,
  })),
  on(BillingActions.setPlans, (state, { plans }) => {
    return { ...state, plans };
  }),
  on(BillingActions.setSubscriptionTerm, (state, { term }) => ({
    ...state,
    currentSubscriptionTerm: term,
  })),
  on(BillingActions.setVatError, (state, { value }) => ({
    ...state,
    vatError: value,
  })),
  on(BillingActions.setSubscription, (state, { subscription }) => ({
    ...state,
    subscription,
  })),
  on(BillingActions.paymentOptionChanged, (state, { option }) => ({
    ...state,
    currentPaymentOption: option,
  })),
  on(BillingActions.confirmBusinessDetails, (state) => ({
    ...state,
    confirmDetailsSpinner: true,
  })),
  on(BillingActions.businessDetailsConfirmed, (state) => ({
    ...state,
    confirmDetailsSpinner: false,
  })),
  on(BillingActions.stripeCardModified, (state) => ({
    ...state,
    changeStripeCard: true,
  })),
  on(BillingActions.stripeCardSaved, (state) => ({
    ...state,
    changeStripeCard: false,
  })),
  on(BillingActions.setPayment, (state, { payment }) => ({
    ...state,
    payment,
  })),
  on(BillingActions.setPaymentURL, (state, { url }) => ({
    ...state,
    paymentURL: url,
  })),
  on(BillingActions.setPromoCodeError, (state, { error }) => ({
    ...state,
    promoCodeError: error,
  })),
  on(BillingActions.addPromotionalCode, (state, { discount_code }) => ({
    ...state,
    promoCodeError: discount_code ? state.promoCodeError : '',
  })),
  // on(
  //   BillingActions.setConfirmDetailsSpinner,
  //   (state, { state: confirmDetailsSpinner }) => ({
  //     ...state,
  //     confirmDetailsSpinner,
  //   })
  // )
);

export function billingReducer(state: BillingState, action: Action) {
  return _billingReducer(state, action);
}
