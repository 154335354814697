import {
  Component,
  OnChanges,
  OnInit,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  input,
  signal,
  ElementRef,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-pdf-html-preview',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './pdf-html-preview.component.html',
  styleUrl: './pdf-html-preview.component.scss',
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class PdfHtmlPreviewComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('iframe', { static: false }) iframe: ElementRef<HTMLIFrameElement>;

  html = input.required<string>();

  currentPage = signal<number | undefined>(undefined);
  totalPages = signal<number | undefined>(undefined);

  private domainName = window.origin;
  private messsageEventListner;
  private supportsBreakBefore: boolean;

  ngOnInit(): void {
    // enable workarounds for browsers with lacking break-before: column support (e.g. Firefox)
    this.supportsBreakBefore = CSS.supports('break-before', 'column');

    const setIframeSizeFn = this.setIframeSize.bind(this);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const ref = this;
    this.messsageEventListner = window.addEventListener(
      'message',
      function (event) {
        if (
          event.origin === ref.domainName &&
          event.data.name === 'pagedJsInit'
        ) {
          ref.currentPage.set(1);
          ref.totalPages.set(event.data.totalPages);

          // set parent iframe size
          setIframeSizeFn(event.data.width, event.data.height, event.data.unit);
        }
      },
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('html' in changes && this.html()) {
      setTimeout(() => {
        this.appendNewIframe();
      }, 0);
    }
  }

  appendNewIframe() {
    const parentElement: HTMLElement = this.iframe.nativeElement.parentElement;
    parentElement.removeChild(this.iframe.nativeElement);

    const newIframe = document.createElement('iframe');

    newIframe.setAttribute('width', '100%');
    newIframe.setAttribute('height', '100%');
    newIframe.classList.add('pdf-preview-iframe', 'mat-elevation-z2');

    this.iframe.nativeElement = newIframe;

    parentElement.appendChild(newIframe);

    const iframeDoc =
      newIframe.contentDocument || newIframe.contentWindow.document;

    iframeDoc.open();
    iframeDoc.write(this.html());

    const iframeHead = iframeDoc.head;
    const iframePagedjsScript = iframeDoc.createElement('script');
    iframePagedjsScript.defer = true;
    iframePagedjsScript.src =
      'https://unpkg.com/pagedjs/dist/paged.polyfill.js';

    const iframePagedjsConfigScript = iframeDoc.createElement('script');
    iframePagedjsConfigScript.textContent = `
    window.PagedConfig = {
      auto: false,
    };

    window.addEventListener("message", function (event) {
      if (
        event.origin === "${this.domainName}" &&
        event.data.name === "changepage"
      ) {
        document
          .querySelectorAll("[data-page-number]")
          .forEach((element, index) => {
            if (index == event.data.page - 1) {
              element.style.display = "block";
            } else {
              element.style.display = "none";
            }
          });
      }
    });

    window.addEventListener("load", () => {
      window.PagedConfig = {
        auto: false,
      };

      class MyHandler extends Paged.Handler {
        constructor(chunker, polisher, caller) {
          super(chunker, polisher, caller);
        }

        afterPageLayout(element, page) {
          if (page.position !== 0) {
            page.element.style.display = "none";
          }
        }
      }
      Paged.registerHandlers(MyHandler);
      window.PagedPolyfill.preview().then((config) => {
        const width = parseFloat(config.size.width.value) + parseFloat(config.size.bleed ? config.size.bleed.left.value : '0.0') + parseFloat(config.size.bleed ? config.size.bleed.right.value : '0.0');
        const height = parseFloat(config.size.height.value) + parseFloat(config.size.bleed ? config.size.bleed.top.value : '0.0') + parseFloat(config.size.bleed ? config.size.bleed.bottom.value : '0.0');
        const unit = config.size.width.unit;
        window.parent.postMessage({name:"pagedJsInit",totalPages:config.total,width:width,height:height,unit:unit}, "${this.domainName}");
      });

      function changePage(pageNumber) {
        document
          .querySelectorAll("[data-page-number]")
          .forEach((element, index) => {
            if (index == pageNumber - 1) {
              element.style.display = "block";
            } else {
              element.style.display = "none";
            }
          });
      }
    });

  `;
    iframeHead.appendChild(iframePagedjsScript);
    iframeHead.appendChild(iframePagedjsConfigScript);
    if (!this.supportsBreakBefore) {
      const style = document.createElement('style');
      style.textContent = `
        .menu .page-break:not(:first-child)::before {
          content: '';
          display: block;
          margin-bottom: 100vh;
        }
      `;
      iframeHead.appendChild(style);
    }
    iframeDoc.close();
  }

  setIframeSize(width: number, height: number, unit: string): void {
    const iframe = this.iframe.nativeElement;
    iframe.classList.add('rendered');
    iframe.style.height = `${height}${unit}`;
    iframe.style.width = `${width}${unit}`;
  }

  showNextPdfPage(): void {
    if (this.currentPage() + 1 > this.totalPages()) return;
    this.iframe.nativeElement.contentWindow.postMessage(
      { name: 'changepage', page: this.currentPage() + 1 },
      this.domainName,
    );
    this.currentPage.set(this.currentPage() + 1);
  }

  showPreviousPdfPage(): void {
    if (this.currentPage() - 1 === 0) return;
    this.iframe.nativeElement.contentWindow.postMessage(
      { name: 'changepage', page: this.currentPage() - 1 },
      this.domainName,
    );
    this.currentPage.set(this.currentPage() - 1);
  }

  ngOnDestroy(): void {
    if (this.messsageEventListner) {
      window.removeEventListener('message', this.messsageEventListner);
    }
  }
}
