import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgModel, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { ContentLanguage, rtlLangs } from 'src/app/shared/constants/languages';
import { CondensedDish, Dish, DishCategory } from 'src/app/shared/Models/dish';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { MenuDishNode } from 'src/app/shared/Models/menu-dish-node';
import {
  ChangeDishOptions,
  MenuDish,
  SimpleMenuDish,
} from 'src/app/shared/Models/menudish';
import { Fulfillable } from 'src/app/shared/Models/models';
import { CondensedSeparator, Separator } from 'src/app/shared/Models/separator';
import { User } from 'src/app/shared/Models/user';

import { DishComponent } from './dish-item/dish-item.component';
import { SectionItemComponent } from './section-item/section-item.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SeparatorItemComponent } from './separator-item/separator-item.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    DishComponent,
    SeparatorItemComponent,
    SectionItemComponent,
    MatProgressBarModule,
    MatMenuModule,
    TourMatMenuModule,
    TranslocoPipe,
  ],
})
export class MenuItemComponent implements OnChanges {
  @Input() autocompleteDishes: CondensedDish[];
  @Input() autocompleteSections: CondensedSeparator[];
  @Input() searchIsActive: boolean;
  @Input() forbiddenDishes: number[];
  @Input() forbiddenSections: number[];
  @Input() inProgress = false;
  @Input() insertItemMenu: MatMenu;
  @Input() isExpanded: boolean;
  @Input() isExtendedLang: boolean;
  @Input() isDragable: boolean;
  @Input() isSorting: boolean;
  @Input() lang: ContentLanguage;
  @Input() node: MenuDishNode;
  @Input() selected: boolean;
  @Input() showNumbering: boolean;
  @Input() translations: any;
  @Input() user: User;

  @Output() autocomplete = new EventEmitter<{
    item: CondensedDish | CondensedSeparator;
    node: MenuDishNode;
    isDish: boolean;
  }>();
  @Output() changeDish = new EventEmitter<{
    node: MenuDishNode;
    data: Fulfillable<MenuDish>;
    options: ChangeDishOptions;
  }>();
  @Output() changeDraggable = new EventEmitter<boolean>();
  @Output() changeNumbering = new EventEmitter<NgModel>();
  @Output() clearAutocomplete = new EventEmitter<string>();
  @Output() delete = new EventEmitter<void>();
  @Output() getSame = new EventEmitter<{
    value: string;
    category: DishCategory;
  }>();
  @Output() getSameSection = new EventEmitter<string>();
  @Output() lineFocused = new EventEmitter<boolean>();
  @Output() setCurrentElement = new EventEmitter<Event>();
  @Output() toggleCurrentElement = new EventEmitter<Event>();
  @Output() toggleSection = new EventEmitter<void>();

  expansionHeight: string;
  forbidden: boolean;
  rtl = false;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('lang' in changes && this.lang) {
      this.rtl = rtlLangs.includes(this.lang);
    }
    if (
      'node' in changes &&
      (this.node.dish?.dish_detail || this.node.dish?.separator_detail)
    ) {
      this.forbidden = this.isForbidden(this.node.dish);
      // FIXME: this field is not included anymore on a SimpleMenuDish
      if ((this.node.dish as MenuDish)?.user_details?.display_options === 1) {
        this.changeDetector.detectChanges();
      }
    }
  }

  changeDishItem({
    item,
    node,
    options,
  }: {
    item: DeepPartial<Dish>;
    node: MenuDishNode;
    options: ChangeDishOptions;
  }): void {
    const newMenuDish = new MenuDish(node.dish);
    newMenuDish.setDish(item as Dish);
    this.changeDish.emit({
      node,
      data: { payload: newMenuDish },
      options,
    });
  }

  changeSectionItem({
    item,
    node,
    options,
  }: {
    item: DeepPartial<Separator>;
    node: MenuDishNode;
    options: ChangeDishOptions;
  }): void {
    const newMenuDish = new MenuDish(node.dish);
    newMenuDish.setSeparator(item as Separator);
    this.changeDish.emit({
      node,
      data: { payload: newMenuDish },
      options,
    });
  }

  isForbidden(dish: SimpleMenuDish | MenuDish): boolean {
    const detail = dish.dish_detail || dish.separator_detail;
    if (detail.category === 'cou' || detail.category === 'opt') return true;
    return dish.dish_detail
      ? this.forbiddenDishes.some((id) => id === detail.id)
      : this.forbiddenSections.some((id) => id === detail.id);
  }

  toggleSectionCollapse(): void {
    this.toggleSection.emit();
  }
}
