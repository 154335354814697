import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { OnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import {
  isRegistrationOption,
  RegistrationOption,
} from 'src/app/shared/Models/registration-steps';
import { UserCategory } from 'src/app/shared/Models/user';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatInputModule } from '@angular/material/input';
import { StopPropagationDirective } from '../../../../shared/Directives/stop-propagation/stop-propagation.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'registration-step-option',
  templateUrl: './registration-step-option.component.html',
  styleUrls: ['./registration-step-option.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatFormFieldModule,
    StopPropagationDirective,
    MatInputModule,
    ReactiveFormsModule,
    TranslocoPipe,
  ],
})
export class RegistrationStepOptionComponent
  implements OnChanges, OnDestroy, OnInit
{
  @Input('option') optionData: RegistrationOption | OnboardingTemplate;
  @Input() other: boolean;
  @Input() label: string;
  @Input() lang: InterfaceLanguage;
  @Input() isSafari: boolean;

  @Output() chooseOption = new EventEmitter<boolean>();

  private destroyed$ = new Subject<void>();
  inputCtrl: UntypedFormControl;
  view: string;
  isOther = false;
  category: UserCategory;

  ngOnInit() {
    if (isRegistrationOption(this.optionData)) {
      this.inputCtrl = new UntypedFormControl('');
      this.inputCtrl.valueChanges
        .pipe(takeUntil(this.destroyed$))
        .subscribe((v: string) => {
          (<RegistrationOption>this.optionData).payload = v;
          this.chooseOption.emit(!!v);
        });
      this.isOther = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      'lang' in changes &&
      this.lang &&
      'is_default' in this.optionData // FIXME: check why this.optionData instanceof OnboardingTemplate is not working anymore
    ) {
      this.view = this.optionData[this.lang];
      this.category = this.optionData.user_category?.[0];
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
