import {
  HttpErrorResponse,
  HttpEvent,
  HttpHeaders,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { getBrowserLang } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { throwError } from 'rxjs';
import { catchError, filter, take, tap } from 'rxjs/operators';
import { LOCAL_STORAGE_KEY } from 'src/app/app.config';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import {
  handleHttpError,
  showSnackbarMessage,
} from 'src/app/shared/ngrx/shared.actions';
import { selectInterfaceLang } from 'src/app/shared/ngrx/shared.selectors';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { getISOLang } from 'src/app/shared/utils.functions';

/**
 * Function to set the headers for the http requests
 * @param headers the headers to set
 * @returns the headers with the tokens set
 */
function setHeaderTokens(headers: HttpHeaders): HttpHeaders {
  const tokenExtractor = inject(HttpXsrfTokenExtractor);

  const token = localStorage.getItem(LOCAL_STORAGE_KEY) || '';
  if (token) {
    headers = headers.set(`Authorization`, `Token ${token}`);
  }
  const csrfToken = tokenExtractor.getToken();
  if (csrfToken) {
    headers = headers.set(`X-CSRFToken`, tokenExtractor.getToken());
  }
  return headers;
}

/**
 * Function to clone the request and set the headers
 * @param req original request to clone
 * @param lang the language to set in the headers
 * @returns clone of the request with the headers set
 */
function cloneRequest(
  req: HttpRequest<any>,
  lang: InterfaceLanguage,
): HttpRequest<any> {
  if (req.url.includes('https://www.googleapis.com/webfonts')) {
    return req;
  }
  let headers = new HttpHeaders();
  if (req.url.includes('menutech.com/')) {
    headers = setHeaderTokens(headers);
  }
  if (lang) {
    headers = headers.set(`Accept-Language`, getISOLang(lang));
  }
  return req.clone({
    withCredentials: true,
    headers,
  });
}

/**
 * Function to intercept the http requests and add the headers, refresh the token if needed and handle errors
 * @param req original request to clone
 * @param next the next handler
 * @returns the next handler with the headers set
 */
export const authInterceptor: HttpInterceptorFn = (req, next) => {
  let lang = getBrowserLang() as InterfaceLanguage;
  const authService = inject(AuthenticationService);
  const store = inject(Store);
  const interfaceLang$ = store.select(selectInterfaceLang);
  interfaceLang$
    .pipe(
      filter((l) => !!l),
      take(1),
    )
    .subscribe((l) => (lang = l));

  const handleError = (event: HttpErrorResponse) => {
    if (event.status === 401) {
      authService.setLogout();
    } else if (!navigator.onLine) {
      store.dispatch(showSnackbarMessage({ errorCode: 0 }));
    } else if ([500, 502].includes(event.status)) {
      store.dispatch(showSnackbarMessage({ errorCode: event.status }));
    }
  };

  // Get the auth header from the service and Clone the request to add the new header.
  // Pass on the cloned request instead of the original request.
  return next(cloneRequest(req, lang)).pipe(
    tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse && event.status === 401) {
        authService.setLogout();
      } else if (event instanceof HttpErrorResponse) {
        store.dispatch(handleHttpError({ error: event }));
      }
    }),
    catchError((event: unknown) => {
      if (!event) return undefined;
      handleError(event as HttpErrorResponse);
      return throwError(() => event);
    }),
  );
};
