<div class="wrapper" [class.opened-mobile]="openMobileSteps">
  <div class="progress-item row">
    <div
      class="steps mobile-title"
      (click)="openMobileSteps = !openMobileSteps"
    >
      <mat-icon
        [style.visibility]="currentStep === 1 ? 'hidden' : 'visible'"
        class="prev-icon"
        (click)="prev($event)"
        >arrow_back</mat-icon
      >
      {{ currentStep }}. {{ stage[currentStep - 1].title }}
      <span class="arrow"></span>
      <mat-icon
        [style.visibility]="currentStep === 4 ? 'hidden' : 'visible'"
        class="next-icon"
        (click)="next($event)"
        >arrow_forward</mat-icon
      >
    </div>
    @for (step of stage; track step; let i = $index) {
      <div
        class="steps"
        [class.current-step]="currentStep === i + 1"
        [class.opened]="mobile && showMobileLangs"
        [class.translate-step]="i === 2"
        (click)="stepClicked(i, $event)"
      >
        @if (i + 1 !== currentStep) {
          <span
            #tooltip="matTooltip"
            class="step"
            [routerLink]="
              privileges?.edit_menu && i !== 2 ? ['/menus', id, i + 1] : null
            "
            [matTooltip]="
              i !== 3 && !privileges?.edit_menu ? userStatus?.message : null
            "
            (click)="i !== 3 && !privileges?.edit_menu ? showSnack() : null"
            matTooltipClass="top-navbar-tooltip"
            (touchstart)="i !== 3 ? showTooltip.emit(tooltip) : null"
          >
            <ng-container
              *ngTemplateOutlet="titleAndMenu; context: { i: i, step: step }"
            ></ng-container>
          </span>
        }
        @if (i + 1 === currentStep) {
          <span class="step" class="disable-link">
            <ng-container
              *ngTemplateOutlet="titleAndMenu; context: { i: i, step: step }"
            ></ng-container>
          </span>
        }
      </div>
    }
  </div>
</div>

<ng-template #titleAndMenu let-i="i" let-step="step">
  @if (i !== 2 || !privileges?.edit_menu || !menu) {
    <span>{{ i + 1 }}. {{ step.title }}</span>
  } @else {
    @if (!openMobileSteps) {
      <span
        #translateMenuTrigger
        class="tran-trigger"
        [matMenuTriggerFor]="tranMenu"
        [class.disable-menu]="!newLanguages?.length"
        >{{ i + 1 }}. {{ step.title }}
        <span class="arrow"></span>
      </span>
    } @else {
      <button class="translate-title" mat-button>
        {{ i + 1 }}. {{ step.title }}...
        <span class="arrow"></span>
      </button>
      <div class="translate-items">
        @if (!mobile || (mobile && showMobileLangs)) {
          @for (lang of newLanguages; track lang) {
            <button
              mat-button
              class="tran-menu"
              (click)="chooseLang(lang, $event)"
            >
              @if (!lang.created) {
                <mat-icon>add</mat-icon>
              }
              <span class="menu-text">{{
                "shared.languages." + lang.value | transloco
              }}</span>
            </button>
          }
        }
      </div>
    }
  }
</ng-template>

<mat-menu #tranMenu="matMenu" [overlapTrigger]="false">
  @if (!mobile || (mobile && showMobileLangs)) {
    @for (lang of newLanguages; track lang) {
      <button mat-menu-item (click)="chooseLang(lang, $event)">
        @if (!lang.created) {
          <mat-icon>add</mat-icon>
        }
        <span class="menu-text">{{
          "shared.languages." + lang.value | transloco
        }}</span>
      </button>
    }
  }
</mat-menu>
