import { Action, createReducer, on } from '@ngrx/store';
import { Module } from 'src/app/shared/Models/user';

import * as ModulesActions from './modules.actions';

export const modulesFeatureKey = 'modules';

export interface ModulesState {
  modules: Module[];
  selected_modules_ids: number[];
  included_modules_ids: number[];
  module_requests: number[];
}

const initialState: ModulesState = {
  modules: null,
  selected_modules_ids: [],
  included_modules_ids: null,
  module_requests: [],
};

const _modulesReducer = createReducer(
  initialState,
  on(ModulesActions.setModules, (state, { modules }) => ({
    ...state,
    modules,
  })),
  on(ModulesActions.setIncludedModulesIDs, (state, { ids }) => ({
    ...state,
    included_modules_ids: ids,
  })),
  on(ModulesActions.setSelectedModulesIDs, (state, { ids }) => ({
    ...state,
    selected_modules_ids: ids,
  })),
  on(ModulesActions.updateSelectedModulesIDS, (state, { ids }) => ({
    ...state,
    selected_modules_ids: ids,
  })),
  on(ModulesActions.moduleRequestsLoadedOrChanged, (state, { requests }) => ({
    ...state,
    module_requests: requests,
  })),
  on(ModulesActions.moduleRequestSent, (state, { module }) => ({
    ...state,
    module_requests: [...state.module_requests, module.module_detail.id],
  })),
);

export function modulesReducer(state: ModulesState, action: Action) {
  return _modulesReducer(state, action);
}
