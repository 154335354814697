import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Validators, ReactiveFormsModule, FormControl } from '@angular/forms';
import {
  MatAutocompleteTrigger,
  MatAutocompleteModule,
} from '@angular/material/autocomplete';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import {
  CondensedDish,
  Dish,
  DishCategory,
  OnMenusInfo,
} from 'src/app/shared/Models/dish';
import { ChangeDishOptions } from 'src/app/shared/Models/menudish';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import * as _ from 'lodash-es';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  tap,
} from 'rxjs/operators';
import { UpperCasePipe } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { GrammarCheckButtonComponent } from '../../../../../shared/Components/grammar-check-button/grammar-check-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MenuDishNode } from 'src/app/shared/Models/menu-dish-node';

@Component({
  selector: 'dish-item',
  templateUrl: './dish-item.component.html',
  styleUrls: ['./dish-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormFieldModule,
    GrammarCheckButtonComponent,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatIconModule,
    MatOptionModule,
    UpperCasePipe,
    TranslocoPipe,
  ],
})
export class DishComponent implements OnChanges, OnInit {
  @Input() focused: boolean;
  @Input() isExtendedLang: boolean;
  @Input() item: Dish;
  @Input() lang: ContentLanguage;
  @Input() node: MenuDishNode;
  @Input() opacity: boolean;
  @Input() same: CondensedDish[];
  @Input() subscriptionType: string;
  @Input() translations: object;

  @Output() autoSelect = new EventEmitter<{
    item: CondensedDish;
    node: MenuDishNode;
    isDish: boolean;
  }>();
  @Output() changeItem = new EventEmitter<{
    item: Dish;
    node: MenuDishNode;
    options: ChangeDishOptions;
  }>();
  @Output() clearAutocomplete = new EventEmitter();
  @Output() fieldFocusChanged = new EventEmitter<boolean>();
  @Output() focusedEvent = new EventEmitter();
  @Output() getSame = new EventEmitter<{
    value: string;
    category: DishCategory;
  }>();
  @Output() setCurrent = new EventEmitter<Event>();

  canShowDays = false;
  control = new FormControl('', {
    nonNullable: true,
    validators: [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(200),
    ],
  });
  currentNode: MenuDishNode;
  inputFocused: boolean;
  placeholderDays: string;
  placeholderName: string;
  showGrammar: boolean;
  skipSave = false;

  days = 'dishes.days';
  last = 'dishes.last';
  next = 'dishes.next';
  today = 'dishes.today';

  @ViewChild('autoTrigger', { static: false })
  auto: MatAutocompleteTrigger;
  @ViewChild('inputRef', { static: true })
  inputRef: ElementRef;

  constructor(
    public element: ElementRef,
    private destroyRef: DestroyRef,
    private translate: TranslocoService,
    private utils: UtilsService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('node' in changes) {
      this.auto?.closePanel();
    }
    if ('item' in changes) {
      this.skipSave = false;
      if (
        changes.item.previousValue &&
        changes.item.currentValue.category !==
          changes.item.previousValue?.category
      ) {
        this.clearAutocomplete.emit();
      }
      if (this.item) {
        this.placeholderDays = this.getPlaceholderDays();
        this.showGrammar = !!this.item?.[`spellcheck_` + this.lang];
      }
    }
    if (
      this.control &&
      (('item' in changes && !this.inputFocused) ||
        ('lang' in changes && !changes.lang.firstChange))
    ) {
      this.changeInputValue();
    }
    if (this.control && 'item' in changes) this.control.enable();
    if ('focused' in changes) setTimeout(() => this.changeInputFocus());
    if ('subscriptionType' in changes)
      this.canShowDays = ['ent', 'hotel'].includes(this.subscriptionType);
  }

  ngOnInit() {
    this.utils.blurInput
      .pipe(
        filter((v) => !!v),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (!this.inputFocused) return undefined;
        this.inputRef.nativeElement.blur();
        this.utils.blurInput.next(false);
      });
    this.control.valueChanges
      .pipe(
        tap(() => (this.currentNode = this.node)),
        debounceTime(300),
        filter((v) => v !== null),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value: string | Dish) => {
        if (this.inputFocused && !this.skipSave) {
          if (!_.isString(value)) value = value[this.lang];
          if (value.length > 2 && this.inputFocused) {
            this.getSame.emit({ value, category: this.item.category });
          }
        }
      });
    this.translate
      .selectTranslate([this.days, this.today, this.last, this.next], {
        x: '<x>',
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((v) => {
        this.days = v[0];
        this.today = v[1];
        this.last = v[2];
        this.next = v[3];
        this.placeholderDays = this.getPlaceholderDays();
      });
  }

  changeInputFocus(): void {
    if (!this.inputRef || !this.inputRef.nativeElement) return undefined;
    if (this.focused && !this.inputFocused) {
      this.inputRef.nativeElement.focus({ preventScroll: true });
    } else if (!this.focused && this.inputFocused) {
      this.inputRef.nativeElement.blur();
    }
  }

  changeInputValue(): void {
    if (!this.item[this.lang]) {
      this.control.reset('', { emitEvent: false });
      if (this.item.id) {
        const { name } = this.utils.tryGetLabel(this.item, this.lang);
        this.placeholderName = name;
      }
    } else if (this.item[this.lang] !== this.control.value) {
      this.control.setValue(this.item[this.lang], {
        emitEvent: false,
      });
    }
  }

  displayWith = (value: string | Dish): string => {
    if (_.isString(value)) return value;
    return value ? value[this.lang] : '';
  };

  calcDays(lastOnMenus: OnMenusInfo[], nextOnMenus: OnMenusInfo[]) {
    const last = lastOnMenus ? lastOnMenus[0] : undefined;
    const result = [];
    if (last) {
      const lastDays = this.calcDay(last.date);
      result[0] = lastDays;
    }
    const next = nextOnMenus ? nextOnMenus[0] : undefined;
    if (next) {
      const nextDays = this.calcDay(next.date);
      result[1] = nextDays;
    }
    return result;
  }

  calcDay(date: string): any {
    const days = this.dateDiffInDays(date);
    if (days === 0) {
      return this.today;
    }
    return days;
  }

  dateDiffInDays = (dateString: string): number => {
    const date = new Date(dateString);
    const utc1 = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
    const d = new Date();
    const utc2 = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
    return -1 * Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
  };

  focusIn(): void {
    this.focusedEvent.emit(true);
    this.fieldFocusChanged.emit(false);
    this.inputFocused = true;
  }

  focusOut(): void {
    if (this.skipSave) return undefined;
    this.focusedEvent.emit(false);
    this.submit();
    this.fieldFocusChanged.emit(true);
    this.inputFocused = false;
    this.auto?.closePanel();
  }

  getDays(dish: CondensedDish): string {
    let result = '';
    const days = this.calcDays(dish.last_on_menus, dish.next_on_menus);
    if (days[0]) {
      if (_.isNumber(days[0])) {
        if (days[0] > 0) {
          result += `+${days[0]}${this.days}`;
        } else {
          result += `${days[0]}${this.days}`;
        }
      } else {
        result += days[0];
      }
    }

    let next = '';
    if (days[1]) {
      if (_.isNumber(days[1])) {
        if (days[1] > 0) {
          next += `+${days[1]}${this.days}`;
        }
      } else {
        next += `${days[1]}`;
      }
      if (days[0]) {
        next = `/${next}`;
      }
      result += next;
    }

    return result;
  }

  getPlaceholderDays(): string {
    if (!this.item || !this.subscriptionType) return '';
    if (!this.canShowDays) return undefined;
    let result = '';
    const days = this.calcDays(
      this.item.last_on_menus,
      this.item.next_on_menus,
    );

    if (days[0]) {
      if (_.isNumber(days[0])) {
        result += `${this.last.replace('<x>', Math.abs(days[0]).toString())}`;
      } else {
        result += `${this.today}`;
      }
    }

    let next = '';
    if (days[1]) {
      if (_.isNumber(days[1])) {
        next += `${this.next.replace('<x>', days[1].toString())}`;
      } else {
        next += `${this.today}`;
      }
      if (days[0]) {
        next = ` | ${next}`;
      }
      result += next;
    }
    return result;
  }

  selectOption({
    option: { value },
  }: {
    option: {
      value: CondensedDish;
    };
  }) {
    this.skipSave = true;
    this.auto?.closePanel();
    this.control.setValue(value[this.lang], { emitEvent: false });
    this.inputRef.nativeElement.blur();
    this.control.disable();
    this.focusedEvent.emit(false);
    this.fieldFocusChanged.emit(true);
    this.autoSelect.emit({
      item: value,
      node: this.currentNode ?? this.node,
      isDish: true,
    });
  }

  submit(): void {
    this.auto?.closePanel();
    if (!this.control.value) this.control.reset(null, { emitEvent: false });
    if (!this.control.value || this.control.value === this.item[this.lang]) {
      return undefined;
    }
    const newItem = {
      ...this.item,
      [this.lang]: this.control.value,
    };
    this.clearAutocomplete.emit();
    if (
      !(this.control.errors && (this.control.dirty || this.control.touched))
    ) {
      this.changeItem.emit({
        item: newItem,
        node: this.currentNode ?? this.node,
        options: { onlyName: true },
      });
      this.control.disable();
    }
  }

  wtf(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }
}
