import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as StyleConstants from 'src/app/shared/constants/style';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { Menu } from 'src/app/shared/Models/menu';
import { User } from 'src/app/shared/Models/user';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { set } from 'lodash-es';
import { TranslocoPipe } from '@jsverse/transloco';
import { SelectItemComponent } from '../select-item/select-item.component';
import { ToggleItemComponent } from '../toggle-item/toggle-item.component';
import { LockItemComponent } from '../lock-item/lock-item.component';
import { MtColorPickerComponent } from '../../../../../shared/Components/mt-color-picker/mt-color-picker.component';
import { MtTooltipDirective } from '../../../../../shared/Directives/mt-tooltip/mt-tooltip.directive';
import { NumberItemComponent } from '../number-item/number-item.component';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'style-mobile-tab',
  templateUrl: './style-mobile-tab.component.html',
  styleUrls: [
    '../../style.component.scss',
    './style-mobile-tab.component.scss',
  ],
  standalone: true,
  imports: [
    MatExpansionModule,
    MatIconModule,
    NumberItemComponent,
    MtTooltipDirective,
    MtColorPickerComponent,
    LockItemComponent,
    ToggleItemComponent,
    SelectItemComponent,
    TranslocoPipe,
  ],
})
export class StyleMobileTabComponent {
  @Input() disabledFields: string[] = [];
  @Input() lockedFields: string[];
  @Input() menu: Menu;
  @Input() showLocks: boolean;
  @Input() user: User;
  @Output() updateMenu = new EventEmitter<DeepPartial<Menu>>();

  optionalInformation = [
    'nutrition_rating',
    'nutrition_values',
    'carbohydrate_units',
    'allergens',
    'labels',
    'additives',
    'origin',
  ];
  styleConstants = StyleConstants;

  constructor(private utils: UtilsService) {}

  clearColor(field: string): void {
    this.updateMenu.emit({
      style: {
        [field]: null,
      },
    });
  }

  colorChanged(field: string, color: string): void {
    if (this.menu.style[field] === color) return undefined;
    this.updateMenu.emit({
      style: {
        [field]: color,
      },
    });
  }

  hasModule = (code: string): boolean => this.utils.hasModules(code);
}
