@if (styleModified || styles?.length) {
  <div class="swiper-controls">
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
    <swiper-container
      class="carousels"
      winSwiper
      init="false"
      [config]="swiperConfig"
    >
      @if (styleModified) {
        <swiper-slide>
          <select-style
            class="carousel-cell"
            [create]="true"
            [styleSpinner]="styleSpinner"
            (createStyle)="createStyle.emit()"
          >
          </select-style>
        </swiper-slide>
      }
      @for (style of styles; track style) {
        <swiper-slide>
          <select-style
            class="carousel-cell"
            [canModify]="canModify"
            [lang]="lang"
            [modified]="styleModified"
            [selected]="menu?.style_original === style.id"
            [style]="style"
            [styleSpinner]="styleSpinner"
            (deleteStyle)="deleteStyle.emit(style)"
            (renameStyle)="renameStyle.emit({ style: style, value: $event })"
            (openDialog)="openDialog.emit(style)"
            (selectStyle)="selectStyle.emit(style)"
            (updateStyle)="updateStyle.emit()"
          >
          </select-style>
        </swiper-slide>
      }
    </swiper-container>
  </div>
}
