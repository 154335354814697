export enum Categories {
  DAY = 'dow',
  SECTION = 'sec',
  DISH = 'dis',
  BEVERAGE = 'bev',
  WINE = 'win',
  OPTION = 'opt',
  COURSE = 'cou',
}

export enum CategoriesSettings {
  'dow' = 'add_days',
  'sec' = 'add_sections',
  'dis' = 'add_dishes',
  'bev' = 'add_beverages',
  'win' = 'add_wines',
  'opt' = 'add_courses',
  'cou' = 'add_courses',
}
