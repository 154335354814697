<div class="wrapper">
  <h1 color="primary" class="title">
    {{ "app.pages.error.title" | transloco }}
  </h1>
  <div class="video">
    @if (!isMobile) {
      <video loop width="100%" autoplay="autoplay" class="error">
        <source src="assets/video/4041.m4v" type="video/mp4" />
      </video>
    } @else {
      @if (isMobile) {
        <img
          src="assets/img/error.jpg"
          alt="error image"
          class="error image-err"
        />
      }
    }
  </div>
  <h3 class="text" [class.mat-body]="isMobile">
    {{ "404.auth.description" | transloco }}
  </h3>
  <div class="actions">
    <button
      mat-flat-button
      color="primary"
      [routerLink]="loggedIn ? ['/dashboard'] : ['/login']"
    >
      {{ "404.auth.actions.back" | transloco }}
    </button>
  </div>
</div>
