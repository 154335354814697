<mat-checkbox
  #checbox
  [color]="color"
  [disabled]="disabled"
  (change)="
    showConfirmDialog
      ? confirmAssign(option, checbox)
      : triggerOption.emit(option)
  "
  [ngModel]="option && option.value"
  (ngModelChange)="option && (option.value = $event)"
  >{{ symbolsData[option.name]?.name }}
  @if (
    symbolsData[option.name]?.app_symbol || symbolsData[option.name]?.symbol
  ) {
    <span
      class="{{ this.type === 'additives' ? classForSymbols : '' }}"
      [class.allergen-symbol]="
        this.type !== 'additives' && !!symbolsData[option.name]?.app_symbol
      "
      [class.allergen-symbol-clear]="
        symbolsData[option.name]?.legend_symbol === 4
      "
      >{{
        symbolsData[option.name]?.app_symbol || symbolsData[option.name]?.symbol
      }}</span
    >
  }
</mat-checkbox>
