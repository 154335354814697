import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { MenuAnalysis } from 'src/app/shared/Models/menu';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-menu-analysis',
  templateUrl: './menu-analysis.component.html',
  styleUrls: ['./menu-analysis.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButtonModule,
    MatDialogClose,
    TranslocoPipe,
  ],
})
export class MenuAnalysisComponent {
  constructor(
    public dialogRef: MatDialogRef<MenuAnalysisComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: MenuAnalysis & {
      credits: number;
      remaining_credits: number;
      analysis_type: string;
      download_analysis: () => void;
    },
  ) {}
}
