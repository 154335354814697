<div class="sidebar-intro">
  <p class="editing-info">{{ "style.sidebar.header.editing" | transloco }}:</p>
  @if (menu && menu.style) {
    <h3>
      {{ menu?.style[lang] }}
      @if (styleModified) {
        <span class="style-modified">
          ({{ "style.sidebar.header.modified" | transloco }})</span
        >
      }
    </h3>
  }
</div>

<mat-tab-group #tabGroup class="field-density-3" [dynamicHeight]="true">
  <mat-tab [label]="'style.sidebar.tabs.general' | transloco">
    <ng-template matTabContent>
      <style-general-tab
        [disabledFields]="disabledFields"
        [lang]="lang"
        [lockedFields]="lockedFields"
        [menu]="menu"
        [options]="options"
        [courses]="courses"
        [mtFonts]="mtFonts"
        [showLocks]="showLocks"
        (uploadLogoImage)="uploadLogo.emit($event)"
        (updateMenu)="updateMenu.emit($event)"
      ></style-general-tab>
    </ng-template>
  </mat-tab>

  <mat-tab [label]="'style.sidebar.tabs.print' | transloco">
    <ng-template matTabContent>
      <style-print-tab
        [backgroundImages]="bgImages"
        [disabledFields]="disabledFields"
        [lockedFields]="lockedFields"
        [menu]="menu"
        [showLocks]="showLocks"
        (updateMenu)="updateMenu.emit($event)"
        (uploadBackgroundImage)="uploadBackgroundImage.emit($event)"
        (showAllBackgrounds)="showAllBackgrounds.emit($event)"
      ></style-print-tab>
    </ng-template>
  </mat-tab>

  <mat-tab [label]="'style.sidebar.tabs.mobile' | transloco">
    <ng-template matTabContent>
      <style-mobile-tab
        [disabledFields]="disabledFields"
        [menu]="menu"
        [lockedFields]="lockedFields"
        [showLocks]="showLocks"
        [user]="user"
        (updateMenu)="updateMenu.emit($event)"
      ></style-mobile-tab>
    </ng-template>
  </mat-tab>
</mat-tab-group>

<div class="info-wrapper">
  @if (menu?.style && [0, 1].includes(user?.role)) {
    <div class="locks-info-wrapper">
      <div class="info">
        {{
          "style.sidebar.locks.info"
            | transloco: { number: lockedFields?.length }
        }}
      </div>
      <mat-slide-toggle
        color="primary"
        (change)="updateShowLocks($event)"
        [(ngModel)]="showLocks"
      >
        <div>
          {{ "style.sidebar.locks.show" | transloco }}
        </div>
      </mat-slide-toggle>
      @if (lockedFields?.length) {
        <button mat-flat-button class="clear-locks" (click)="clearLocks()">
          {{ "style.sidebar.locks.clear" | transloco }}
        </button>
      }
    </div>
    @if (user.organisation) {
      <div class="locks-info-wrapper">
        <div class="info">
          {{ "style.sidebar.locks.block-fields" | transloco }}
        </div>
        <mat-slide-toggle color="primary" [formControl]="blockFieldsControl">
          <div>
            {{ "style.sidebar.locks.block" | transloco }}
          </div>
        </mat-slide-toggle>
      </div>
    }
  }
</div>
