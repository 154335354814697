import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-html-preview',
  templateUrl: './html-preview.component.html',
  styleUrls: ['./html-preview.component.scss'],
  standalone: true,
})
export class HtmlPreviewComponent implements OnChanges {
  @Input() htmlPreview = '';
  @ViewChild('iframe', { static: false }) iframe: ElementRef;

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      if ('htmlPreview' in changes && this.htmlPreview && this.iframe) {
        // Remove the existing iframe from the DOM
        const parentElement: HTMLElement =
          this.iframe.nativeElement.parentElement;
        parentElement.removeChild(this.iframe.nativeElement);

        // Create a new iframe element
        const newIframe = document.createElement('iframe');
        newIframe.setAttribute('frameborder', '0');
        newIframe.setAttribute('width', '100%');
        newIframe.setAttribute('style', 'height: 70vh; min-height: 300px');
        newIframe.style.display = 'block';
        newIframe.style.boxShadow =
          '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)';
        this.iframe.nativeElement = newIframe; // Update the ViewChild reference

        // Add the new iframe to the DOM
        parentElement.appendChild(newIframe);

        // Write the new content to the new iframe
        const iframeDoc =
          newIframe.contentDocument || newIframe.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(this.htmlPreview);
        iframeDoc.close();
      }
    });
  }
}
