<h3>
  {{ "menus.steps.complete.title" | transloco }}
</h3>
<mat-card class="wrapper">
  @if ((translationsLoading$ | async) || !menu?.url) {
    <spinner></spinner>
  }
  @if (menu?.url && (translationsLoading$ | async) === false) {
    <div class="list-of-items">
      <div class="completed-menu" [class.mat-elevation-z5]="isMobile">
        <div class="header">
          {{ "menus.steps.complete.headers.completed" | transloco }}
        </div>
        @for (translate of translations$ | async; track translate) {
          <div
            class="completed-menu-item"
            [class.single-language]="
              !(multilingual && (translations$ | async).length > 1)
            "
            [class.selected]="
              translate.id === checkedTranslations[0]?.translation?.id
            "
          >
            @if ((translations$ | async).length > 1) {
              <mat-checkbox
                color="primary"
                class="language-name-checkbox"
                [disabled]="
                  multilingual &&
                  !params[translate.language] &&
                  this.currentLangNumber === this.languagesNumber
                "
                (change)="handleCheckLang(translate, $event)"
                [(ngModel)]="params[translate.language]"
              >
              </mat-checkbox>
            }
            <span class="language-name">{{
              languagesTitle[translate.language]
            }}</span>
          </div>
        }
        @if (newLanguages?.length) {
          <div
            #tooltip="matTooltip"
            class="add-language"
            (click)="createClicked(translateButton, tooltip)"
            [matTooltip]="!privileges?.edit_menu ? userStatus?.message : null"
            matTooltipClass="top-navbar-tooltip"
          >
            <button
              #translateButton
              [matMenuTriggerFor]="matMenu"
              [disabled]="!privileges?.edit_menu || !userStatus?.ready"
              (touchstart)="showTooltip(tooltip)"
              mat-button
            >
              <mat-icon>add</mat-icon>
              <span class="text">{{
                "menus.steps.complete.add" | transloco
              }}</span>
            </button>
          </div>
        }
      </div>
      @if (!isMobile && menu?.url) {
        <div class="menu-review">
          <div class="header">
            {{ "menus.steps.complete.headers.review" | transloco }}
          </div>
          <ng-container *ngTemplateOutlet="menuReview"></ng-container>
        </div>
        <div class="menu-actions">
          <div class="header">
            {{ "menus.steps.complete.headers.actions" | transloco }}
          </div>
          <ng-container *ngTemplateOutlet="actions"></ng-container>
        </div>
      }
      @if (isMobile && menu?.url) {
        <mat-tab-group class="tabs">
          <mat-tab [label]="'menus.steps.complete.headers.review' | transloco">
            <ng-container *ngTemplateOutlet="menuReview"></ng-container>
          </mat-tab>
          <mat-tab [label]="'menus.steps.complete.headers.actions' | transloco">
            <ng-container *ngTemplateOutlet="actions"></ng-container>
          </mat-tab>
        </mat-tab-group>
      }
    </div>
  }
  <div class="button-actions">
    <button
      mat-button
      [disabled]="!menu?.url || (translations$ | async)?.length === 0"
      (click)="showTranslatedMenu()"
    >
      {{ "menus.steps.complete.actions.preview" | transloco }}
    </button>
    <button
      mat-button
      [disabled]="
        !menu?.url || downloadingPdf || (translations$ | async)?.length === 0
      "
      (click)="downloadPdf()"
    >
      @if (downloadingPdf) {
        <spinner
          [style.display]="'inline-block'"
          [config]="{ radius: 7 }"
        ></spinner>
      }
      {{ "menus.steps.complete.actions.download" | transloco }}
    </button>
    <button
      mat-button
      [disabled]="
        !menu?.url || (translations$ | async)?.length === 0 || printLoading
      "
      (click)="printTranslatedMenu()"
    >
      @if (printLoading) {
        <mat-spinner class="spinner" diameter="20"></mat-spinner>
      }
      {{ "menus.steps.complete.actions.print" | transloco }}
    </button>
  </div>
</mat-card>

<ng-template #menuReview>
  @for (translate of translations$ | async; track translate) {
    <div class="menu-review-item">
      @if (translate?.translations_missing) {
        <span
          [innerHtml]="
            'menus.steps.complete.missing'
              | transloco: { num: translate?.translations_missing }
          "
        ></span>
        <span class="icon-button-density-3">
          <button
            mat-icon-button
            class="button"
            [routerLink]="privileges?.edit_menu ? ['/menus', menu.id, 3] : null"
            [queryParams]="
              privileges?.edit_menu
                ? { lang: translate.language, sort: 2 }
                : null
            "
          >
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </span>
      } @else {
        <mat-icon class="done-icon">done</mat-icon>
      }
    </div>
  }
</ng-template>
<ng-template #actions>
  @for (translate of translations$ | async; track translate) {
    <div class="action-review-item">
      <div
        #editTooltip="matTooltip"
        class="icon-button-density-3"
        [matTooltip]="
          !privileges?.edit_menu
            ? ('menus.complete.edit_disable' | transloco)
            : ''
        "
        matTooltipClass="top-navbar-tooltip"
        (click)="showEditSnackMessage(editButton, editTooltip)"
      >
        <button
          mat-icon-button
          class="button"
          #editButton
          [disabled]="!privileges?.edit_menu || !userStatus?.ready"
          [routerLink]="
            privileges?.edit_menu
              ? [
                  '/menus',
                  menu.id,
                  menu.base_language === translate.language ? 1 : 3,
                ]
              : null
          "
          [queryParams]="
            privileges?.edit_menu ? { lang: translate.language } : null
          "
        >
          <mat-icon class="icon">mode_edit</mat-icon>
        </button>
      </div>
      <div class="icon-button-density-3">
        @if (translate?.language !== menu.base_language) {
          <button mat-icon-button (click)="deleteTranslation(translate.url)">
            <mat-icon class="icon">delete</mat-icon>
          </button>
        }
      </div>
    </div>
  }
  @if (menu?.url) {
    <div class="print-instructions button-density-3">
      <button mat-flat-button (click)="openPrintInstructions()">
        {{ "menus.complete.instructions.title" | transloco }}
      </button>
    </div>
  }
</ng-template>

<mat-menu #matMenu="matMenu">
  @for (item of newLanguages; track item) {
    <button mat-menu-item (click)="addTranslation(item)">
      <span>{{ languagesTitle[item] }}</span>
    </button>
  }
</mat-menu>
