import { Action, createReducer, on } from '@ngrx/store';
import { Dashboard } from 'src/app/shared/Models/dashboard';
import * as DashboardActions from './dashboard.actions';
import { UserOnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import { cloneDeep } from 'lodash-es';

export const dashboardFeatureKey = 'dashboard';

const initialState = <Dashboard>{
  navigation: false,
  is_loading: false,
  latest_templates: [],
  most_popular_dish: {},
  last_menu: null,
  menus_by_date: [],
  menus_created: null,
  dishes: {
    list: [],
    is_loading: false,
  },
  courses: {
    results: [],
    is_loading: false,
  },
  user_onboarding_templates: [],
};

const _dashboardReducer = createReducer(
  initialState,
  on(DashboardActions.setData, (state, { dashboard }) => {
    return {
      ...state,
      ...dashboard,
    };
  }),
  on(DashboardActions.setTemplateRestrictions, (state, { templates }) => {
    return {
      ...state,
      latest_templates: templates,
    };
  }),
  on(DashboardActions.startLoadingDashboard, (state, { is_loading }) => {
    return {
      ...state,
      is_loading,
    };
  }),
  on(DashboardActions.clearData, () => {
    return {
      ...initialState,
    };
  }),
  on(DashboardActions.clearLastMenu, (state, { id }) => {
    if (state.last_menu?.id === id) {
      return {
        ...state,
        last_menu: null,
      };
    }
    return {
      ...state,
    };
  }),
  on(DashboardActions.setDishesCurrentMenuLoading, (state, { loading }) => {
    return {
      ...state,
      dishes: {
        ...state.dishes,
        is_loading: loading,
      },
    };
  }),
  on(DashboardActions.setDishesCurrentMenu, (state, { dishes }) => {
    return {
      ...state,
      dishes: {
        ...state.dishes,
        list: dishes,
      },
    };
  }),
  on(DashboardActions.setCoursesLoading, (state, { loading }) => {
    return {
      ...state,
      courses: {
        ...state.courses,
        is_loading: loading,
      },
    };
  }),
  on(DashboardActions.setCourses, (state, { courses }) => {
    return {
      ...state,
      courses: {
        ...state.courses,
        results: courses,
      },
    };
  }),
  on(DashboardActions.setUserOnboardingTemplates, (state, { payload }) => ({
    ...state,
    user_onboarding_templates: payload,
  })),
  on(DashboardActions.setUserOnboardingTemplateUsed, (state, { id, menu }) => {
    let templates = state.user_onboarding_templates?.slice() || [];
    templates = templates.map((tmp) => {
      if (tmp.id === id) {
        return new UserOnboardingTemplate({
          ...tmp,
          menu: menu.id,
          menu_detail: menu,
        });
      }
      return tmp;
    });
    return {
      ...state,
      user_onboarding_templates: templates,
    };
  }),
  on(DashboardActions.removeUserOnboardingTemplateMenu, (state, { menuId }) => {
    return {
      ...state,
      user_onboarding_templates: state.user_onboarding_templates.map(
        (template) => {
          if (template.menu === menuId) {
            template = cloneDeep(template);
            template.menu = null;
            template.menu_detail = null;
          }
          return template;
        },
      ),
    };
  }),
);

export function dashboardReducer(state: Dashboard, action: Action) {
  return _dashboardReducer(state, action);
}
