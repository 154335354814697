import { Component } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss'],
  standalone: true,
  imports: [TranslocoPipe],
})
export class ServerErrorComponent {
  constructor() {}
}
