import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { Rule } from 'src/app/shared/Models/ruleset';
import { SimpleType, Type } from 'src/app/shared/Models/type';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'locations-menu-context-item',
  styleUrls: ['./locations-menu-context-item.component.css'],
  templateUrl: './locations-menu-context-item.component.html',
  standalone: true,
  imports: [MatMenuModule, MatIconModule, MatTooltipModule, TranslocoPipe],
})
export class LocationsMenuContextItemComponent {
  @Input() lang: InterfaceLanguage;
  @Input() items: (Rule | Type)[];
  @Input() privileges: any;

  @Output() createArchive = new EventEmitter<void>();
  @Output() createMenu = new EventEmitter<number>();

  @ViewChild('childMenu', { static: true })
  public childMenu: MatMenu;

  constructor(private utils: UtilsService) {}

  getAlternativeLanguage = (item: Rule | SimpleType) =>
    this.utils.tryGetLabel(item, this.lang).name;
}
