import { createSelector } from '@ngrx/store';
import { menusFeatureKey } from 'src/app/menus/ngrx/menus.store';
import { State } from 'src/app/reducers';
import { menuStyleFeatureKey } from './menu-style.reducer';

const selectState = (state: State) =>
  state[menusFeatureKey]?.[menuStyleFeatureKey];

export const selectUserLayouts = createSelector(
  selectState,
  (state) => state?.user_layouts,
);

export const selectPublicLayouts = createSelector(
  selectState,
  (state) => state?.public_layouts,
);

export const selectSpecialLayouts = createSelector(
  selectState,
  (state) => state?.event_layouts,
);

export const selectLayoutsCategories = createSelector(
  selectState,
  (state) => state?.categories,
);

export const selectSwitchLayoutTab = createSelector(
  selectState,
  (state) => state?.show_tab,
);

export const selectStyleCategories = createSelector(
  selectState,
  (state) => state?.styleCategories,
);

export const selectEventStyles = createSelector(
  selectState,
  (state) => state?.event_styles,
);

export const selectPublicStyles = createSelector(
  selectState,
  (state) => state?.public_styles,
);

export const selectUserStyles = createSelector(
  selectState,
  (state) => state?.user_styles,
);

export const selectSwitchStyleTab = createSelector(
  selectState,
  (state) => state?.show_style_tab,
);

export const selectStyleSpinner = createSelector(
  selectState,
  (state) => state?.show_style_spinner,
);

export const selectSeparatorOptions = createSelector(
  selectState,
  (state) => state?.separator_options,
);

export const selectSeparatorCourses = createSelector(
  selectState,
  (state) => state?.separator_courses,
);
