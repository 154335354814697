<header>
  <h1>
    {{
      enableNextStep
        ? ("auth.registration.steps.done.title" | transloco: titleParams)
        : ("auth.registration.steps.three.title" | transloco)
    }}
  </h1>
  @if (!this.user?.status?.email_verified) {
    <p>
      {{
        enableNextStep
          ? ("auth.registration.steps.done.subtitle" | transloco)
          : ("auth.registration.steps.three.subtitle" | transloco)
      }}
    </p>
  }
  <ng-content></ng-content>
</header>

<div class="spinner-wrapper">
  @if (!enableNextStep) {
    <mat-progress-spinner
      mode="determinate"
      [value]="values$ | async"
      [strokeWidth]="7"
    ></mat-progress-spinner>
    <div class="spinner-value">{{ values$ | async }}%</div>
  } @else {
    <img
      src="../../../../../../assets/img/email-animation.gif"
      alt="email received"
    />
  }
</div>

<ng-template #button>
  <button
    mat-flat-button
    color="primary"
    class="footer-button"
    (click)="clickEventsStream.next(undefined)"
    [disabled]="!enableNextStep"
  >
    {{ "auth.registration.steps.three.button" | transloco }}
    <mat-icon class="footer-button-icon">arrow_forward</mat-icon>
  </button>
</ng-template>
