import {
  InterfaceLangsAsDescriptionObject,
  InterfaceLangsAsObject,
  InterfaceLanguage,
} from '../constants/languages';
import { DISHES_VIEW_VILTERS } from './dish';
import { Invitation } from './invitation';
import { Plan, Subscription } from './subscription';
import {
  arrayToObject,
  BillingCurrenciesObject,
  InterfaceDescriptionsObject,
  InterfaceLangsObject,
} from './type-utils';

export const billingCurrencies = [
  'eur',
  'aud',
  'cad',
  'gbp',
  'nzd',
  'usd',
] as const;

export const interfaceCurrencies = [
  'EUR',
  'GBP',
  'CHF',
  'USD',
  'ZAR',
  'CAD',
  'AUD',
  'NZD',
  'HRK',
  'SEK',
  'DKK',
  'PLN',
] as const;

export const BillingCurrenciesAsObject = arrayToObject(
  billingCurrencies.slice(1, billingCurrencies.length) as unknown as string[],
  'price_',
);

export enum UserRole {
  OWNER = 0,
  ADMIN = 1,
  EDITOR = 2,
  CREATOR = 3,
  READONLY = 4,
  READONLY_EDITOR = 5,
}

export interface Affiliate {
  code: string;
  discount_rate: number;
  id: number;
  logo: string;
  logo_small: string;
  name: string;
  url: string;
}

export class SimpleUser {
  url: string;
  id: number;
  email: string;
  first_name: string;
  location: number;
  last_name: string;
  role: UserRole;
  user_data_pdf: string;
}

export type UserCategory =
  | 'rest'
  | 'hotel'
  | 'bakery'
  | 'catering'
  | 'school'
  | 'bar_cafe'
  | 'senior_care'
  | 'hospital'
  | 'edu';

export const NUTRITION_UNITS = [
  'energy',
  'fat',
  'fat_saturated',
  'carbs',
  'carbs_sugar',
  'protein',
  'salt',
  'fibres',
  'fat_monounsaturated',
  'fat_polyunsaturated',
  'fat_trans',
  'calcium',
  'cholesterol',
  'iron',
  'potassium',
  'sodium',
  'vitamina',
  'vitaminc',
  'kalium',
  'natrium',
  'magnesium',
  'iodine',
  'vitamine',
  'vitaminb',
  'acidoctd',
  'acidurid',
] as const;

export const NUTRITION_UNITS_MAP: { [key: string]: string } = {
  energy: 'kcal',
  fat: 'g',
  fat_saturated: 'g',
  carbs: 'g',
  carbs_sugar: 'g',
  protein: 'g',
  salt: 'g',
  fibres: 'g',
  fat_monounsaturated: 'g',
  fat_polyunsaturated: 'g',
  fat_trans: 'g',
  acidurid: 'g',
  acidoctd: 'g',
  cholesterol: 'mg',
  calcium: 'mg',
  iron: 'mg',
  potassium: 'mg',
  sodium: 'mg',
  vitaminb: 'mg',
  vitamine: 'mg',
  kalium: 'mg',
  natrium: 'mg',
  magnesium: 'mg',
  iodine: 'mg',
  vitaminc: 'mg',
  vitamina: 'IU',
};

export type NutritionUnit = (typeof NUTRITION_UNITS)[number];

export interface AccountSettings {
  enabled_nutrition_values: NutritionUnit[];
  consumer_overview_template: string;
  handbook: string;
  invoicing_email: string;
  notfound_template: string;
  product_overview_template: string;
  product_template: string;
  recipe_overview_template: string;
  recipe_template: string;
  units_imperial: boolean;
  units_metric: boolean;
  units_us: boolean;
  vat_rate: number;
  vat_rate_full: number;
}

export type InterfaceCurrency = (typeof interfaceCurrencies)[number];

export interface Organisation {
  acronym: string;
  administrator: SimpleUser;
  date_created: string;
  default_currency: InterfaceCurrency;
  default_language: InterfaceLanguage;
  id: number;
  identifier: string;
  invitees: Invitation[];
  last_changed: string;
  limit_users: number;
  logo: string;
  logo_small: string;
  name: string;
  number_users: number;
  templates: number[];
  custom_domain: string;
}

export class User extends SimpleUser {
  accountsettings: AccountSettings;
  category: UserCategory;
  email_preferences: EmailPreferences;
  errorStatusCode?: number;
  invoices: string;
  is_staff: boolean;
  organisation: Organisation;
  partner_code: string;
  partner_detail: PartnerDetail;
  partner_username: string;
  profile: Profile;
  settings: Settings;
  status: Status;
  subscription: Subscription;
  subscription_extensions: SubscriptionExtension[];
  tutorials: Tutorials;
  var_error?: string;

  constructor(user?) {
    super();
    Object.assign(this, user);
  }
}

export class SubscriptionExtension {
  extra_ai_dishes: number;
  extra_ai_menu_analysis: number;
  extra_backups: number;
  extra_edits: number;
  extra_menus: number;
  extra_templates: number;
  reason: number;
  reason_display: string;
  valid_from: string;
  valid_to: string;
}

export class PartnerDetail {
  code: string;
  link: string;
  logo: string;
  logo_small: string;
  name: string;
  restricted_interface: boolean;
  video_menuwriting?: string;
}

export class EmailPreferences {
  newsletter: boolean;
  onboarding: boolean;
}

export class UserEmailPreferences {
  email: string;
  email_preferences: EmailPreferences;
}

export class UserModule {
  active: boolean;
  date_activated: Date;
  inactive_on: Date;
  module: number;
  module_detail: Module;
  settings: any;
  options: any;
  subscription_active?: boolean;
  subscription_created_at: string;
  trial: boolean;
  url: string;
  enabled?: boolean;
  requested?: boolean;

  constructor(mod?: UserModule) {
    if (mod) {
      Object.assign(this, mod);
    }
  }

  setDetail(detail: Module): UserModule {
    this.module_detail = detail;
    return this;
  }
}

export const modules = [
  'all',
  'add',
  'label',
  'man',
  'recip',
  'diver',
  'order',
  'procu',
  'loca',
  'data',
  'integ',
  'diet',
  'auto',
] as const;

export type DeclarationModuleCode = 'all' | 'add' | 'label';

export type ModuleCode = (typeof modules)[number];

export class Module {
  annual_discount: number;
  code: ModuleCode;
  exclusive: boolean;
  free: boolean;
  id: number;
  illustration: string;
  image: string;
  price: number;
  price_for_user: number;
  price_for_user_gross: number;
  price_for_user_tax: number;
  trial_available: boolean;
  trial_category: any[];
  trial_duration: number;
  url: string;
  supported_options: any;

  constructor() {
    Object.assign(this, InterfaceLangsAsObject);
    Object.assign(this, InterfaceLangsAsDescriptionObject);
  }
}

export enum CreditReason {
  FREE = 0,
  PURCHASE = 1,
  DOWNGRADE = 2,
}

export interface CreditsLine {
  menus: number;
  templates: number;
  dateStart: string;
  dateEnd: string;
  subscription: string;
  reason: CreditReason;
  display: string;
}

export interface Module
  extends InterfaceLangsObject,
    InterfaceDescriptionsObject,
    BillingCurrenciesObject {}

export class ModulePurchaseView extends Module {
  selected?: boolean;
  included?: boolean;
  touched?: boolean;

  constructor(module: Module) {
    super();
    Object.assign(this, module);
  }
}

export class Tutorials {
  dashboard_firstvisit: boolean;
  dashboard_hide_courses: boolean;

  menu_write: boolean;
  menu_style: boolean;
  menu_translate: boolean;
  menu_review: boolean;
}

export class Settings {
  language: InterfaceLanguage;
  currency: InterfaceCurrency;
  currency_symbol: string;
  currencies_enabled: string[];
  menu_settings: any;
  units_imperial: boolean;
  units_metric: boolean;
  units_us: boolean;
  products_view: DISHES_VIEW_VILTERS;
}

export interface UserStatusPrivileges {
  create_menu: boolean;
  edit_menu: boolean;
  delete_menu: boolean;
  change_location?: boolean;
}

type confirmationFailureTypes = null | 'bounced' | 'deferred' | 'rejected';

export class Status {
  account_limits: {
    limit_days?: number;
  };
  active_modules: string[];
  block_on?: string;
  completed_profile?: boolean;
  completed_setup?: boolean;
  confirmation_failure: confirmationFailureTypes;
  current_usage: {
    menus_created_this_month: number;
    templates_used_this_month: number;
    number_edited_this_month: number;
    ai_allergens_this_month: number;
    ai_dishes_this_month: number;
    ai_menu_analysis_this_month: number;
    ai_recipes_this_month: number;
  };
  date_joined?: string;
  delete_on?: string;
  email_verified: boolean;
  inactive_on?: string;
  is_staff: boolean;
  limit_edits_per_month: number;
  list_templates_used_this_month: number[];
  next_reset_date: string;
  next_reset_days: number;
  privileges: UserStatusPrivileges;
  received_trialextension: boolean;
  payment_failure: boolean;
  payment_date_upcoming: string;
  subscription_active: boolean;
  subscription_name: string;
  subscription_oneoff: boolean;
  subscription_type: string;
  trial_active: boolean;
  trial_period: boolean;
  trial_reactivated_on: string;
  subscription_currency: string;
  usage_limits: {
    limit_menus_per_month?: number;
    limit_templates_per_month?: number;
    limit_edits_per_month?: number;
    limit_backups_per_month?: number;
    limit_ai_allergens_per_month?: number;
    limit_ai_dishes_per_month?: number;
    limit_ai_menu_analysis_per_month?: number;
    limit_ai_recipes_per_month?: number;
  };

  ready?: boolean;
  message?: string;
}

export class Profile {
  phone?: string;
  logo?: string;
  organisation_name?: string;
  premise?: string;
  street?: string;
  postal_code?: string;
  locality?: string;
  administrative_area?: string;
  country?: string;
  country_name?: string;
}

export class Task {
  url: string;
  enqueued: string;
  processing: boolean;
  processing_start: string;
  processing_end: string;
  expected_duration: string;
  failed: boolean;
  download: string;
  downloaded: boolean;
  hidden: boolean;
  id: number;
  task_type: number;
  task_name: string;
  ready: boolean;
  user: number;
  object_id: number;
  content_type: 'menu';
  has_archive: boolean;
}

export class Discount {
  applicability?: number;
  code: string;
  discount: number;
  id: number;
  is_active: boolean;
  usage_limit?: number;
}

export class Payment {
  after_trial?: boolean;
  modules: number[];
  modules_detail: Module[];
  payment_failure: boolean;
  payment_method: number;
  payment_method_name: string;
  subscription_active: boolean;
  subscription_created_at: string;
  subscription_next_payment_amount: number;
  subscription_next_payment_date: string;
  subscription_plan: number;
  subscription_plan_detail: Plan;
  subscription_term: number;
}

export class PaymentVerification {
  payment_intent_secret?: string;
  message?: string;
  error?: string;
}

export function isPayment(obj: Payment | PaymentVerification): obj is Payment {
  return !!(obj as Payment).payment_method_name;
}

export const currencyList = [
  { currency: 'EUR', minPrice: 1200, currencySymbol: '€' },
  { currency: 'CAD', minPrice: 2000, currencySymbol: '$' },
  { currency: 'GBP', minPrice: 1200, currencySymbol: '£' },
  { currency: 'USD', minPrice: 1600, currencySymbol: '$' },
  { currency: 'AUD', minPrice: 2160, currencySymbol: '$' },
  { currency: 'NZD', minPrice: 2400, currencySymbol: '$' },
];

export interface DaysProgress {
  days: number;
  extraDays: number;
  limitDays: number;
}

export interface ContentProgress {
  remaining: number;
  extra: number;
  used: number;
  percentUsed: number;
  percentExtra: number;
  percentRemaining: number;
}

export interface AiCreditsRemaining {
  allergens: number;
  dishes: number;
  recipes: number;
  menu_analysis: number;
}
