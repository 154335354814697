import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'navigation-buttons',
  templateUrl: './navigation-buttons.component.html',
  styleUrls: ['./navigation-buttons.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule, TranslocoPipe],
})
export class NavigationButtonsComponent {
  @Input() currentStep = 1;
  @Input() first = false;
  @Input() disabled = false;

  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();

  constructor() {}
}
