import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'side-panel-placeholder',
  templateUrl: './side-panel-placeholder.component.html',
  styleUrls: ['./side-panel-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe],
})
export class SidePanelPlaceholderComponent {
  constructor() {}
}
