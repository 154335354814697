import { Component, Input } from '@angular/core';
import { FileUploadControl } from '@iplab/ngx-file-upload';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'file-upload-placeholder',
  templateUrl: './file-upload-placeholder.component.html',
  styleUrls: ['./file-upload-placeholder.component.scss'],
  standalone: true,
  imports: [MatIconModule, MatProgressSpinnerModule],
})
export class FileUploadPlaceholderComponent {
  @Input() control: FileUploadControl;
  @Input() formats: string;
  @Input() multiple: boolean;
  @Input() showSpinner: boolean;
  @Input() labelClass: string;
  @Input() placeholder: string;
}
