import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import {
  Status,
  SubscriptionExtension,
  User,
} from 'src/app/shared/Models/user';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MtTooltipDirective } from '../../Directives/mt-tooltip/mt-tooltip.directive';
import { UserStatusProgressComponent } from '../user-status-progress/user-status-progress.component';
@Component({
  selector: 'plan-limit',
  templateUrl: `plan-limit.component.html`,
  styleUrls: [
    `../status-block/status-block.component.scss`,
    `./plan-limit.component.scss`,
  ],
  standalone: true,
  imports: [
    UserStatusProgressComponent,
    MtTooltipDirective,
    MatButtonModule,
    RouterLink,
    MatIconModule,
    DatePipe,
    TranslocoPipe,
  ],
})
export class PlanLimitComponent implements OnChanges {
  @Input() extensions: SubscriptionExtension[];
  @Input() lang: InterfaceLanguage;
  @Input() status: Status;
  @Input() user: User;

  onInactive: string;

  ngOnChanges(changes: SimpleChanges): void {
    if ('status' in changes && this.status && this.status.inactive_on) {
      this.onInactive = formatDate(
        new Date(this.status.inactive_on),
        'mediumDate',
        this.lang,
      );
    }
  }
}
