import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, FormGroup, FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { PasswordValidators } from 'ngx-validators';
import { resetPasswordConfirm } from 'src/app/auth/ngrx/auth.actions';
import { State } from 'src/app/reducers';
import { ValidationMessages } from 'src/app/shared/constants/validation';
import { ResetPassword } from 'src/app/shared/Models/models';

@Component({
  selector: 'reset-password-confirm',
  templateUrl: './reset-password-confirm.component.html',
  styleUrls: ['./reset-password-confirm.component.css'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    RouterLink,
    TranslocoPipe,
  ],
})
export class ResetPasswordConfirmComponent implements OnInit {
  @Input() uid: string;
  @Input() token: string;

  formErrors = {
    new_password1: '',
    new_password2: '',
  };
  resetForm = new FormGroup({
    new_password1: new FormControl(''),
    new_password2: new FormControl(''),
  });
  resetPassword: ResetPassword;
  validationMessages = ValidationMessages;

  constructor(
    private destroyRef: DestroyRef,
    private ngrxStore: Store<State>,
    private translate: TranslocoService,
  ) {
    this.translate.langChanges$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.onValueChanged();
      });
  }

  handleLang(keys): void {
    this.translate
      .selectTranslate(keys)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: object) => {
        for (const val in keys) {
          const spl = keys[val].split('.');
          this.validationMessages[spl[4]][spl[6]] = res[val];
        }
      });
  }

  ngOnInit(): void {
    this.buildForm();
    this.resetForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.onValueChanged());
  }

  onSubmit(event: Event): void {
    event.preventDefault();
    this.resetPassword = new ResetPassword(
      this.resetForm.value.new_password1,
      this.resetForm.value.new_password2,
      this.uid,
      this.token,
    );
    if (this.resetForm.valid) {
      this.ngrxStore.dispatch(
        resetPasswordConfirm({ resetPassword: this.resetPassword }),
      );
    }
  }

  onValueChanged(): void {
    if (!this.resetForm) return undefined;
    const form = this.resetForm;
    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.createKeysArray(field, key);
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  createKeysArray(field, errorType): void {
    const keys = [];
    keys.push(
      `auth.reset_password.confirm.fields.${field}.validation.${errorType}`,
    );
    this.handleLang(keys);
  }

  buildForm(): void {
    this.resetForm.setValidators(
      PasswordValidators.mismatchedPasswords('new_password1', 'new_password2'),
    );
  }
}
