import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { Layout } from 'src/app/shared/Models/template';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'select-layout',
  templateUrl: './select-layout.component.html',
  styleUrls: [
    '../../select-styles/select-style/select-style.component.scss',
    './select-layout.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    TranslocoPipe,
  ],
})
export class SelectLayoutComponent implements OnChanges {
  @Input() create: boolean;
  @Input() lang: InterfaceLanguage;
  @Input() selected: boolean;
  @Input() layout: Layout;

  @Output() selectItem = new EventEmitter();
  @Output() sendRequest = new EventEmitter();

  loading = false;

  ngOnChanges(changes: SimpleChanges) {
    if ('selected' in changes) this.loading = false;
  }

  selectLayout() {
    this.loading = true;
    this.selectItem.emit();
  }
}
