import {
  Directive,
  ElementRef,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[DndSortItem]',
  standalone: true,
})
export class DndSortItemDirective implements OnDestroy, OnInit {
  handle: HTMLElement;
  target: HTMLElement;
  private dragEnterListener: () => void;

  constructor(
    private element: ElementRef,
    private ngZone: NgZone,
    private renderer2: Renderer2,
  ) {
    if (this.ngZone)
      this.ngZone.runOutsideAngular(() => {
        this.dragEnterListener = this.renderer2.listen(
          this.element.nativeElement,
          `dragenter`,
          (event: DragEvent) => {
            event?.preventDefault();
          },
        );
      });
  }

  ngOnInit(): void {
    this.handle = this.element.nativeElement.querySelector('.drag');
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    this.target = event.target as HTMLElement;
  }

  @HostListener('dragstart', ['$event'])
  onDragStart(event: DragEvent) {
    if (!this.handle) {
      this.handle = this.element.nativeElement.querySelector('.drag');
    }
    if (this.handle && !this.handle.contains(this.target)) return;

    const node = this.createGhostImage();
    document.body.appendChild(node);
    event.dataTransfer.setDragImage(node, 0, 0);
  }

  @HostListener('dragend')
  onDragEnd() {
    const result = document.getElementById('dragged-ghost-image');
    if (result) document.body.removeChild(result);
  }

  @HostListener('drop', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    const target = event.target as HTMLElement;
    const isBottomDrop =
      target.classList && target.classList.contains('bottom-drop');
    if (isBottomDrop && target.classList) {
      target.classList.remove('bottom-drop-indicator');
    }
    const newEvent = this.parseElementData(event, isBottomDrop);
    if (newEvent) this.element.nativeElement.dispatchEvent(newEvent);
  }

  createGhostImage(): HTMLElement {
    const node: HTMLElement = this.element.nativeElement.cloneNode(true);
    node.id = 'dragged-ghost-image';
    node.style.zIndex = '100000';
    node.style.position = 'absolute';
    node.style.top = '100%';
    node.style.right = '100%';
    node.style.width = getComputedStyle(
      this.element.nativeElement,
    ).getPropertyValue('width');
    return node;
  }

  parseElementData(event: DragEvent, isBottomDrop: boolean): CustomEvent {
    const { dataset } = event.currentTarget as HTMLElement;
    if (!dataset.index && !isBottomDrop) return undefined;
    const data = parseInt(event.dataTransfer.getData('dragElement'), 10);
    const dropItemIndex = parseInt(dataset.index);
    const dropItemDnDIndex = parseInt(dataset.dndindex);
    const dragItemDnDIndex = parseInt(
      event.dataTransfer.getData('dndindex'),
      10,
    );
    if (data === dropItemIndex) return undefined;
    return new CustomEvent('dnd-sort-drop', {
      bubbles: true,
      detail: {
        dragItemIndex: data,
        dropItemIndex,
        event,
        isBottomDrop,
        dragItemDnDIndex,
        dropItemDnDIndex,
      },
    });
  }

  ngOnDestroy(): void {
    this.dragEnterListener?.();
  }
}
