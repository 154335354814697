<div class="auth-page">
  <side-info class="side" [lang]="language.current"></side-info>
  <div class="wrapper">
    <div class="g-top-nav">
      <language-selector
        class="lang"
        [model]="language"
        [simple]="true"
        [left]="true"
        [longLang]="true"
        changeValue="current"
        (changed)="changeLanguage()"
      ></language-selector>
      <div class="trial">
        <span class="trial-text">{{'auth.login.side.trial' | transloco}}</span>
        <button class="trial-button" mat-button>
          <a routerLink="/registration" [queryParams]="{lang: language.current}"
            >{{'auth.login.side.trial-button' | transloco}}</a
          >
        </button>
      </div>
    </div>
    <div class="auth-bar">
      <h1 class="auth-title">{{'auth.reset_password.title' | transloco}}</h1>
      @if (!uid || !token) {
      <reset-password [lang]="language?.current"></reset-password>
      } @if (uid && token) {
      <reset-password-confirm
        [uid]="uid"
        [token]="token"
      ></reset-password-confirm>
      }
    </div>
    <app-footer
      class="footer"
      [lang]="language"
      [backgroundColor]="'white'"
    ></app-footer>
  </div>
</div>
