import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/Components/dialogs/confirm-dialog/confirm-dialog.component';
import { SendMessageModalComponent } from 'src/app/shared/Components/menutech-navbar/send-message-modal/send-message-modal.component';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { DeepPartial } from 'src/app/shared/Models/generics';
import {
  TaskType,
  DownloadReportDetails,
  Menu,
  MenuBackup,
  MenuPreviewData,
} from 'src/app/shared/Models/menu';
import { AccountStatusMessage } from 'src/app/shared/Models/models';
import { Rule } from 'src/app/shared/Models/ruleset';
import { SimpleLayout } from 'src/app/shared/Models/template';
import { Translation } from 'src/app/shared/Models/translation';
import { User, UserStatusPrivileges } from 'src/app/shared/Models/user';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { filter } from 'rxjs';
import { TranslocoPipe } from '@jsverse/transloco';
import { LinkedTemplatesComponent } from './linked-templates/linked-templates.component';
import { BackupMenusComponent } from './backup-menus/backup-menus.component';
import { LinkedTemplateComponent } from './linked-templates/linked-template/linked-template.component';
import { MatButtonModule } from '@angular/material/button';
import { OrdertakingComponent } from '../../../shared/Components/ordertaking/ordertaking.component';
import { ContentBlockComponent } from '../../../shared/Components/content-block/content-block.component';
import { ContactlessComponent } from './contactless/contactless.component';
import { CompletedMenuComponent } from './completed-menu/completed-menu.component';

@Component({
  selector: 'review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  standalone: true,
  imports: [
    CompletedMenuComponent,
    ContactlessComponent,
    ContentBlockComponent,
    OrdertakingComponent,
    MatButtonModule,
    LinkedTemplateComponent,
    BackupMenusComponent,
    LinkedTemplatesComponent,
    TranslocoPipe,
  ],
})
export class ReviewComponent {
  @Input() customUrl = '';
  @Input() lang: InterfaceLanguage;
  @Input() menu: Menu;
  @Input() menuTranslations: Translation[];
  @Input() user: User;
  @Input() orderTakingLayouts: SimpleLayout[];
  @Input() privileges: UserStatusPrivileges;
  @Input() userStatus: AccountStatusMessage;

  @Output() archiveMenu = new EventEmitter<{
    menu: Menu;
    task_type: TaskType;
  }>();
  @Output() createMenuBackup = new EventEmitter<void>();
  @Output() deleteMenuBackup = new EventEmitter<MenuBackup>();
  @Output() downloadReport = new EventEmitter<{
    data: DownloadReportDetails;
  }>();
  @Output() enableOrdertaking = new EventEmitter<boolean>();
  @Output() patchMenu = new EventEmitter<DeepPartial<Menu>>();
  @Output() restoreMenuBackup = new EventEmitter<MenuBackup>();
  @Output() showPreview = new EventEmitter<MenuPreviewData>();
  @Output() downloadStats = new EventEmitter<{
    date_from: string;
    date_to: string;
  }>();

  cancelButtonTranslation = '';
  confirmButtonTranslation = '';
  deleteBackupTranslations = {};
  restoreBackupTranslations = {};

  constructor(
    private dialog: MatDialog,
    private utils: UtilsService,
  ) {
    this.getTranslations();
  }

  changeMenu(changes: DeepPartial<Menu> | Partial<Rule>): void {
    this.patchMenu.emit(changes as DeepPartial<Menu>);
  }

  getTranslations(): void {
    this.utils.getTranslation(
      ['shared.buttons.cancel', 'shared.buttons.confirm'],
      (v) => {
        this.cancelButtonTranslation = v[0];
        this.confirmButtonTranslation = v[1];
      },
    );
    this.utils.getTranslation(
      [
        'menus.complete.backup.delete-dialog.title',
        'menus.complete.backup.delete-dialog.content',
      ],
      (v) => (this.deleteBackupTranslations = v),
    );
    this.utils.getTranslation(
      [
        'menus.complete.backup.restore-dialog.title',
        'menus.complete.backup.restore-dialog.content',
      ],
      (v) => (this.restoreBackupTranslations = v),
    );
  }

  localiseDate = (date: string, format: string): string =>
    formatDate(date, format, this.lang);

  showDeleteBackupDialog(backup: MenuBackup): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.title = this.deleteBackupTranslations[0];
    dialogRef.componentInstance.content = this.deleteBackupTranslations[1];
    dialogRef.componentInstance.confirmMessage = this.confirmButtonTranslation;
    dialogRef.componentInstance.cancelMessage = this.cancelButtonTranslation;

    dialogRef.componentInstance.dialogConfirmed
      .pipe(filter((v) => !!v))
      .subscribe((v) => this.deleteMenuBackup.emit(backup));
  }

  // FIXME: check if this is still used
  showRestoreBackupDialog(backup: MenuBackup): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.title = this.restoreBackupTranslations[0];
    dialogRef.componentInstance.content = this.restoreBackupTranslations[0]
      .replace('{date}', this.localiseDate(backup.created_at, 'mediumDate')) // mediumDate
      .replace('{time}', this.localiseDate(backup.created_at, 'shortTime')); // shortTime
    dialogRef.componentInstance.confirmMessage = this.confirmButtonTranslation;
    dialogRef.componentInstance.cancelMessage = this.cancelButtonTranslation;

    dialogRef.componentInstance.dialogConfirmed
      .pipe(filter((v) => v))
      .subscribe((v) => this.restoreMenuBackup.emit(backup));
  }

  hasModule = (code: string): boolean => this.utils.hasModules(code);

  openSupportDialog(): void {
    this.dialog.open(SendMessageModalComponent, {
      data: {
        subject: 4,
        user: this.user,
      },
      width: '750px',
      height: this.user.email.includes('@partners.menutech.com')
        ? '650px'
        : '610px',
      panelClass: 'send-message-panel',
      autoFocus: false,
    });
  }
}
