@for (dish of dishes; track dish) {
  <div class="dish">
    <div class="similar-name">
      {{ dish[lang] }}
      @for (
        item of type === "allergens"
          ? dish.allergens_contained
          : dish.additives_contained;
        track item
      ) {
        <span>
          <span
            title="{{ item[lang] }}"
            [ngClass]="{
              'additives-symbol': type === 'additives',
            }"
            [class.allergen-symbol]="type === 'allergens' && !!item.app_symbol"
            [class.allergen-symbol-clear]="item?.legend_symbol === 4"
          >
            {{ item.app_symbol || item.symbol }}
          </span>
        </span>
      }
    </div>
    <button
      mat-icon-button
      tabindex="-1"
      color="primary"
      class="apply-button"
      (click)="apply(dish)"
    >
      <mat-icon class="icon">content_copy</mat-icon>
    </button>
  </div>
}
