<app-user-status
  [complete]="complete"
  [isLoading]="isLoading$ | async"
  [lang]="lang"
  [menusCreated]="menusCreated$ | async"
  [status]="(user$ | async)?.status"
  [user]="user$ | async"
  [userOnboardingTemplates]="userOnboardingTemplates$ | async"
  [statusMessages]="statusMessages$ | async"
  (createMenuFromOnboardingTemplate)="createMenuFromOnboardingTemplate($event)"
></app-user-status>

@if ((isLoading$ | async) || !(user$ | async)?.id) {
<spinner></spinner>
} @if (showSpinner) {
<div class="spinner">
  <spinner></spinner>
</div>
} @if ((statusMessages$ | async)?.ready && (lastTemplates$ | async)?.length) {
<div class="full-width templates">
  <h2 class="mat-h2 top">{{ "dashboard.templates.title" | transloco }}</h2>
  <div class="row">
    @for (template of lastTemplates$ | async; track template) {
    <template-item
      class="template"
      matTooltipPosition="above"
      matTooltipClass="top-navbar-tooltip"
      [class.template-disabled]="!(privileges$ | async).create_menu || ((limitTemplates$ | async) && !(templatesUsed$ | async).includes(template.id))"
      [data]="template"
      [disabled]="!(privileges$ | async).create_menu || ((limitTemplates$ | async) && !(templatesUsed$ | async).includes(template.id))"
      [latest]="true"
      [matTooltip]="
        (statusMessages$ | async)?.message || (
          (limitMenus$ | async) && (menusStatus$ | async)?.message
        ) || (
          (limitTemplates$ | async) && !(templatesUsed$ | async).includes(template.id) &&
          (templateStatus$ | async)?.message
        ) || ''
      "
      (choose)="createMenuEvent($event, template.id)"
      (showError)="showError()"
    >
    </template-item>
    }
  </div>
</div>
} @if ((menusCreated$ | async) !== null || (lastMenu$ | async) !== null) {
<div class="statistics full-width">
  <h2>{{ "dashboard.statistics.title" | transloco }}</h2>
  <app-user-stats
    [lastMenu]="lastMenu$ | async"
    [menusCreated]="menusCreated$ | async"
    (previewMenu)="previewMenu($event)"
  ></app-user-stats>
</div>
} @if ((statusMessages$ | async)?.ready) {
<div class="full-width">
  <quick-overview
    [menus]="menusByDate$ | async"
    (previewMenu)="previewMenu($event)"
  ></quick-overview>
</div>
} @if ((isLoading$ | async) === false && (courses$ | async)?.length) {
<app-courses
  [courses]="courses$ | async"
  [coursesLoading]="coursesLoading$ | async"
  [lang]="lang"
  [hideCourses]="(user$ | async)?.tutorials.dashboard_hide_courses"
  (updateUser)="updateUser($event)"
>
</app-courses>
} @if ((isLoading$ | async) === false) {
<app-customer-support
  [isTrialActive]="(user$ | async)?.status.trial_active"
  [isConsumerActive]="(user$ | async)?.status.subscription_active"
  (showMessageOverlayEvent)="showMessageOverlay($event)"
>
</app-customer-support>
}
