<form
  #ingredientForm
  [formGroup]="ingredientGroup"
  (keydown.enter)="$event.preventDefault()"
>
  <div class="line field-density-5">
    @if (recipeIngredient?.url) {
      <div class="name icon-button-density-3" [class.italic]="name?.italic">
        {{ name?.name }}
        @if (showIcons) {
          <div class="icons">
            @if (
              recipeIngredient?.ingredient_detail?.allergens_contained?.length
            ) {
              @for (
                allergen of recipeIngredient.ingredient_detail
                  .allergens_contained;
                track allergen
              ) {
                <span
                  [class.allergen-symbol]="!!allergen.app_symbol"
                  [class.allergen-symbol-clear]="allergen?.legend_symbol === 4"
                  >{{ allergen.app_symbol || allergen.symbol }}</span
                >
              }
            }
            @if (
              recipeIngredient?.ingredient_detail?.additives_contained?.length
            ) {
              @for (
                additive of recipeIngredient.ingredient_detail
                  .additives_contained;
                track additive
              ) {
                <span class="additives-symbol">{{
                  additive.app_symbol || additive.symbol
                }}</span>
              }
            }
          </div>
        }
        @if (showIcons) {
          <button mat-icon-button (click)="toggleDeclarationsView()">
            <mat-icon>edit_mode</mat-icon>
          </button>
        } @else {
          <button
            mat-icon-button
            class="edit"
            [class.disabled]="isLocked"
            (click)="openNameDialog(recipeIngredient)"
          >
            <mat-icon class="edit-icon">edit_mode</mat-icon>
          </button>
        }
      </div>
    } @else {
      <div class="name">
        <mat-form-field subscriptSizing="dynamic" class="add-ingredient">
          <input
            #ingredientName
            matInput
            type="text"
            class="ingredient-name-input"
            [formControl]="autoControl"
            [matAutocomplete]="auto"
            [placeholder]="'ingredients.table.add_ingr' | transloco"
            (keydown.enter)="createNewIngredient(ingredientName.value)"
          />
        </mat-form-field>
      </div>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        @for (ingr of ingredientsAuto; track ingr) {
          <mat-option
            [value]="ingr"
            (onSelectionChange)="ingredientSelected(ingr)"
          >
            {{ ingr[lang] }}
          </mat-option>
        }
        @if (autoControl.value && !focusQuantity) {
          <mat-option
            (click)="createNewIngredient(ingredientName.value)"
            (keydown.enter)="createNewIngredient(ingredientName.value)"
          >
            {{ "recipes.content.ingredients.add-new" | transloco }}
          </mat-option>
        }
      </mat-autocomplete>
    }

    <div class="quantity value number" [class.extra]="!showNetQuantity">
      <mat-form-field subscriptSizing="dynamic">
        <input
          inputProgressBar
          type="number"
          lang="en-150"
          matInput
          [readonly]="isLocked"
          formControlName="quantity"
          autocomplete="off"
          (keydown.enter)="addLine($event)"
          #ingredientQuantity
        />
        <span matSuffix>{{
          recipeIngredient?.unit
            ? ("ingredients.info.units_abbrev." + recipeIngredient?.unit
              | transloco)
            : ""
        }}</span>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </mat-form-field>
    </div>
    @if (showNetQuantity) {
      <div class="value number">
        <mat-form-field subscriptSizing="dynamic">
          <input
            inputProgressBar
            type="number"
            lang="en-150"
            matInput
            [readonly]="isLocked"
            formControlName="quantity_net"
            autocomplete="off"
            (keydown.enter)="addLine($event)"
          />
          <span matSuffix>{{
            recipeIngredient?.unit
              ? ("ingredients.info.units_abbrev." + recipeIngredient?.unit
                | transloco)
              : ""
          }}</span>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-form-field>
      </div>
    }
    @if (showNetQuantity) {
      <div class="value number" autocomplete="off">
        <mat-form-field subscriptSizing="dynamic">
          <input
            inputProgressBar
            readonly
            type="number"
            lang="en-150"
            matInput
            [readonly]="isLocked"
            formControlName="weight_loss"
            (keydown.enter)="addLine($event)"
          />
          <span matSuffix>{{
            recipeIngredient?.unit
              ? ("ingredients.info.units_abbrev." + recipeIngredient?.unit
                | transloco)
              : ""
          }}</span>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-form-field>
      </div>
    }
    @if (showNetQuantity) {
      <div class="value number" autocomplete="off">
        <mat-form-field subscriptSizing="dynamic">
          <input
            inputProgressBar
            type="number"
            lang="en-150"
            matInput
            [readonly]="isLocked"
            formControlName="weight_loss_perc"
            class="perc"
            (keydown.enter)="addLine($event)"
          />
          <span matSuffix>%</span>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-form-field>
      </div>
    }
    <div class="value unit" [class.extra]="!showNetQuantity">
      <mat-form-field subscriptSizing="dynamic">
        <mat-select
          formControlName="unit"
          [placeholder]="
            !ingredientGroup.get('unit').value
              ? ('ingredients.info.unit' | transloco)
              : ''
          "
        >
          <mat-select-trigger>
            @if (ingredientGroup.get("unit").value) {
              {{
                "ingredients.info.units." + ingredientGroup.get("unit").value
                  | transloco
              }}
            }
          </mat-select-trigger>
          <mat-option [value]="null"></mat-option>
          @for (unit of units; track unit) {
            <mat-option [value]="unit">
              {{ "ingredients.info.units." + unit | transloco }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="delete icon-button-density-3">
      <button
        class="delete-button"
        [disabled]="disableDelete || isLocked"
        mat-icon-button
        (click)="delete()"
        (keydown.enter)="delete()"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</form>
<option-popover class="options-panel" [(visible)]="showDeclarationsView">
</option-popover>
