import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { requestNewContent } from 'src/app/auth/ngrx/auth.actions';
import { State } from 'src/app/reducers';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import {
  ObservablePortal,
  RegistrationOption,
} from 'src/app/shared/Models/registration-steps';
import { User } from 'src/app/shared/Models/user';
import { setTemplatesState } from 'src/app/shared/ngrx/shared.actions';
import { patchUser } from 'src/app/shared/user/ngrx/user.actions';
import { CategoryOptions } from 'src/app/shared/constants/auth';
import {
  Country,
  MatSelectCountryModule,
} from '@angular-material-extensions/select-country';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RegistrationStepOptionComponent } from '../registration-step-option/registration-step-option.component';
import { NavigationButtonsComponent } from '../navigation-buttons/navigation-buttons.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MtTooltipDirective } from 'src/app/shared/Directives/mt-tooltip/mt-tooltip.directive';

@Component({
  selector: 'registration-step-one',
  templateUrl: './registration-step-one.component.html',
  styleUrls: ['./registration-step-one.component.scss'],
  standalone: true,
  imports: [
    NavigationButtonsComponent,
    ReactiveFormsModule,
    MatSelectCountryModule,
    RegistrationStepOptionComponent,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MtTooltipDirective,
    TranslocoPipe,
  ],
})
export class RegistrationStepOneComponent
  implements AfterViewInit, OnChanges, OnInit
{
  @Input() user: User;
  @Input() lang: InterfaceLanguage;
  @Input() isSafari: boolean;

  @Output() buttonPortal = new EventEmitter<ObservablePortal>();

  @ViewChild('button', { static: true })
  buttonPortalContent: TemplateRef<any>;

  clickEventsStream = new Subject<true | undefined>();
  enableNextStep = false;
  options: RegistrationOption[] = CategoryOptions;

  step1Group = new FormGroup({
    country: new FormControl<Country>(null, Validators.required),
  });
  titleParams: { firstName: string };

  constructor(
    private viewContainerRef: ViewContainerRef,
    protected ngrxStore: Store<State>,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.step1Group.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.validateStep());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('user' in changes && this.user) {
      // prefill existing country
      if (this.user?.profile.country) {
        const country: Country = {
          alpha2Code: this.user?.profile?.country?.toLowerCase(),
          name: this.user?.profile?.country_name,
          alpha3Code: '',
          numericCode: '',
          callingCode: '',
        };
        setTimeout(() => {
          this.step1Group.patchValue({ country }, { emitEvent: false });
        });
      }

      // prefill existing category
      if (this.user?.category) {
        const option = this.options.find(
          (opt) => opt.value === this.user?.category,
        );
        option && (option.active = true);
      }

      // set title params and validate step
      this.titleParams = { firstName: this.user.first_name };
      this.validateStep();
    }
  }

  ngAfterViewInit(): void {
    this.buttonPortal.emit({
      portal: new TemplatePortal(
        this.buttonPortalContent,
        this.viewContainerRef,
      ),
      eventStream: this.clickEventsStream.asObservable(),
    });
  }

  chooseOption(option: RegistrationOption, forceState?: boolean): void {
    if (forceState !== undefined) {
      option.active = forceState;
    } else {
      this.options
        .filter((opt) => opt !== option)
        .forEach((opt) => (opt.active = false));
      option.active = !option.active;
    }
    this.validateStep();
  }

  next(): void {
    const selectedOption = this.options.find((opt) => opt.active);
    if (selectedOption.other) {
      const country = this.step1Group.value?.country?.alpha2Code;
      this.ngrxStore.dispatch(
        patchUser({ user: { category: null, profile: { country } } }),
      );
      this.ngrxStore.dispatch(
        requestNewContent({
          message: selectedOption.payload,
          content: 'type',
          user: this.user,
        }),
      );
    } else {
      const category = this.options.find((opt) => opt.active).value;
      const country = this.step1Group.value?.country?.alpha2Code;
      this.ngrxStore.dispatch(
        patchUser({ user: { category, profile: { country } } }),
      );
    }
    this.ngrxStore.dispatch(setTemplatesState({ loading: true }));
    this.clickEventsStream.next(undefined);
  }

  prev(): void {
    this.clickEventsStream.next(true);
  }

  validateStep(): void {
    this.enableNextStep =
      this.options.some((opt) =>
        opt.other ? opt.payload && opt.active : opt.active,
      ) && this.step1Group.valid;
  }
}
