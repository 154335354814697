import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GenericsService {
  constructor(private http: HttpClient) {}

  get = <O>(
    url: string,
    params?: any,
    headers?: any,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
  ) =>
    this.http.get<O>(url, {
      params,
      headers,
      responseType: (responseType ?? 'json') as any,
    });

  post = <I, O>(url: string, data: I, params?: any, headers?: any) =>
    this.http.post<O>(url, data, { params, headers });

  patch = <I, O = I>(url: string, data: I, params?: any) =>
    this.http.patch<O>(url, data, { params });

  delete = <O>(url: string, params?: any, body?: object) =>
    this.http.delete<O>(url, { params, body });
}
