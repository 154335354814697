import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { StyleCategories } from 'src/app/shared/Models/menu';

import { Updatable } from '../select-styles/styles-carousel/styles-carousel.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'categories-tabs',
  templateUrl: './categories-tabs.component.html',
  styleUrls: ['./categories-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatTabsModule, TranslocoPipe],
})
export class CategoriesTabsComponent {
  @Input() categories: StyleCategories = [];
  @Input() tabIndex: number;
  @Input() translationKey: string;
  @Output() tabChange = new EventEmitter<number>();

  @ContentChildren(Updatable) carousels: QueryList<Updatable>;
}
