import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  CustomData,
  CustomDataToFormGroup,
  SupportedCustomData,
  SupportedCustomDataTypes,
} from 'src/app/shared/Models/menu';
import { isEqual } from 'lodash-es';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { TranslocoPipe } from '@jsverse/transloco';
import { KeyValuePipe } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-custom-data-form',
  templateUrl: './custom-data-form.component.html',
  styleUrls: ['./custom-data-form.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatCheckboxModule,
    KeyValuePipe,
    TranslocoPipe,
  ],
})
export class CustomDataFormComponent implements OnChanges, OnDestroy {
  @Input() control: any; // FIXME: remove or update
  @Input() type: any; // FIXME: remove or update
  @Input() translationKey: any; // FIXME: remove or update
  @Input() showProgressBar: any; // FIXME: remove or update
  @Input() customData: any = {}; // SupportedCustomData[]
  @Input() supportedCustomData: any; // SupportedCustomData[]
  @Output() valueChanged = new EventEmitter<any>();

  customForm: UntypedFormGroup = new UntypedFormGroup({});
  customDataToFormGroup: CustomDataToFormGroup;
  private destroyed$ = new Subject<void>();
  filteredSupportedData = [];
  formFieldsView = [];
  showForm = false;
  skip = true;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.supportedCustomData && this.supportedCustomData) {
      this.clearFormSetup();
      this.filteredSupportedData = Object.entries(this.supportedCustomData).map(
        (entry) => {
          return { key: entry[0], type: entry[1], order: 1 };
        },
      );
      this.skip = true;
      this.initForm();
    }
  }

  clearForm(): void {
    this.customForm.reset();
  }

  clearFormSetup() {
    Object.entries(this.customForm.controls).forEach((val) => {
      this.customForm.removeControl(val[0]);
    });
    this.formFieldsView = [];
  }

  getFormValue = (): any =>
    this.removeFalsy(
      this.validateCustomData(
        this.customForm.value,
        this.customDataToFormGroup,
        this.filteredSupportedData,
      ),
    );

  initForm() {
    this.customDataToFormGroup = this.mapCustomDataToFormGroupData(
      this.filteredSupportedData,
      this.customData,
    );
    this.formFieldsView = Object.entries(this.customDataToFormGroup)
      .map(([key, value]) => ({ ...value, key }))
      .sort((a, b) => a.order - b.order);
    const formField = this.buildFormGroupFromCustomData(
      this.customDataToFormGroup,
      this.fb,
    );
    Object.entries(formField.controls).forEach((val) => {
      this.customForm.addControl(val[0], val[1]);
    });
    this.showForm = true;
    this.customForm.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
      )
      .subscribe((data) => {
        const newData = { ...data };
        Object.keys(newData).forEach((key) => {
          if (this.supportedCustomData[key] === 'number') {
            newData[key] = +newData[key];
          }
          if (newData[key] === 0) {
            newData[key] = null;
            this.customForm.patchValue({ [key]: null }, { emitEvent: false });
          }
        });
        if (
          !isEqual(newData, this.customData) &&
          !!this.customData &&
          !this.skip
        )
          this.valueChanged.emit(newData);
        this.skip = false;
      });
    setTimeout(() => (this.skip = false), 600);
  }

  /**
   * Capitalizes a key
   * @param key
   */
  capitalize(key: string): string {
    return `${key[0].toUpperCase()}${key.substring(1, key.length)}`;
  }

  getInputFormat(value: string): 'number' | 'checkbox' {
    switch (value) {
      case 'boolean':
        return 'checkbox';
      case 'number':
        return 'number';
      default:
        return 'checkbox';
    }
  }

  /**
   * Map custom data of a consumer to CustomDataToFormGroup model
   * @param supportedCustomData of current User
   * @param customData of consumer
   */
  mapCustomDataToFormGroupData(
    supportedCustomData: SupportedCustomData[],
    customData: CustomData,
  ): CustomDataToFormGroup {
    return supportedCustomData.reduce((acc, { key, type, order }) => {
      let placeholder: string;
      if (key.includes(`_`)) {
        placeholder = key
          .split(`_`)
          .map((val, index) => (index === 0 ? this.capitalize(val) : val))
          .join(` `);
      } else {
        placeholder = this.capitalize(key);
      }
      return {
        ...acc,
        [key]: {
          order,
          type: this.getInputFormat(type),
          value: customData[key] !== 0 ? customData[key] : null,
          placeholder,
          formControlName: key.replace(` `, `_`).replace(`.`, ``),
        },
      } as CustomDataToFormGroup;
    }, {});
  }

  /**
   * Remove undefined and null, and empty string values from an object
   * @param obj - params object
   */
  removeFalsy(obj: object): NonNullable<any> {
    return Object.entries(obj)
      .filter(([key, value]) => !!value)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  }

  /**
   * Use CustomDataToFormGroup data to build a FormGroup
   * @param data - result of mapCustomDataToFormGroupData function
   * @param fb - FormBuilder to build the FormGroup with
   */
  buildFormGroupFromCustomData(
    data: CustomDataToFormGroup,
    fb: UntypedFormBuilder,
  ): UntypedFormGroup {
    return fb.group(
      Object.entries(data).reduce((acc, [key, { value, formControlName }]) => {
        return { ...acc, [formControlName]: [value] };
      }, {}),
    );
  }

  /**
   * Validates custom_data before sending so it complies with the rules of SupportedCustomData object
   * @param data - custom_data object
   * @param supportedCustomData - supported_custom_data or supported_custon_data_consumers of user.organisation
   */
  validateCustomData(
    data: object,
    formGroupData: CustomDataToFormGroup,
    supportedCustomData: SupportedCustomData[],
  ): { [x: string]: string | number } | undefined {
    if (!data || !supportedCustomData) return undefined;

    return Object.entries(data)
      .map(([key, value]) => {
        const newKey = Object.entries(formGroupData).find(
          ([k, val]) => val.formControlName === key,
        )[0];
        return [newKey, value];
      })
      .reduce((acc, [key, value]) => {
        const customData = supportedCustomData.find(
          (entry) => entry.key === key,
        );
        if (customData) {
          return {
            ...acc,
            [key]: value ? this.castToValidType(customData.type, value) : value,
          };
        }
        return acc;
      }, {});
  }

  castToValidType(
    key: SupportedCustomDataTypes,
    value: string | number | boolean,
  ): string | number | boolean {
    switch (key) {
      case 'number':
        return +value;
      case 'boolean':
        return !!value;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
