<h2
  mat-dialog-title
  class="modal-header"
  [innerHtml]="
    'write.blocks.modal.title-' + type
      | transloco
        : {
            dish: dish[contentLang || lang],
          }
  "
></h2>
<mat-dialog-content class="modal-wrapper">
  <div class="copy">
    <div class="allergens-wrapper">
      <h3
        class="copy-header"
        [transloco]="'write.blocks.modal.copy.title-' + type"
      ></h3>
      <same-allergens
        (copyDeclarations)="
          copyDeclarations.emit({
            source: $event,
            target: dish,
            type: type === 'allergens' ? 'all' : 'add',
          })
        "
        [dishes]="filteredDishes"
        [lang]="contentLang || lang"
        [type]="type"
      >
      </same-allergens>
    </div>
    @if (allDishesNumber - filteredDishes.length > 0) {
      <button
        mat-flat-button
        class="more-similar-options"
        color="primary"
        [transloco]="'write.blocks.modal.copy.button-' + type"
        [translocoParams]="{ rest: allDishesNumber - filteredDishes.length }"
        (click)="loadMoreDishes.emit(type)"
      ></button>
    }
  </div>
  <div class="options">
    @if (dish) {
      <options
        [dish]="dish | copyDeep"
        [lang]="lang"
        [type]="type"
        (changed)="changedOption($event)"
      >
      </options>
    }
  </div>
</mat-dialog-content>
<mat-dialog-actions class="actions">
  <button
    mat-button
    mat-dialog-close
    class="cancel"
    color="primary"
    [transloco]="'shared.buttons.done'"
  ></button>
</mat-dialog-actions>
