<mat-menu #childMenu="matMenu">
  <button mat-menu-item (click)="createArchive.emit()" class="option-button">
    <mat-icon class="icon">archive</mat-icon>
    <span>{{ "menus.overview.archive" | transloco }}</span>
  </button>
  @for (item of items; track item) {
    <span>
      <button
        mat-menu-item
        [matTooltip]="
          privileges && !privileges.create_menu
            ? ('menus.overview.cant_create' | transloco)
            : ''
        "
        matTooltipClass="top-navbar-tooltip"
        [title]="item[lang] ? item[lang] : getAlternativeLanguage(item)"
        (click)="createMenu.emit(item.id)"
      >
        <span [class.italic]="!item[lang]">{{
          item[lang] ? item[lang] : getAlternativeLanguage(item)
        }}</span>
      </button>
    </span>
  }
</mat-menu>
