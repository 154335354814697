import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { Recipe, RecipeIngredient } from 'src/app/shared/Models/recipe';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { StopPropagationDirective } from '../../../../../Directives/stop-propagation/stop-propagation.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'ingredient-info',
  templateUrl: './ingredient-info.component.html',
  styleUrls: ['./ingredient-info.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatOptionModule,
    StopPropagationDirective,
    TranslocoPipe,
  ],
})
export class IngredientInfoComponent implements OnDestroy, OnChanges, OnInit {
  @Input() ingredient: RecipeIngredient;
  @Input() recipe: Recipe;
  @Input() lang: ContentLanguage;

  @Output() updateIngredient = new EventEmitter<{
    data: Partial<RecipeIngredient>;
    url: string;
  }>();
  @Output() clearIngredientInfo = new EventEmitter<void>();

  ingredientName: string;
  ingredientItalic: boolean;
  itemItalic: boolean;
  recipeName: string;
  selectedUnit = null;
  selectedUnitName: string;
  units: string[];

  constructor(
    private translate: TranslocoService,
    private utils: UtilsService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      'ingredient' in changes &&
      this.ingredient &&
      this.ingredient.ingredient_detail
    ) {
      this.selectedUnit = this.ingredient.unit;
      this.updateUnitName();
      const { name, italic } = this.utils.tryGetLabel(
        this.ingredient.ingredient_detail,
        this.lang,
      );
      this.ingredientName = name;
      this.ingredientItalic = italic;
    }
    if ('recipe' in changes && this.recipe) {
      const { name: recipeName, italic: itemItalic } = this.utils.tryGetLabel(
        this.recipe,
        this.lang,
      );
      this.recipeName = recipeName;
      this.itemItalic = itemItalic;
    }
  }

  ngOnInit(): void {
    this.units = this.utils.getUnits();
  }

  unitChange({ value }): void {
    this.selectedUnit = value;
    this.updateUnitName();
    this.updateIngredient.emit({
      data: {
        quantity: this.ingredient.quantity,
        unit: this.selectedUnit,
      },
      url: this.ingredient.url,
    });
  }

  updateUnitName(): void {
    if (!this.selectedUnit) return undefined;
    this.translate
      .selectTranslate('ingredients.info.units_abbrev.' + this.selectedUnit)
      .subscribe((v) => (this.selectedUnitName = v));
  }

  changeQuantity(event: FocusEvent): void {
    const value = (event.target as HTMLInputElement).value;
    if (!value) {
      this.updateIngredient.emit({
        data: { quantity: null, unit: this.selectedUnit },
        url: this.ingredient.url,
      });
    } else {
      const quantity = +parseFloat(value).toFixed(2);
      this.updateIngredient.emit({
        data: {
          quantity,
          unit: this.selectedUnit,
        },
        url: this.ingredient.url,
      });
    }
  }

  ngOnDestroy(): void {
    this.clearIngredientInfo.emit();
  }
}
