import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { selectLoginState } from 'src/app/auth/ngrx/auth.selectors';
import { State } from 'src/app/reducers';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ErrorResolverService {
  login = this.store.select(selectLoginState);
  constructor(
    private router: Router,
    private store: Store<State>,
  ) {}

  canActivate = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return this.login.pipe(
      map((el) => {
        if (!el) {
          if (state.url !== '/404') {
            this.router.navigate(['404']);
          } else {
            return true;
          }
        }
        return el;
      }),
    );
  };
}
