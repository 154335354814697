@if ((partner$ | async)?.code === "msm") {
  <static-header-msm [user]="user$ | async"></static-header-msm>
}
@if ((login$ | async) === true) {
  <div class="header" [style.top.px]="(partner$ | async)?.topOffset">
    <div class="bar-wrapper">
      @if ((!simple || showStatus) && (status$ | async)?.description) {
        <status-bar
          [user]="user$ | async"
          [isEmail]="isStatusBarEmail"
          [isMobile]="isStatusBarMobile"
          [status]="status$ | async"
        >
        </status-bar>
      }
      @for (task of tasks$ | async; track trackByArchive($index, task)) {
        <archive-bar
          [task]="task"
          (closed)="hideArchive(task)"
          (download)="downloadArchive($event)"
        >
        </archive-bar>
      }
    </div>
    @if ((login$ | async) === true && !hideTopBar) {
      <menutech-navbar
        class="navbar"
        (logout)="logout()"
        [partner]="partner$ | async"
        [lang]="lang"
        [user]="user$ | async"
        [class.hide]="hideTopNav && sidePanelVisible"
      >
      </menutech-navbar>
    }
  </div>
}

<mat-sidenav-container
  class="sidenav-container"
  [class.logout]="(login$ | async) === false"
  [hasBackdrop]="mode === 'over'"
  [style.top]="
    !(hideTopNav && sidePanelVisible)
      ? sidenavTopOffset + ((partner$ | async)?.topOffset ?? 0) + 'px'
      : 0 + ((partner$ | async)?.topOffset ?? 0)
  "
  [style.minHeight]="
    !(hideTopNav && sidePanelVisible) ? sidenavContainerHeight : '100%'
  "
  (backdropClick)="closeNav()"
>
  @if (!simple && (login$ | async) === true) {
    <mat-sidenav
      #sidenav
      class="sidenav"
      [class.small]="small"
      [fixedInViewport]="true"
      [fixedTopGap]="topOffset"
      [mode]="mode"
      [opened]="isOpen"
      (keydown.escape)="closeNav()"
    >
      <navigation
        [defaultTemplates]="defaultTemplates$ | async"
        [lang]="lang"
        [showModulesLink]="!currentPartner?.hideModulesLink"
        [small]="small"
        [user]="user$ | async"
        (closed)="closeNav()"
        (createMenu)="createMenuFromOnboardingTemplate($event)"
        (hide)="hideOrShow($event)"
        (logout)="logout()"
        (makeSmall)="smaller($event)"
      >
      </navigation>
    </mat-sidenav>
  }
  <mat-sidenav-content
    #sidenavContent
    class="sidenav-content"
    id="mat-sidenav-content"
    [class.sidepanel-visible]="sidePanelVisible && !sidePanelMobileView"
  >
    <main
      class="app"
      [ngClass]="{
        simple: simple,
        small: small,
      }"
      [style.minHeight]="
        !simple && !hideTopNavigation ? sidenavContainerHeight : '100%'
      "
    >
      @if (!simple && !hideTopNavigation) {
        <top-navigation
          [style.paddingLeft]="topNavigationCustomLeft + 'px'"
          [title]="
            pageVariable
              ? ('app.pages.' + pageVariable + '.title' | transloco)
              : ''
          "
          (toggle)="toggle()"
        >
        </top-navigation>
      }
      <div class="content">
        <router-outlet
          [class.side-panel-shown]="sidePanelVisible && sidePanelOpened"
          (activate)="refreshScroll()"
        >
        </router-outlet>
        <div class="loader">
          <spinner></spinner>
        </div>
        @if (!simple || showFooter) {
          <app-footer
            [lang]="lang"
            [style.paddingLeft]="
              (isStatusBarMobile ? 0 : topNavigationCustomLeft) + 'px'
            "
          ></app-footer>
        }
      </div>
    </main>
    @if (isOpen) {
      <div add-class="noscroll" to="body"></div>
    }
  </mat-sidenav-content>
</mat-sidenav-container>
<side-panel
  #sidePanel
  class="side-panel"
  (visibleEvent)="setSidePanelVisible($event)"
  (mobileViewEvent)="setSidePanelMobileView($event)"
  [style.display]="sidePanelVisible ? 'block' : 'none'"
  [hidden]="!sidePanelOpened"
  [topOffset]="topOffset"
>
</side-panel>

@if (showGlobalSpinner$ | async) {
  <div class="global-spinner">
    <spinner></spinner>
  </div>
}

<bottom-block-overlay></bottom-block-overlay>
