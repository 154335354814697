import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { DeclarationKey } from 'src/app/shared/constants/declarations';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import {
  Additive,
  Allergen,
  Declaration,
  GenericDeclaration,
  Label,
} from 'src/app/shared/Models/declarations';
import { Dish } from 'src/app/shared/Models/dish';
import { Ingredient } from 'src/app/shared/Models/ingredients';
import {
  Recipe,
  RecipeIngredient,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';
import {
  selectAddictivesDetail,
  selectAllergensDetail,
  selectLabelsDetail,
} from 'src/app/shared/ngrx/shared.selectors';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import * as _ from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { IngredientSelectDialogComponent } from '../ingredient select-dialog/ingredient-select.component';
import { AffectModalComponent } from './affect-modal/affect-modal.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { OptionItemComponent } from './option-item/option-item.component';

@Component({
  selector: 'options',
  templateUrl: './options.component.html',
  styles: [
    `
      .dependants {
        padding-left: 35px;
      }
    `,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [OptionItemComponent],
})
export class OptionsComponent implements OnChanges, OnDestroy, OnInit {
  @Input() color = 'primary';
  @Input() dish: Dish | Ingredient;
  @Input() lang: ContentLanguage;
  @Input() type: 'allergens' | 'additives' | 'labels';
  @Input() recipes: Recipe[];
  @Input() allergensView: boolean;
  @Input() additivesView: boolean;
  @Input() hasRecipes: boolean;

  @Output() changed = new EventEmitter<{
    data: Dish | Ingredient;
    type: 'allergens' | 'additives' | 'labels';
  }>();
  @Output() fetchRecipes = new EventEmitter<void>();
  @Output() searchIngredients = new EventEmitter<string>();
  @Output() deleteIngredientEvent = new EventEmitter<{
    deletingIngredient: SimpleRecipeIngredient;
    recipe: Recipe;
  }>();
  @Output() addIngredientEvent = new EventEmitter<Recipe>();
  @Output() selectedIngredientEvent = new EventEmitter<{
    ingredient_id: number;
    recipe: Recipe;
  }>();
  @Output() createIngredientEvent = new EventEmitter<{
    newIngredient: Partial<Recipe>;
    recipe: Recipe;
  }>();
  @Output() updateRecipeIngredientEvent = new EventEmitter<{
    recipe: Recipe;
    updatedIngredient: {
      url: string;
      recipeIngredient: Partial<RecipeIngredient>;
      onFulfilled: Function;
    };
  }>();
  @Output() updateIngredientEvent = new EventEmitter<{
    recipe: Recipe;
    updatedIngredient: {
      ingredient: Partial<Ingredient>;
      recipeIngredient: RecipeIngredient;
    };
  }>();

  changeOptions = new EventEmitter();
  private destroyed$ = new Subject<void>();
  options: GenericDeclaration[];
  localOptions: { [idx: number]: GenericDeclaration };
  translations: { [key: DeclarationKey]: boolean }[];
  allergens: Allergen[] = [];
  additives: Additive[] = [];
  labels: Label[] = [];
  countries = ['DE'];
  showConfirmDialog = false;

  affectedOptions = {
    col: ['adv'],
    sweet: ['phen', 'lax'],
    caf: ['hcaf'],
  };
  showAfter = Object.keys(this.affectedOptions).reduce(
    (res, el) =>
      Array.isArray(this.affectedOptions[el])
        ? [...res, ...this.affectedOptions[el]]
        : [...res, this.affectedOptions[el]],
    [],
  );

  allergens$ = this.ngrxStore.select(selectAllergensDetail);
  additives$ = this.ngrxStore.select(selectAddictivesDetail);
  labels$ = this.ngrxStore.select(selectLabelsDetail);
  constructor(
    private ngrxStore: Store<State>,
    private utils: UtilsService,
    private dialog: MatDialog,
  ) {
    this.allergens$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((all) => (this.allergens = all ? all : this.allergens));
    this.additives$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((add) => (this.additives = add ? add : this.additives));
    this.labels$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((lab) => (this.labels = lab ? lab : this.labels));
  }

  ngOnInit() {
    this.utils
      .getTranslationObject(`${this.type}.elements`, (v) =>
        this.handleTranslation(v as { [key: DeclarationKey]: boolean }[]),
      )
      .subscribe();
    this.changeOptions
      .pipe(debounceTime(400), takeUntil(this.destroyed$))
      .subscribe((options: { [key: DeclarationKey]: boolean }) => {
        const clonedDish = _.cloneDeep(this.dish);
        clonedDish[this.type] = options;
        this.changed.emit({ data: clonedDish, type: this.type });
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.dish) return undefined;
    if (this.recipes) {
      this.showConfirmDialog =
        this.recipes.length || this.hasRecipes ? true : false;
    }
    if (changes.translations || changes.dish)
      this.fillOptions(this.translations);
  }

  hasModuleSetting = (code: string, setting: string, value: any): boolean =>
    this.utils.hasModuleSetting(code, setting, value);

  showDialog({
    declaration,
    checkbox,
  }: {
    declaration: GenericDeclaration;
    checkbox: MatCheckbox;
  }) {
    if (this.hasRecipes && !this.recipes?.length) {
      this.fetchRecipes.emit();
    }
    this.dialog.open(IngredientSelectDialogComponent, {
      data: {
        dish: this.dish,
        lang: this.lang,
        type: this.type,
        recipes: this.recipes,
        option: declaration,
        action: !declaration.value,
        allergensView: this.allergensView,
        additivesView: this.additivesView,
        save: (checkBoxValue: boolean) => {
          checkbox.checked = checkBoxValue;
          this.changeOption(declaration);
        },
        searchIngredients: (searchIngredient) => {
          this.searchIngredients.emit(searchIngredient);
        },
        chooseIngredient: (data: { ingredient_id: number; recipe: Recipe }) => {
          this.selectedIngredientEvent.emit(data);
        },
        updateIngredient: (data) => {
          this.updateIngredientEvent.emit(data);
        },
        updateRecipeIngredient: (data: {
          recipe: Recipe;
          updatedIngredient: {
            url: string;
            recipeIngredient: Partial<RecipeIngredient>;
            onFulfilled: Function;
          };
        }) => {
          this.updateRecipeIngredientEvent.emit(data);
        },
        deleteIngredient: (data: {
          deletingIngredient: SimpleRecipeIngredient;
          recipe: Recipe;
        }) => {
          this.deleteIngredientEvent.emit(data);
        },
        addIngredient: (Recipe: Recipe) => {
          this.addIngredientEvent.emit(Recipe);
        },
        createIngredient: (data: {
          newIngredient: Partial<Recipe>;
          recipe: Recipe;
        }) => {
          this.createIngredientEvent.emit(data);
        },
        changeRecipeDeclarativeOption: (data) => {
          this.updateIngredientEvent.emit(data);
        },
      },
      autoFocus: false,
      width: '900px',
      maxWidth: '100vw',
    });
  }

  hasModule = (code: string): boolean => this.utils.hasModules(code);

  getParent = (val: DeclarationKey) =>
    Object.keys(this.affectedOptions).find((parent) =>
      this.affectedOptions[parent].find((child) => child === val),
    );

  parentEnable = (val: DeclarationKey): boolean => {
    const parent = this.getParent(val);
    return this.getOptionValue(parent);
  };

  hasEnableChild = (parent: DeclarationKey): boolean => {
    if (!this.affectedOptions[parent]) return false;
    return !!this.affectedOptions[parent].filter((el) =>
      this.getOptionValue(el),
    ).length;
  };

  getOptionValue = (val): boolean => {
    const parentObject = this.options.find((el) => el.name === val);
    return parentObject ? parentObject.value : false;
  };

  handleTranslation = (
    translations: { [key: DeclarationKey]: boolean }[],
  ): void => {
    this.translations = translations;
    this.fillOptions(translations);
  };

  openModalOfAffects = ({ name: parent }): void => {
    const childrens = this.options.filter(({ name }) =>
      this.affectedOptions[parent].includes(name),
    );
    const dialog = this.dialog.open(AffectModalComponent);
    const instance = dialog.componentInstance;
    instance.option = parent;
    instance.childrens = childrens;
    instance.items =
      this.type === 'allergens'
        ? this.allergens
        : this.type === 'additives'
          ? this.additives
          : this.labels;
    instance.changed
      .pipe(filter((el) => el.name !== parent))
      .subscribe(this.changeOption);
  };

  fillOptions = (translations: { [key: DeclarationKey]: boolean }[]): void => {
    if (
      !translations ||
      !this ||
      !this.allergens ||
      !this.additives ||
      !this.labels ||
      !this.dish ||
      !this.dish[this.type]
    ) {
      return undefined;
    }
    if (!translations) return undefined;
    const newOptions = this.getFilteredOptions(translations);
    if (!_.isEqual(newOptions, this.options)) {
      this.options = newOptions;
      this.localOptions = { ...this.options };
    }
  };

  getFilteredOptions(
    translations: { [key: DeclarationKey]: boolean }[],
  ): GenericDeclaration[] {
    return Object.keys(translations)
      .map((el: DeclarationKey) => this.getElement(el))
      .filter((el) => el && !this.getProperty(el, 'depends_on'))
      .map(this.elementMapper)
      .sort((a, b) => a.order - b.order);
  }

  elementMapper = (el: Declaration): GenericDeclaration => {
    return new GenericDeclaration(
      el.code,
      this.findElem(el.code),
      this.getProperty(el, 'order', -1),
      this.getDependants(el),
    );
  };

  findElem(code: string): boolean {
    return !!this.dish[this.type][code];
  }

  getElement(code: DeclarationKey): Declaration {
    const arr =
      this.type === 'allergens'
        ? this.allergens
        : this.type === 'additives'
          ? this.additives
          : this.labels;

    if (!arr) return undefined;
    return arr.find((v) => v.code === code);
  }

  getProperty(item: Declaration, field: string, def?: number): number {
    if (!item) return def;
    return item ? item[field] : def;
  }

  getDependants(elem: Declaration): GenericDeclaration[] {
    const arr: Declaration[] =
      this.type === 'allergens'
        ? this.allergens
        : this.type === 'additives'
          ? this.additives
          : this.labels;
    if (!arr) return undefined;
    return arr
      .filter((item) => item.depends_on === elem.id)
      .map((el) => this.elementMapper(el));
  }

  changeOption = (option: GenericDeclaration): void => {
    if (this.affectedOptions[option.name] && option.value)
      this.openModalOfAffects(option);
    this.localOptions[option.name] = option.value;
    if (option.dependants && !option.value) {
      option.dependants = option.dependants.map((v) => {
        v.value = false;
        return v;
      });
    }
    if (this.dish && !this.dish[this.type]) this.dish[this.type] = {};
    this.changeOptions.emit(this.extractValues(this.localOptions));
  };

  extractValues(options: { [idx: number]: GenericDeclaration }): {
    [key: DeclarationKey]: boolean;
  } {
    const values = {};
    Object.keys(options).forEach((key) => {
      const opt: GenericDeclaration = options[key];
      if (_.isObject(opt)) {
        if (opt.dependants) {
          opt.dependants.forEach((dep) => (values[dep.name] = dep.value));
        }
        values[opt.name] = opt.value;
      }
    });
    return values;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
