import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { Menu } from 'src/app/shared/Models/menu';

import {
  applyStyle,
  patchRuleDetail,
  setLayout,
  updateStyle,
} from '../ngrx/menu-style.actions';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MtTooltipDirective } from '../../../../shared/Directives/mt-tooltip/mt-tooltip.directive';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'update-rule',
  templateUrl: './update-rule.component.html',
  styleUrls: ['./update-rule.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MtTooltipDirective,
    MatCardModule,
    MatButtonModule,
    TranslocoPipe,
  ],
})
export class UpdateRuleComponent {
  @Input() lang: InterfaceLanguage;
  @Input() menu: Menu;
  styleCreated = false;

  constructor(protected ngrxStore: Store<State>) {}

  restoreStyle(): void {
    this.ngrxStore.dispatch(
      applyStyle({
        showSpinner: false,
        style: this.menu?.rule_detail?.style,
        restore: true,
        callback: () => {
          this.ngrxStore.dispatch(
            setLayout({
              layoutId: this.menu?.rule_detail?.template,
            }),
          );
        },
      }),
    );
  }

  updateRuleStyle(): void {
    this.styleCreated = true;
    this.ngrxStore.dispatch(
      updateStyle({
        payload: { target: 'rule' },
        params: { current_menu: this.menu.id },
      }),
    );
    this.ngrxStore.dispatch(
      patchRuleDetail({
        url: this.menu?.rule_detail.url,
        data: { template: this.menu?.template },
      }),
    );
  }
}
