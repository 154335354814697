<div
  class="wrapper"
  #tooltip="matTooltip"
  [matTooltip]="disabled && !template?.menu_detail ? tooltipMessage : ''"
  matTooltipClass="top-navbar-tooltip"
>
  <figure>
    <img
      [src]="
        (template?.menu &&
          template?.onboarding_template_detail?.template_detail?.image) ||
        '/assets/img/Empty menu.jpg'
      "
      alt="(template?.onboarding_template_detail)[lang]"
    />
    <figcaption>
      <mat-icon [class.used]="template?.menu_detail">check</mat-icon>
      {{ (template?.onboarding_template_detail)[lang] }}
    </figcaption>
  </figure>
</div>
