import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-customer-support',
  templateUrl: './customer-support.component.html',
  styleUrls: ['./customer-support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe],
})
export class CustomerSupportComponent {
  @Input() isTrialActive: boolean;
  @Input() isConsumerActive: boolean;

  @Output() showMessageOverlayEvent = new EventEmitter<boolean>();
}
