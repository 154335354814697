<div class="input-block">
  @if (description) {
    <h3>
      {{ "shared.dialogs.item-name.name" | transloco }}
    </h3>
  }
  <app-translatable-field
    [allLangs]="allLanguages"
    [initialLang]="lang"
    [item]="item"
    [isTextarea]="false"
    [loadingBars]="loadingBars"
    (fieldChanged)="onValueChanged($event)"
  ></app-translatable-field>
</div>

@if (description) {
  <div class="input-block description">
    <h3>{{ "shared.dialogs.item-name.description" | transloco }}</h3>
    <app-translatable-field
      field="description"
      [allLangs]="allLanguages"
      [item]="item"
      [isTextarea]="false"
      [loadingBars]="loadingBars"
      (fieldChanged)="onValueChanged($event)"
    ></app-translatable-field>
  </div>
}

@if (item?.category === "win") {
  <div class="wine-information">
    <mat-form-field>
      <mat-label>{{
        "write.blocks.dish-overview.information.wine.winery" | transloco
      }}</mat-label>
      <input
        matInput
        type="text"
        name="winery"
        [ngModel]="item.winery"
        (focusout)="patchItem($event)"
      />
    </mat-form-field>
    <mat-form-field hintLabel="{{ minYear }} - {{ maxYear }}">
      <mat-label>{{
        "write.blocks.dish-overview.information.wine.vintage" | transloco
      }}</mat-label>
      <input
        matInput
        type="number"
        name="vintage"
        [max]="maxYear"
        [min]="minYear"
        [ngModel]="item.vintage"
        (focusout)="patchItem($event)"
      />
    </mat-form-field>
  </div>
}
