import { ActionReducer, combineReducers } from '@ngrx/store';

import {
  menuEditReducer,
  MenuEditState,
} from '../menu-edit/ngrx/menu-edit.reducer';
import {
  menuStyleReducer,
  MenuStyleState,
} from '../menu-edit/style/ngrx/menu-style.reducer';
import {
  menuTranslateReducer,
  MenuTranslateState,
} from './../menu-edit/translate/ngrx/menu-translate.reducer';
import {
  menuWriteReducer,
  MenuWriteState,
} from './../menu-edit/write/ngrx/menu-write.reducer';
import {
  menusOverviewReducer,
  MenusOverviewState,
} from './../menus-overview/ngrx/menus-overview.reducer';

export const menusFeatureKey = 'menus';

export interface MenusState {
  menu_edit: MenuEditState;
  menu_style: MenuStyleState;
  menu_translate: MenuTranslateState;
  menu_write: MenuWriteState;
  menus_overview: MenusOverviewState;
}

export const reducers = {
  menu_edit: menuEditReducer,
  menu_style: menuStyleReducer,
  menu_translate: menuTranslateReducer,
  menu_write: menuWriteReducer,
  menus_overview: menusOverviewReducer,
};

export const menusReducer: ActionReducer<MenusState> =
  combineReducers(reducers);
