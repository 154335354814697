import { createSelector } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { dishesMenuFeatureKey } from './dishes-menu.reducer';

const selectState = (state: State) => state[dishesMenuFeatureKey];

export const selectRecipes = createSelector(
  selectState,
  (state) => state?.recipes,
);

export const isRecipesLoading = createSelector(
  selectState,
  (state) => state?.loading,
);

export const selectIngredientInfo = createSelector(
  selectState,
  (state) => state?.ingredient_info,
);

export const selectAiRecipes = createSelector(
  selectState,
  (state) => state?.ai_recipes,
);

export const selectIsAiRecipesLoading = createSelector(
  selectState,
  (state) => state?.ai_recipes_loading,
);

export const selectIsAiAllergensLoading = createSelector(
  selectState,
  (state) => state?.ai_allergens_loading,
);

export const selectIsAiDescriptionLoading = createSelector(
  selectState,
  (state) => state?.ai_description_loading,
);
