@if (title) {
  <h2 mat-dialog-title>
    {{ title }}
  </h2>
}
<mat-dialog-content>
  <p>
    {{ text }}
    <a [href]="link">{{ linkText }}</a>
  </p>
</mat-dialog-content>
<mat-dialog-actions class="actions" align="end">
  @if (cancelable) {
    <button data-cy="simple-dialog-cancel" mat-button mat-dialog-close>
      {{ cancelText | transloco }}
    </button>
  }
  <button
    data-cy="simple-dialog-confirm"
    mat-button
    cdkFocusInitial
    [mat-dialog-close]="true"
    color="primary"
  >
    {{ confirmText | transloco }}
  </button>
</mat-dialog-actions>
