<div class="wrapper">
  <div class="report-name">
    <div class="image-container">
      <img class="image" [src]="'assets/img/reports/statistics.svg'" />
    </div>
    <span class="title">{{
      "menus.linked_templates.view_stats" | transloco
    }}</span>
  </div>

  <div class="highlights dates icon-button-density-3">
    <mat-form-field class="date" subscriptSizing="dynamic">
      <mat-label>{{
        "menus.linked_templates.start_date" | transloco
      }}</mat-label>
      <input
        matInput
        #inputStart="matDatepickerInput"
        (click)="pickerStart.open()"
        [matDatepicker]="pickerStart"
        [min]="minDate"
        [max]="maxDate"
      />
      @if (inputStart.value) {
        <button
          stop-propagation
          matSuffix
          mat-icon-button
          (click)="inputStart.value = null; endDate.min = null"
        >
          <mat-icon>clear</mat-icon>
        </button>
      }
      <mat-datepicker-toggle
        matSuffix
        [for]="pickerStart"
      ></mat-datepicker-toggle>
      <mat-datepicker #pickerStart></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="date" subscriptSizing="dynamic">
      <mat-label>{{ "menus.linked_templates.end_date" | transloco }}</mat-label>
      <input
        matInput
        #inputEnd="matDatepickerInput"
        (click)="pickerEnd.open()"
        [matDatepicker]="pickerEnd"
        [max]="maxDate"
        [min]="inputStart.value"
      />
      @if (inputEnd.value) {
        <button
          stop-propagation
          matSuffix
          mat-icon-button
          (click)="inputEnd.value = null; startDate.max = null"
        >
          <mat-icon>clear</mat-icon>
        </button>
      }
      <mat-datepicker-toggle
        matSuffix
        [for]="pickerEnd"
      ></mat-datepicker-toggle>
      <mat-datepicker #pickerEnd></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="buttons">
    <button
      mat-button
      class="button"
      [disabled]="!inputStart.value || !inputEnd.value"
      (click)="downloadStats()"
    >
      {{ "menus.steps.complete.actions.download" | transloco }}
    </button>
  </div>
</div>
