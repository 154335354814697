import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LANDIN_CONFIG } from 'src/app/app.config';
import { TranslocoPipe } from '@jsverse/transloco';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'app-footer',
  template: `
    <div class="footer" [ngStyle]="{ 'background-color': backgroundColor }">
      <div class="copyright">
        <span
          >&copy; Copyright {{ currentYear }} Menutech GmbH. All rights
          reserved.</span
        >
      </div>
      <ul>
        @for (item of items; track item) {
          <li>
            <a
              [href]="url + (directory | transloco) + (item.link | transloco)"
              rel="noopener"
              target="_blank"
            >
              {{ item.label | transloco }}
            </a>
          </li>
        }
      </ul>
    </div>
  `,
  styles: [
    `
      .footer {
        width: 100%;
        padding: 5px 15px 5px 25px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
      }
      .copyright {
        padding: 5px 0;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 5px 0;
      }
      li {
        display: inline-block;
        padding: 0 10px;
      }
      a,
      span {
        color: #757575;
        font-size: 15px;
        font-weight: 300;
      }
      @media only screen and (max-width: 767px) {
        .footer {
          display: block;
          text-align: center;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle, TranslocoPipe],
})
export class FooterComponent {
  @Input() lang;
  @Input() backgroundColor = 'rgb(221, 221, 221)';

  currentYear;
  url = LANDIN_CONFIG.value;
  directory = 'shared.footer.directory';

  items = [
    {
      link: 'shared.footer.links.imprint',
      label: 'shared.footer.labels.imprint',
    },
    {
      link: 'shared.footer.links.privacy',
      label: 'shared.footer.labels.privacy',
    },
    {
      link: 'shared.footer.links.terms_link',
      label: 'shared.footer.labels.terms_link',
    },
  ];

  constructor() {
    const d = new Date();
    this.currentYear = d.getFullYear();
  }
}
