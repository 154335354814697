import { createSelector } from '@ngrx/store';
import { menusFeatureKey } from 'src/app/menus/ngrx/menus.store';
import { State } from 'src/app/reducers';
import { menuEditFeatureKey } from './menu-edit.reducer';

const selectState = (state: State) =>
  state[menusFeatureKey]?.[menuEditFeatureKey];

export const selectCurrentSpellcheckItem = createSelector(
  selectState,
  (state) => state?.current_spellcheck_item,
);

export const selectCurrentMenu = createSelector(
  selectState,
  (state) => state?.current_menu,
);

export const selectCurrentMenuOnboardingTemplateDetail = createSelector(
  selectState,
  (state) => state.current_menu?.onboarding_template_detail,
);

export const selectStyleModified = createSelector(
  selectState,
  (state) => state?.current_menu?.style_modified,
);

export const selectBackgroundImages = createSelector(
  selectState,
  (state) => state?.background_images,
);

export const selectStyleBackgroundImages = createSelector(
  selectState,
  (state) => state?.background_images.filter((img) => img.type === 1),
);

export const selectWriteBackgroundImages = createSelector(
  selectState,
  (state) => state?.background_images.filter((img) => img.type === 2),
);

export const selectIsAiAnalysisLoading = createSelector(
  selectState,
  (state) => state?.ai_analysis_loading,
);
