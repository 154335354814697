import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MatDatepicker,
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { resendEmailConfirmation } from 'src/app/auth/ngrx/auth.actions';
import { State } from 'src/app/reducers';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import {
  AccountStatusMessage,
  ButtonActions,
} from 'src/app/shared/Models/models';
import { UserOnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import { Status as UserStatus, User } from 'src/app/shared/Models/user';
import {
  EventBusService,
  SharedEventTypes,
} from 'src/app/shared/Services/event-bus/event-bus.service';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import {
  selectLimitTemplates,
  selectUserPrivileges,
} from 'src/app/shared/user/ngrx/user.selectors';

import {
  selectMainStatus,
  selectMenuStatus,
  selectTemplateStatus,
  selectTemplatesUsed,
} from './../../user/ngrx/user.selectors';
import { TranslocoPipe } from '@jsverse/transloco';
import { MtTooltipDirective } from '../../Directives/mt-tooltip/mt-tooltip.directive';
import { UserOnboardingTemplateComponent } from './user-onboarding-template/user-onboarding-template.component';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'status-block',
  templateUrl: './status-block.component.html',
  styleUrls: ['./status-block.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    NgClass,
    MatButtonModule,
    RouterLink,
    UserOnboardingTemplateComponent,
    MtTooltipDirective,
    MatDatepickerModule,
    MatMenuModule,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class StatusBlockComponent {
  @Input() complete;
  @Input() menusCreated;
  @Input() status: UserStatus;
  @Input() steps: Partial<AccountStatusMessage>;
  @Input() user: User;
  @Input() userOnboardingTemplates: UserOnboardingTemplate[];

  @Output() createMenuFromOnboardingTemplate = new EventEmitter<{
    date: Date;
    template: number;
  }>();

  currentTemplate: number;
  lang: InterfaceLanguage;
  latestOnboardingTemplate: UserOnboardingTemplate;

  templatesUsed$ = this.ngrxStore.select(selectTemplatesUsed);
  limitTemplates$ = this.ngrxStore.select(selectLimitTemplates);
  menuStatus$ = this.ngrxStore.select(selectMenuStatus);
  privileges$ = this.ngrxStore.select(selectUserPrivileges);
  templateStatus$ = this.ngrxStore.select(selectTemplateStatus);
  userStatus$ = this.ngrxStore.select(selectMainStatus);

  @ViewChild(`datepicker`) matDatepicker: MatDatepicker<Date>;
  @ViewChild(MatMenuTrigger) onboardingTemplatesTrigger: MatMenuTrigger;

  constructor(
    private router: Router,
    private utils: UtilsService,
    private eventBus: EventBusService,
    private ngrxStore: Store<State>,
  ) {
    this.utils.getLang((lang: InterfaceLanguage) => (this.lang = lang));
  }

  openMenu(): void {
    this.eventBus.emit({ type: SharedEventTypes.TriggerCreateMenuButton });
  }

  next(): void {
    if (this.steps?.link?.route) {
      this.router.navigate(this.steps.link.route, {
        queryParams: this.steps.link.query,
      });
      return undefined;
    }
    if (this.steps?.link?.function) return this.steps.link.function();
    if (this.steps.link)
      this.utils.getNativeWindow().open(this.steps.link, '_self');
    if (this.steps.buttonAction === ButtonActions.showTemplatesMenu) {
      this.onboardingTemplatesTrigger?.openMenu();
    }
  }

  openDatepicker(template: UserOnboardingTemplate): void {
    if (template?.menu_detail) {
      this.router.navigate(['menus', template?.menu_detail?.id, 4]);
      return undefined;
    }
    this.latestOnboardingTemplate = template;
    if (template?.onboarding_template_detail?.ask_date) {
      this.matDatepicker.open();
    } else {
      this.dateSelected({ value: new Date() });
    }
  }

  dateSelected(date: MatDatepickerInputEvent<Date> | { value: Date }): void {
    this.createMenuFromOnboardingTemplate.emit({
      date: date?.value,
      template: this.latestOnboardingTemplate.onboarding_template_detail?.id,
    });
  }

  resendEmailConfirmation = (): void => {
    this.ngrxStore.dispatch(resendEmailConfirmation());
  };
}
