import { createSelector } from '@ngrx/store';
import { menusFeatureKey } from 'src/app/menus/ngrx/menus.store';
import { State } from 'src/app/reducers';
import { menuTranslateFeatureKey } from './menu-translate.reducer';

const selectState = (state: State) =>
  state[menusFeatureKey]?.[menuTranslateFeatureKey];

export const selectIsMenuTranslationLoading = createSelector(
  selectState,
  (state) => state?.menu_translation_loading,
);

export const selectTranslationsList = createSelector(
  selectState,
  (state) => state?.list,
);

export const selectCurrentTranslation = createSelector(
  selectState,
  (state) => state?.current,
);

export const isCurrentTranslationLoading = createSelector(
  selectState,
  (state) => state?.current_translation_loading,
);

export const selectSimilarTranslations = createSelector(
  selectState,
  (state) => state?.similar,
);

export const selectSimilarTranslationsNextUrl = createSelector(
  selectState,
  (state) => state?.similar_next_url,
);

export const selectSimilarTranslationsCount = createSelector(
  selectState,
  (state) => state?.similar_count,
);
