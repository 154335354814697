<form [formGroup]="form">
  <mat-form-field>
    <mat-label>{{
      showLabel
        ? translations?.[
            "write.blocks.dish-overview.information.frozen_product.placeholder"
          ] ||
          ("write.blocks.dish-overview.information.frozen_product.placeholder"
            | transloco)
        : ""
    }}</mat-label>
    <mat-select
      formControlName="frozen_product"
      [value]="dish?.dish_detail?.user_details?.frozen_product"
    >
      @if (dish?.dish_detail?.user_details?.frozen_product) {
        <mat-select-trigger>{{
          translations?.[
            "write.blocks.dish-overview.information.frozen_product." +
              options[dish?.dish_detail?.user_details?.frozen_product].name
          ] ||
            ("write.blocks.dish-overview.information.frozen_product." +
              options[dish?.dish_detail?.user_details?.frozen_product].name
              | transloco)
        }}</mat-select-trigger>
      }
      @for (option of options; track option) {
        <mat-option class="capitalize" stop-propagation [value]="option.value">
          {{
            translations?.[
              "write.blocks.dish-overview.information.frozen_product." +
                option.name
            ] ||
              ("write.blocks.dish-overview.information.frozen_product." +
                option.name | transloco)
          }}</mat-option
        >
      }
    </mat-select>
    @if (loading.frozen_product) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{
      showLabel
        ? translations?.[
            "write.blocks.dish-overview.information.enhancement_declaration.placeholder"
          ] ||
          ("write.blocks.dish-overview.information.enhancement_declaration.placeholder"
            | transloco)
        : ""
    }}</mat-label>
    <mat-select
      formControlName="enhancement_declaration"
      [value]="dish?.dish_detail?.user_details?.enhancement_declaration"
    >
      @if (dish?.dish_detail?.user_details?.enhancement_declaration) {
        <mat-select-trigger>{{
          translations?.[
            "write.blocks.dish-overview.information.enhancement_declaration." +
              optionsEnhancers[
                dish?.dish_detail?.user_details?.enhancement_declaration
              ].name
          ] ||
            ("write.blocks.dish-overview.information.enhancement_declaration." +
              optionsEnhancers[
                dish?.dish_detail?.user_details?.enhancement_declaration
              ].name | transloco)
        }}</mat-select-trigger>
      }
      @for (option of optionsEnhancers; track option) {
        <mat-option
          class="capitalize"
          stop-propagation
          [value]="option.value"
          [title]="
            translations?.[
              'write.blocks.dish-overview.information.enhancement_declaration.' +
                option.name
            ]
          "
        >
          {{
            translations?.[
              "write.blocks.dish-overview.information.enhancement_declaration." +
                option.name
            ] ||
              ("write.blocks.dish-overview.information.enhancement_declaration." +
                option.name | transloco)
          }}</mat-option
        >
      }
    </mat-select>
    @if (loading.enhancement_declaration) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
  </mat-form-field>
</form>
