import { AfterViewInit, Directive, Input, OnDestroy } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[add-class]',
  standalone: true,
})
export class AddClassDirective implements OnDestroy, AfterViewInit {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('add-class') className: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('to') selector: string;

  ngOnDestroy(): void {
    document.querySelector(this.selector).classList.remove(this.className);
  }

  ngAfterViewInit(): void {
    document.querySelector(this.selector).classList.add(this.className);
  }
}
