import { createSelector } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { userFeatureKey } from './user.reducer';

const selectState = (state: State) => state[userFeatureKey];

export const selectUser = createSelector(
  selectState,
  (state) => state?.currentUser,
);

export const selectUserOrganisation = createSelector(
  selectUser,
  (user) => user?.organisation,
);

export const selectUserPartner = createSelector(
  selectUser,
  (user) => user?.partner_detail,
);

export const selectUserPartnerCode = createSelector(
  selectUser,
  (user) => user?.partner_code,
);

export const selectUserEmail = createSelector(
  selectState,
  (state) => state?.currentUser?.email,
);

export const selectUserFullName = createSelector(
  selectState,
  (state) =>
    `${state?.currentUser?.first_name} ${state?.currentUser?.last_name}`,
);

export const selectInterfaceLanguage = createSelector(
  selectState,
  (state) => state?.currentUser?.settings?.language,
);

export const selectUserCurrency = createSelector(
  selectUser,
  (user) => user?.settings?.currency_symbol,
);

export const selectUserProfileComplete = createSelector(
  selectUser,
  (user) => user?.status?.completed_profile,
);

export const selectUserPrivileges = createSelector(
  selectUser,
  (user) => user?.status?.privileges,
);

export const selectMenusLimit = createSelector(
  selectUser,
  (user) => user?.status?.usage_limits?.limit_menus_per_month,
);

export const selectMenusUsed = createSelector(
  selectUser,
  (user) => user?.status?.current_usage?.menus_created_this_month,
);

export const selectLimitMenus = createSelector(
  selectMenusLimit,
  selectMenusUsed,
  (menusLimit, menusUsedCount) => menusLimit && menusUsedCount >= menusLimit,
);

export const selectTemplatesLimit = createSelector(
  selectUser,
  (user) => user?.status?.usage_limits?.limit_templates_per_month,
);

export const selectTemplatesUsed = createSelector(
  selectUser,
  (user) => user?.status?.list_templates_used_this_month,
);

export const selectLimitTemplates = createSelector(
  selectTemplatesUsed,
  selectTemplatesLimit,
  (templatesUsed, templatesLimit) =>
    templatesUsed && templatesUsed?.length >= templatesLimit,
);

export const selectUserField = ({ field }) =>
  createSelector(selectUser, (user) => {
    return user?.[field];
  });

export const selectIsTrialPeriod = createSelector(
  selectUser,
  (user) => user?.status?.trial_period,
);

export const selectIsAdministrator = createSelector(selectUser, (user) =>
  [0, 1].includes(user?.role),
);

export const selectOrganisationUsers = createSelector(
  selectState,
  (state) => state?.organisation_users,
);

export const selectMainStatus = createSelector(
  selectState,
  (state) => state?.main,
);

export const selectMenuStatus = createSelector(
  selectState,
  (state) => state?.menus,
);

export const selectTemplateStatus = createSelector(
  selectState,
  (state) => state?.templates,
);

export const selectBlockStatusMessage = createSelector(
  selectMainStatus,
  selectMenuStatus,
  (main, menu) => main?.message ?? menu?.message,
);

export const selectStatusBar = createSelector(
  selectState,
  (state) => state?.statusBar,
);

export const selectStatusBarEmail = createSelector(
  selectState,
  (state) => state?.statusBarEmail,
);

export const selectStatusBarMobile = createSelector(
  selectState,
  (state) => state?.statusBarMobile,
);

export const selectAiLimits = createSelector(selectUser, (user) => ({
  allergens: user?.status?.usage_limits?.limit_ai_allergens_per_month,
  dishes: user?.status?.usage_limits?.limit_ai_dishes_per_month,
  menu_analysis: user?.status?.usage_limits?.limit_ai_menu_analysis_per_month,
  recipes: user?.status?.usage_limits?.limit_ai_recipes_per_month,
}));

export const selectRemainingAiCredits = createSelector(selectUser, (user) => ({
  allergens:
    user?.status?.usage_limits?.limit_ai_allergens_per_month -
    user?.status?.current_usage?.ai_allergens_this_month,
  dishes:
    user?.status?.usage_limits?.limit_ai_dishes_per_month -
    user?.status?.current_usage?.ai_dishes_this_month,
  menu_analysis:
    user?.status?.usage_limits?.limit_ai_menu_analysis_per_month -
    user?.status?.current_usage?.ai_menu_analysis_this_month,
  recipes:
    user?.status?.usage_limits?.limit_ai_recipes_per_month -
    user?.status?.current_usage?.ai_recipes_this_month,
}));

export const selectCustomDomain = createSelector(
  selectUserOrganisation,
  (organisation) => organisation?.custom_domain ?? '',
);
