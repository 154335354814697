<div class="page-selector">
  <button (click)="showPreviousPdfPage()" class="page-selector-button">
    <span>&#10094;</span>
  </button>
  <div class="page-selector-text">{{ currentPage() }} / {{ totalPages() }}</div>
  <button (click)="showNextPdfPage()" class="page-selector-button">
    <span>&#10095;</span>
  </button>
</div>

<div class="pdf-preview-container">
  <iframe #iframe class="pdf-preview-iframe mat-elevation-z2"></iframe>
</div>
