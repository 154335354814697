import { createAction, props } from '@ngrx/store';
import { Affiliate, EmailPreferences, User } from 'src/app/shared/Models/user';
import {
  AcceptInvitationForm,
  EmailVerification,
  LoginForm,
  RegistrationForm,
} from 'src/app/shared/Models/authentication';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { Invitee } from 'src/app/shared/Models/invitee';
import { ResetPassword } from 'src/app/shared/Models/models';
import { TemplatesRequest } from 'src/app/shared/Models/onboarding_template';

export const checkEmailRemotely = createAction(
  '[Registration / Settings container / Account tab] Check email remotely',
  props<{ email: string }>(),
);

export const setEmailRemoteCheck = createAction(
  '[Registration / Settings container / Account tab] Set remote email check data locally',
  props<{ verificationData: EmailVerification }>(),
);

export const fetchLoggedIn = createAction(
  `[Authentication] Fetch logged-in status`,
);

export const loginUser = createAction(
  '[Authentication] Login user',
  props<{ loginFormData: LoginForm }>(),
);

export const loginWithMagicLink = createAction(
  '[Auth guard] Login with magic link',
  props<{ token: string }>(),
);

export const loginWithToken = createAction(
  '[Authentication] Login user using a token',
  props<{ token: string }>(),
);

export const logoutUser = createAction(
  `[Authentication] Logout user`,
  props<{ redirectUrl?: string; relativeRedirect?: boolean }>(),
);

export const notifyLoginSuccess = createAction(
  `[Authentication] Login was successful`,
);

export const notifyRegistrationSuccess = createAction(
  `[Authentication] Registration was successful`,
);

export const notifyAcceptInvitationSuccess = createAction(
  `[Authentication] Invitation accepted was successful`,
);

export const notifyEmailConfirmationSuccess = createAction(
  `[Authentication] Email confirmation was successful`,
);

export const redirectAfterLogin = createAction(
  '[Authentication] Redirect user after login',
  props<{ email?: string }>(),
);

export const redirectAfterLogout = createAction(
  '[Authentication] Redirect user after logout',
  props<{ relative: boolean; url: string }>(),
);

export const resendEmailConfirmation = createAction(
  '[Authentication] Resend email confirmation',
);

export const setLoggedIn = createAction(
  `[Authentication] Set user logged-in locally`,
  props<{ isLoggedIn: boolean }>(),
);

export const setLoginSpinnerState = createAction(
  '[Authentication] Set login spinner state locally',
  props<{ loading: boolean }>(),
);

export const setMagicLink = createAction(
  `[Authentication] Set magic link state locally`,
  props<{ isMagicLink: boolean }>(),
);

export const setNextPage = createAction(
  `[Authentication] Set next page locally`,
  props<{ nextPage: string }>(),
);

export const showErrorMessage = createAction(
  '[Authentication] Show error message',
  props<{ showError: boolean }>(),
);

export const confirmEmail = createAction(
  `[Email confirmation] Confirm email address`,
  props<{ key: string }>(),
);

export const setEmailVerified = createAction(
  `[Email confirmation] Set email verified locally`,
  props<{ success: boolean }>(),
);

export const setEmailPreferencesError = createAction(
  `[Email preferences] Set email preferences error locally`,
  props<{ message: string }>(),
);

export const updateEmailPreferences = createAction(
  `[Email preferences] Update email preferences`,
  props<{ data: EmailPreferences; email: string; token: string }>(),
);

export const completeSetup = createAction(
  '[Onboarding] Complete onboarding setup',
);

export const requestNewContent = createAction(
  `[Onboarding] Request new content`,
  props<{ message: string; content: 'template' | 'type'; user: User }>(),
);

export const sendOnboardingTemplates = createAction(
  '[Onboarding] Send onboarding onboarding templates',
  props<{ data: TemplatesRequest }>(),
);

export const fetchAffiliates = createAction(
  `[Registration] Fetch affiliates`,
  props<{ code: string }>(),
);

export const register = createAction(
  '[Registration] Register new user',
  props<{ data: RegistrationForm; lang: InterfaceLanguage }>(),
);

export const setAffiliates = createAction(
  `[Registration] Set affiliates locally`,
  props<{ affiliates: Affiliate[] }>(),
);

export const setAffiliatesError = createAction(
  `[Registration] Set affiliates error locally`,
  props<{ error: boolean }>(),
);

export const setRegisterButtonState = createAction(
  '[Registration] Set register button state locally',
  props<{ loading: boolean }>(),
);

export const resetPassword = createAction(
  '[Reset password] Sent email for reset password',
  props<{ email: string }>(),
);

export const setResetPasswordButtonState = createAction(
  '[Reset password] Set reset password button state locally',
  props<{ loading: boolean }>(),
);

export const resetPasswordConfirm = createAction(
  '[Reset password] Reset password',
  props<{ resetPassword: ResetPassword }>(),
);

export const reactivateTrial = createAction(
  '[Registration / Reactivation] Reactive trial',
  props<{ uid: string; token: string }>(),
);

export const setReactivationDone = createAction(
  `[Registration / Reactivation] Set reactivation state locally`,
  props<{ done: boolean }>(),
);

export const setReactivationRestoreError = createAction(
  `[Registration / Reactivation / Restore] Set reactivation/restore error locally`,
  props<{ message: string }>(),
);

export const restoreAccount = createAction(
  '[Registration / Restore] Restore account',
  props<{ uid: string; token: string }>(),
);

export const acceptInvitation = createAction(
  '[Invitation container] Accept Invitation ',
  props<{ data: Partial<AcceptInvitationForm> }>(),
);

export const setAcceptButton = createAction(
  '[Invitation container] Accept button spinner state',
  props<{ loading: boolean }>(),
);

export const checkInvitationKey = createAction(
  '[Invitation container] Check invitation key',
  props<{ key: string }>(),
);

export const setInvitee = createAction(
  '[Invitation container] Set invitee',
  props<{ invitee: Invitee }>(),
);

export const setInviteeSpinner = createAction(
  '[Invitation container] Set invitee spinner',
  props<{ loading: boolean }>(),
);

export const setExistingEmailError = createAction(
  '[Authentication] Set existing email text',
  props<{ error: boolean }>(),
);
