import { BaseDescriptionExtendedSecondary, BaseNameExtended } from './models';
import { Variant, SeparatorCategory } from './dish';
import { DeepPartial } from './generics';

export class UserDetails extends BaseDescriptionExtendedSecondary {
  auto_translated?: string[];
  image?: string;
  image_small?: string;
  translation_disabled?: boolean;
  translation_disabled_description?: boolean;
  translation_disabled_description_secondary?: boolean;
  background?: number;
  background_detail?: any;
}

export class CondensedSeparator extends BaseNameExtended {
  url: string;
  id: number;
  category: SeparatorCategory;
  injectable: boolean;
  partner: null | number;
}

export class Separator extends CondensedSeparator {
  created_at?: Date;
  last_modified_at?: Date;
  last_modified_by?: number;
  original?: number;
  original_detail?: Separator;
  user_details: UserDetails;
  level?: 1 | 2 | 3 | 4;
  variants: Variant[];
  spellcheck_ignore?: boolean;

  constructor(separator: DeepPartial<Separator>, category?: SeparatorCategory) {
    super();
    if (separator) Object.assign(this, separator);
    if (category) this.category = category;
  }
}
