import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Menu } from 'src/app/shared/Models/menu';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'save-restore',
  templateUrl: './save-restore.component.html',
  styleUrls: ['./save-restore.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatMenuModule, MatButtonModule, TranslocoPipe],
})
export class SaveRestoreComponent {
  @Input() disabled = false;
  @Input() field: string;
  @Input() menu: Menu;
  @Output() restoreDefault = new EventEmitter<string>();
  @Output() saveDefault = new EventEmitter<string>();

  constructor() {}
}
