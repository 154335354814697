<div class="menu-actions">
  <magic-stick
    class="grammar-check menu-action"
    [loading]="loading"
    [disabled]="!menu || blockGrammarCheckLangs.includes(menu?.base_language)"
    [title]="'dishes.translation.grammar-check.title' | transloco"
    [description]="'dishes.translation.grammar-check.description' | transloco"
    [buttonMsg]="'dishes.translation.grammar-check.button' | transloco"
    [buttonLoadingMsg]="
      'dishes.translation.grammar-check.button-loading' | transloco
    "
    (clicked)="
      isTrial && !profileComplete ? showTrialBanner() : grammarCheck.emit()
    "
  ></magic-stick>
  <magic-stick
    class="menu-action"
    [loading]="loading"
    [disabled]="!menu"
    [title]="'dishes.bulk-price.title' | transloco"
    [buttonMsg]="'dishes.bulk-price.button' | transloco"
    [buttonLoadingMsg]="'dishes.bulk-price.button-loading' | transloco"
    (clicked)="
      isTrial && !profileComplete
        ? showTrialBanner()
        : bulkPriceForm.value.percentage_increase &&
          bulkPriceChange.emit(bulkPriceForm.value)
    "
  >
    <form [formGroup]="bulkPriceForm">
      <div class="element-group field-density-3">
        <div class="element-label">
          {{ "dishes.bulk-price.percentage-change" | transloco }}
        </div>
        <div class="element-item">
          <mat-form-field>
            <input
              matInput
              type="number"
              min="-1000"
              max="1000"
              step="1"
              formControlName="percentage_increase"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="element-group field-density-3">
        <div class="element-label">
          {{ "dishes.bulk-price.rounding" | transloco }}
        </div>
        <div class="element-item">
          <mat-form-field>
            <input
              matInput
              type="number"
              min="0.01"
              max="1000"
              step="0.01"
              formControlName="rounding_base"
            />
          </mat-form-field>
        </div>
      </div>
      <p class="mat-hint">
        {{
          "dishes.bulk-price.description"
            | transloco: { currency: user.settings.currency_symbol }
        }}
      </p>
    </form>
  </magic-stick>

  <magic-stick
    class="menu-action"
    [aiCreditsRemaining]="aiCreditsRemaining?.menu_analysis"
    [loading]="aiAnalysisLoading"
    [title]="'write.ai.panel.title' | transloco"
    [description]="'write.ai.panel.description' | transloco"
  >
    @for (analysisType of menuAnalyses; track analysisType) {
      <app-menu-analysis-item
        [analysisType]="analysisType"
        [aiAnalysisLoading]="aiAnalysisLoading"
        (runAnalysis)="
          isTrial && !profileComplete
            ? showTrialBanner()
            : showMenuAnalysis.emit(analysisType)
        "
      ></app-menu-analysis-item>
    }
  </magic-stick>

  <magic-stick
    class="menu-action"
    customIcon="drive_folder_upload"
    [loading]="loading"
    [disabled]="!menu"
    [title]="'write.import-export.title' | transloco"
    [description]="'write.import-export.description' | transloco"
  >
    <p>&nbsp;</p>
    <p>
      <button
        mat-flat-button
        color="primary"
        class="full-width"
        (click)="dataExport.emit()"
        [disabled]="!menu"
      >
        {{ "write.import-export.button-export" | transloco }}
      </button>
    </p>
    <p>
      <button
        mat-flat-button
        color="primary"
        class="full-width"
        (click)="dataImport.emit()"
        [disabled]="!menu"
      >
        {{ "write.import-export.button-import" | transloco }}
      </button>
    </p>
  </magic-stick>

  @if (menu.rule || menu.diets?.length || menu.type_detail) {
    <button
      class="refresh-menu-content menu-action"
      mat-button
      (click)="refreshMenuContent.emit()"
    >
      {{ "write.refresh-menu-content" | transloco }}
      <mat-icon color="primary" [inline]="true" class="cached-icon"
        >cached</mat-icon
      >
    </button>
  }
</div>

<mat-accordion displayMode="flat">
  @if (locationGroups?.length || locations?.length) {
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header class="primary-panel">
        <mat-panel-title>
          {{ "write.dishes.additional.title" | transloco }}
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon>place</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        @if (locationGroups?.length) {
          <div class="element-group template-option select-location">
            <mat-form-field subscriptSizing="dynamic">
              <mat-label>{{
                translations[
                  "write.dishes.additional.location_groups.placeholder"
                ] ||
                  ("write.dishes.additional.location_groups.placeholder"
                    | transloco)
              }}</mat-label>
              <mat-select
                #locationGroup
                class="select-location-input"
                [disabled]="
                  menu?.location !== null || !menu?.privileges?.change_location
                "
                [value]="menu?.location_group"
                (selectionChange)="changeLocationGroup.emit($event)"
              >
                @for (locationGroup of locationGroups; track locationGroup) {
                  <mat-option [value]="locationGroup.id">
                    {{ locationGroup.name }}
                  </mat-option>
                }
              </mat-select>
              @if (locationGroup.value) {
                <mat-icon
                  stop-propagation
                  class="clear-location"
                  (click)="changeLocationGroup.emit({ value: null })"
                  >clear</mat-icon
                >
              }
            </mat-form-field>
          </div>
        }
        @if (locations?.length) {
          <div class="element-group template-option select-location">
            <mat-form-field subscriptSizing="dynamic">
              <mat-label>{{
                translations["write.dishes.additional.locations.placeholder"] ||
                  ("write.dishes.additional.locations.placeholder" | transloco)
              }}</mat-label>
              <mat-select
                #location
                class="select-location-input"
                [disabled]="
                  menu?.location_group !== null ||
                  !menu?.privileges?.change_location
                "
                [value]="menu?.location"
                (selectionChange)="changeLocation.emit($event)"
              >
                @for (location of locations; track location) {
                  <mat-option [value]="location.id">
                    {{ location.name }}
                  </mat-option>
                }
              </mat-select>
              @if (location.value) {
                <mat-icon
                  stop-propagation
                  class="clear-location"
                  (click)="changeLocation.emit({ value: null })"
                  >clear</mat-icon
                >
              }
            </mat-form-field>
          </div>
        }
      </ng-template>
    </mat-expansion-panel>
  }

  <mat-expansion-panel
    #menuSettings
    class="expansion-panel mat-elevation-z0"
    id="menuTourSettings"
    tourAnchor="menu-settings"
  >
    <mat-expansion-panel-header class="expansion-header">
      <mat-panel-title>
        {{ "write.blocks.sidebar.menu-settings.title" | transloco }}
      </mat-panel-title>
      <mat-panel-description>
        <mat-icon>menu_book</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div class="flex-setting">
        <span class="language-selector-label"
          >{{
            "write.blocks.sidebar.menu-settings.options.language.title"
              | transloco
          }}:</span
        >
        <language-selector
          #langSelector
          changeValue="base_language"
          [disabled]="!menu?.id"
          [extendLangs]="true"
          [immutable]="true"
          [model]="menu"
          (changed)="
            updateMenu.emit({
              base_language: $event.base_language,
              forceUpdateDishes: true,
            })
          "
        ></language-selector>
      </div>
      <p class="language-selector-description">
        {{
          "write.blocks.sidebar.menu-settings.options.language.description"
            | transloco
        }}
      </p>

      <div class="flex-setting">
        <span class="language-selector-label"
          >{{
            "write.blocks.sidebar.menu-settings.options.currency.title"
              | transloco
          }}:</span
        >
        <currency-selector
          #currencySelector
          [currencies]="user?.settings?.currencies_enabled"
          [model]="menu?.currency"
          (updateCurrency)="changeMenuSettings.emit($event)"
        ></currency-selector>
      </div>

      <div class="flex-setting">
        <span class="language-selector-label"
          ><label class="menu-label"
            >{{ "menus.complete.featured.menu-date" | transloco }}:</label
          ></span
        >
        <mat-form-field class="change-date-wrapper icon-button-density-3">
          <input
            matInput
            [disabled]="!menu?.id || !privileges.edit_menu"
            [matDatepicker]="picker"
            [ngModel]="menuDate"
            (click)="picker.open()"
            (dateChange)="changeDate.emit($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel
    class="expansion-panel mat-elevation-z0"
    id="fieldSettings"
    tourAnchor="menu-fields"
  >
    <mat-expansion-panel-header class="expansion-header">
      <mat-panel-title>
        {{ "write.blocks.sidebar.field-settings" | transloco }}
      </mat-panel-title>
      <mat-panel-description>
        <mat-icon>toc</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <menu-view-settings
        [menu]="menu"
        (changed)="changeMenuSettings.emit($event)"
      ></menu-view-settings>

      <mat-checkbox
        color="primary"
        [disabled]="
          !menu?.id ||
          (menu?.template_detail?.locked_fields &&
            menu?.template_detail?.locked_fields.indexOf(
              'print_customnumbering'
            ) > -1)
        "
        (change)="changeNumbering($event)"
        [ngModel]="menu && menu.style && menu.style.print_customnumbering"
        (ngModelChange)="
          menu && menu.style && (menu.style.print_customnumbering = $event)
        "
        ><span>{{ "write.dishes.numbering.custom" | transloco }}</span>
      </mat-checkbox>
    </ng-template>
  </mat-expansion-panel>

  @if (menu?.onboarding_template !== null) {
    <mat-expansion-panel class="expansion-panel mat-elevation-z0">
      <mat-expansion-panel-header class="expansion-header">
        <mat-panel-title>
          {{ "write.blocks.sidebar.preset.title" | transloco }}
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon>color_lens</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="preset-action">
          <button
            mat-button
            class="primary-lightest"
            (click)="presetAction(menu?.onboarding_template_detail?.has_preset)"
          >
            {{
              menu?.onboarding_template_detail?.has_preset
                ? ("write.blocks.sidebar.preset.update" | transloco)
                : ("write.blocks.sidebar.preset.create" | transloco)
            }}
          </button>
        </div>
      </ng-template>
    </mat-expansion-panel>
  }

  <mat-expansion-panel
    class="expansion-panel mat-elevation-z0"
    id="instructionVideo"
  >
    <mat-expansion-panel-header class="expansion-header">
      <mat-panel-title>
        {{ "write.blocks.sidebar.instruction-video" | transloco }}
      </mat-panel-title>
      <mat-panel-description>
        <mat-icon>ondemand_video</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div class="guide">
        <h4>{{ "write.blocks.sidebar.help.guide" | transloco }}</h4>
        <button mat-flat-button color="primary" (click)="startTour.emit()">
          {{ "write.blocks.sidebar.help.guide-start" | transloco }}
        </button>
      </div>
      <div class="instruction-video">
        @if (user?.partner_detail?.video_menuwriting) {
          <h3>{{ "write.blocks.sidebar.help.video" | transloco }}</h3>
          <iframe
            width="560"
            height="250"
            [src]="
              user?.partner_detail?.video_menuwriting +
                '?rel=0&modestbranding=1&vq=hd1080' | safe
            "
            frameborder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        } @else if (menuwritingVideo) {
          <h4>{{ "write.blocks.sidebar.help.video" | transloco }}</h4>
          <iframe
            width="560"
            height="250"
            [src]="
              menuwritingVideo + '?rel=0&modestbranding=1&vq=hd1080' | safe
            "
            frameborder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        }
      </div>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>

<!-- <mat-expansion-panel
            *ngIf="menu?.template_detail?.menu_presets?.length"
            class="expansion-panel presets"
            >
            <mat-expansion-panel-header class="expansion-header">
              <mat-panel-title>
                {{ "write.blocks.sidebar.preset.title" | transloco }}
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon>list</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <button
              mat-flat-button
              class="menu-preset"
              *ngFor="let preset of menu?.template_detail?.menu_presets"
              (click)="choosePreset(preset)"
              >
              {{ preset[menu?.base_language] }}
            </button>
          </mat-expansion-panel> -->
