import { BaseName } from './models';
import { Menu, Detail, StyleCategories } from './menu';
import { Location } from './location';
import { UserCategory } from './user';

export class UserOnboardingTemplate {
  id: number;
  location: Location;
  menu: number;
  menu_detail: Menu;
  onboarding_template: number;
  onboarding_template_detail: OnboardingTemplate;
  organisation: number;
  url: string;
  user: number;

  constructor(data: any) {
    Object.assign(this, data);
  }
}

export class OnboardingTemplate extends BaseName {
  active?: boolean;
  add_beverages: boolean;
  add_courses: boolean;
  add_days: boolean;
  add_dishes: boolean;
  add_sections: boolean;
  add_wines: boolean;
  ask_date: boolean;
  can_delete?: boolean;
  category: StyleCategories[number];
  user_category: Array<UserCategory>;
  created_at: string;
  created_by: number;
  default_category: Array<UserCategory>;
  details: Detail;
  icon: string;
  id: number;
  image: string;
  last_modified_at: string;
  last_modified_by: number;
  location: number;
  migrated: boolean;
  order: number;
  organisation: number;
  partner: number;
  preset: number;
  show_from: string;
  show_until: string;
  style: number;
  template: number;
  template_detail: any;
  url: string;
  is_default: boolean;
  data_import_template: boolean;
  data_import: string[];

  constructor(data: Partial<OnboardingTemplate>) {
    super();
    Object.assign(this, data);
  }
}

export interface TemplatesRequest {
  items: number[];
}
