import { RegistrationOption } from '../Models/registration-steps';

export const CategoryOptions: RegistrationOption[] = [
  {
    view: 'rest',
    value: 'rest',
    icon: 'location_city',
  },
  {
    view: 'hotel',
    value: 'hotel',
    icon: 'location_city',
  },
  {
    view: 'bakery',
    value: 'bakery',
    icon: 'location_city',
  },
  {
    view: 'catering',
    value: 'catering',
    icon: 'location_city',
  },
  {
    view: 'school',
    value: 'school',
    icon: 'location_city',
  },
  {
    view: 'bar_cafe',
    value: 'bar_cafe',
    icon: 'location_city',
  },
  {
    view: 'senior_care',
    value: 'senior_care',
    icon: 'location_city',
  },
  {
    view: 'hospital',
    value: 'hospital',
    icon: 'location_city',
  },
  {
    view: 'other',
    icon: 'question_answer',
    other: true,
  },
];
