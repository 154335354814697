import { Organisation } from './user';

export class Invitee {
  email: string;
  first_name: string;
  last_name: string;
  role: number;
  invitation: InvitationShort;
  terms: boolean;
  password: string;
  confirm_password: string;
}

export class InvitationShort {
  invited_at: string;
  inviter: Inviter;
  organisation: Organisation;
}

export class Inviter {
  first_name: string;
  last_name: string;
  email: string;
}
