<h3 class="mat-h3">
  {{ "menus.overview.duplicate.dialog.title" | transloco }}
</h3>
<div class="duplicate-options">
  <mat-form-field class="field">
    <mat-select
      [multiple]="true"
      [placeholder]="'menus.overview.duplicate.dialog.diets' | transloco"
      [formControl]="dietIds"
    >
      @for (diet of filteredDiets; track diet) {
        <mat-option [value]="diet.id">{{ diet[lang] }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>

<div class="actions">
  <button mat-button class="cancel" (click)="cancelAction.emit()">
    {{ "menus.overview.duplicate.dialog.actions.cancel" | transloco }}
  </button>
  <button
    mat-flat-button
    color="primary"
    class="done"
    [disabled]="!dietIds?.value?.length"
    (click)="createNewDiet.emit(dietIds?.value)"
  >
    {{ "menus.overview.duplicate.dialog.actions.confirm" | transloco }}
  </button>
</div>
