import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'refresh-sub-menu',
  templateUrl: './refresh-submenu.component.html',
  styleUrls: [`./refresh-submenu.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButtonModule, TranslocoPipe],
})
export class RefreshSubMenuComponent {
  @Output() cancelAction = new EventEmitter<void>();
  @Output() refreshSubMenuActions = new EventEmitter<void>();

  constructor() {}
}
