<div
  class="wrapper icon-button-density-3"
  [mtTooltip]="
    disabled
      ? ('style.sidebar.restrictions.layout-field-locked' | transloco)
      : ''
  "
>
  <input
    #colorInput
    class="{{ className }} color"
    [style.backgroundColor]="disabled ? 'lightgray' : color || 'white'"
    [cpAlphaChannel]="'disabled'"
    [cpOKButtonClass]="'ok-button mdc-button mat-mdc-button mat-primary'"
    [cpCancelButtonClass]="'cancel-button mdc-button mat-mdc-button'"
    [cpCancelButton]="true"
    [cpOKButton]="true"
    [cpPosition]="'auto'"
    [cpUseRootViewContainer]="true"
    [disabled]="disabled"
    [(colorPicker)]="color"
    (colorPickerSelect)="changed($event)"
  />
  @if (!disabled && !color) {
    <span class="null"></span>
  }
  @if (!isLocked && showClear && color) {
    <button
      aria-label="Clear"
      class="clear-color"
      mat-icon-button
      (click)="clearColor.emit()"
    >
      <mat-icon>close</mat-icon>
    </button>
  }
</div>
