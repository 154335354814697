import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'delete-menu',
  templateUrl: './delete-menu.component.html',
  styleUrls: [
    `../regenerate-menu/regenerate-menu.component.scss`,
    `./delete-menu.component.scss`,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButtonModule, TranslocoPipe],
})
export class DeleteMenuComponent {
  @Input() deleteMenuWarning: string;
  @Output() cancelAction = new EventEmitter<void>();
  @Output() deleteSelectedMenu = new EventEmitter<void>();

  constructor() {}
}
