import { Directive, ElementRef, OnInit } from '@angular/core';

const PROGRESS_BAR_NAME = 'MAT-PROGRESS-BAR';

@Directive({
  selector: '[inputProgressBar]',
  standalone: true,
})
export class InputProgressBarDirective implements OnInit {
  progressBarElement: HTMLElement;
  nameIdentifier: string;

  constructor(private el: ElementRef) {
    const attr = (<HTMLInputElement>el.nativeElement).attributes.getNamedItem(
      `formcontrolname`,
    );
    this.nameIdentifier = attr && attr.value;
  }

  ngOnInit(): void {
    const parent = <HTMLInputElement>this.el.nativeElement.parentElement;
    if (
      parent &&
      parent.classList &&
      parent.classList.contains(`mat-mdc-form-field-infix`)
    ) {
      parent.childNodes.forEach((node: ChildNode) => {
        if (node.nodeName === PROGRESS_BAR_NAME) {
          this.progressBarElement = <HTMLElement>node;
        }
      });
      this.changeBarVisibility();
    } else {
      console.error(`Couldn't find progress bar`);
    }
  }

  changeBarVisibility(show = false): void {
    this.progressBarElement &&
      (this.progressBarElement.style.display = show ? `block` : `none`);
  }
}
