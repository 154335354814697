<div class="registration-page">
  <div class="wrapper">
    <div class="g-top-nav">
      <language-selector
        class="lang"
        [model]="language"
        [simple]="true"
        [left]="true"
        [longLang]="true"
        changeValue="current"
        (changed)="changeLanguage()"
      ></language-selector>
      <div class="trial">
        <span class="trial-text"
          >{{ "auth.registration.login" | transloco }}</span
        >
        <button class="trial-button" mat-button>
          <a routerLink="/login"
            >{{ "auth.registration.login-button" | transloco }}</a
          >
        </button>
      </div>
    </div>
    @if ((spinner$ | async) === false) {
    <div class="auth-bar" [class.error]="(invitee$ | async) === null">
      @if (invitee$ | async) {
      <div class="trial-period">
        {{ "auth.accept_invitation.title-1" | transloco }}
        <span class="name">{{ name }}</span>
        {{ "auth.accept_invitation.title-2" | transloco }}
        <span class="name">{{ organisation }}</span> .
      </div>
      @if ((spinner$ | async) === false && (invitee$ | async) !== null) {
      <div class="title">
        @if (logo) {
        <img [src]="logo" alt="logo" class="logo" />
        }
        <span class="text"
          >{{ "auth.accept_invitation.confirm" | transloco }}</span
        >
      </div>
      }
      <accept-invitation
        [lang]="language.current"
        [data]="invitee$ | async"
        (submitData)="submitData($event)"
      >
      </accept-invitation>
      } @else {
      <h1 class="error-title">
        {{ "auth.accept_invitation.error.title" | transloco }}
      </h1>
      <p>
        {{ "auth.accept_invitation.error.problem" | transloco }} <br />{{
        "auth.accept_invitation.error.solve" | transloco }}
      </p>
      }
    </div>
    }
    <app-footer [lang]="language" [backgroundColor]="'white'"></app-footer>
  </div>
</div>
@if (spinner$ | async) {
<div class="spinner">
  <spinner></spinner>
</div>
}
