<h2
  mat-dialog-title
  class="title"
  [innerHTML]="
    data.action
      ? ('write.dishes.select-diet-dialog.remove-from-dish'
        | transloco: { allergen: translatedName, dish: data.dish[data.lang] })
      : ('write.dishes.select-diet-dialog.add-to-dish'
        | transloco: { allergen: translatedName, dish: data.dish[data.lang] })
  "
></h2>

<mat-dialog-content class="content">
  <p class="checkbox-text">
    <mat-checkbox
      color="primary"
      #confirmed
      (click)="toggle(!confirmed.checked)"
    >
      <span
        class="confirm-text"
        [innerHTML]="
          data.action
            ? ('write.dishes.select-diet-dialog.confirm-remove-from-dish'
              | transloco
                : { allergen: translatedName, dish: data.dish[data.lang] })
            : ('write.dishes.select-diet-dialog.confirm-add-to-dish'
              | transloco
                : { allergen: translatedName, dish: data.dish[data.lang] })
        "
      ></span>
    </mat-checkbox>
  </p>

  @for (recipe of recipes$ | async; track recipe) {
    <div>
      <h3 class="mat-h3 recipe-title">
        <span [class.italics]="getRecipeName(recipe).italic">{{
          getRecipeName(recipe).name
        }}</span>
        (<span
          [innerHTML]="
            'write.dishes.select-diet-dialog.servings'
              | transloco: { number: recipe.servings }
          "
        ></span
        >)
      </h3>
      <recipe-ingredients
        class="mat-elevation-z2 recipe-ingredients"
        [calcBasis]="'gross'"
        [isLocked]="false"
        [ingredientsAuto]="ingredientsAuto"
        [ingredients]="recipe.ingredients_list"
        [lang]="data.lang"
        [showNetQuantity]="false"
        [showIcons]="true"
        [additivesView]="this.data.additivesView"
        [allergensView]="this.data.allergensView"
        (updateIngredient)="updateIngredient(recipe, $event)"
        (updateRecipeIngredient)="updateRecipeIngredient(recipe, $event)"
        (deleteIngredientEvent)="deleteIngredient(recipe, $event)"
        (addIngredientEvent)="addIngredientToRecipe(recipe)"
        (searchIngredients)="searchIngredients($event)"
        (chooseIngredientEvent)="chooseIngredient(recipe, $event)"
        (createIngredientEvent)="createIngredient(recipe, $event)"
        (changed)="changeRecipeDeclarativeOption(recipe, $event)"
      >
      </recipe-ingredients>
    </div>
  }
</mat-dialog-content>

<mat-dialog-actions class="actions">
  <button mat-button mat-dialog-close>
    {{ "write.dishes.select-diet-dialog.cancel" | transloco }}
  </button>
  <button
    mat-flat-button
    mat-dialog-close
    color="primary"
    class="done"
    [disabled]="!(confirmed.checked || enableSaveButton)"
    (click)="save()"
  >
    {{ "write.dishes.select-diet-dialog.save-changes" | transloco }}
  </button>
</mat-dialog-actions>
