import { LoginForm } from 'src/app/shared/Models/authentication';
import { Component } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { Language } from 'src/app/shared/Models/models';
import { setInterfaceLang } from 'src/app/shared/ngrx/shared.actions';
import {
  selectAuthErrorState,
  selectIsMagicLink,
  selectLoginSpinnerState,
} from './../../ngrx/auth.selectors';
import { loginUser } from 'src/app/auth/ngrx/auth.actions';
import { AsyncPipe } from '@angular/common';
import { SpinnerComponent } from '../../../shared/Components/spinner/spinner.component';
import { FooterComponent } from '../../../shared/Components/footer/footer.component';
import { AuthenticationComponent } from '../../Components/authentication/authentication.component';
import { MatButtonModule } from '@angular/material/button';
import { LanguageSelectorComponent } from '../../../shared/Components/language-selector/language-selector.component';
import { SideInfoComponent } from '../../../shared/Components/side-info/side-info.component';

@Component({
  selector: 'login',
  templateUrl: './login.container.html',
  styleUrls: ['./login.container.css'],
  standalone: true,
  imports: [
    SideInfoComponent,
    LanguageSelectorComponent,
    MatButtonModule,
    RouterLink,
    AuthenticationComponent,
    FooterComponent,
    SpinnerComponent,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class LoginContainer {
  language: { current: InterfaceLanguage } = { current: 'en' };

  magicLink$ = this.ngrxStore.select(selectIsMagicLink);
  showError$ = this.ngrxStore.select(selectAuthErrorState);
  spinnerState$ = this.ngrxStore.select(selectLoginSpinnerState);

  constructor(
    private translate: TranslocoService,
    private router: ActivatedRoute,
    private ngrxStore: Store<State>,
  ) {
    this.translate.langChanges$.subscribe(
      (el) =>
        (this.language = {
          current: translate.getActiveLang() as InterfaceLanguage,
        }),
    );
    this.translate.getActiveLang() &&
      (this.language = {
        current: translate.getActiveLang() as InterfaceLanguage,
      });
    this.router.queryParams.subscribe(this.handleLang);
  }

  changeLanguage() {
    this.translate.setActiveLang(this.language.current);
    this.ngrxStore.dispatch(setInterfaceLang({ lang: this.language.current }));
  }

  handleLang = ({ lang }: { lang: string }) => {
    if (lang) {
      const l = Language(lang);
      this.language.current = l;
      this.translate.setActiveLang(l);
      this.ngrxStore.dispatch(setInterfaceLang({ lang: l }));
    }
  };

  loginUser(loginData: LoginForm): void {
    this.ngrxStore.dispatch(loginUser({ loginFormData: loginData }));
  }
}
