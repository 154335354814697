import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { GenerateArchiveOptions } from 'src/app/shared/Models/menu';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'generate-archive',
  templateUrl: './generate-archive.component.html',
  styleUrls: [
    `../regenerate-menu/regenerate-menu.component.scss`,
    `./generate-archive.component.scss`,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    TranslocoPipe,
  ],
})
export class GenerateArchiveComponent implements OnInit {
  @Input() showOrdertaking = false;
  @Output() cancelAction = new EventEmitter<void>();
  @Output() createArchive = new EventEmitter<GenerateArchiveOptions>();

  hasOrderModule = false;
  options = {
    enable_ordertaking: false,
    create_archive: false,
  };

  constructor(private utils: UtilsService) {}

  ngOnInit() {
    this.hasOrderModule = this.hasModule('order');
    this.options.enable_ordertaking = this.hasOrderModule;
  }

  hasModule = (code: string): boolean => this.utils.hasModules(code);
}
