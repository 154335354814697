import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { selectUserPrivileges } from 'src/app/shared/user/ngrx/user.selectors';
import { filter, map } from 'rxjs/operators';
import { inject } from '@angular/core';

export const menuPrivilegesGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const ngrxStore = inject(Store) as Store<State>;
  const router = inject(Router);

  const privileges$ = ngrxStore.select(selectUserPrivileges);

  const { lang } = route.queryParams;
  const step = parseInt(route.params.step, 10) || null;

  if (!lang && step === 5) {
    router.navigate(['menus']);
  }

  const checkPrivileges = (step, privileges): boolean => {
    if (!step && !privileges.create_menu) {
      router.navigate(['menus']);
      return false;
    }
    if (step < 3 && !privileges.edit_menu) {
      router.navigate(['menus']);
      return false;
    }
    return true;
  };

  return privileges$.pipe(
    filter((priv) => !!priv),
    filter(
      ({ edit_menu, create_menu }) =>
        edit_menu !== null && create_menu !== null,
    ),
    map((el) => checkPrivileges(step, el)),
  );
};
