<mat-expansion-panel
  #panel
  [hideToggle]="true"
  class="express-filter"
  [class.faded]="loading"
  [expanded]="false"
>
  <mat-expansion-panel-header class="panel-header">
    <mat-panel-title>
      <span class="express-filter-title">{{ title }}</span>
      @if (loading) {
        <spinner
          class="express-filter-spinner"
          [config]="{ radius: 12 }"
        ></spinner>
      }
      @if (!loading) {
        <mat-icon class="express-filter-icon">{{
          customIcon ? customIcon : ai ? "auto_awesome" : "auto_fix_high"
        }}</mat-icon>
      }
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="content" class="express-filter-content">
    @if (ai) {
      <span class="ai-credits">
        {{ aiCredits }}<mat-icon class="ai-credits-icon">auto_awesome</mat-icon>
      </span>
    }
    <span class="description">
      {{ description }}
      @if (tooltip) {
        <mat-icon [mtTooltip]="tooltip" class="info-tooltip">info</mat-icon>
      }
    </span>
    <ng-content></ng-content>
  </div>
  @if (buttonMsg) {
    <button
      mat-flat-button
      color="primary"
      class="express-filter-button"
      (click)="!disabled && clicked.emit()"
      [class.disabled]="disabled"
    >
      {{ loading ? buttonLoadingMsg : buttonMsg }}
    </button>
  }
  @if (aiCreditsRemaining !== undefined) {
    <div class="ai-credits-remaining">
      <hr />
      {{ "shared.ai.you-have" | transloco }}
      <strong class="ai-credits-number">{{ aiCreditsRemaining }}</strong
      ><mat-icon class="credits-icon">auto_awesome</mat-icon>
      {{ "shared.ai.credits-left" | transloco }}
    </div>
  }
</mat-expansion-panel>
