import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StopPropagationDirective } from '../../Directives/stop-propagation/stop-propagation.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'currency-selector',
  templateUrl: './currency-selector.component.html',
  styleUrls: ['./currency-selector.component.css'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    StopPropagationDirective,
  ],
})
export class CurrencySelectorComponent {
  @Input() model;
  @Input() currencies = [];
  @Output() updateCurrency = new EventEmitter<any>();

  emptyModel = {};

  constructor() {}

  handleChange({ value }) {
    this.updateCurrency.emit({
      currency: value,
      currency_symbol: '',
    });
  }
}
