import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteModule,
} from '@angular/material/autocomplete';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { Dish } from 'src/app/shared/Models/dish';
import {
  Ingredient,
  PreIngredients,
  SimpleIngredient,
} from 'src/app/shared/Models/ingredients';
import {
  Recipe,
  RecipeParams,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';
import { AiCreditsRemaining } from 'src/app/shared/Models/user';
import {
  addIngredientToRecipe,
  addMultipleIngredientsToRecipe,
  createNewIngredient,
  deleteDishRecipeIngredient,
  fetchIngredientInfo,
  setIngredientInfo,
  uncollapseRecipe,
  updateIngredientInfo,
} from 'src/app/shared/ngrx/dishes-menu/dishes-menu.actions';
import {
  isRecipesLoading,
  selectIngredientInfo,
  selectRecipes,
} from 'src/app/shared/ngrx/dishes-menu/dishes-menu.selectors';
import {
  clearRecipesAutocomplete,
  fetchIngredientsAutocomplete,
  setIngredientsAutocomplete,
} from 'src/app/shared/ngrx/shared.actions';
import { selectIngredientsAutocomplete } from 'src/app/shared/ngrx/shared.selectors';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  skip,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { TranslocoPipe } from '@jsverse/transloco';
import { AsyncPipe } from '@angular/common';
import { MagicStickComponent } from '../magic-stick/magic-stick.component';
import { StopPropagationDirective } from '../../Directives/stop-propagation/stop-propagation.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RecipeItemComponent } from './recipe-item/recipe-item.component';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'recipes-tab',
  templateUrl: './recipes-tab.component.html',
  styleUrls: ['./recipes-tab.component.scss'],
  standalone: true,
  imports: [
    SpinnerComponent,
    RecipeItemComponent,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatProgressBarModule,
    MatOptionModule,
    StopPropagationDirective,
    MagicStickComponent,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class RecipesTabComponent implements OnChanges, OnDestroy, OnInit {
  @Input() aiCreditsRemaining: AiCreditsRemaining;
  @Input() aiRecipesLoading: boolean;
  @Input() autoRecipies: Recipe[];
  @Input() dish: Dish;
  @Input() lang: ContentLanguage;
  @Input() translations: any;
  @Input() autoRecipes: Recipe[];
  @Input() isTrial: boolean;
  @Input() profileComplete: boolean;

  @Output() addRecipe = new EventEmitter<{ dish: Dish; recipeId: number }>();
  @Output() createRecipe = new EventEmitter<{
    data: Partial<Recipe>;
    url: string;
  }>();
  @Output() fetchDishRecipes = new EventEmitter<string>();
  @Output() refreshDish = new EventEmitter<void>();
  @Output() removeRecipe = new EventEmitter<{ dish: Dish; recipeId: number }>();
  @Output() searchRecipe = new EventEmitter<Partial<RecipeParams>>();
  @Output() patchRecipe = new EventEmitter<{
    url: string;
    payload: Partial<Recipe>;
    onFulfilled: Function;
  }>();
  @Output() generateAiRecipes = new EventEmitter<void>();

  ingredientsAuto$ = this.ngrxStore.select(selectIngredientsAutocomplete);
  isLoading$ = this.ngrxStore.select(isRecipesLoading);
  recipes$ = this.ngrxStore.select(selectRecipes);
  ingredientInfo$ = this.ngrxStore.select(selectIngredientInfo);

  private destroyed$ = new Subject<void>();
  loading = false;
  recipeControl = new FormControl();
  recipeName: string;
  shouldRefreshDish = false;

  @ViewChild('recipeInput', { static: false })
  recipeInput: ElementRef;

  constructor(
    private ngrxStore: Store<State>,
    private utils: UtilsService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      'dish' in changes &&
      this.dish &&
      !(
        changes.dish.previousValue &&
        changes.dish.previousValue.id === this.dish.id
      )
    ) {
      this.shouldRefreshDish = false;
      this.fetchDishRecipes.emit(this.dish.recipes_list);
    }
  }

  ngOnInit(): void {
    this.recipes$.pipe(skip(1), takeUntil(this.destroyed$)).subscribe(() => {
      this.loading = false;
      this.recipeControl.enable({ emitEvent: false });
      if (this.shouldRefreshDish) this.refreshDish.emit();
      this.shouldRefreshDish = false;
    });
    this.recipeControl.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter((val) => typeof val === 'string'),
        withLatestFrom(this.recipes$),
        switchMap(([value, currentRecipes]: [string, Recipe[]]) => {
          this.recipeName = value;
          if (value && value !== '') {
            this.searchRecipe.emit({
              [this.lang]: value,
              condensed: true,
              exclude: currentRecipes.map((recipe) => recipe.id),
            });
          }
          return [];
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  addIngredientToRecipeEvent({
    ingredient,
    recipe,
    onFulfilled,
  }: {
    ingredient: SimpleIngredient;
    recipe: Recipe;
    onFulfilled: Function;
  }): void {
    this.ngrxStore.dispatch(
      addIngredientToRecipe({
        ingredientId: ingredient.id,
        recipe,
        onFulfilled,
      }),
    );
  }

  collapsed(recipe: Recipe): void {
    this.ngrxStore.dispatch(uncollapseRecipe({ recipe }));
  }

  displayWith(value: Recipe): string {
    if (!value) return undefined;
    return value[this.lang];
  }

  clearIngredientsAuto(): void {
    this.ngrxStore.dispatch(
      setIngredientsAutocomplete({
        payload: { count: 0, next: null, previous: null, results: [] },
      }),
    );
  }

  clearIngredientInfo(): void {
    this.ngrxStore.dispatch(setIngredientInfo({ info: null }));
  }

  fetchIngredientsAuto({
    search,
    recipeId,
  }: {
    search: string;
    recipeId: number;
  }): void {
    const params = {
      [this.lang]: search,
      condensed: true,
      current_recipe: recipeId,
    };
    this.ngrxStore.dispatch(fetchIngredientsAutocomplete({ params }));
  }

  fetchIngredientsInfo({
    url,
    params,
  }: {
    url: string;
    params: RecipeParams;
  }): void {
    this.ngrxStore.dispatch(fetchIngredientInfo({ url, params }));
  }

  patchIngredient({
    url,
    data,
    params,
  }: {
    url: string;
    data: Partial<Ingredient>;
    params: RecipeParams;
  }): void {
    this.ngrxStore.dispatch(updateIngredientInfo({ url, data, params }));
  }

  deleteDishRecipeIngredient({
    recipe,
    recipeIngredient,
  }: {
    recipe: Recipe;
    recipeIngredient: SimpleRecipeIngredient;
  }): void {
    this.ngrxStore.dispatch(
      deleteDishRecipeIngredient({
        recipe: recipe,
        recipeIngredient,
      }),
    );
  }

  createNewIngredient({
    recipe,
    data,
    onError,
    params,
  }: {
    recipe: Recipe;
    data: Partial<Recipe>;
    onError?: Function;
    params?: RecipeParams;
  }): void {
    this.ngrxStore.dispatch(
      createNewIngredient({
        recipe,
        data,
        onError,
        params,
      }),
    );
  }

  addMultipleIngredientsToRecipe({
    recipe,
    data,
  }: {
    recipe: Recipe;
    data: PreIngredients;
  }): void {
    this.ngrxStore.dispatch(
      addMultipleIngredientsToRecipe({
        recipe,
        data,
      }),
    );
  }

  handleCreateRecipe(): void {
    this.ngrxStore.dispatch(clearRecipesAutocomplete());
    this.loading = true;
    this.recipeInput.nativeElement.blur();
    this.recipeControl.disable({ emitEvent: false });
  }

  recipeSelected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value) {
      this.handleCreateRecipe();
      this.addRecipe.emit({
        dish: this.dish,
        recipeId: event.option.value.id,
      });
    } else if (this.recipeName && this.recipeName !== '') {
      this.handleCreateRecipe();
      this.createRecipe.emit({
        url: this.dish.url,
        data: { [this.lang]: this.recipeName },
      });
    }
    this.recipeName = null;
  }

  removeSelectedRecipe(recipe: Recipe): void {
    this.removeRecipe.emit({
      dish: this.dish,
      recipeId: recipe.id,
    });
  }

  setShouldRefresh(value: boolean): void {
    this.shouldRefreshDish = value;
  }

  trackByName(_, item: Recipe): number {
    return item.id;
  }

  showTrialBanner() {
    this.utils.showTrialBlockedBox();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
