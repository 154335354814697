export const allergenCodes = {
  glut: 'A',
  crus: 'B',
  egg: 'C',
  fish: 'D',
  pea: 'E',
  soy: 'F',
  milk: 'G',
  nut: 'F',
  cel: 'L',
  must: 'M',
  ses: 'N',
  suf: 'O',
  lup: 'P',
  mol: 'R',
  glut_weiz: 'Â',
  glut_rogg: 'Ã',
  glut_gers: 'Ç',
  glut_hafe: 'Æ',
  glut_dink: 'Ì',
  glut_khor: 'Å',
  glut_emme: 'È',
  glut_eink: 'É',
  nut_mand: 'Ñ',
  nut_hase: 'Ó',
  nut_waln: 'Ó',
  nut_cash: 'Ô',
  nut_peka: 'Ï',
  nut_para: 'Ð',
  nut_pist: 'Î',
  nut_maca: 'Ò',
};

const allergenKeysList = Object.keys(allergenCodes);

export type AllergenKey = (typeof allergenKeysList)[number];

export const labelCodes = {
  rec: 'Ä',
  diet: '',
  bio: 'U',
  glutf: 'W',
  lacf: 'X',
  ngmo: 'K',
  lowc: 'Z',
  veg: 'Q',
  vega: 'V',
  home: 'S',
  regio: 'T',
  seas: 'Y',
  spicy: 'À',
  alc: 'Ö',
  hal: 'I',
  kos: 'J',
  pork: 'Á',
  sust: 'Õ',
  new: 'Ă',
};

const labelsKeysList = Object.keys(labelCodes);

export type LabelKey = (typeof labelsKeysList)[number];

export const additiveCodes = {
  col: 1,
  adv: 2,
  cons: 3,
  nit: 4,
  anti: 5,
  flav: 6,
  sul: 7,
  dark: 8,
  wax: 9,
  phos: 10,
  sweet: 11,
  phen: 12,
  lax: 13,
  caf: 14,
  qui: 15,
  tau: 16,
  gmo: 17,
  mip: 18,
  sur: 19,
  fmeat: 20,
  ffish: 21,
  choc: 22,
  hcaf: 24,
  pack: 25,
  jod: 26,
  oxy: 27,
};

const additivesKeysList = Object.keys(additiveCodes);

export type AdditiveKey = (typeof additivesKeysList)[number];

export type DeclarationKey = AllergenKey | LabelKey | AdditiveKey;
