import {
  ComponentRef,
  EmbeddedViewRef,
  EventEmitter,
  Injectable,
  TemplateRef,
  Type,
  ViewContainerRef,
} from '@angular/core';

export interface OnDeletedFromOverlay {
  onDeletedFromOverlay: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  containerRefs = new Map<string, ViewContainerRef>();
  templateRefs = new Map<string, Type<any> | TemplateRef<any>>();
  components = new Map<string, any>();

  mainContentScrollEvent = new EventEmitter<number>();

  registerContainer(key: string, containerView: ViewContainerRef): void {
    if (this.containerRefs.has(key)) {
      this.containerRefs.get(key).clear();
      this.containerRefs.delete(key);
    }
    this.containerRefs.set(key, containerView);
  }

  registerComponent(key: string, component: any): void {
    if (this.components.has(key)) {
      this.components.delete(key);
    }
    if (component) {
      this.components.set(key, component);
    }
  }

  insertTemplate<T>(
    key: string,
    templateView: TemplateRef<T> | Type<any>,
    context?: any,
  ): EmbeddedViewRef<T> | ComponentRef<T> {
    if (this.templateRefs.has(key) && this.containerRefs.has(key)) {
      this.containerRefs.get(key).clear();
      this.templateRefs.delete(key);
    }

    this.templateRefs.set(key, templateView);

    if (this.containerRefs.has(key) && this.templateRefs.has(key)) {
      const componentOrTemplate = this.templateRefs.get(key);
      if (componentOrTemplate instanceof TemplateRef) {
        return this.containerRefs
          .get(key)
          .createEmbeddedView(componentOrTemplate, context);
      } else {
        return this.containerRefs.get(key).createComponent(componentOrTemplate);
      }
    }

    return undefined;
  }

  clear(key: string): void {
    if (this.containerRefs.has(key)) {
      this.containerRefs.get(key).clear();
      this.templateRefs.delete(key);
      this.components.get(key)?.onDeletedFromOverlay?.();
    }
  }

  getContainer(key: string): any {
    if (this.containerRefs.has(key)) {
      return this.containerRefs.get(key);
    }
  }

  getComponent<T>(key: string): T | null {
    if (this.components.has(key)) {
      return this.components.get(key);
    }
    return undefined;
  }

  deleteComponent(key: string): any {
    if (this.components.has(key)) {
      this.components.delete(key);
    }
  }

  mainContentScrollChanged(value: number): void {
    this.mainContentScrollEvent.emit(value);
  }
}
