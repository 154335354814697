import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Invoice, InvoicesParams } from 'src/app/shared/Models/subscription';

import * as InvoicesActions from './invoices.actions';

export const invoicesFeatureKey = 'invoices';

export const invoicesAdapter: EntityAdapter<Invoice> =
  createEntityAdapter<Invoice>({
    selectId: (invoice) => invoice.number,
  });

export interface InvoicesState {
  invoices: EntityState<Invoice>;
  currentPageIndex: number;
  count: number;
  queryParams: InvoicesParams;
  showSpinner: boolean;
}

const initialState: InvoicesState = {
  invoices: invoicesAdapter.getInitialState(),
  currentPageIndex: 0,
  count: 0,
  queryParams: {},
  showSpinner: false,
};

const _invoicesReducer = createReducer(
  initialState,
  on(InvoicesActions.setInvoices, (state, { invoices }) => ({
    ...state,
    invoices: invoicesAdapter.setAll(invoices, state.invoices),
    showSpinner: false,
  })),
  on(InvoicesActions.setCurrentPageIndex, (state, { index }) => ({
    ...state,
    currentPageIndex: index,
  })),
  on(InvoicesActions.setInvoicesCount, (state, { count }) => ({
    ...state,
    count,
  })),
  on(InvoicesActions.paramsChanged, (state, { params }) => ({
    ...state,
    queryParams: params,
    showSpinner: true,
  })),
  on(InvoicesActions.invoicesError, (state) => ({
    ...state,
    showSpinner: false,
  })),
);

export function invoicesReducer(state: InvoicesState, action: Action) {
  return _invoicesReducer(state, action);
}
