import {
  Directive,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  selector: '[pageSelector]',
  standalone: true,
})
export class PageSelectorDirective implements OnDestroy {
  @Input()
  set pageSelector(param) {
    this.pdfViewer = this.view.createEmbeddedView(this.template);
    this.pdfViewer.context.page = this.currentPage;
    this.pdfViewer.context.onLoad = this.handlePages;
  }

  pages: number;
  currentPage = 1;
  currentScrollTopDiv = 'mat-sidenav-content';
  pdfDocumentProxy;
  pageSelectorElement: HTMLDivElement;
  pdfViewer: EmbeddedViewRef<any>;

  constructor(
    private view: ViewContainerRef,
    private render: Renderer2,
    private template: TemplateRef<any>,
  ) {}

  handlePages = (pdf, page = 1) => {
    this.pages = pdf.numPages;
    this.currentPage = page;
    if (!this.pageSelectorElement) {
      this.pageSelectorElement = document.createElement('div');
      this.pageSelectorElement.setAttribute('id', 'ml-page-selector-view');
      this.pageSelectorElement.style.display = 'flex';
      this.pageSelectorElement.style.alignItems = 'center';
      this.pageSelectorElement.style.justifyContent = 'center';
      this.pageSelectorElement.style.position = 'absolute';
      this.pageSelectorElement.style.opacity = '0.5';
      this.pageSelectorElement.style.transition = 'opacity .5s ease-out';
      this.pageSelectorElement.style.zIndex = '1';
      this.pageSelectorElement.style.width = '100%';
      this.pageSelectorElement.onmouseenter = (event) => {
        // tslint:disable-next-line:no-invalid-this
        (event.target as HTMLElement).style.opacity = '0.9';
      };
      this.pageSelectorElement.onmouseleave = (event) => {
        // tslint:disable-next-line:no-invalid-this
        (event.target as HTMLElement).style.opacity = '0.5';
      };

      const prev = this.generateButton('navigate_before', false, this.prevPage);
      const next = this.generateButton('navigate_next', false, this.nextPage);
      const numberView = this.createNumberView(this.currentPage, this.pages);

      this.pageSelectorElement.appendChild(prev);
      this.pageSelectorElement.appendChild(numberView);
      this.pageSelectorElement.appendChild(next);

      this.attachViewHelper(this.render, this.view, this.pageSelectorElement);
    } else {
      const prev = this.generateButton('navigate_before', false, this.prevPage);
      const next = this.generateButton('navigate_next', false, this.nextPage);
      const numberView = this.createNumberView(this.currentPage, this.pages);
      this.pageSelectorElement.replaceChild(
        prev,
        this.pageSelectorElement.childNodes.item(0),
      );
      this.pageSelectorElement.replaceChild(
        numberView,
        this.pageSelectorElement.childNodes.item(1),
      );
      this.pageSelectorElement.replaceChild(
        next,
        this.pageSelectorElement.childNodes.item(2),
      );
    }
  };

  generateButton = (str, disabled, handler) => {
    const next = document.createElement('button');
    if (disabled) next.setAttribute('disabled', disabled);
    const icon = document.createElement('mat-icon');
    icon.setAttribute(
      'class',
      'mat-icon icons material-symbols-outlined mat-icon-no-color',
    );
    icon.appendChild(document.createTextNode(str));
    next.appendChild(icon);
    next.addEventListener('click', handler);
    next.style.padding = '6px';
    next.style.backgroundColor = '#FFF';
    next.style.lineHeight = '17px';
    next.style.opacity = '0.7';
    next.style.border = '1px solid rgb(170,170,170)';
    next.style.color = '#029ae4';
    return next;
  };

  nextPage = (event: MouseEvent) => {
    const currentScrollTop = document.getElementById(
      this.currentScrollTopDiv,
    ).scrollTop;
    const interval = setInterval(() => {
      document.getElementById(this.currentScrollTopDiv).scrollTop =
        currentScrollTop;
    }, 1);
    setTimeout(() => {
      clearInterval(interval);
    }, 400);
    this.currentPage =
      this.currentPage + 1 > this.pages
        ? this.currentPage
        : this.currentPage + 1;
    this.setView();
  };

  prevPage = (event: MouseEvent) => {
    const currentScrollTop = document.getElementById(
      this.currentScrollTopDiv,
    ).scrollTop;
    const interval = setInterval(() => {
      document.getElementById(this.currentScrollTopDiv).scrollTop =
        currentScrollTop;
    }, 1);
    setTimeout(() => {
      clearInterval(interval);
    }, 400);
    this.currentPage =
      this.currentPage - 1 ? this.currentPage - 1 : this.currentPage;
    this.setView();
  };

  setView = () => {
    this.detachViewHelper(this.render, this.pageSelectorElement);
    const pageView = this.createNumberView(this.currentPage, this.pages);

    const left = this.generateButton(
      'navigate_before',
      this.currentPage === 1,
      this.prevPage,
    );

    const right = this.generateButton(
      'navigate_next',
      this.currentPage === this.pages,
      this.nextPage,
    );

    this.pageSelectorElement.replaceChild(
      left,
      this.pageSelectorElement.childNodes.item(0),
    );
    this.pageSelectorElement.replaceChild(
      pageView,
      this.pageSelectorElement.childNodes.item(1),
    );
    this.pageSelectorElement.replaceChild(
      right,
      this.pageSelectorElement.childNodes.item(2),
    );

    this.attachViewHelper(this.render, this.view, this.pageSelectorElement);

    this.pdfViewer.context.page = this.currentPage;
  };

  createNumberView = (page, size) => {
    const view = document.createElement('div');
    view.setAttribute('id', 'ml-page-number-view');
    view.appendChild(document.createTextNode(`${page} / ${size}`));
    view.style.padding = '10px 12px';
    view.style.color = '#FFF';
    view.style.fontSize = '13px';
    view.style.lineHeight = '22px';
    view.style.boxShadow = '-2 1 1 rgba(0,0,0,0.4)';
    view.style.letterSpacing = '1.3';
    view.style.backgroundColor = 'rgb(170,170,170)';
    view.style.opacity = '0.7';
    return view;
  };

  attachViewHelper(
    renderer: Renderer2,
    view: ViewContainerRef,
    div: HTMLDivElement,
  ): void {
    const parent = renderer.parentNode(view.element.nativeElement);
    renderer.insertBefore(
      parent,
      div,
      parent.childNodes[0] || this.template.elementRef.nativeElement,
    );
  }

  detachViewHelper(renderer: Renderer2, div: HTMLDivElement): void {
    renderer && div && renderer.removeChild(renderer.parentNode(div), div);
  }

  ngOnDestroy(): void {
    this.detachViewHelper(this.render, this.pageSelectorElement);
  }
}
