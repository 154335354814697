<categories-tabs
  [categories]="categories"
  [tabIndex]="tabIndex"
  translationKey="layout"
>
  <layouts-wrapper
    class="tabUser"
    [lang]="lang"
    [menu]="menu"
    [layouts]="userLayouts"
    (selectLayout)="selectLayoutEvent.emit($event)"
    (sendRequest)="sendRequest()"
  ></layouts-wrapper>
  <layouts-wrapper
    class="tabPublic"
    [menu]="menu"
    [layouts]="publicLayouts"
    [lang]="lang"
    (selectLayout)="selectLayoutEvent.emit($event)"
    (sendRequest)="sendRequest()"
  ></layouts-wrapper>
  <layouts-wrapper
    class="tabSpecial"
    [menu]="menu"
    [layouts]="eventsLayouts"
    (selectLayout)="selectLayoutEvent.emit($event)"
    [lang]="lang"
    (sendRequest)="sendRequest()"
  ></layouts-wrapper>
</categories-tabs>
