<mat-form-field class="ingredients-chip-list" subscriptSizing="dynamic">
  <mat-chip-grid #chipGrid aria-label="Fruit selection">
    @for (ingr of ingrs; track ingr) {
      <mat-chip-option
        #chipRef
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(ingr)"
        (click)="selected(chipRef, ingr)"
        [style.fontStyle]="ingr?.italic ? 'italic' : 'none'"
      >
        {{ ingr?.display || "NONE" }}
        @if (removable) {
          <mat-icon matChipRemove>cancel</mat-icon>
        }
      </mat-chip-option>
    }
    <input
      #ingrInput
      #autoTrigger="matAutocompleteTrigger"
      [formControl]="ingrControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipGrid"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [placeholder]="
        translations
          ? translations['ingredients.add_ingr']
          : ('ingredients.add_ingr' | transloco)
      "
      (matChipInputTokenEnd)="add($event, autoTrigger)"
      (paste)="paste($event)"
    />
  </mat-chip-grid>
  <mat-autocomplete
    #auto="matAutocomplete"
    (closed)="clearIngredientsAuto.emit()"
    (optionSelected)="autocompleteSelected($event)"
  >
    @for (ingr of filteredIngrs; track ingr) {
      <mat-option stop-propagation [value]="ingr">
        {{ ingr?.display || "NONE" }}
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
@if (loading) {
  <mat-progress-bar
    class="progress-bar"
    mode="indeterminate"
  ></mat-progress-bar>
}
