import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoPipe } from '@jsverse/transloco';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { StopPropagationDirective } from 'src/app/shared/Directives/stop-propagation/stop-propagation.directive';
import { Dish } from 'src/app/shared/Models/dish';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { MenuDish } from 'src/app/shared/Models/menudish';

@Component({
  selector: 'frozen-product',
  templateUrl: './frozen-product.component.html',
  styleUrls: ['./frozen-product.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    StopPropagationDirective,
    MatProgressBarModule,
    ReactiveFormsModule,
    TranslocoPipe,
  ],
})
export class FrozenProductComponent implements OnChanges, OnInit {
  @Input() dish: MenuDish;
  @Input() showLabel = true;
  @Input() translations: object = {};

  @Output() changeField = new EventEmitter<{
    menuDish: MenuDish;
    data: DeepPartial<Dish> & { onFulfilled?: () => void };
  }>();

  currentMenuDish: MenuDish;
  form = new FormGroup({
    frozen_product: new FormControl<1 | 2>(null),
    enhancement_declaration: new FormControl<1 | 2>(null),
  });
  loading = {
    frozen_product: false,
    enhancement_declaration: false,
  };
  options = [
    {
      name: 'none',
      value: null,
      symbol: '',
    },
    {
      name: 'frozen',
      value: 1,
      symbol: 'Ë',
    },
    {
      name: 'chilled',
      value: 2,
      symbol: 'Ê',
    },
  ];

  optionsEnhancers = [
    {
      name: 'none',
      value: null,
      symbol: '',
    },
    {
      name: 'hormones',
      value: 1,
      symbol: '',
    },
    {
      name: 'antibiotics',
      value: 2,
      symbol: '',
    },
  ];

  constructor(private destroyRef: DestroyRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      'dish' in changes &&
      this.dish &&
      changes.dish.currentValue.id !== changes.dish.previousValue?.id
    ) {
      Object.keys(this.loading).forEach((key) => {
        this.loading[key] = false;
      });
      this.form.reset(null, { emitEvent: false });
      this.form.patchValue(
        {
          frozen_product: this.dish.dish_detail.user_details.frozen_product,
          enhancement_declaration:
            this.dish.dish_detail.user_details.enhancement_declaration,
        },
        { emitEvent: false },
      );
    }
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        tap(() => {
          this.currentMenuDish = this.dish;
        }),
        distinctUntilChanged(),
        debounceTime(400),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value) => this.onValueChange(value));
  }

  onValueChange(value: typeof this.form.value): void {
    // Set the data to update and set the loading state
    const fieldsToUpdate = ['frozen_product', 'enhancement_declaration'];
    const newData = {};
    fieldsToUpdate.forEach((field) => {
      if (
        value[field] !== this.currentMenuDish.dish_detail.user_details[field]
      ) {
        newData[field] = value[field];
        this.loading[field] = true;
      }
    });

    // If there is no new data, return null
    if (!Object.keys(newData).length) return null;

    // Selectively update the currentMenuDish with the new data
    const newDish = new Dish(this.currentMenuDish.dish_detail);
    newDish.user_details = {
      ...newDish.user_details,
      ...newData,
    };
    this.currentMenuDish = new MenuDish(this.currentMenuDish).setDish(newDish);

    // Emit the changeField event if there is new data
    this.changeField.emit({
      menuDish: this.currentMenuDish,
      data: {
        user_details: newData,
        onFulfilled: () => {
          Object.keys(newData).forEach((key) => {
            this.loading[key] = false;
          });
        },
      },
    });
  }
}
