import { Component, Input } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'no-translations-screen',
  templateUrl: './no-translations-screen.component.html',
  styleUrls: ['./no-translations-screen.component.scss'],
  standalone: true,
  imports: [MatIconModule, TranslocoPipe],
})
export class NoTranslationsScreenComponent {
  @Input() isOtherLang: boolean;
  @Input() selectedQuote: string;
}
