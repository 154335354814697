<mat-card class="options">
  @if (icon) {
    <div class="block">
      <mat-icon class="icon">{{ icon }}</mat-icon>
    </div>
  }
  @if (!hideTitle && (mainTitle || secondTitle)) {
    <div class="description">
      <h3 class="title">
        <span [style.color]="color">{{ mainTitle }}</span>
        {{ title }}
        <span [style.color]="color">{{ secondTitle }}</span>
      </h3>
    </div>
  }
  @if (loading) {
    <div class="spinner-wrapper">
      <spinner class="spinner"></spinner>
    </div>
  } @else {
    <div class="content" [class.translations]="translations">
      <ng-content></ng-content>
    </div>
  }
</mat-card>
