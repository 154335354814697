@if (hasModules("all")) {
  <section class="item">
    @if (
      hasModules("recip") && item?.user_details?.recipes_allergens_discordant
    ) {
      <span class="declaration-warning"></span>
    }
    <div class="item-title">
      {{ "write.dishes.allergens.title" | transloco }}:
    </div>
    <div class="icons">
      @for (allergen of allergens; track allergen) {
        <span>
          <span
            [class.allergen-symbol]="!!allergen.app_symbol"
            [class.allergen-symbol-clear]="allergen?.legend_symbol === 4"
            >{{ allergen.app_symbol || allergen.symbol }}</span
          >
        </span>
      }
      <button mat-icon-button class="add-button" (click)="showAllergens()">
        <mat-icon class="icon add-icon">add_circle</mat-icon>
      </button>
    </div>
    @if ((numberSimilarAllergens$ | async) > 0) {
      <button
        mat-button
        class="similar-options"
        (click)="
          showModal.emit({
            item,
            type: 'allergens',
            contentLang: contentLang,
          })
        "
      >
        {{
          "shared.declarations.similar-products"
            | transloco: { number: (numberSimilarAllergens$ | async) }
        }}
      </button>
    }
    @if (
      hasModules("recip") && item?.user_details?.recipes_allergens_discordant
    ) {
      <div class="discordancy">
        <span class="warning-title"
          >{{ "write.dishes.discordance.warning" | transloco }}:</span
        >&nbsp;
        <span
          class="synchronise-decl"
          [innerHTML]="'write.dishes.discordance.allergens' | transloco"
          (click)="
            synchroniseRecipeDeclarations.emit({ dish: item, type: 'all' })
          "
        ></span>
      </div>
    }
    <option-popover class="option" [(visible)]="allergensView">
      <options
        type="allergens"
        [dish]="item | copyDeep"
        [hasRecipes]="hasRecipes"
        [lang]="lang"
        [recipes]="recipes"
        [allergensView]="allergensView"
        (changed)="changeOption.emit($event)"
        (fetchRecipes)="fetchRecipes.emit()"
        (searchIngredients)="searchIngredients.emit($event)"
        (deleteIngredientEvent)="deleteIngredientEvent.emit($event)"
        (addIngredientEvent)="addIngredientEvent.emit($event)"
        (selectedIngredientEvent)="selectedIngredientEvent.emit($event)"
        (createIngredientEvent)="createIngredientEvent.emit($event)"
        (updateRecipeIngredientEvent)="updateRecipeIngredientEvent.emit($event)"
        (updateIngredientEvent)="updateIngredientEvent.emit($event)"
      ></options>
    </option-popover>
  </section>
}

@if (hasModules("add") && item?.category !== "win") {
  <section class="item">
    @if (
      hasModules("recip") && item?.user_details?.recipes_additives_discordant
    ) {
      <span class="declaration-warning"></span>
    }
    <div class="item-title">
      {{ "write.dishes.additives.title" | transloco }}:
    </div>
    <div class="icons">
      @for (additive of additives; track additive) {
        <span class="additives-symbol">{{
          additive.app_symbol || additive.symbol
        }}</span>
      }
      <button mat-icon-button class="add-button" (click)="showAdditives()">
        <mat-icon class="icon add-icon">add_circle</mat-icon>
      </button>
    </div>
    @if ((numberSimilarAdditives$ | async) > 0) {
      <button
        mat-button
        class="similar-options"
        (click)="
          showModal.emit({ item, type: 'additives', contentLang: contentLang })
        "
      >
        {{
          "shared.declarations.similar-products"
            | transloco: { number: (numberSimilarAdditives$ | async) }
        }}
      </button>
    }
    @if (
      hasModules("recip") && item?.user_details?.recipes_additives_discordant
    ) {
      <div class="discordancy">
        <span class="warning-title"
          >{{ "write.dishes.discordance.warning" | transloco }}:</span
        >&nbsp;
        <span
          class="synchronise-decl"
          [innerHTML]="'write.dishes.discordance.additives' | transloco"
          (click)="
            synchroniseRecipeDeclarations.emit({ dish: item, type: 'add' })
          "
        ></span>
      </div>
    }
    <option-popover class="option" [(visible)]="additivesView">
      <options
        type="additives"
        [dish]="item | copyDeep"
        [lang]="lang"
        [recipes]="recipes"
        [additivesView]="additivesView"
        (changed)="changeOption.emit($event)"
        (searchIngredients)="searchIngredients.emit($event)"
        (deleteIngredientEvent)="deleteIngredientEvent.emit($event)"
        (addIngredientEvent)="addIngredientEvent.emit($event)"
        (selectedIngredientEvent)="selectedIngredientEvent.emit($event)"
        (createIngredientEvent)="createIngredientEvent.emit($event)"
        (updateRecipeIngredientEvent)="updateRecipeIngredientEvent.emit($event)"
        (updateIngredientEvent)="updateIngredientEvent.emit($event)"
      ></options>
    </option-popover>
  </section>
}

@if (hasModules("label")) {
  <section class="item">
    <div class="item-title">{{ "write.dishes.labels.title" | transloco }}:</div>
    <div class="icons">
      @for (label of labels; track label) {
        <span
          [class.allergen-symbol]="!!label.app_symbol"
          [class.allergen-symbol-clear]="label?.legend_symbol === 4"
          >{{ label?.app_symbol || label?.symbol }}</span
        >
      }
      <button mat-icon-button class="add-button" (click)="showLabels()">
        <mat-icon class="icon add-icon">add_circle</mat-icon>
      </button>
    </div>
    <option-popover class="option" [(visible)]="labelsView">
      <options
        [dish]="item | copyDeep"
        [lang]="lang"
        (changed)="changeOption.emit($event)"
        type="labels"
      ></options>
    </option-popover>
  </section>
}
