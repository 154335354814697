import { createSelector } from '@ngrx/store';
import { State } from 'src/app/reducers';

import {
  adapter,
  rulesAdapter,
  sharedFeatureKey,
  userModuleAdapter,
} from './shared.reducer';

const { selectAll } = adapter.getSelectors();

const selectState = (state: State) => state[sharedFeatureKey];

export const selectInterfaceLang = createSelector(
  selectState,
  (state) => state.interface_language,
);

export const selectCurrentLocation = createSelector(
  selectState,
  (state) => state?.current_location,
);

export const selectAllLocations = createSelector(
  selectState,
  (state) => state?.all_locations,
);

export const selectAllLocationGroups = createSelector(
  selectState,
  (state) => state?.all_location_groups,
);

export const selectAllGroupedLocations = createSelector(
  selectState,
  (state) => state?.all_locations_grouped,
);

export const selectSidePanelOpen = createSelector(
  selectState,
  (state) => state?.side_panel_open,
);

export const selectTemplates = createSelector(selectState, (state) =>
  selectAll(state?.templates),
);

export const selectTemplatesLocation = createSelector(
  selectState,
  (state) => state?.templates_location,
);

export const selectCurrentTemplates = createSelector(selectState, (state) =>
  state?.templates_location === null
    ? selectAll(state?.templates)
    : state?.templates_location,
);

export const selectTemplateState = createSelector(
  selectState,
  (state) => state.templates_state,
);

export const selectDefaultTemplates = createSelector(
  selectState,
  (state) => state?.default_templates,
);

export const selectCondensedLayouts = createSelector(
  selectState,
  (state) => state?.condensed_layouts,
);

export const selectOrderTakingLayouts = createSelector(
  selectState,
  (state) => state?.ordertaking_layouts,
);

export const selectUsedTemplates = createSelector(
  selectState,
  (state) => state?.used_templates,
);

export const selectGeneralFormErrors = createSelector(
  selectState,
  (state) => state?.form_errors,
);

export const selectPartner = createSelector(
  selectState,
  (state) => state?.partner,
);

export const selectSharedRules = createSelector(selectState, (state) =>
  rulesAdapter.getSelectors().selectAll(state?.rulesets),
);

export const selectRecipesSidenavTopOffset = createSelector(
  selectState,
  (state) => state?.recipes_sidenav_top_offset,
);

export const selectGlobalSpinnerState = createSelector(
  selectState,
  (state) => state?.show_global_spinner,
);

export const selectMtFonts = createSelector(
  selectState,
  (state) => state?.fonts,
);

export const selectDishesAutocomplete = createSelector(
  selectState,
  (state) => state?.dishes_autocomplete,
);

export const selectSeparatorAutocomplete = createSelector(
  selectState,
  (state) => state?.sections_autocomplete,
);

export const selectIngredientsAutocomplete = createSelector(
  selectState,
  (state) => state?.ingredients_autocomplete,
);

export const selectAllergensDetail = createSelector(
  selectState,
  (state) => state?.allergens,
);

export const selectAddictivesDetail = createSelector(
  selectState,
  (state) => state?.additives,
);

export const selectLabelsDetail = createSelector(
  selectState,
  (state) => state?.labels,
);

export const selectTasks = createSelector(
  selectState,
  (state) => state?.archives,
);

export const selectUserModules = createSelector(selectState, (state) =>
  userModuleAdapter.getSelectors().selectAll(state?.user_modules),
);

export const selectActiveUserModules = createSelector(
  selectUserModules,
  (userModules) => userModules?.filter((um) => um.active),
);

export const selectUserModulesLoaded = createSelector(
  selectState,
  (state) => state?.user_modules_loaded,
);

export const selectHasActiveModule = (props: { code: string }) =>
  createSelector(selectActiveUserModules, (userModules) =>
    userModules?.filter(
      (um) => um.module_detail.code === props.code && um.active,
    ),
  );

export const selectPreviewHtml = createSelector(
  selectState,
  (state) => state?.preview_html,
);

export const selectPreviewPdfHtml = createSelector(
  selectState,
  (state) => state?.preview_pdf_html,
);

export const selectDiets = createSelector(selectState, (state) => state?.diets);

export const selectTypes = createSelector(selectState, (state) => state?.types);

export const selectRecipesAutocomplete = createSelector(
  selectState,
  (state) => state?.recipes,
);

export const selectSimilarDishesAllergens = createSelector(
  selectState,
  (state) => state?.dishes_similar.similar_allergens,
);

export const selectSimilarDishesAdditives = createSelector(
  selectState,
  (state) => state?.dishes_similar.similar_additives,
);

export const selectSimilarDishesAllergensCount = createSelector(
  selectState,
  (state) => state?.dishes_similar.similar_allergens_count,
);

export const selectSimilarDishesAdditivesCount = createSelector(
  selectState,
  (state) => state?.dishes_similar.similar_additives_count,
);

export const selectSimilarDishesAllergensNextPageUrl = createSelector(
  selectState,
  (state) => state?.dishes_similar.similar_allergens_url_next,
);

export const selectSimilarDishesAdditivesNextPageUrl = createSelector(
  selectState,
  (state) => state?.dishes_similar.similar_additives_url_next,
);

export const selectInitialMenuDataLoaded = createSelector(
  selectState,
  (state) => state?.initial_menu_data_loaded,
);

export const selectCollections = createSelector(
  selectState,
  (state) => state?.collections,
);

export const selectLinks = createSelector(selectState, (state) => state?.links);
