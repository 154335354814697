@if (!checkbox) {
  <mat-slide-toggle
    class="field"
    color="primary"
    [mtTooltip]="
      disabledFields.includes(field) || disabled
        ? ('style.sidebar.restrictions.layout-field-locked' | transloco)
        : ''
    "
    [disabled]="!menu?.style || disabledFields.includes(field) || disabled"
    [ngModel]="menu?.style ? (menu?.style)[field] : null"
    (change)="toggleChanged($event, field)"
  >
    <div>{{ "style.sidebar.toggles." + field | transloco }}</div>
  </mat-slide-toggle>
} @else {
  <div
    [mtTooltip]="
      disabledFields.includes(field) || disabled
        ? ('style.sidebar.restrictions.layout-field-locked' | transloco)
        : ''
    "
  >
    <mat-checkbox
      class="field"
      color="primary"
      [disabled]="!menu?.style || disabledFields.includes(field) || disabled"
      [ngModel]="menu?.style ? (menu?.style)[field] : null"
      (change)="toggleChanged($event, field)"
    >
      {{ "style.sidebar.toggles." + field | transloco }}
    </mat-checkbox>
  </div>
}
@if (showLock) {
  <lock-item
    [field]="field"
    [lockedFields]="lockedFields"
    (updateMenu)="updateMenu.emit($event)"
  ></lock-item>
}
