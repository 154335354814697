@if (menu && isMobileView) {
  <button
    mat-flat-button
    color="primary"
    class="add-entity-button mobile-customise-button"
    (click)="showSidePanel()"
  >
    {{ "style.customise" | transloco }}
  </button>
}

@if (menu) {
  <mat-card class="preview">
    <app-preview
      #preview
      [baseLanguage]="menu.base_language"
      [htmlPreview]="
        menu?.style?.print_reverse === false &&
        menu?.style?.print_mirror < 1 &&
        menu?.style?.print_folding === null
      "
      [langs]="langs"
      [nonModal]="true"
      [numberLanguages]="menu?.template_detail?.number_languages"
      [params]="{ print_mode: true }"
      [showRefresh]="true"
      [url]="menu.preview"
    ></app-preview>
  </mat-card>
} @else {
  <div class="spinner-wrapper"><mat-spinner></mat-spinner></div>
}

@if (menu) {
  <select-styles
    [categories]="categoriesStyles$ | async"
    [eventStyles]="eventStyles$ | async"
    [lang]="lang"
    [menu]="menu"
    [publicStyles]="publicStyles$ | async"
    [showStyleSpinner]="showStyleSpinner$ | async"
    [styleModified]="styleModified$ | async"
    [tabIndex]="(styleTab$ | async) ?? 0"
    [userStyles]="userStyles$ | async"
    (createStyle)="createStyle()"
    (deleteStyle)="deleteStyle($event)"
    (openNameDialog)="openNameDialog($event)"
    (renameStyle)="patchStyle($event)"
    (selectStyle)="selectStyle($event)"
    (tabChange)="tabChange($event)"
    (updateStyle)="updateStyle()"
  ></select-styles>
}

@if (menu && menu.rule_detail) {
  <update-rule [menu]="menu" [lang]="lang"></update-rule>
}

@if (menu) {
  <select-layouts
    [categories]="categories$ | async"
    [eventsLayouts]="eventLayouts$ | async"
    [publicLayouts]="publicLayouts$ | async"
    [userLayouts]="userLayouts$ | async"
    [tabIndex]="layoutTab$ | async"
    [menu]="menu"
    [lang]="lang"
    (selectLayoutEvent)="selectLayout($event)"
  >
  </select-layouts>
}

<select-template
  [isTrial]="isTrial"
  [profileComplete]="profileComplete"
  [menu]="menu"
  [onboardingTemplates]="templates$ | async"
  [lang]="lang"
  (createNewTemplateDialog)="createNewTemplateDialog.emit()"
  (deleteTemplate)="deleteTemplate.emit($event)"
  (editTemplateDialog)="editTemplateDialog.emit($event)"
  (linkMenuToTemplate)="linkMenuToTemplate.emit($event)"
  (unlink)="unlink.emit()"
  (updateStyleAndLayout)="updateStyleAndLayout.emit()"
  (updateTemplateDetail)="updateTemplateDetail.emit($event)"
>
</select-template>

<ng-template #sidePanel>
  <div class="side-panel-wrapper">
    <div class="top-right-corner">
      @if (!isMobileView) {
        <button
          mat-flat-button
          class="add-entity-button light"
          (click)="previousStep.emit()"
        >
          <mat-icon aria-hidden="true" class="arrow-icon">arrow_back</mat-icon
          >{{ "shared.buttons.back" | transloco }}
        </button>
      }
      @if (!isMobileView) {
        <button
          mat-flat-button
          color="primary"
          class="add-entity-button"
          (click)="nextStep.emit()"
        >
          {{ "shared.buttons.next" | transloco
          }}<mat-icon aria-hidden="true" class="arrow-icon"
            >arrow_forward</mat-icon
          >
        </button>
      }
    </div>

    @if (isMobileView) {
      <return-side-panel-mobile
        [text]="'style.go-back' | transloco"
        (click)="hideSidePanel()"
      ></return-side-panel-mobile>
    }

    <app-style-sidebar
      [bgImages]="backgroundImages | slice: 0 : 3"
      [courses]="courses$ | async"
      [lang]="lang"
      [lockedFields]="lockedFields"
      [menu]="menu"
      [mtFonts]="mtFonts$ | async"
      [options]="options$ | async"
      [styleModified]="styleModified$ | async"
      [user]="user$ | async"
      (showAllBackgrounds)="showAllBackgrounds($event)"
      (updateMenu)="updateMenu.emit($event)"
      (uploadBackgroundImage)="uploadBackgroundImage($event)"
      (uploadLogo)="uploadLogo($event)"
    ></app-style-sidebar>
  </div>
</ng-template>
