import { Action, createReducer, on } from '@ngrx/store';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { BaseNameExtended } from 'src/app/shared/Models/models';
import { Translation } from 'src/app/shared/Models/translation';

import * as MenuActions from './menu-translate.actions';

export const menuTranslateFeatureKey = 'menu_translate';

export interface MenuTranslateState {
  menu_translation_loading: boolean;
  list: Translation[];
  current: MenuDish;
  current_translation_loading: boolean;
  similar: BaseNameExtended[];
  similar_count: number;
  similar_next_url: string;
}

const initialState: MenuTranslateState = {
  menu_translation_loading: false,
  list: [],
  current: null,
  current_translation_loading: false,
  similar: [],
  similar_count: 0,
  similar_next_url: null,
};

const _menuTranslateReducer = createReducer(
  initialState,
  on(MenuActions.clearCurrentMenuTranslations, () => {
    return {
      ...initialState,
    };
  }),
  on(MenuActions.setMenuTranslationLoading, (state, { loading }) => {
    return {
      ...state,
      menu_translation_loading: loading,
    };
  }),
  on(MenuActions.setMenuTranslations, (state, { translations }) => {
    return {
      ...state,
      list: translations,
    };
  }),
  on(MenuActions.addTranslationForLanguage, (state, { translation }) => {
    return {
      ...state,
      list: [...state.list, translation],
    };
  }),
  on(MenuActions.removeMenuTranslation, (state, { url }) => {
    return {
      ...state,
      list: state.list.filter((translation) => translation.url !== url),
    };
  }),
  on(MenuActions.setCurrentTranslations, (state, { menuDish }) => {
    return {
      ...state,
      current: menuDish,
    };
  }),
  on(MenuActions.setCurrentTranslationLoading, (state, { loading }) => {
    return {
      ...state,
      current_translation_loading: loading,
    };
  }),
  on(MenuActions.setSimilar, (state, { result }) => {
    return {
      ...state,
      similar: result.results,
      similar_next_url: result.next,
      similar_count: result.count,
    };
  }),
  on(MenuActions.setMoreSimilar, (state, { result }) => {
    return {
      ...state,
      similar: state.similar.concat(result.results),
      similar_next_url: result.next,
    };
  }),
);

export function menuTranslateReducer(
  state: MenuTranslateState,
  action: Action,
) {
  return _menuTranslateReducer(state, action);
}
